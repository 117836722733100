import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chartjs-plugin-datalabels';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import {
    isMobile
} from "react-device-detect";

import {
    FaPlus,
    FaClock,
    FaPen,
    FaSave,
    FaTimes,
    FaTrash,
    FaCaretUp,
    FaRegClone,
    FaExternalLinkAlt
} from "react-icons/fa";
import EditorPageWrapper from "../editor/EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import 'moment/locale/id'

import Event, {EVENT_STATUS, PAYMENT_TYPE, PUBLISH_STATUS} from "../../../models/Event"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "react-bootstrap/Card";
import Palette from "../../../util/Palette";
import LineChart from "../../reusable/Charts/LineChart";
import Divider from "@material-ui/core/Divider";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CustomButton from "../../reusable/CustomButton";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import ImageThumbnail from "../../reusable/ImageThumbnail";
import {Dialog, DialogContent, DialogTitle, withStyles} from "@material-ui/core";
import moment from "moment";
import GlobalData from "../../../util/GlobalData";
import PieChart from "../../reusable/Charts/PieChart";
import Class from "../../../models/Class";
import {FaFileDownload, FaMoneyBillWave, FaUserFriends, FiAlertTriangle} from "react-icons/all";
import {Bar, HorizontalBar, Line} from "react-chartjs-2";
import Dashboard from "../../../models/Dashboard";
import MobTable from "../../reusable/Table/MobTable";
import textFormatter from "../../../util/textFormatter";
import ReactPaginate from 'react-paginate';
import QuestionModel from "../../../models/Question";
import EditQuestionModal from "../../reusable/EditQuestionModal";
import {CSVLink} from "react-csv";
import ContactForm from "../../../models/ContactForm";
import PresenceForm from "../../../models/PresenceForm";
import ApiRequest from "../../../util/ApiRequest";
import UserListModal from "../../reusable/modals/UserListModal";


let anchor
let popperFadeTimer;

let currentDate = new moment();
currentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

let dates = []
dates.push(currentDate.format("D MMMM"))

let formattedCurrentDate = currentDate.format("D MMMM")

for (let i = 0; i < 6; i++) {
    currentDate.subtract(1, "days")
    dates.push(currentDate.format("D MMMM"))
}


dates.reverse()

export default function EventDetail(props) {
    const [allFeedback, setAllFeedback] = useState([]);
    const [allQuestions, setAllQuestions] = useState([]);

    const downloadFeedbackBtnRef = useRef(null);
    const downloadQuestionsBtnRef = useRef(null);

    const [isFeedbackDataReady, setFeedbackDataReady] = useState(false);

    let questionModel = new QuestionModel()
    let history = useHistory()
    const {event_url} = useParams()

    const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState({})
    const [isDeleting, setDeleting] = useState(false);
    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isPopperVisible, setIsPopperVisible] = useState(false)

    const [waitingCount, setIsWaitingCount] = useState(10)
    const [paymentType, setPaymentType] = useState("FREE")
    const [eventStatus, setEventStatus] = useState("FINISHED")

    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeEventURL, setActiveEventURL] = useState(event_url)
    const [feedbackCurrentPage, setFeedbackCurrentPage] = useState(0);
    const [feedbackTotalPages, setFeedbackTotalPages] = useState(1);

    const [questionCurrentPage, setQuestionCurrentPage] = useState(0);
    const [questionTotalPages, setQuestionTotalPages] = useState(1);

    const [pastClassPage, setPastClassPage] = useState(0)
    const [upcomingClassPage, setUpcomingClassPage] = useState(0)
    const [showClassInUserList, setShowClassInUserList] = useState(false);

    const popPopper = () => {
        setIsPopperVisible(true)
        clearTimeout(popperFadeTimer)
        popperFadeTimer = setTimeout(() => {
            setIsPopperVisible(false)
        }, 1500)
    }

    const getFilteredUserByCity = async (city) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredUserByCity(city)
            console.log('ganteng', users)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }

    const getFilteredUserByGender = async (gender) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredUserByGender(gender)
            console.log(users)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }

    const getFilteredUserByRegisterDate = async (registerDate) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredUserByRegisterDate(registerDate)
            console.log(users)
            setShowClassInUserList(true)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }

    const getFilteredCourseUserByRegisterDate = async (startDate, endDate) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredCourseUserByRegisterDateRange(startDate, endDate)
            console.log(users)
            setShowClassInUserList(true)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }

    const getWeeklyRegistrationData = async () => {

        try {
            const dashboardModel = new Dashboard();
            const weeklyData = await dashboardModel.getAllWeeklyData();

            console.log("weekly_data", weeklyData)

            const kdmRegistrationLabel = [];
            const kdmRegistrationValue = [];
            const kdmRegistrationColor = [];
            const startDate = []

            const params = [];

            weeklyData.map((member, idx) => {
                if (startDate.length !== 0 && moment(member.sample_date).isSameOrAfter(moment(startDate[idx - 1]), 'day') && moment(member.sample_date).isSameOrBefore(moment(startDate[idx - 1]).add(6, 'day'))) {
                    // kdmRegistrationValue
                    // kdmRegistrationValue[kdmRegistrationValue.length - 1] += member.registration_count
                } else {

                    let prev = kdmRegistrationLabel.findIndex(obj => obj === moment(member.max_date).format('DD MMM YY') + "-" + moment(member.max_date).add(6, 'days').format('DD MMM YY'))
                    console.log("prev_", prev)
                    if(prev !== -1){
                        return
                    }

                    params.push({
                        startDate: moment(member.max_date).format('YYYY-MM-DD'),
                        endDate: moment(member.max_date).add(6, 'days').format('YYYY-MM-DD')
                    })

                    kdmRegistrationLabel.push(moment(member.max_date).format('DD MMM YY') + "-" + moment(member.max_date).add(6, 'days').format('DD MMM YY'))
                    kdmRegistrationValue.push(member.registration_count);

                    kdmRegistrationColor.push('#4673c8')
                }

                startDate.push(member.max_date)

            })

            setKdmRegistrationGraph({
                label: kdmRegistrationLabel,
                value: kdmRegistrationValue,
                bgColor: kdmRegistrationColor
            })

        } catch (e) {
            console.log(e)
        }

    }

    const getFilteredUserBySinaraRegisterDate = async (registerDate) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredUserBySinaraRegisterDate(registerDate)
            console.log(users)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }

    const getFilteredUserByAge = async (minAge, maxAge) => {
        let dashboardModel = new Dashboard()
        try {
            let users = await dashboardModel.getFilteredUserByAge(minAge, maxAge)
            console.log("bruh", users)
            setSelectedUsers(users)
        } catch (e) {
            console.log(e)
        }
    }


    //Page Params
    const [currentEvent, setCurrentEvent] = useState({
        name: event_url ? event_url : "",
        iconUrl: "",
        shortDescription: "",
        eventRoute: "",
        additionalInformation: {
            registrationFormIntroduction: "",
        },
        metrics: {}

    })
    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker: "",
        quota: -1,
        price: 0,
        requirePayment: false,
        description: "",
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        selectedPaymentPreset: 0,
        additionalInformation: {}
    })

    const [eventMetrics, setEventMetrics] = useState({
        categorizedRegistrantCount: {}
    })

    const [registrationFields, setRegistrationFields] = useState([])

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [upcomingClass, setUpcomingClass] = useState([])
    const [pastClass, setPastClass] = useState([])

    const routerLocation = useLocation()

    const [registrationGraph, setRegistrationGraph] = useState({});
    const [kdmRegistrationGraph, setKdmRegistrationGraph] = useState({});
    const [memberRegistrationGraph, setMemberRegistrationGraph] = useState({});
    const [top5CitiesGraph, setTop5CitiesGraph] = useState({});
    const [ageDemographicData, setAgeDemographicData] = useState([]);
    const [genderDemographicData, setGenderDemographicData] = useState([])
    const [participantFeedbacks, setParticipantFeedbacks] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([])

    const feedbacksColumns = useMemo(
        () => [
            {
                Header: "Kelas",
                accessor: "class.name",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values
                    let className = rowInfo["class.name"]

                    return <>
                        {className}
                    </>
                })
            },
            {
                Header: "Nama Lengkap",
                accessor: "member.full_name",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values
                    let memberFullName = rowInfo["member.full_name"]

                    return <>
                        {memberFullName}
                    </>
                })
            },
            {
                Header: "Surel",
                accessor: "member.email",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values
                    let memberEmail = rowInfo["member.email"]

                    return <>
                        {memberEmail}
                    </>
                })
            },
            {
                Header: "Masukan",
                accessor: "masukan",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values
                    let className = rowInfo["masukan"]

                    return <>
                        {className}
                    </>
                })
            },
            {
                Header: "Waktu Diisi",
                accessor: "created_at",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values

                    return <>
                        {moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm")}
                    </>
                })
            },
        ]
    )

    const interactions = [
        {
            name: "Hapus",
            action: (question) => {
                setSelectedQuestion(question)
                setDeleting(true);
            },
            style: {width: 100},
            variant: 'secondary',
            condition: (question) => {
                return question.active
            }
        },
        {
            name: "Pulihkan",
            action: (quiz) => {
                toggleQuestion(quiz.id);
            },
            variant: 'info',
            style: {width: 100},
            condition: (quiz) => {
                return !quiz.active
            },
        },
        {
            name: "Jawab",
            action: (quiz) => {
                setSelectedQuestion(quiz)
                setIsAnswerModalOpen(true)
            },
            variant: 'primary',
            style: {width: 100},
            condition: (quiz) => {
                return quiz.active
            },
        }
    ];

    const toggleQuestion = async (id) => {
        try {
            let result = await questionModel.toggle(id)

            if (result.success) {
                setDeleting(false)
                getDashboardQuestion(questionCurrentPage + 1)

                if (isDeleting) alert('Pertanyaan berhasil terhapus!');
                else alert('Pertanyaan berhasil dipulihkan');
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    const [questions, setQuestions] = useState([]);
    const questionsColumns = useMemo(
        () => [
            {
                Header: "Kelas",
                accessor: "class.name",
                Cell: (cellInfo => {
                    // console.log(cellInfo.row.values)
                    let rowInfo = cellInfo.row.values
                    let className = rowInfo["class.name"]

                    return <>
                        {className}
                    </>
                })
            },
            {
                Header: "Pertanyaan",
                accessor: "question",
            },
            {
                Header: "Jawaban",
                accessor: "answer",
            },
            {
                Header: "Penanya",
                accessor: "full_name",
            },
            {
                Header: "Ditanya pada",
                accessor: "asked_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {moment(rowInfo.asked_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
            {
                Header: "Dijawab pada",
                accessor: "answered_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    if (!rowInfo.answered_at) {
                        return <>-</>
                    }

                    return <>
                        {moment(rowInfo.answered_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
        ]
    )

    const getAllFeedback = async () => {
        const contactFormModel = new ContactForm();
        try {
            const result = await contactFormModel.getAll();

            console.log('getAll contact result', result)
        } catch (e) {
            console.log('getAll contact err', e)
        }

        const dashboardModel = new Dashboard();

        try {
            const feedbacks = await dashboardModel.getFeedbacks(null);

            const allFeedbackTemp = [["Kelas", "ID Peserta", "ID Anggota", "Nama Lengkap", "Surel", "Masukan", "Waktu Diisi"]];

            feedbacks.participantFeedbacks.map((obj, key) => {
                allFeedbackTemp.push([obj.class.name, obj.participant_id, obj.member.id, obj.member.full_name, obj.member.email, obj.masukan, obj.created_at])
            })

            console.log('allFeedbackTemp', allFeedbackTemp)

            setAllFeedback(allFeedbackTemp)
        } catch (e) {
            console.log('e', e)
        }
    }

    const getAllQuestions = async () => {
        const dashboardModel = new Dashboard();

        try {
            const questions = await dashboardModel.getQuestions(null);

            const allQuestionsTemp = [["Kelas", "ID Peserta", "ID Anggota", "Penanya", "Pertanyaan", "Jawaban", "Ditanya pada", "Dijawab pada"]];

            questions.questions.map((obj, key) => {
                allQuestionsTemp.push([obj.class.name, obj.participant_id, obj.member_id, obj.full_name, obj.question, obj.answer ? obj.answer : '', obj.asked_at, obj.answered_at ? obj.answered_at : ''])
            })

            console.log('allQuestionsTemp', allQuestionsTemp)

            setAllQuestions(allQuestionsTemp)
        } catch (e) {
            console.log('e', e)
        }
    }

    useEffect(() => {
        getAllFeedback();
        getAllQuestions()
        getWeeklyRegistrationData()
    }, [])

    useEffect(() => {
        getDashboardFeedback(feedbackCurrentPage + 1)
    }, [feedbackCurrentPage])

    useEffect(() => {
        getDashboardQuestion(questionCurrentPage + 1)
    }, [questionCurrentPage])

    const getDashboardFeedback = async (page) => {
        const dashboardModel = new Dashboard();

        const feedbacks = await dashboardModel.getFeedbacks(page);

        setFeedbackTotalPages(feedbacks.totalPages)

        console.log('feedbacks', feedbacks)

        setParticipantFeedbacks(feedbacks.participantFeedbacks)
    }

    const getDashboardQuestion = async (page) => {
        const dashboardModel = new Dashboard();

        const questions = await dashboardModel.getQuestions(page);


        console.log('questions', questions)

        setQuestions(questions.questions)
        setQuestionTotalPages(questions.totalPages);

        // set(feedbacks.participantFeedbacks)
    }

    useEffect(() => {
        scroll.scrollTo(0, {
            smooth: false,
            duration: 0
        })

        getAllClass()
        getData()
    }, [routerLocation.pathname])

    const getData = async () => {
        console.log('getting data')

        try {
            const dashboardModel = new Dashboard();
            const data = await dashboardModel.getData();

            console.log('adat', data)

            getDashboardFeedback(1)
            getDashboardQuestion(1)

            const registrationLabel = [];
            const registrationValue = [];
            const registrationColor = [];

            const tempGenderData = []

            for (let g of ["M", "F"]) {
                for (let mgd of data.memberGenderData) {
                    if (mgd.gender === g) {
                        tempGenderData.push(mgd.count)
                    }
                }
            }

            setGenderDemographicData(tempGenderData)

            console.log('data.memberLoginData', data.memberLoginData)

            const memberLoginData = [];

            data.memberLoginData.forEach(datum => {
                let idx = -1;

                memberLoginData.forEach((datum2, idx2) => {
                    if (datum.register_date === datum2.register_date) {
                        idx = idx2;
                    }
                })

                if (idx === -1) {
                    memberLoginData.push(datum)
                } else {
                    memberLoginData[idx].register_count += datum.register_count
                }
            })

            data.memberLoginData = memberLoginData;

            const secondRegistrationLabel = [];
            const secondRegistrationValue = [];
            const secondRegistrationColor = [];

            console.log("hoihoi", data.memberUserRegisterData)

            data.memberUserRegisterData.map((member, idx) => {


                secondRegistrationLabel.push(moment(member.register_date).format('DD-MMM-YY'))


                secondRegistrationValue.push(member.register_count);

                console.log("hoi", moment(member.register_date).format('DD-MMM-YY'), moment().format('DD-MMM-YY'))
                if (moment(member.register_date).format('DD-MMM-YY') === moment().format('DD-MMM-YY')) {
                    secondRegistrationColor.push("#f58b1e")
                } else {
                    secondRegistrationColor.push('#f58b1e')
                }

                if (idx === data.memberUserRegisterData.length - 1) {

                    console.log("reg value 1hoi", secondRegistrationValue)

                    setMemberRegistrationGraph({
                        label: secondRegistrationLabel,
                        value: secondRegistrationValue,
                        bgColor: secondRegistrationColor
                    })
                }
            })

            function compare(a, b) {
                if (a.date_diff < b.date_diff) {
                    return -1;
                }
                if (a.date_diff > b.date_diff) {
                    return 1;
                }
                return 0;
            }


            data.memberLoginData.sort(compare).map((member, idx) => {
                console.log('pushpush', moment(member.register_date).format('DD-MMM-YY'))

                registrationLabel.push(moment(member.register_date).format('DD-MMM-YY'))
                registrationValue.push(member.register_count);

                if (moment(member.register_date).format('DD-MMM-YY') === moment().format('DD-MMM-YY')) {
                    registrationColor.push('#4673c8')
                } else {
                    registrationColor.push('#b0aaaa')
                }


                if (idx === data.memberLoginData.length - 1) {

                    console.log("reg value", registrationValue)

                    setRegistrationGraph({
                        label: registrationLabel,
                        value: registrationValue,
                        bgColor: registrationColor
                    })

                }
            })

            const citiesLabel = [];
            const citiesValue = [];
            const citiesColor = [];

            data.memberCityData.map((city, idx) => {
                if (idx < 5) {
                    citiesLabel.push(city.city);
                    citiesValue.push(city['count(id)'])
                    citiesColor.push('#4673c8')
                }

                if (idx === 4) {
                    setTop5CitiesGraph({
                        label: citiesLabel,
                        value: citiesValue,
                        bgColor: citiesColor
                    })
                }
            })

            const ageDemographicDataTemp = [];
            console.log("agetem", data.memberAgeData)
            if (data.memberAgeData[0]) {
                ageDemographicDataTemp.push(data.memberAgeData[0].below_17);
                ageDemographicDataTemp.push(data.memberAgeData[0].between_18_24);
                ageDemographicDataTemp.push(data.memberAgeData[0].between_25_39);
                ageDemographicDataTemp.push(data.memberAgeData[0].more_40);
            }


            console.log("agetemp", ageDemographicDataTemp)
            setAgeDemographicData(ageDemographicDataTemp)

        } catch (e) {
            console.log(e)
        }
    }

    const getAllClass = async () => {

        setIsLoading(true)

        try {
            const classModel = new Class()
            const classList = await classModel.getAll();

            const upcomingNoDeleted = []
            const pastNoDeleted = []

            console.log(classList)

            for (let classObj of classList) {


                if (new Date(classObj.start_time) > new Date()) {
                    if (classObj.active) {
                        upcomingNoDeleted.push(classObj)
                    }
                } else {
                    if (classObj.active) {
                        pastNoDeleted.push(classObj)
                    }
                }

            }

            let upcomingNDReversed = upcomingNoDeleted.reverse()

            // setUpcomingClass(pastNoDeleted)
            // setUpcomingClass(pastNoDeleted)
            console.log(upcomingNDReversed)
            setUpcomingClass(upcomingNDReversed)
            setPastClass(pastNoDeleted)

            setIsLoading(false)

        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }

    }

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -150;
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    const renderContent = () => {
        return <>
            <Dialog open={isDeleting}
                    maxWidth="sm">
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Pertanyaan
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus pertanyaan "<span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedQuestion.question}</span>"?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setDeleting(false)
                                }}
                                style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => toggleQuestion(selectedQuestion.id)}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditQuestionModal
                visible={isAnswerModalOpen}
                hide={() => setIsAnswerModalOpen(false)}
                selectedQuestion={selectedQuestion}
                onSubmit={async () => {
                    getDashboardQuestion(questionCurrentPage + 1)
                }}
            />

            <Container fluid style={{
                minHeight: "100vh"
            }}>

                <Row style={{marginTop: "1em"}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Kelas Mendatang
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>

                                    <Container fluid>
                                        <Row>
                                            <Col md={12} style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                paddingBottom: 20,
                                            }}>
                                                {
                                                    Math.floor(upcomingClass.length / 3) > 0 &&
                                                    <ButtonGroup>
                                                        <Button
                                                            size={"sm"}
                                                            onClick={() => {
                                                                setUpcomingClassPage(Math.max(0, upcomingClassPage - 1))
                                                            }}
                                                            variant={"outline-primary"}>
                                                            {"<"} Sebelumnya
                                                        </Button>
                                                        <Button
                                                            size={"sm"}
                                                            variant={"outline-primary"}>
                                                            {upcomingClassPage + 1}/{Math.ceil(upcomingClass.length / 3)}
                                                        </Button>
                                                        <Button
                                                            size={"sm"}
                                                            onClick={() => {
                                                                setUpcomingClassPage(Math.min(Math.ceil(upcomingClass.length / 3) - 1, upcomingClassPage + 1))
                                                            }}
                                                            variant={"outline-primary"}>
                                                            Selanjutnya {">"}
                                                        </Button>
                                                    </ButtonGroup>
                                                }
                                            </Col>
                                        </Row>

                                        <Row>
                                            {
                                                upcomingClass.slice(upcomingClassPage * 3, upcomingClassPage * 3 + 3).map((obj, key) => {
                                                    return <Col md={4}>
                                                        <b>{obj.name}</b><br/>
                                                        {new moment(obj.start_time).format("D/MM/YYYY HH:mm")}
                                                        <br/>
                                                        <div>

                                                            <FaUserFriends/> {obj.metrics?.accepted}

                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            // justifyContent : "flex-end",
                                                            marginTop: 10
                                                        }}>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/classDetail/${obj.code}`)}
                                                                variant={"outlined"}>Kelola</CustomButton>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/visitors/${obj.code}`)}
                                                                variant={"outlined"} style={{marginLeft: 10}}>
                                                                Peserta</CustomButton>
                                                        </div>
                                                        {!obj.stream_url ?
                                                            <Link
                                                                scroll={(el) => {
                                                                    scrollWithOffset(el)
                                                                }}
                                                                to={`/editor/classDetail/${obj.code}#tautan_streaming`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    URL Stream belum diunggah
                                                                </div>
                                                            </Link>
                                                            : null}

                                                        {(!obj.attachment_url && !(obj.attachment_urls && obj.attachment_urls.length > 0)) ?
                                                            <Link
                                                                scroll={(el) => {
                                                                    scrollWithOffset(el)
                                                                }}
                                                                to={`/editor/classDetail/${obj.code}#materi`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    Materi kelas belum diunggah
                                                                </div>
                                                            </Link>

                                                            : null}

                                                        {!obj.quiz_question_id ?

                                                            <Link
                                                                to={`/editor/quiz/${obj.code}`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    Tes akhir belum dibuat
                                                                </div>
                                                            </Link>

                                                            : null}


                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Container>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginTop: "1em"}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Kelas Sebelumnya
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>

                                    <Container fluid>
                                        <Row>
                                            <Col md={12} style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                paddingBottom: 20,
                                            }}>
                                                {
                                                    pastClass.length / 3 > 0 &&
                                                    <ButtonGroup>
                                                        <Button
                                                            size={"sm"}
                                                            onClick={() => {
                                                                setPastClassPage(Math.max(0, pastClassPage - 1))
                                                            }}
                                                            variant={"outline-primary"}>
                                                            {"<"} Sebelumnya
                                                        </Button>
                                                        <Button
                                                            size={"sm"}
                                                            variant={"outline-primary"}>
                                                            {pastClassPage + 1}/{Math.ceil(pastClass.length / 3)}
                                                        </Button>
                                                        <Button
                                                            size={"sm"}
                                                            onClick={() => {
                                                                setPastClassPage(Math.min(Math.ceil(pastClass.length / 3) - 1, pastClassPage + 1))
                                                            }}
                                                            variant={"outline-primary"}>
                                                            Selanjutnya {">"}
                                                        </Button>
                                                    </ButtonGroup>
                                                }


                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                pastClass.slice(pastClassPage * 3, pastClassPage * 3 + 3).map((obj, key) => {
                                                    // if (key > 2) return
                                                    // console.log(obj)
                                                    return <Col md={4}>
                                                        <b>{obj.name}</b><br/>
                                                        {new moment(obj.start_time).format("DD/MM/YYYY HH:mm")}
                                                        <br/>
                                                        <div>
                                                            <FaUserFriends/> {obj.metrics?.accepted}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            // justifyContent : "flex-end",
                                                            marginTop: 10
                                                        }}>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/classDetail/${obj.code}`)}
                                                                variant={"outlined"}>Kelola</CustomButton>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/visitors/${obj.code}`)}
                                                                variant={"outlined"} style={{marginLeft: 10}}>
                                                                Peserta</CustomButton>
                                                        </div>
                                                        {!obj.recording_url_array && obj.recording_url_array !== "null" ?
                                                            <Link
                                                                scroll={(el) => {
                                                                    scrollWithOffset(el)
                                                                }}
                                                                to={`/editor/classDetail/${obj.code}#tautan_rekaman`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    <div style={{flex: 1}}>
                                                                        Tautan Rekaman belum tersedia
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            : null}
                                                        {!obj.show_answers_to_user ?
                                                            <Link
                                                                scroll={(el) => {
                                                                    scrollWithOffset(el)
                                                                }}
                                                                to={`/editor/question/${obj.code}`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    <div style={{flex: 1}}>
                                                                        Pertanyaan dan Jawaban belum di tampilkan
                                                                    </div>
                                                                </div>
                                                            </Link>

                                                            : null}


                                                        {obj.certificate_count === 0 ?
                                                            <Link
                                                                scroll={(el) => {
                                                                    scrollWithOffset(el)
                                                                }}
                                                                to={`/editor/certificate/${obj.code}`}>
                                                                <div style={{
                                                                    color: "#ed5858",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    marginTop: "0.5em"
                                                                }}>
                                                                    <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                    <div style={{flex: 1}}>
                                                                        Sertifikat belum dikirim
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            : null}

                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Container>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{marginBottom: 20, marginTop: 20}}>
                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em",
                            paddingBottom: 20
                        }}>
                            <Card.Body className="pb-0">
                                <div style={{marginBottom: 20}}>
                                    5 Besar Kota Asal Pendaftar
                                </div>

                                <Bar
                                    onElementsClick={elem => {
                                        console.log(top5CitiesGraph)
                                        if (elem && elem[0]) {
                                            console.log(elem[0]._index)
                                            getFilteredUserByCity(top5CitiesGraph.label[elem[0]._index])
                                        } else {
                                            console.log("hei", elem)
                                        }
                                    }}
                                    data={{
                                        labels: top5CitiesGraph.label,
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: top5CitiesGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: top5CitiesGraph.value
                                            }
                                        ]
                                    }}
                                    height={"100px"}
                                    options={{
                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'white'
                                            }
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.0)",
                                                    },
                                                    ticks: {
                                                        fontColor: "black"
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.1)",
                                                    },
                                                    ticks: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        fontColor: "black"
                                                    },
                                                }
                                            ]
                                        },
                                        legend: {
                                            display: false
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return tooltipItem.yLabel;
                                                }
                                            }
                                        },
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={12} style={{marginBottom: 20}}>
                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em",
                            paddingBottom: 20
                        }}
                        >
                            <Card.Body className="pb-0">
                                <div style={{marginBottom: 20}}>
                                    Pendaftar per Hari
                                </div>

                                <Bar
                                    getElementAtEvent={(elem) => {
                                        const clickedIdx = elem[0]._datasetIndex;
                                        let date = new moment(elem[0]._model.label, "DD-MMM-YYYY").format('YYYY-MM-DD')
                                        console.log(date)

                                        if (clickedIdx === 0) {
                                            getFilteredUserByRegisterDate(date)
                                        } else if (clickedIdx === 1) {
                                            getFilteredUserBySinaraRegisterDate(date)
                                        }
                                    }}
                                    data={{
                                        labels: registrationGraph.label,
                                        datasets: [
                                            {
                                                label: 'Registrasi Kelas',
                                                backgroundColor: registrationGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: registrationGraph.value
                                            },
                                            {
                                                label: 'Registrasi Peserta',
                                                backgroundColor: memberRegistrationGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: memberRegistrationGraph.value
                                            }
                                        ]
                                    }}
                                    height={"100px"}
                                    options={{
                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'white'
                                            }
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.0)",
                                                    },
                                                    ticks: {
                                                        fontColor: "black"
                                                    },
                                                }
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.1)",
                                                    },
                                                    ticks: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        fontColor: "black"
                                                    },
                                                }
                                            ]
                                        },
                                        legend: {
                                            display: false
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem, obj) {
                                                    let index = tooltipItem.datasetIndex
                                                    let label = obj.datasets[index].label
                                                    return label + ": " + tooltipItem.yLabel

                                                }
                                            }
                                        }
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={12} style={{marginBottom: 20}}>
                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em",
                            paddingBottom: 20
                        }}
                        >
                            <Card.Body className="pb-0">
                                <div style={{marginBottom: 20}}>
                                    Pendaftar KDM
                                </div>

                                <Bar
                                    getElementAtEvent={(elem) => {
                                        const clickedIdx = elem[0]._datasetIndex;

                                        console.log("ELEM", elem[0])

                                        let dateParts = elem[0]._model.label.split("-")

                                        let date1 = new moment(dateParts[0], "DD-MMM-YYYY").format('YYYY-MM-DD')
                                        let date2 = new moment(dateParts[1], "DD-MMM-YYYY").format('YYYY-MM-DD')

                                        getFilteredCourseUserByRegisterDate(date1, date2)
                                    }}
                                    data={{
                                        labels: kdmRegistrationGraph.label,
                                        datasets: [
                                            {
                                                label: 'Registrasi KDM',
                                                backgroundColor: kdmRegistrationGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: kdmRegistrationGraph.value
                                            }
                                        ]
                                    }}
                                    height={"100px"}
                                    options={{
                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'white'
                                            }
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.0)",
                                                    },
                                                    ticks: {
                                                        fontColor: "black"
                                                    },
                                                }
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.1)",
                                                    },
                                                    ticks: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        fontColor: "black"
                                                    },
                                                }
                                            ]
                                        },
                                        legend: {
                                            display: false
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem, obj) {
                                                    let index = tooltipItem.datasetIndex
                                                    let label = obj.datasets[index].label
                                                    return label + ": " + tooltipItem.yLabel

                                                }
                                            }
                                        }
                                    }}
                                />

                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Row style={{marginBottom: 20}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Sebaran Peserta
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>
                                </div>

                                <Container>
                                    <Row>
                                        <Col md={6} style={{textAlign: "right",}}>
                                            <PieChart
                                                backgroundColor={Palette.SECONDARY}
                                                onElementsClick={elem => {
                                                    if (elem && elem[0]) {
                                                        if (elem[0]._model.label === "Wanita") {
                                                            getFilteredUserByGender("F")
                                                        } else {
                                                            getFilteredUserByGender("M")
                                                        }
                                                    }
                                                    // console.log(elem[0])
                                                    // console.log("this el is click")
                                                }}
                                                options={{
                                                    onClick: () => {
                                                        console.log("ON KIRIKU")
                                                    }
                                                }}
                                                variables={["Pendaftaran Terkonfirmasi",
                                                    // "Pendaftaran", "Dilihat"
                                                ]}
                                                labels={
                                                    [
                                                        "Pria", "Wanita"
                                                    ]
                                                }
                                                data={[
                                                    ...genderDemographicData
                                                ]}
                                            />
                                            {/*Jenis Kelamin*/}
                                        </Col>
                                        <Col md={6} style={{textAlign: "right"}}>

                                            <PieChart
                                                onElementsClick={elem => {
                                                    if (elem && elem[0]) {
                                                        switch (elem[0]._index) {
                                                            case 0 :
                                                                getFilteredUserByAge(0, 17)
                                                                break;
                                                            case 1 :
                                                                getFilteredUserByAge(18, 24)
                                                                break;
                                                            case 2 :
                                                                getFilteredUserByAge(25, 39)
                                                                break;
                                                            case 3 :
                                                                getFilteredUserByAge(40, 200)
                                                                break;
                                                            default :
                                                                getFilteredUserByAge(0, 200)
                                                                break;

                                                        }

                                                        // getFilteredUserByAge()
                                                    }

                                                }}
                                                backgroundColor={Palette.SECONDARY}
                                                variables={["Pendaftaran Terkonfirmasi",
                                                    // "Pendaftaran", "Dilihat"
                                                ]}
                                                labels={
                                                    // eventMetrics.viewCount ? eventMetrics.viewCount.labels : []
                                                    [
                                                        "~17", "18-24", "25-39", "40~"
                                                    ]
                                                }
                                                data={ageDemographicData}
                                            />
                                            {/*Umur*/}
                                        </Col>
                                    </Row>
                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginBottom: 20}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "row",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                        flex: 1
                                    }}>
                                        Masukan
                                    </span>

                                    {allFeedback.length !== 0
                                        &&
                                        <CSVLink
                                            ref={downloadFeedbackBtnRef}
                                            style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                                            separator={";"}
                                            data={allFeedback}
                                            filename={`daftar-masukan.csv`}
                                            className="btn btn-primary"
                                            target="_blank"
                                        >
                                            Download Semua Data&nbsp;&nbsp;<FaFileDownload/>
                                        </CSVLink>
                                    }
                                </div>

                                <Container fluid>
                                    <Row>
                                        <Col style={{textAlign: "left",}}>
                                            <MobTable columns={feedbacksColumns}
                                                      data={participantFeedbacks}
                                                      hidePagination={true}/>
                                        </Col>
                                    </Row>

                                    <Row style={{paddingLeft: '45%', marginTop: 15}}>
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            pageCount={feedbackTotalPages}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            forcePage={feedbackCurrentPage}
                                            initialPage={feedbackCurrentPage}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            onPageChange={(page) => {
                                                console.log(page.selected)
                                                setFeedbackCurrentPage(page.selected)
                                                // setPage(page.selected)
                                            }}/>
                                    </Row>
                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginBottom: 20}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">
                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "row",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                        flex: 1
                                    }}>
                                        Pertanyaan
                                    </span>

                                    <div>
                                        {allQuestions.length > 0 &&
                                            <CSVLink
                                                style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                                                separator={";"}
                                                data={
                                                    allQuestions
                                                }
                                                filename={`daftar-peserta.csv`}
                                                className="btn btn-primary"
                                                target="_blank"
                                            >
                                                Download Semua Data&nbsp;&nbsp;<FaFileDownload/>
                                            </CSVLink>
                                        }
                                    </div>
                                </div>

                                <Container fluid>
                                    <Row>
                                        <Col style={{textAlign: "left",}}>
                                            <MobTable columns={questionsColumns} data={questions} hidePagination={true}
                                                      interactions={interactions}/>
                                        </Col>
                                    </Row>

                                    <Row style={{paddingLeft: '45%', marginTop: 15}}>
                                        <ReactPaginate
                                            previousLabel={'<'}
                                            nextLabel={'>'}
                                            breakLabel={'...'}
                                            pageCount={questionTotalPages}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            forcePage={questionCurrentPage}
                                            initialPage={questionCurrentPage}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            onPageChange={(page) => {
                                                console.log(page.selected)
                                                setQuestionCurrentPage(page.selected)
                                                // setPage(page.selected)
                                            }}/>
                                    </Row>
                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"dashboard"}
            >
                {renderContent()}
            </EditorPageWrapper>
            <UserListModal
                show={selectedUsers.length > 0}
                members={selectedUsers}
                showClass={showClassInUserList}
                onClose={() => {
                    setShowClassInUserList(false)
                    setSelectedUsers([])
                }}
            />
        </>

    );

}
