import Collapse from "@material-ui/core/Collapse"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import React from "react"
import {FaTimes} from "react-icons/all"
import PropTypes from 'prop-types'

export default function AlertBox({message, handleClose, error}) {

    AlertBox.propTypes = {
        message: PropTypes.string,
        handleClose: PropTypes.func,
        error: PropTypes.bool
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={message.length > 0}>
                <Alert
                    severity={error ? "error" : "success"}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {message}
                </Alert>
            </Collapse>
        </div>
    }


    return renderAlertBox()

}
