import ApiRequest from "../util/ApiRequest";

export default class Quiz {
    addNew = async (body)=>{
        let result = await ApiRequest.set(`/v1/quiz/question`, "POST", body);

        return result
    }

    getAllByClassCode = async (classCode) => {
        let result = await ApiRequest.set(`/v1/class/${classCode}/quiz/question`, "GET");

        return result
    }

    update = async (id, body) => {
        let result = await ApiRequest.set(`/v1/quiz/question/${id}`, "PUT", body);

        return result
    }

    delete = async (id) => {
        let result = await ApiRequest.set(`/v1/quiz/question/${id}`, "DELETE");

        return result
    }

    undelete = async (id) => {
        let result = await ApiRequest.set(`/v1/quiz/question/${id}/toggle`, "PUT");

        return result
    }

    getParticipantAnswers = async (id) => {
        let result = await ApiRequest.set(`/v1/quiz/question/${id}/answer`, "GET");

        return result
    }

    getAllFromFormClassCode = async (classCode) => {
        let result = await ApiRequest.set(`/v1/quiz_forms/${classCode}`, "GET");

        return result
    }

    updateCertificate = async (id,file,classCode) => {

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        let result = await ApiRequest.setMultipart(`/v1/quiz_forms/certificate/${classCode}/${id}`, "POST",formData);

        return result
    }

    deleteAdditionalAnswer = async (id) => {
        let result = await ApiRequest.set(`/v1/quiz_forms/${id}`, "DELETE");

        return result
    }
}
