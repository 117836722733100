import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {
    Button,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tab,
    Tabs
} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

import {CSVLink} from "react-csv";

import {useHistory, useLocation, useParams} from "react-router-dom";

import Palette from "../../../../util/Palette";

import EditorPageWrapper from "../EditorPageWrapper";

import 'moment/locale/id'
import CustomDropzone from "../../../reusable/CustomDropzone";

import Event, {PAYMENT_TYPE, PUBLISH_STATUS} from "../../../../models/Event"
import CustomButton from "../../../reusable/CustomButton";
import MiniTooltip from "../../../reusable/MiniTooltip";

import Session from "../../../../models/Session";
import {FaEye, FaUserFriends, FaFileDownload, FaUserPlus, FaUsers} from "react-icons/all";
import MobTable from "../../../reusable/Table/MobTable"
import moment from "moment"
import RegistrantInspectorDialog from "./specifics/ReigstrantInspectorDialog"
import ApiRequest from "../../../../util/ApiRequest"
import User from "../../../../models/User"
import Registration from "../../../../models/Registration"
import RegistrantRejectionDialog from "./specifics/RegistrantRejectionDialog"
import AlertBox from "../../../reusable/AlertBox/AlertBox"

import textFormatter from "../../../../util/textFormatter";

import _ from "lodash"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

import Class from "../../../../models/Class";
import Participation from "../../../../models/Participation";
import InviteUserModal from "../../../reusable/InviteUserModal";
import EditUserModal from "../../../reusable/EditUserModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RegistrantNoteDialog from "./specifics/RegistrantNoteDialog"
import BroadcastReminderDialog from "./specifics/BroadcastReminderDialog"
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import FileUpload from "../../../reusable/FileUpload";
import QuestionModel from "../../../../models/Question";

import Papa from "papaparse"
import BatchInvitationModal from "../../../reusable/modals/BatchInvitationModal";
import {HiSpeakerphone} from "react-icons/hi";
import LagFreeTextEditor from "../../../reusable/LagFreeTextEditor";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdEvent, MdSchedule} from "react-icons/md";
import MomentUtils from '@date-io/moment';
import {AiOutlineClose} from "react-icons/ai";
import ProvincesWithCities from "../../../../models/ProvincesWithCities";

let classModel = new Class()

export default function UserManagement(props) {

    const {event_url} = useParams()
    let history = useHistory()

    const [filter, setFilter] = useState({});

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeClass, setActiveClass] = useState({})
    const [registrationField, setRegistrationFields] = useState([])
    const [registrantsProcessed, setRegistrantsProcessed] = useState([])

    const [paymentType, setPaymentType] = useState("FREE")

    //Alert message
    let [errorMessage, setErrorMessage] = useState("")
    let [successMessage, setSuccessMessage] = useState("")

    //for the table
    const [registrants, setRegistrants] = useState([])
    const [filteredRegistrants, setFilteredRegistrants] = useState([]);

    const [requiredVisitorInformation, setRequiredVisitorInformation] = useState([])


    //For the note dialog
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState({})

    const [isInviting, setIsInviting] = useState(false)

    //Processed Info for the seperate tables
    const categorizedRegistrants = useMemo(() => {
        console.log('registrants', registrants)

        let waiting = registrants.filter(r => r.status === Registration.states.WAITING)
        let accepted = registrants.filter(r => r.status === Registration.states.ACCEPTED)
        let rejected = registrants.filter(r => r.status === Registration.states.REJECTED || r.status === Registration.states.CANCELLED)
        let pending = registrants.filter(r => r.status === Registration.states.PENDING)

        return {
            waiting,
            accepted,
            rejected,
            pending
        }

    }, [registrants])

    //Processed Info for the seperate tables
    const categorizedFilteredRegistrants = useMemo(() => {
        let waiting = filteredRegistrants.filter(r => r.status === Registration.states.WAITING)
        let accepted = filteredRegistrants.filter(r => r.status === Registration.states.ACCEPTED)
        let rejected = filteredRegistrants.filter(r => r.status === Registration.states.REJECTED || r.status === Registration.states.CANCELLED)
        let pending = filteredRegistrants.filter(r => r.status === Registration.states.PENDING)

        return {
            waiting,
            accepted,
            rejected,
            pending
        }

    }, [filteredRegistrants])

    //for the registrant dialog
    const [activeRegistrant, setActiveRegistrant] = useState({})
    const [registrantInspectorOpen, setRegistrantInspectorOpen] = useState(false)

    //for the rejection reason dialog
    const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false)
    const [rejectedRegistrant, setRejectedRegistrant] = useState({})
    const [rejectionMessage, setRejectionMessage] = useState("")

    const [totalIncome, setTotalIncome] = useState(0)

    const [showUploadDialog, setShowUploadDialog] = useState(false)

    const [showBroadcastDateDialog, setShowBroadcastDateDialog] = useState(false)
    const [classStartDate, setClassStartDate] = useState(null);
    const [classStartTime, setClassStartTime] = useState(null);
    const [classEndTime, setClassEndTime] = useState(null);

    const [provinces, setProvinces] = useState([]);

    const [cities, setCities] = useState([]);

    const columns = [
        {
            Header: "ID Registrasi",
            accessor: "id"
        },
        {
            Header: "Nama Lengkap",
            accessor: "full_name",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let fullName = rowInfo["full_name"]

                return <>
                    {fullName}
                </>
            }
        },
        {
            Header: "Email",
            accessor: "email",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let email = rowInfo["email"]

                return <>
                    {email}
                </>
            }
        },
        {
            Header: "Presensi",
            accessor: "is_present",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let isClaimed = rowInfo["is_present"]
                let id = rowInfo["id"]

                return <>
                    <Select
                        value={isClaimed}
                        onChange={(val) => {
                            updateAbsent(id, !isClaimed)
                        }}
                    >
                        <MenuItem value={1}>Ya</MenuItem>
                        <MenuItem value={0}>Tidak</MenuItem>
                    </Select>
                    {/*{isClaimed ? "Ya" : "Tidak"}*/}
                </>
            }
        },
        {
            Header: "Status Pembayaran",
            accessor: "status",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let value = cellInfo.value

                let statusString = "MENUNGGU PEMBAYARAN"

                //console.log(cellInfo)
                let color = "#000000"

                if (rowInfo.status === "ACCEPTED") {

                    console.log('rowInfo', cellInfo.row.original)


                    if(cellInfo.row.original.order && cellInfo.row.original.status_change_info !== "Invited, status changed from WAITING to ACCEPTED"){
                        color = "LimeGreen"
                        statusString = "TELAH BAYAR"
                    }else{

                        if(cellInfo.row.original.status_change_info && (cellInfo.row.original.status_change_info === "Invited" || cellInfo.row.original.status_change_info === "Invited, status changed from WAITING to ACCEPTED")){
                            statusString = "UNDANGAN"
                        }else{
                            statusString = "TERDAFTAR"
                        }
                        color = "LimeGreen"
                    }


                } else if (rowInfo.status === "REJECTED" || rowInfo.status === "CANCELLED") {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (rowInfo.status === "WAITING") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                } else if (rowInfo.status === "PENDING") {
                    color = "DarkOrange"
                    statusString = "DALAM ANTREAN"

                }
                return <p style={{color: color}}>{statusString}</p>
            }
        },
        {
            Header: "Harga",
            accessor: "price",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let price = rowInfo["price"]

                if(cellInfo?.row?.original.status_change_info === "Invited, status changed from WAITING to ACCEPTED"){
                    return "-"
                }

                return <>
                    {price ? `Rp${textFormatter.moneyFormatter(price)}` : "-"}
                </>
            }
        },
        {
            Header: "Voucer",
            accessor: "voucher",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let voucher = rowInfo["voucher"]

                return <>
                    {!voucher ? '-' : voucher.amount ? `Rp${textFormatter.moneyFormatter(voucher.amount)}` : `${voucher.percentage}%`}
                </>
            }
        },
        {
            Header: "Catatan",
            accessor: "note",
            Cell: (cellInfo) => {
                let participant = cellInfo.row.original
                let note = participant.note ?? "-"
                if (note.length > 35) {
                    note = note.substr(0, 35) + "..."
                }
                return <>{note}</>
            }
        },
        {
            Header: "Opsi",
            accessor: "confirmed_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let originalRowInfo = cellInfo.row.original
                return <div style={{display: "flex", flexDirection: "row"}}>
                    <CustomButton
                        style={{
                            marginRight: 10
                        }}
                        variant={"outlined"}
                        onClick={() => {
                            setActiveRegistrant(originalRowInfo)
                            setRegistrantInspectorOpen(true)
                        }}
                    >
                        Info
                    </CustomButton>

                    {
                        rowInfo.status === "ACCEPTED" && rowInfo.confirmed_at === null ?
                            <CustomButton variant={"outlined"} onClick={async () => {
                                try {
                                    setIsLoadingBar(true)

                                    let result = await Registration.acceptParticipant(rowInfo.id)
                                    console.log(result)
                                    await getParticipants()
                                    setIsLoadingBar(false)
                                    alert("Sukses")

                                } catch {
                                    alert("Terjadi Kesalahan")
                                    setIsLoadingBar(false)

                                    console.error()
                                }

                            }}>
                                KONFIRMASI
                            </CustomButton>
                            :
                            null
                    }
                    {
                        <CustomButton
                            variant={"outlined"}
                            onClick={() => {
                                console.log('cellInfo', cellInfo.row)

                                setSelectedParticipant(originalRowInfo)
                                setShowNoteModal(true)
                            }}
                        >
                            Catatan
                        </CustomButton>
                    }

                </div>
            }
        },
    ]

    const getCitiesByProvince = async (province) => {
        const provincesWithCities = new ProvincesWithCities();

        const cities = await provincesWithCities.getCitiesByProvince(province);

        setCities(cities)
    }

    //Interaction information for MobTable
    const interactions = []

    const getParticipants = async () => {

        let result = await classModel.getClassParticipant(event_url)
        console.log('baja unggul', result)


        let totalIncome = 0;

        for (let r of result) {
            if (r.status === "ACCEPTED" && r.status_change_info !== "Invited, status changed from WAITING to ACCEPTED") {
                totalIncome += r.price
            }

        }

        let formattedRegistrants = result.map((obj, key) => {
            return {
                ...obj.member,
                ...obj,
                member: {...obj.member}
            }
        })

        setTotalIncome(totalIncome)
        setRegistrants(formattedRegistrants)

    }

    useEffect(() => {
        const filteredUsers = [];

        registrants.forEach((user) => {
            if (filter.city) {
                if (filter.city === user.member?.city) {
                    filteredUsers.push(user);
                }
            } else if (filter.province) {
                if (filter.province === user.member?.province) {
                    filteredUsers.push(user);
                }
            }
        })

        setFilteredRegistrants(filteredUsers)
    }, [filter])

    useEffect(() => {
        getParticipants()
        getClassInfo()
        getProvinces()
    }, [])

    const getClassInfo = async function () {
        const ClassInstance = new Class()
        let cls = await ClassInstance.getByCode(event_url)
        setActiveClass(cls)
    }


    const updateAbsent = async (id, newValue) => {

        try {
            let participationModel = new Participation();

            let result = await participationModel.updateAbsent(id, newValue)

            let tempRegistrants = [...registrants]

            let idx = registrants.findIndex((obj) => obj.id === id)
            tempRegistrants[idx] = {
                ...tempRegistrants[idx],
                is_present: newValue ? 1 : 0
            }

            console.log(tempRegistrants)

            setRegistrants(tempRegistrants)

        } catch (e) {
            console.log(e)
            setErrorMessage(JSON.stringify(e))
        }


    }

    const getNewsletterRegistrant = () => {
        let filteredRegistrant = categorizedRegistrants.accepted.filter((obj) => {
            return obj.subscribe_newsletter
        })
        return filteredRegistrant.map((obj, key) => {
            return {
                "Email": obj.email,
                "FullName": obj.full_name
            }
        })
    }

    const getProvinces = async () => {
        const provincesWithCities = new ProvincesWithCities();

        const provinces = await provincesWithCities.getProvinces();

        setProvinces(provinces)
    }

    const broadcastReminder = async () => {
        console.log(new Date(classStartDate))
        console.log(classStartDate)


        if (!classStartDate || !classStartTime || !classEndTime) {
            alert('Harap lengkapi semua data')
        } else if (moment(classStartTime).isAfter(classEndTime)) {
            alert('Waktu mulai kelas harus lebih awal dari waktu selesai kelas')
        } else {
            const classStartDateTemp = new Date(classStartDate);
            const classEndDateTemp = new Date(classStartDate);

            classStartDateTemp.setHours(new Date(classStartTime).getHours());
            classStartDateTemp.setMinutes(new Date(classStartTime).getMinutes());

            classEndDateTemp.setHours(new Date(classEndTime).getHours())
            classEndDateTemp.setMinutes(new Date(classEndTime).getMinutes())

            try {
                const result = await new Participation().broadcastReminder(event_url, classStartDateTemp, classEndDateTemp);

                console.log('result', result)

                if (result.success) {
                    setShowBroadcastDateDialog(false)
                    alert('Pengingat kelas berhasil terkirim!')
                }
            } catch (e) {
                console.log(e)
            }

        }
        // setShowBroadcastDateDialog(false)
    }


    const renderContent = () => {

        //console.log(categorizedRegistrants.accepted)

        return <>
            <AlertBox error message={errorMessage} handleClose={() => {
                setErrorMessage("")
            }}/>
            <AlertBox message={successMessage} handleClose={() => {
                setSuccessMessage("")
            }}/>
            <Row style={{marginBottom: "1em"}}>
                <Col md={12}>
                    <Collapse
                        in={activeEvent.publish_status === PUBLISH_STATUS.DRAFT || activeEvent.publish_status === PUBLISH_STATUS.HIDDEN}>
                        <Alert
                            severity="warning"
                            action={
                                <></>
                            }
                            style={{
                                marginTop: "1em"
                            }}
                        >
                            Pendaftaran acara
                            mu {activeEvent.publish_status === PUBLISH_STATUS.DRAFT ? "belum" : "sedang tidak"} dibuka.
                            Aktifkan pendaftaran acaramu&nbsp;
                            <a
                                onClick={() => {
                                    history.push("/editor/detail/" + activeEvent.event_url)
                                }}
                                href={""}>disini</a>.
                        </Alert>
                    </Collapse>
                </Col>
            </Row>

            <BroadcastReminderDialog
                isOpen={showBroadcastDateDialog}
                onClose={() => setShowBroadcastDateDialog(false)}
                onSave={broadcastReminder}
                classStartDate={classStartDate}
                setClassStartDate={setClassStartDate}
                classStartTime={classStartTime}
                setClassStartTime={setClassStartTime}
                classEndTime={classEndTime}
                setClassEndTime={setClassEndTime}
            />

            <RegistrantNoteDialog
                registrant={selectedParticipant}
                onClose={() => {
                    setShowNoteModal(false)
                }}
                isOpen={showNoteModal}
                onSaveSuccess={(participant) => {
                    let participants = [...registrants]
                    let i = participants.findIndex(p => p.id === participant.id)
                    participants[i].note = participant.note
                    setRegistrants(participants)
                    setShowNoteModal(false)
                }}/>
            <RegistrantInspectorDialog show={registrantInspectorOpen}
                                       registrant={activeRegistrant}
                                       handleClose={() => setRegistrantInspectorOpen(false)}
                                       onAccept={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let participationModel = new Participation()
                                               await participationModel.accept(activeRegistrant.id)
                                               getParticipants()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }

                                       }}
                                       onReject={async (registrant) => {
                                           setRejectionDialogOpen(true)
                                           setRejectedRegistrant(registrant)
                                           setRegistrantInspectorOpen(false)
                                           if (activeRegistrant.status === 'REJECTED') setRejectionMessage(activeRegistrant.status_change_info)
                                       }}
                                       onSendScenarioEmail={async (scenario) => {
                                           setIsLoadingBar(true)
                                           try {
                                               let participationModel = new Participation()
                                               await participationModel.sendScenarioEmail(activeRegistrant.id, scenario)
                                               alert("Pengiriman email berhasil!")
                                               setRegistrantInspectorOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               alert("Sebuah kesalahan terjadi. Periksa console.")
                                           }
                                           setIsLoadingBar(false)
                                       }}
                                       activeSession={activeClass}
            />

            <RegistrantRejectionDialog
                show={rejectionDialogOpen}
                registrant={rejectedRegistrant}
                handleClose={() => {
                    setRejectionMessage('');
                    setRejectionDialogOpen(false);
                    setRejectedRegistrant({})
                }}
                rejectionMessage={rejectionMessage}
                onRejectWithoutEmail={async()=>{
                    if (!rejectionMessage) {
                        alert('Harap mengisi alasan penolakan')
                    } else {
                        setIsLoadingBar(true)
                        try {
                            let participationModel = new Participation()
                            await participationModel.reject(activeRegistrant.id, rejectionMessage, true)
                            getParticipants()
                            setIsLoadingBar(false)
                            setRegistrantInspectorOpen(false)
                            setRejectionDialogOpen(false)
                        } catch (e) {
                            console.log(e)
                            setIsLoadingBar(false)
                        }
                    }
                }}
                onReject={async () => {
                    if (!rejectionMessage) {
                        alert('Harap mengisi alasan penolakan')
                    } else {
                        setIsLoadingBar(true)
                        try {
                            let participationModel = new Participation()
                            await participationModel.reject(activeRegistrant.id, rejectionMessage)
                            getParticipants()
                            setIsLoadingBar(false)
                            setRegistrantInspectorOpen(false)
                            setRejectionDialogOpen(false)
                        } catch (e) {
                            console.log(e)
                            setIsLoadingBar(false)
                        }
                    }
                }}
                onAbort={() => {
                    setRejectionMessage('');
                    setRejectionDialogOpen(false);
                    setRejectedRegistrant({})
                }}
                onReasonChange={(reason) => {
                    setRejectionMessage(reason)
                }}
                activeSession={activeClass}
            />
            <span>
                Peserta Sesi
            </span>

            <b style={{
                color: Palette.SECONDARY,
                fontSize: "2em",
                fontFamily: "Montserrat"
            }}>
                {activeEvent.name}
            </b>
            <div style={{marginBottom: "1em"}}>
                <span>
                    <FaUserFriends/>&nbsp; Peserta Sesi : {categorizedRegistrants.waiting.length + categorizedRegistrants.accepted.length}{activeSession.quota > 0 ? `/${activeSession.quota}` : null}
                </span>
                <div>
                    <br/>
                    <b>Ringkasan</b>
                    <br/>

                    <div style={{marginTop: 4}}>
                        <div style={{marginLeft: 5}}>Pendaftar
                            : {categorizedRegistrants.waiting.length + categorizedRegistrants.accepted.length}</div>
                        <div style={{marginLeft: 5}}>Terdaftar
                            : {categorizedRegistrants.accepted.length}</div>

                        <div style={{marginLeft: 5}}>Peserta Bayar: {categorizedRegistrants.accepted.reduce((accu,obj)=>(obj.price && obj.status_change_info !== "Invited, status changed from WAITING to ACCEPTED") ? accu + 1 : accu, 0)}</div>
                        <div style={{marginLeft: 5}}>Peserta Undangan: {categorizedRegistrants.accepted.length - categorizedRegistrants.accepted.reduce((accu,obj)=>obj.order ? accu + 1 : accu, 0)}</div>
                        <div style={{marginLeft: 5}}>Pemasukan :
                            Rp{textFormatter.moneyFormatter(totalIncome)}</div>
                    </div>

                </div>
            </div>

            <Row
                style={{
                    marginBottom: "3em",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                <CSVLink
                    style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                    separator={";"}
                    data={
                        categorizedRegistrants.accepted.map((obj, key) => {
                            return {
                                timestamp: new moment(obj.accepted_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Nama Lengkap": obj.full_name,
                                "Organisasi": obj.organization,
                                "Surel": obj.email,
                                "Nomor Ponsel": obj.phone_num ? `${obj.phone_num}` : "",
                                "Topik Pelatihan": activeClass.name,
                                "Sumber Informasi": obj.source,
                                "Kota": obj.city,
                                "Nawala": obj.subscribe_newsletter,
                                "Bukti Bayar": "",
                                "Kuitansi": "",
                                "#Daftar": obj.id,
                                "Tanggal Daftar": new moment(obj.created_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Biaya": obj?.price ? obj?.price : 0,
                            }
                        })
                    }
                    filename={`daftar-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Semua Data&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <CSVLink
                    style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                    separator={";"}
                    data={getNewsletterRegistrant()}
                    filename={`email-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Data Untuk Email&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white", marginBottom: 10
                        }}
                        variant={"primary"}
                        onClick={() => {
                            setIsInviting(true)
                        }}
                    >
                        Undang Peserta&nbsp;&nbsp;<FaUserPlus/>
                    </Button>

                </div>


                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white", marginBottom: 10
                        }}
                        variant={"primary"}
                        onClick={() => {
                            setShowUploadDialog(true)
                        }}
                    >
                        Undang Banyak Peserta&nbsp;&nbsp;<FaUsers/>
                    </Button>

                </div>

                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white", marginBottom: 10
                        }}
                        variant={"primary"}
                        onClick={async () => {
                            setShowBroadcastDateDialog(true)
                            // try {
                            //     let participationModel = new Participation()
                            //     await participationModel.broadcastReminder(event_url)
                            // } catch (e) {
                            //     console.log(e)
                            // }
                        }}
                    >
                        Broadcast Pengingat Kelas&nbsp;&nbsp;<HiSpeakerphone/>
                    </Button>

                </div>
            </Row>

            {registrants.length > 0 &&
            <>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em',
                }}
                >
                    <Col md={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                Filter Provinsi
                            </div>

                            <Select
                                style={{marginLeft: 10, marginTop: 6}}
                                value={filter.province ? filter.province : 0}
                                onChange={e => {
                                    setFilter({
                                        province: e.target.value,
                                        city: ''
                                    })

                                    getCitiesByProvince(e.target.value)
                                    // setCorrectAnswer(e.target.value)
                                }}>
                                <MenuItem value={0} key={-1}>
                                    Pilih Provinsi
                                </MenuItem>
                                {provinces.map((option, key) => (
                                    <MenuItem value={option} key={key}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        {filter.province &&
                        <div style={{width: 30, height: 30, marginLeft: 5, marginBottom: 10}}>
                            <IconButton aria-label="Close" onClick={e => {
                                setFilter({
                                    province: '',
                                })
                            }}>
                                <AiOutlineClose size={20}/>
                            </IconButton>
                        </div>
                        }
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em',
                    marginBottom: '3em'
                }}
                >
                    <Col md={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                Filter Kota
                            </div>

                            <Select
                                style={{marginLeft: 10, marginTop: 6}}
                                value={filter.city ? filter.city : 0}
                                onChange={e => {
                                    setFilter({
                                        ...filter,
                                        city: e.target.value,
                                    })
                                }}>
                                <MenuItem value={0} key={-1}>
                                    Pilih Kota
                                </MenuItem>
                                {cities.map((option, key) => (
                                    <MenuItem value={option} key={key}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        {filter.city &&
                        <div style={{width: 30, height: 30, marginLeft: 5, marginBottom: 10}}>
                            <IconButton aria-label="Close" onClick={e => {
                                setFilter({
                                    ...filter,
                                    city: '',
                                })
                            }}>>
                                <AiOutlineClose size={20}/>
                            </IconButton>
                        </div>
                        }
                    </Col>
                </Row>
            </>
            }

            {
                registrants.length > 0 ?
                    <>
                        <Tabs defaultActiveKey="all">
                            <Tab
                                eventKey="all"
                                title={
                                    "Semua"
                                }
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              data={filter.province || filter.city ? filteredRegistrants : registrants}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="accepted"
                                title={"Diterima"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              data={filter.province || filter.city ? categorizedFilteredRegistrants.accepted : categorizedRegistrants.accepted}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="waiting"
                                title={"Menunggu Pembayaran"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              data={filter.province || filter.city ? categorizedFilteredRegistrants.waiting : categorizedRegistrants.waiting}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="rejected"
                                title={"Ditolak"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              data={filter.province || filter.city ? categorizedFilteredRegistrants.rejected : categorizedRegistrants.rejected}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                        </Tabs>

                    </>
                    :
                    <>
                        Data Pendaftaran tidak ditemukan
                    </>
            }

        </>
    }


    return (
        <>
            <InviteUserModal
                isOpen={isInviting}
                onClose={() => {
                    setIsInviting(false)
                    getParticipants()
                }}
                eventUrl={event_url}
            />
            <BatchInvitationModal
                eventCode={event_url}
                open={showUploadDialog}
                onClose={(refresh) => {
                    if (refresh) {
                        getParticipants()
                    }
                    setShowUploadDialog(false)
                }}
            />
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"user-management"}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
