import React, {useEffect, useMemo, useRef, useState} from "react";
import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import TextField from "@material-ui/core/TextField";
import {Button} from "react-bootstrap"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useParams} from "react-router-dom";
import QuestionModel from "../../../models/Question"
import moment from "moment";
import ManageQuestionModal from "../../reusable/ManageQuestionModal";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import CustomButton from "../../reusable/CustomButton";
import {IoMdEye, IoMdEyeOff, IoMdDownload} from "react-icons/io";
import EditQuestionModal from "../../reusable/EditQuestionModal";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Class from "../../../models/Class";
import {Alert, useTabContext} from "@material-ui/lab"
import {CSVLink} from "react-csv"
import {FaFileDownload} from "react-icons/all"
import FileUpload from "../../reusable/FileUpload"
import Participation from "../../../models/Participation"
import AlertBox from "../../reusable/AlertBox/AlertBox"

export default function Question(props) {

    let questionModel = new QuestionModel()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isBtnHovered, setBtnHovered] = useState(false);

    const [questions, setQuestions] = useState([])
    const [questionsWithoutDeleted, setQuestionsWithoutDeleted] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState({})

    const [isDeleting, setDeleting] = useState(false);

    const {event_url} = useParams()

    const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false)
    const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false)

    const urlRef = useRef(null);
    const [isDeletedShown, setDeletedShown] = useState(false);

    const [classInfo, setClassInfo] = useState({})
    const [showAnswerToUser, setShowAnswerToUser] = useState(false)

    const [showUploadDialog, setShowUploadDialog]  = useState(false)
    const [stagedQuestions, setStagedQuestions] = useState([])
    const [stagingProblems, setStagingProblems] = useState([])

    const stagedQuestionColumns = useMemo(() =>[
        {
            Header: "No.",
            accessor: "no"
        },
        {
            Header: "ID Peserta",
            accessor: "participant_id",
            Cell: (cellInfo) =>{
                let row = cellInfo.row.original
                let display = "-"
                if(row.participant_id) {
                    display = row.participant_id
                }
                return <>{display}</>
            }
        },
        {
            Header: "Surel Penanya",
            accessor: "email"
        },
        {
            Header: "Nama",
            accessor: "full_name"
        },
        {
            Header: "Pertanyaan",
            accessor: "question"
        },
        {
            Header: "Jawaban",
            accessor: "answer"
        },


    ])


    const columns = useMemo(
        () => [
            {
                Header: 'Pertanyaan',
                accessor: 'question',
                Cell: (cellInfo) => {
                   return <div dangerouslySetInnerHTML={{ __html: cellInfo.row.values.question}} />
                    // return <p>{cellInfo.row.values.question} {cellInfo.row.original.active ? null : <span style={{
                    //     backgroundColor: Palette.SECONDARY,
                    //     color: 'white',
                    //     paddingLeft: 10,
                    //     paddingRight: 10,
                    //     borderRadius: 20,
                    //     marginLeft: 5,
                    //     fontFamily: 'OpenSans-SemiBold',
                    //     fontSize: '.9em'
                    // }}>Terhapus</span>}</p>
                }
            },
            {
                Header: 'Jawaban',
                accessor: 'answer',
                Cell: (cellInfo, obj) => {
                    let rowInfo = cellInfo.row.values

                    return <>
                    <div dangerouslySetInnerHTML={{ __html: rowInfo.answer ? rowInfo.answer : "-"}} />
            
                    </>
                }
            },
            {
                Header: 'Penanya',
                accessor: 'full_name',
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {rowInfo.full_name ? rowInfo.full_name : "Admin"}
                    </>
                }
            },
            {
                Header: "Ditanya pada",
                accessor: "asked_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {moment(rowInfo.asked_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
            {
                Header: "Dijawab pada",
                accessor: "answered_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    if (!rowInfo.answered_at) {
                        return <>-</>
                    }

                    return <>
                        {moment(rowInfo.answered_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            }
        ]
    )

    const interactions = [
        {
            name: "Hapus",
            action: (question) => {
                setSelectedQuestion(question)
                setDeleting(true);
            },
            style: {width: 100},
            variant: 'secondary',
            condition: (question) => {
                return question.active
            }
        },
        {
            name: "Pulihkan",
            action: (quiz) => {
                toggleQuestion(quiz.id);
            },
            variant: 'info',
            style: {width: 100},
            condition: (quiz) => {
                return !quiz.active
            },
        },
        {
            name: "Jawab",
            action: (quiz) => {
                setSelectedQuestion(quiz)
                setIsAnswerModalOpen(true)
            },
            variant: 'primary',
            style: {width: 100},
            condition: (quiz) => {
                return quiz.active
            },
        }
    ];

    useEffect(() => {
        getQuestions()
        getClassByCode()
    }, [])

    const getClassByCode = async ()=>{
        try{
            const classModel = new Class();
            const classInfo = await classModel.getByCode(event_url);

            setClassInfo(classInfo)
            // console.log(classInfo, classInfo.show_answers_to_user)

            setShowAnswerToUser(classInfo.show_answers_to_user)

        }catch(e){
            console.log(e)
        }

    }

    const getQuestions = async () => {
        console.log("fetching")
        try {

            let result = await questionModel.getAll(event_url)

            const questionsWithoutDeletedTemp = [];

            result.forEach(question => {
                if (question.active) questionsWithoutDeletedTemp.push(question)
            })

            setQuestionsWithoutDeleted(questionsWithoutDeletedTemp)
            setQuestions(result)

            console.log("result : questions")

            console.log(result)

        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }

    }

    const updateQuestionStatus = async(newStatus) =>{

        setIsLoadingBar(true)

        console.log(newStatus + " new status")

        try {

            let classModel = new Class()
            let result = await classModel.updateQuestionShowingStatus(event_url, newStatus)

            await getClassByCode();
            setIsLoadingBar(false)

            return result
        } catch (e) {
            setIsLoadingBar(false)
            console.log(e)
            throw e
        }
    }

    const createNewQuestion = async (question, participantId) => {
        try {

            console.log(question, participantId)

            let result = await questionModel.addQuestion(event_url, question, participantId)

            getQuestions();

            return result
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    const createNewQuestionWithName = async (question, askerName) =>{
        try {
            let result = await questionModel.addQuestionWithFullName(event_url, question, askerName)

            getQuestions();

            return result
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    const toggleQuestion = async (id) => {
        try {
            let result = await questionModel.toggle(id)

            if (result.success) {
                setDeleting(false)
                getQuestions()

                if (isDeleting) alert('Pertanyaan berhasil terhapus!');
                else alert('Pertanyaan berhasil dipulihkan');
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    return (
        <EditorPageWrapper
            activeTab={"question"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <Dialog open={showUploadDialog} maxWidth={"xl"} onClose={() => setShowUploadDialog(false)}>
                <DialogTitle title={stagedQuestions.length === 0 ? "Unggah CSV" : "Periksa Data Unggahan"}>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            {stagedQuestions.length === 0 ? "Unggah CSV" : "Periksa Data Unggahan"}
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    {/*Display upload button only when stagedQuestion is empty*/}
                    <div style={{display : "flex",flexDirection : "row"}}>
                        <CSVLink
                            style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                            separator={";"}
                            data={
                                `Nama;Surel;Pertanyaan;Jawaban
nama contoh;contoh@email.com;Contoh Pertanyaan;Contoh Jawaban
nama contoh;contoh@email.com;Contoh Pertanyaan;Contoh Jawaban
`
                            }
                            filename={`contoh-pertanyaan.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            Unduh Contoh Format &nbsp;&nbsp;<FaFileDownload/>
                        </CSVLink>

                        {stagedQuestions.length === 0 && <FileUpload
                            allowedType={["text/csv","application/vnd.ms-excel"]}
                            hideSpinner={true}
                            text={"Unggah File CSV"}
                            isLoading={isLoadingBar}
                            onDrop={async (result) => {

                                setIsLoadingBar(true)

                                console.log(result)
                                let questionModel = new QuestionModel()

                                try {
                                    let uploadResult = await questionModel.uploadQuestionCSV(result[0], event_url)
                                    console.log(uploadResult)
                                    //alert("Upload Berhasil")

                                    setIsLoadingBar(false)
                                    setStagedQuestions(uploadResult.questions)
                                    setStagingProblems(uploadResult.problems)
                                    console.log(uploadResult)
                                } catch (e) {
                                    console.error(e)
                                    alert(e.msg)
                                    setIsLoadingBar(false)
                                }
                            }}
                        />}

                    </div>

                    {/*Display table only when stagedQuestion is set*/}
                    {stagedQuestions.length > 0 && <div>
                        {stagingProblems.length > 0 && <Alert severity={"warning"}>
                            <div>Akun yang tidak terdaftar di kelas terdeteksi. Bila informasi pertanyaan ingin tetap dimasukkan, maka pertanyaan akan dibuat tanpa identitas penanya.</div>
                            <div>Pertanyaan yang dibuat oleh akun tidak terdaftar tidak memiliki ID Peserta.</div>
                            {[...new Set(stagingProblems)].map(problem => <div>{problem}</div>)}
                        </Alert>}

                        <MobTable columns={stagedQuestionColumns} data={stagedQuestions}/>
                    </div> }
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        marginRight: 15,
                        marginBottom: 15
                    }}>
                        <CustomButton
                            onClick={() => {
                                setShowUploadDialog(false)
                                setStagedQuestions([])
                                setStagingProblems([])
                            }}
                            style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                            variant={"outlined"}
                            color="primary">
                            Batal
                        </CustomButton>
                        {stagedQuestions.length > 0 &&
                            <CustomButton
                                style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={async () => {
                                    try {
                                        let questionModel = new QuestionModel()
                                        await questionModel.commitQuestions(stagedQuestions, event_url)
                                        await getQuestions()
                                        alert("Unggahan Berhasil!")
                                        setStagingProblems([])
                                        setStagedQuestions([])
                                        setShowUploadDialog(false)

                                    } catch(err) {
                                        alert("Kesalahan terjadi. Periksa console")
                                        if (err.response) {
                                            console.error(err.response.data);
                                            console.error(err.response.status);
                                            console.error(err.response.headers);
                                        } else {
                                            console.error(err.message)
                                        }

                                    }
                                }}
                                variant={"contained"}
                                color="secondary">
                                Simpan
                            </CustomButton>
                        }

                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={isDeleting}
                    maxWidth="sm">
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Pertanyaan
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus pertanyaan "<span
                        style={{fontFamily: 'OpenSans-Bold'}}><div dangerouslySetInnerHTML={{ __html: selectedQuestion.question }} /></span>"?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setDeleting(false)
                                }}
                                style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => toggleQuestion(selectedQuestion.id)}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditQuestionModal
                visible={isAnswerModalOpen}
                hide={() => setIsAnswerModalOpen(false)}
                selectedQuestion={selectedQuestion}
                onSubmit={async () => {
                    getQuestions()
                }}
            />

            <ManageQuestionModal
                eventCode={event_url}
                visible={isQuestionModalOpen}
                hide={() => setIsQuestionModalOpen(false)}
                onSubmit={async (result, pariticpantId) => {
                    try {
                        await createNewQuestion(result, pariticpantId)
                    } catch (e) {
                        console.log(e)
                        throw e
                    }
                }}
                onSubmitNoParticipant={async (result, customName) => {
                    try {
                        await createNewQuestionWithName(result, customName)
                    } catch (e) {
                        console.log(e)
                        throw e
                    }
                }}
            />

            <Row style={{marginTop: 30, marginBottom: 30}}>
                <Col>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        // marginBottom: 15,
                        // marginTop: '1.075em'
                    }}>
                        Pertanyaan
                    </div>

                    <Row style={{marginLeft: 2, marginTop: 15}}>
                        <Button variant={'info'}
                                onClick={() => setDeletedShown(!isDeletedShown)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    marginTop: 5,
                                    width: 200,
                                    color: 'white', marginRight: 5
                                }}
                        >
                            {isDeletedShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                                <IoMdEye style={{marginRight: 10}}/>}
                            {isDeletedShown ? 'Sembunyikan Terhapus' : 'Tampilkan Terhapus'}
                        </Button>
                        <Button variant={'outline-primary'}
                                onClick={() => setIsQuestionModalOpen(true)}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                                style={{
                                    alignSelf: 'flex-start',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    marginLeft: 2,
                                    marginTop: 5,
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                }}
                            // onClick={() => {
                            //     setAdminManagementModalVisible(true)
                            // }}
                        >
                            + <span style={{marginLeft: 6}}>Tambah Pertanyaan</span>
                        </Button>
                        <Button variant={'outline-primary'}
                                onClick={() => setShowUploadDialog(true)}
                                style={{
                                    alignSelf: 'flex-start',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    marginLeft: 2,
                                    marginTop: 5,
                                    backgroundColor: "#00000000",
                                    color: Palette.PRIMARY,
                                }}
                        >
                            + <span style={{marginLeft: 6}}>Unggah Pertanyaan</span>
                        </Button>
                        <CSVLink
                            style={{
                                alignSelf: 'flex-start',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 30,
                                fontSize: '.8em',
                                fontFamily: 'OpenSans-SemiBold',
                                marginLeft: 2,
                                marginTop: 5,
                                backgroundColor: "#00000000",
                                color: Palette.PRIMARY,
                            }}
                            separator={";"}
                            data={
                                questions.filter(e=>e.active).map((obj, key) => {
                                    return {
                                        "ID Peserta": obj.participant_id,
                                        "ID Anggota": obj.member_id,
                                        Penanya: obj.full_name,
                                        Pertanyaan: obj.question,
                                        Jawaban: obj.answer,
                                        "Ditanya pada": obj.asked_at,
                                        "Dijawab pada": obj.answered_at

                                    }
                                })
                            }
                            filename={`daftar-pertanyaan.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            <IoMdDownload/> <span style={{marginLeft: 6}}>Unduh Pertanyaan</span>
                        </CSVLink>


                    </Row>
                    <Row style={{marginTop : 30}}>
                        <Col md={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAnswerToUser}
                                        // checked={classInfo.show_answers_to_user}
                                        onChange={(e) => {
                                            setClassInfo({
                                                ...classInfo,
                                                show_answers_to_user : !classInfo.show_answers_to_user
                                            })
                                            updateQuestionStatus(!classInfo.show_answers_to_user)
                                        }}
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />
                                }
                                label={"Tunjukan Jawaban ke Pengguna"}
                            />
                        </Col>
                    </Row>
                </Col>

                {/*<Col style={{height: '100%'}}>*/}
                {/*    <div style={{*/}
                {/*        flexDirection: 'column',*/}
                {/*        fontFamily: 'OpenSans-Regular',*/}
                {/*        fontSize: '.8em',*/}
                {/*        display: 'flex',*/}
                {/*        alignItems: 'flex-end',*/}
                {/*        justifyContent: 'flex-start',*/}
                {/*        marginTop: 15,*/}
                {/*        textAlign: 'right',*/}
                {/*    }}>*/}
                {/*        <div style={{marginRight: 145, fontFamily: 'OpenSans-SemiBold'}}>Tautan Narasumber</div>*/}
                {/*        <TextField readOnly value={'https://narabahasa.com/jawab?id=120j2'}*/}
                {/*                   style={{marginLeft: 10, width: 270, fontSize: '.5em'}}*/}
                {/*                   inputProps={{*/}
                {/*                       style: {*/}
                {/*                           fontFamily: 'OpenSans-Regular',*/}
                {/*                           paddingLeft: 5,*/}
                {/*                           fontSize: '.8em'*/}
                {/*                       }*/}
                {/*                   }}*/}
                {/*                   ref={urlRef} onFocus={(e) => {*/}
                {/*            e.target.select()*/}
                {/*        }}/>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>

            <MobTable columns={columns} interactions={interactions}
                      data={isDeletedShown ? questions : questionsWithoutDeleted}/>

        </EditorPageWrapper>
    )
}
