import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import CustomButton from "../../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../../models/Member";
import UserParticipationModal from "../../reusable/modals/UserParticipationModal";
import EditMerchandiseModal from "../../reusable/EditMerchandiseModal";
import {CSVLink} from "react-csv";
import Logs from "../../../models/Logs"
import moment from "moment";

let logs;

export default function Profile(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)

    const [isNew, setIsNew] = useState(false);

    const [userList, setUserList] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [isClassModalVisible, setClassModalVisible] = useState(false)

    const [selectedMerchandise, setSelectedMerchandise] = useState({});

    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [isDelete, setIsDelete] = useState(false)

    let columns = [
        {
            Header: "Admin",
            accessor: "name"
        },
        {
            Header: "Kode",
            accessor: "code",
            Cell: cellInfo => {
                return translateCode(cellInfo)
            }
        },
        {
            Header: "Aktivitas",
            accessor: "activity",
            Cell: (cellInfo => {
                return translateActivity(cellInfo)
            })
        },
        {
            Header: "Waktu",
            accessor: "created_at",
            Cell: (cellInfo) => {
                return new moment(cellInfo.value).format("DD MMM YYYY HH:mm")
            }
        }
    ]

    logs = new Logs();

    const getLogs = async () => {
        setIsLoadingBar(true)
        try {
            let result = await logs.getAll()
            console.log('Get all logs', result)

            setUserList(result)

            setIsLoadingBar(false)


        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }
    useEffect(() => {
        getLogs()
    }, [])

    const translateActivity = (cellInfo) => {
        let activity = cellInfo.value
        let code = cellInfo.row.cells[2].value
        let adminId = cellInfo.row.cells[1].value
        let actId
        let course = 'kelas daring mandiri'

        if (code === 'CREATE_COURSE') {
            code = 'MEMBUAT KDM'
            actId = activity.split("has created Course with id ");
            activity = `Admin dengan id ` + adminId + ' telah membuat ' + course + ' dengan id ' + actId[1];
        }

        if (code === 'UPDATE_COURSE') {
            code = 'MENGUBAH KDM'
            actId = activity.split("has updated Course with id ");
            activity = `Admin dengan id ` + adminId + ' telah menyunting ' + course + ' dengan id ' + actId[1];
        }

        if (code === 'DELETE_COURSE') {
            code = 'MENGHAPUS KDM'
            actId = activity.split("has deleted Course with id ");
            activity = `Admin dengan id ` + adminId + ' telah menghapus ' + course + ' dengan id ' + actId[1];
        }

        if (code === 'RESTORE_COURSE') {
            code = 'MEMULIHKAN KDM'
            actId = activity.split("has restored Course with id ");
            activity = `Admin dengan id ` + adminId + ' telah memulihkan ' + course + ' dengan id ' + actId[1];
        }

        if (code === 'CREATE_CLASS') {
            code = 'MEMBUAT KELAS'
            actId = activity.split("has updated Class with code ");
            activity = `Admin dengan id ` + adminId + ' telah membuat kelas dengan kode ' + actId[1];
        }

        if (code === 'UPDATE_CLASS') {
            code = 'MENYUNTING KELAS'
            actId = activity.split("has updated Class with code ");
            activity = `Admin dengan id ` + adminId + ' telah menyunting kelas dengan kode ' + actId[1];
        }

        if (code === 'DELETE_CLASS') {
            code = 'MENGHAPUS KELAS'
            actId = activity.split("has deleted Class with code ");
            activity = `Admin dengan id ` + adminId + ' telah menghapus kelas dengan kode ' + actId[1];
        }

        if (code === 'RESTORE_CLASS') {
            code = 'MEMULIHKAN KELAS'
            actId = activity.split("has restored Class with code ");
            activity = `Admin dengan id ` + adminId + ' telah memulihkan kelas dengan kode ' + actId[1];
        }

        if (code === 'INVITE_PARTICIPANT') {
            code = 'MENGUNDANG PESERTA'
            actId = activity.split("has invited several participants to Class with code ");
            activity = `Admin dengan id ` + adminId + ' telah mengundang partisipan ke kelas dengan kode ' + actId[1];
        }

        if (code === 'REJECT_PARTICIPANT') {
            code = 'MENOLAK PESERTA'
            actId = activity.split("has rejected participant with id");
            activity = `Admin dengan id ` + adminId + ' telah menolak partisipan dengan id ' + actId[1];
        }

        if (code === 'ACCEPT_PARTICIPANT') {
            code = 'MENERIMA PESERTA'
            actId = activity.split("has accepted participant with id");
            activity = `Admin dengan id ` + adminId + ' telah menerima partisipan dengan id ' + actId[1];
        }

        return activity
    }

    const translateCode = (cellInfo) => {
        let code = cellInfo.value

        if (code === 'CREATE_COURSE') {
            code = 'MEMBUAT KDM'
        }

        if (code === 'UPDATE_COURSE') {
            code = 'MENYUNTING KDM'
        }

        if (code === 'DELETE_COURSE') {
            code = 'MENYEMBUNYIKAN KDM'
        }

        if (code === 'RESTORE_COURSE') {
            code = 'MEMULIHKAN KDM'
        }

        if (code === 'CREATE_CLASS') {
            code = 'MEMBUAT KELAS'
        }

        if (code === 'UPDATE_CLASS') {
            code = 'MENYUNTING KELAS'
        }

        if (code === 'DELETE_CLASS') {
            code = 'MENYEMBUNYIKAN KELAS'
        }

        if (code === 'RESTORE_CLASS') {
            code = 'MEMULIHKAN KELAS'
        }

        if (code === 'INVITE_PARTICIPANT') {
            code = 'MENGUNDANG PESERTA'
        }

        if (code === 'REJECT_PARTICIPANT') {
            code = 'MENOLAK PESERTA'
        }

        if (code === 'ACCEPT_PARTICIPANT') {
            code = 'MENERIMA PESERTA'
        }

        if (code === 'UPDATE_QUESTION') {
            code = 'MENYUNTING PERTANYAAN'
        }

        if (code === 'DELETE_QUESTION') {
            code = 'MENYEMBUNYIKAN PERTANYAAN'
        }

        if (code === 'RESTORE_QUESTION') {
            code = 'MEMULIHKAN PERTANYAAN'
        }

        if (code === 'CREATE_TOPIC') {
            code = 'MEMBUAT TOPIK'
        }

        if (code === 'UPDATE_TOPIC') {
            code = 'MENYUNTING TOPIK'
        }

        if (code === 'DELETE_TOPIC') {
            code = 'MENYEMBUNYIKAN TOPIK'
        }

        if (code === 'RESTORE_TOPIC') {
            code = 'MEMULIHKAN TOPIK'
        }

        if (code === 'CREATE_NOTE') {
            code = 'MEMBUAT CATATAN'
        }

        if (code === 'HARD_DELETE_CLASS' || code === 'HARD_DELETE_COURSE') {
            code = 'MENGHAPUS KELAS'
        }

        if (code === 'CREATE_INSTRUCTOR') {
            code = 'MEMBUAT WIDYAISWARA'
        }

        if (code === 'UPDATE_INSTRUCTOR') {
            code = 'MENYUNTING WIDYAISWARA'
        }

        if (code === 'HARD_DELETE_INSTRUCTOR') {
            code = 'MENGHAPUS WIDYAISWARA'
        }

        if (code === 'UPDATE_MEMBER') {
            code = 'MENYUNTING PENGGUNA'
        }

        if (code === 'DELETE_MEMBER') {
            code = 'MENYEMBUNYIKAN PENGGUNA'
        }

        if (code === 'RESTORE_MEMBER') {
            code = 'MEMULIHKAN PENGGUNA'
        }

        if (code === 'CREATE_VOUCHER') {
            code = 'MEMBUAT VOUCER'
        }

        if (code === 'CREATE_VOUCHER_NOTE') {
            code = 'MEMBUAT CATATAN VOUCER'
        }

        if (code === 'CREATE_LIVE_STREAMING') {
            code = 'MEMBUAT SIARAN LANGSUNG'
        }

        if (code === 'UPDATE_LIVE_STREAMING') {
            code = 'MENYUNTING SIARAN LANGSUNG'
        }

        if (code === 'DELETE_LIVE_STREAMING') {
            code = 'MENYEMBUNYIKAN SIARAN LANGSUNG'
        }

        if (code === 'RESTORE_LIVE_STREAMING') {
            code = 'MEMULIHKAN SIARAN LANGSUNG'
        }

        if (code === 'HARD_DELETE_LIVE_STREAMING') {
            code = 'MENGHAPUS SIARAN LANGSUNG'
        }

        if (code === 'CREATE_ADMIN') {
            code = 'MEMBUAT ADMIN'
        }

        if (code === 'UPDATE_ADMIN') {
            code = 'MENYUNTING ADMIN'
        }

        if (code === 'DELETE_ADMIN') {
            code = 'BAN ADMIN'
        }

        if (code === 'RESTORE_ADMIN') {
            code = 'UNBAN ADMIN'
        }

        if (code === 'CREATE_MERCHANDISE') {
            code = 'MEMBUAT KREASI'
        }

        if (code === 'UPDATE_MERCHANDISE') {
            code = 'MENYUNTING KREASI'
        }

        if (code === 'HARD_DELETE_MERCHANDISE') {
            code = 'MENGHAPUS KREASI'
        }

        if (code === 'RESTORE_MERCHANDISE') {
            code = 'MEMULIHKAN KREASI'
        }

        if (code === 'UPDATE_DOCUMENT_FORM') {
            code = 'MENYUNTING FORMULIR DOKUMEN'
        }

        if (code === 'UPDATE_STRINGS') {
            code = 'MENYUNTING TAMPILAN ANTARMUKA'
        }

        if (code === 'CREATE_NOTIFICATION') {
            code = 'MEMBUAT NOTIFIKASI'
        }

        if (code === 'CREATE_COURSE_INSTRUCTOR' || code === 'CREATE_CLASS_INSTRUCTOR')  {
            code = 'MENAMBAH WIDYAISWARA'
        }

        if (code === 'HARD_DELETE_COURSE_INSTRUCTOR' || code === 'HARD_DELETE_CLASS_INSTRUCTOR')  {
            code = 'MENGHAPUS WIDYAISWARA'
        }

        if (code === 'CREATE_COURSE_CHAPTER')  {
            code = 'MENAMBAH BAB'
        }

        if (code === 'UPDATE_COURSE_CHAPTER')  {
            code = 'MENYUNTING BAB'
        }

        if (code === 'HARD_DELETE_COURSE_CHAPTER')  {
            code = 'MENGHAPUS BAB'
        }

        if (code === 'CREATE_COURSE_SUB_CHAPTER')  {
            code = 'MENAMBAH SUBBAB'
        }

        if (code === 'UPDATE_COURSE_SUB_CHAPTER')  {
            code = 'MENYUNTING SUBBAB'
        }

        if (code === 'HARD_DELETE_COURSE_SUB_CHAPTER')  {
            code = 'MENGHAPUS SUBBAB'
        }

        if (code === 'DELETE_COURSE_RATING')  {
            code = 'MENYEMBUNYIKAN UMPAN BALIK'
        }

        if (code === 'RESTORE_COURSE_RATING')  {
            code = 'MEMULIHKAN UMPAN BALIK'
        }

        return code;
    }

    const closeUserParticipationModal = () => {
        setClassModalVisible(false)
        setSelectedMerchandise({})
    }

    const activateUser = async (id) => {

        try {
            let member = new Member();
            let result = await member.activate(id)

            getLogs()

            console.log(result)

        } catch (e) {
            console.log(e)
        }

    }

    const deleteMerchandise = async () => {

        try {
            let merchandise = new Logs();
            let result = await merchandise.deleteById(selectedMerchandise.id)

            setIsDelete(false)

            getLogs()

            console.log(result)

        } catch (e) {
            console.log(e)
        }

    }

    const unDeleteMerchandise = async (id) => {

        try {
            console.log('id to be restored', id)
            let merchandise = new Logs();
            let result = await merchandise.restoreById(id)

            getLogs()

            console.log(result)

        } catch (e) {
            console.log(e)
        }

    }

    const getNewsletterRegistrant = () => {
        return userList.map((obj, key) => {
            return {
                "Id": obj.id,
                "Admin Id": obj.admin_id,
                "Kode": obj.code,
                "Aktivitas": obj.activity,
                "Waktu": obj.created_at

            }
        })
    }

    return (
        <>

            <Dialog open={isDelete}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Peserta
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus merchandise <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedMerchandise.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setIsDelete(false)
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={deleteMerchandise}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditMerchandiseModal
                isOpen={isUserInfoModalVisible}
                onClose={() => {
                    setUserInfoModalVisible(false)
                    setSelectedUserInfo({})
                    getLogs()
                }}
                merchandise={selectedUserInfo}
                isNew={isNew}
            />

            <UserParticipationModal
                isOpen={isClassModalVisible}
                onClose={closeUserParticipationModal}
                user={selectedMerchandise}
            />

            {/*<UserInfoModal*/}
            {/*    show={isUserInfoModalVisible}*/}
            {/*    onClose={() => setUserInfoModalVisible(false)}*/}
            {/*    member={selectedUserInfo}*/}
            {/*/>*/}

            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"logs"}
            >
                <>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 0}}>
                        <div style={{
                            marginTop: "0",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Log
                        </div>
                    </div>


                    <CSVLink
                        style={{
                            color: 'white', fontFamily: 'OpenSans-SemiBold', fontSize: '.8em', display: 'flex',
                            alignItems: 'center',
                            width: 190,
                            justifyContent: 'center',
                        }}
                        separator={";"}
                        data={getNewsletterRegistrant()}
                        filename={`data-log.csv`}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        Unduh Log&nbsp;&nbsp;<FaFileDownload/>
                    </CSVLink>
                    <MobTable
                        columns={columns}
                        data={userList}
                    />
                </>
            </EditorPageWrapper>
        </>
    );

}
