import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class User {

    login = async (user) =>{

        let userData = await ApiRequest.set("/v1/admins/login", "POST", {
            email : user.email,
            password : user.password
        });

        GlobalData.user = userData

        if(userData.token) {
            GlobalData.token = userData.token
        }

        return userData

    }

    tokenLogin = async()=>{
        let userData = await ApiRequest.set(`/v1/admins/tokenlogin`, "POST");
        GlobalData.user = userData
        // console.log(GlobalData.user)
        return userData
    }
}
