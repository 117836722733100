import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types'

import {makeStyles} from '@material-ui/core/styles';

import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'draft-js/dist/Draft.css'

import {Typography} from "@material-ui/core"

const useStyles = makeStyles({
    editor: {
        padding: "0px 14px 18.5px 14px",
        height: "20em",
        overflow: "scroll"
    },
    toolBar: {
        margin: "16px 8px",
        border: "solid 1px rgba(0, 0, 0, 0.23)"
    },
    wrapper: {
        color: "rgba(0, 0, 0, 0.87)",
        border: "solid 1px",
        borderColor: "rgba(0, 0, 0, 0.23)",
        borderRadius: "4px",
        transition: "padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",

        "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.87)"
        },

        "&:focus-within": {
            border: "solid 2px",
            //borderColor: theme.palette.primary.main,
        },
    },
    label: {
        position: "relative",
        top: "10px",
        left: "10px",
        backgroundColor: "#FFFFFF",
        padding: "0px 4px",
    },
    labelColorFocused: {
        //color: theme.palette.primary.main,
    }
})

export default function RichText(props) {

    RichText.propTypes = {
        value: PropTypes.string,
        onBlurAndSave: PropTypes.func.isRequired
    }

    const classes = useStyles()

    useEffect(() => {
        //props processing
        const rtf = props.value ?? null
        const contentBlock = rtf ? htmlToDraft(rtf) : null

        let newEditorState
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            newEditorState = EditorState.createWithContent(contentState)
        } else {
            newEditorState = EditorState.createEmpty()
        }
        setEditorState(newEditorState)
    }, [props])

    //props processing
    const rtf = props.value ?? null
    const contentBlock = rtf ? htmlToDraft(rtf) : null

    //Component States
    //draft editor state
    //If the props have a value content (RTF/HTML string) it will init the editor with contentBlock
    const [editorState, setEditorState] = useState(() => {
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            return EditorState.createWithContent(contentState)
        } else {
            return EditorState.createEmpty()
        }
    })

    const [isFocused, setIsFocused] = useState(false)

    let {label} = props

    return <div>
        <Typography
            color="textSecondary"
            variant="caption">
            {label}
        </Typography>
        <Editor
            toolbar={{
                options: [
                    'inline'
                ],
                inline: {
                    options: ['bold', 'italic', 'underline'],
                },
            }}
            ref={(ref) => {
                if (props.richTextRef) {
                    props.richTextRef(ref)
                }
            }}
            editorState={editorState}
            toolbarClassName={classes.toolBar}
            wrapperClassName={classes.wrapper}
            editorClassName={classes.editor}
            onFocus={() => {
                setIsFocused(true)
            }}
            // onEditorStateChange={(newState)=>{
            //     // setEditorState(newState)
            //     console.log(newState)
            //     props.onBlurAndSave(draftToHtml(convertToRaw(newState.getCurrentContent())))
            // }}
            onEditorStateChange={(newState) => {
                setEditorState(newState)
            }}
            onBlur={() => {
                const html = convertToRaw(editorState.getCurrentContent());

                console.log('html_saving', html, html.blocks)

                if (html.blocks) {
                    html.blocks.forEach((block, idx) => {
                        let temp = []
                        for(let style of html.blocks[idx].inlineStyleRanges){
                            if(style.style === "ITALIC" || style.style === "BOLD" || style.style === "UNDERLINE"){
                                temp.push(style)
                            }
                        }
                        html.blocks[idx].inlineStyleRanges = temp;
                    })
                }

                setIsFocused(false)
                props.onBlurAndSave(draftToHtml(html))
            }}
        />
    </div>


}
