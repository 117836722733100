import ApiRequest from "../util/ApiRequest";
import no_image from "../asset/no_image.png"

export default class Notification {
    getAll = async () => {
        let notifications = await ApiRequest.set("/v1/notification", "GET");

        return notifications
    }

    create = async (body) => {

        let notifications = await ApiRequest.set("/v1/notification", "POST", body);

        return notifications
    }
}
