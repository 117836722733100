import { Button, Col, Row } from "react-bootstrap";
import Palette from "../../../util/Palette";
import { TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Topic from "../../../models/Topic";
import Util from "../../../models/Util";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import ManageTopicModal from "../ManageTopicModal";
import InputAdornment from "@material-ui/core/InputAdornment";
import LagFreeTextEditor from "../LagFreeTextEditor";
import CustomButton from "../CustomButton";
import { AiFillWarning, FaTrash, MdClear, MdFileDownload, MdOpenInNew, SiZoom } from "react-icons/all";
import textFormatter from "../../../util/textFormatter";
import FileUpload from "../FileUpload";
import Email from "../../../models/Email";
import IconButton from "@material-ui/core/IconButton";
import { MdCheckCircle, MdModeEdit } from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import ManageRecordingModal from "../ManageRecordingModal";
import { useLocation } from "react-router-dom";
import ReactDOM from 'react-dom'
import moment from 'moment'
import Switch from "@material-ui/core/Switch";
import RichText from "../../reusable/RichText/LimitedRichText";
let speakerTimer;
let nameTimer;
let shortDescriptionTimer;
let longDescriptionTimer;
let urlTimer;
const topicModel = new Topic();

export default function AboutEditor(props) {

    let { editSessionState, editEventState, classObj, setClass } = props

    let queryParams = new URLSearchParams(useLocation().search);

    const [code, setCode] = useState(null);
    const [source, setSource] = useState('');
    const [streamUrl, setStreamUrl] = useState('');
    let [speaker, setSpeaker] = useState("")
    let [name, setName] = useState("")
    let [description, setDescription] = useState("")
    let [url, setURL] = useState("")
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(0);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isAddTopicModalShown, setAddTopicModalShown] = useState(false);

    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)
    const [isUploadingDocumentation, setIsUploadingDocumentation] = useState(false)
    const [attachment, setAttachment] = useState({})

    const [isManageRecordingModalShown, setManageRecordingModalShown] = useState(false);

    const [forceZoomEdit, setForceZoomEdit] = useState(false)

    const rekamanAnchorRef = useRef(null);
    const materiAnchorRef = useRef(null);


    useEffect(() => {
        getTopic();

        setCode(props.classObj.code);
        setSource(props.classObj.source);
        setSelectedTopic(props.classObj.topic_code)
        setName(props.classObj.name)
        setStreamUrl(props.classObj.stream_url);

        setSpeaker(props.speaker)
        setDescription(props.description)

        setURL(props.url)
    }, [props.speaker, props.shortDescription, props.longDescription, props.name, props.classObj])

    const getTopic = async () => {
        const result = await topicModel.getAll();

        console.log('topics', result)

        const topicsWithoutDeleted = [];

        result.forEach(topic => {
            if (topic.active) {
                topicsWithoutDeleted.push(topic)
            }
        })

        setTopics(topicsWithoutDeleted)
    }

    const editSpeaker = (value) => {
        setSpeaker(value)

        setClass({
            ...classObj,
            speaker: value
        })
    }

    const editDescription = (value) => {
        setDescription(value)

        setClass({
            ...classObj,
            description: value
        })
        console.log('hubla', value)
    }

    const editName = (value) => {
        setName(value)

        setClass({
            ...classObj,
            name: value
        })
    }

    const addAttachmentNew = async function (addedFile) {

        setIsUploadingAttachment(true)

        try {

            console.log(addedFile)
            let result = await new Email().addAttachment(addedFile[0])

            //
            console.log(result)

            let temp = { ...classObj }

            let attachmentUrls = []

            if (temp.attachment_urls && Array.isArray(temp.attachment_urls)) {
                attachmentUrls = temp.attachment_urls
            }

            attachmentUrls.push(result.location)

            setClass({
                ...classObj,
                attachment_urls: attachmentUrls
            })

            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }


    const addAttachment = async function (addedFile) {

        setIsUploadingAttachment(true)

        try {

            console.log(addedFile)
            let result = await new Email().addAttachment(addedFile[0])

            //
            console.log(result)

            setAttachment({
                value: result.location,
                label: addedFile[0].name
            })

            setClass({
                ...classObj,
                attachment_url: result.location
            })

            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }

    const addDocumentation = async function (addedFile) {

        setIsUploadingDocumentation(true)

        try {

            console.log(addedFile)
            let result = await new Email().addAttachment(addedFile[0])

            //
            console.log(result)

            setAttachment({
                value: result.location,
                label: addedFile[0].name
            })

            setClass({
                ...classObj,
                documentation_url: result.location
            })

            setIsUploadingDocumentation(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingDocumentation(false)

        }

    }

    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    return <>
        <ManageTopicModal visible={isAddTopicModalShown} hide={() => {
            setAddTopicModalShown(false)
        }} updateData={getTopic} />

        <ManageRecordingModal show={isManageRecordingModalShown}
            handleClose={() => setManageRecordingModalShown(false)}
            classObj={classObj}
            setClass={setClass} />

        <Row style={{ marginTop: 30 }}>
            <Col md={12}>
                <p style={{
                    color: Palette.PRIMARY,
                    fontFamily: "OpenSans-Bold",
                    fontSize: "1.2em"
                }}>Deskripsi Kelas</p>
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        ><Col md={12}>
                <LagFreeTextEditor
                    title={'Kode Kelas'}
                    disabled={props.update}
                    changeValue={value => {
                        if (!props.update) {
                            setClass({
                                ...classObj,
                                code: value
                            })
                        }
                    }}
                    subtitle={'(akan dibuat secara acak jika dibiarkan kosong)'}
                    value={code}
                    placeholder={"Kode Kelas"} />
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        ><Col md={12}>
                <LagFreeTextEditor
                    changeValue={value => setClass({
                        ...classObj,
                        ios_product_id: value
                    })}
                    InputProps={{
                        style: {
                            fontFamily: "OpenSans-Regular"
                        },
                    }}
                    title={classObj.ios_product_id || !props.update ? 'Product ID' : 'ID Product'}
                    // disabled={props.update}
                    value={classObj.ios_product_id ? classObj.ios_product_id : classObj.id}
                    placeholder={classObj.ios_product_id || !props.update ? 'Product ID' : 'ID Product'} />
            </Col>
        </Row>
        {
            props.update ?
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: "1em",
                    fontFamily: 'OpenSans-SemiBold'
                }}
                ><Col md={12}>
                        <LagFreeTextEditor


                            InputProps={{
                                style: {
                                    fontFamily: "OpenSans-Regular"
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {

                                            <IconButton
                                                onMouseDown={e => e.preventDefault()}
                                            >
                                                <a target="_blank"
                                                    href={`https://sinara.narabahasa.id/kelas/detail/${code}`}>
                                                    <MdOpenInNew style={{ color: Palette.PRIMARY }} />
                                                </a>
                                            </IconButton>

                                        }

                                    </InputAdornment>
                                ),
                            }}

                            title={'Tautan Kelas'}
                            disabled={props.update}
                            changeValue={value => {
                                if (!props.update) {
                                    setClass({
                                        ...classObj,
                                        code: value
                                    })
                                }
                            }}
                            value={`https://s.narabahasa.id/${code}`}
                            placeholder={"Kode Kelas"} />
                    </Col>
                </Row>
                :
                null
        }

        <Row
            style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                marginTop: "1em",
                fontFamily: 'OpenSans-SemiBold',
                scrollPaddingTop: "6em", scrollMarginTop: "6em",
            }}
            id={"tautan_streaming"}
        >
            <Col md={12}>
                <LagFreeTextEditor
                    disabled={classObj.zoom_id}
                    InputProps={{
                        style: {
                            fontFamily: "OpenSans-Regular"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {

                                    <IconButton
                                        onClick={async () => {
                                            try {
                                                let utilModel = new Util()

                                                console.log(moment(classObj.start_time).format("DD-MM-YYYYTHH:mm:ss") + "Z")

                                                let duration = (new Date(classObj.end_time) - new Date(classObj.start_time)) / 1000 / 60

                                                if (!classObj.start_time || !classObj.end_time || !classObj.name) {
                                                    return alert("Pastikan waktu mulai, waktu selesai dan nama kelas telah diisi")
                                                }

                                                console.log("generating zoom")

                                                let result = await utilModel.generateZoom({
                                                    start_time: moment(classObj.start_time).format("YYYY-MM-DDTHH:mm:ss"),
                                                    duration: duration,
                                                    name: classObj.name
                                                })

                                                console.log(result)

                                                setClass({
                                                    ...classObj,
                                                    stream_url: result.join_url,
                                                    zoom_id: result.id
                                                })

                                            } catch (e) {
                                                console.log(e)
                                            }

                                        }}
                                        disabled={classObj.zoom_id}
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <SiZoom style={{ color: Palette.PRIMARY }} />
                                    </IconButton>

                                }

                            </InputAdornment>
                        ),
                    }}
                    title={'Tautan Streaming'}
                    subtitle={'(kosongkan bila belum tersedia)'}
                    changeValue={value => setClass({
                        ...classObj,
                        stream_url: value
                    })}
                    value={streamUrl}
                    placeholder={"Tautan Streaming"} />
                {
                    !validURL(classObj.stream_url) ?
                        <div style={{
                            color: "grey",
                            fontWeight: "lighter"
                        }}>
                            <AiFillWarning
                                style={{ marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em" }} />
                            Peringatan : Pastikan URL yang diketik valid (contoh :
                            https://us02web.zoom.us/j/82590270612)
                        </div>
                        :
                        null
                }
                {
                    !!classObj.zoom_id && <div style={{
                        color: "grey",
                        fontWeight: "lighter"
                    }}>
                        Kelas ini telah terhubung dengan Zoom Sinara&nbsp;
                        <small><a
                            onClick={(e) => {
                                e.preventDefault()
                                setClass({
                                    ...classObj,
                                    zoom_id: ""
                                })
                                alert("Peringatan : Dengan mengganti tautan, kelas tidak akan tersinkron dengan Zoom yang dibuat oleh sistem. Untuk membatalkan proses ini silahkan refresh halaman ini.")
                            }}
                            href={"#"}>(Ganti Tautan)</a></small>
                    </div>
                }
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <div
                    id={"tautan_rekaman"}
                    style={{
                        scrollPaddingTop: "6em", scrollMarginTop: "6em",
                        display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
                    }}>
                    <span
                        style={{ fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold' }}>
                        Tautan Rekaman&nbsp;
                        <span
                            ref={rekamanAnchorRef}
                            style={{
                                fontFamily: 'OpenSans-Regular', fontSize: '.8em', color: 'grey',
                            }}>(kosongkan bila belum tersedia)</span></span>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {
                            classObj.recording_url_array?.map(recording => {
                                return (
                                    <a href={recording.url} style={{ marginRight: 15 }}>
                                        <CustomButton
                                            style={{
                                                fontSize: "0.8em",
                                                fontFamily: "Poppins",
                                                textTransform: "none",
                                                marginTop: 6,
                                                color: Palette.PRIMARY
                                            }}
                                            variant={"outlined"}>
                                            <AiOutlineLink
                                                style={{ marginRight: 5, color: Palette.PRIMARY }} /> {recording.title}
                                        </CustomButton>
                                    </a>
                                )
                            })
                        }


                        <CustomButton
                            onClick={() => setManageRecordingModalShown(true)}
                            style={{
                                fontSize: "0.8em",
                                fontFamily: "Poppins",
                                textTransform: "none",
                                marginTop: 6
                            }}
                            variant={"outlined"}>
                            <MdModeEdit style={{ marginRight: 5 }} /> Sunting Tautan
                        </CustomButton>
                    </div>
                </div>


                {/*<LagFreeTextEditor*/}
                {/*    title={'Tautan Rekaman'}*/}
                {/*    subtitle={'(kosongkan bila belum tersedia)'}*/}
                {/*    changeValue={value => setClass({*/}
                {/*        ...classObj,*/}
                {/*        recording_url: value*/}
                {/*    })}*/}
                {/*    value={classObj.recording_url}*/}
                {/*    placeholder={"Tautan Rekaman"}/>*/}
                {/*{*/}
                {/*    !validURL(classObj.recording_url) ?*/}
                {/*        <div style={{*/}
                {/*            color: "grey",*/}
                {/*            fontWeight: "lighter"*/}
                {/*        }}>*/}
                {/*            <AiFillWarning*/}
                {/*                style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>*/}
                {/*            Peringatan : Pastikan URL yang diketik valid (contoh :*/}
                {/*            https://us02web.zoom.us/j/82590270612)*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}

            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <LagFreeTextEditor
                    title={'Tautan Whatsapp'}
                    subtitle={'(kosongkan bila belum tersedia)'}
                    changeValue={value => setClass({
                        ...classObj,
                        whatsapp_url: value
                    })}
                    value={classObj.whatsapp_url}
                    placeholder={"Tautan Whatsapp"} />
                {
                    !validURL(classObj.whatsapp_url) ?
                        <div style={{
                            color: "grey",
                            fontWeight: "lighter"
                        }}>
                            <AiFillWarning
                                style={{ marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em" }} />
                            Peringatan : Pastikan URL yang diketik valid (contoh :
                            https://us02web.zoom.us/j/82590270612)
                        </div>
                        :
                        null
                }

            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <LagFreeTextEditor
                    title={'Nama Kelas'}
                    changeValue={value => editName(value)}
                    value={name}
                    placeholder={"Nama Kelas"} />
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <LagFreeTextEditor
                    title={<div className={"d-flex flex-row align-items-center"}>
                        {classObj.narasumber_instead_of_pengajar ? "Narasumber" : "Pengajar"}
                        <Switch
                            size={"small"}
                            checked={classObj.narasumber_instead_of_pengajar}
                            value={classObj.narasumber_instead_of_pengajar}
                            onChange={(value) => {
                                setClass({
                                    ...classObj,
                                    narasumber_instead_of_pengajar: !classObj.narasumber_instead_of_pengajar
                                })
                            }}
                        />
                        <div style={{fontSize : "0.6em", color : "grey"}}>{classObj.narasumber_instead_of_pengajar ? "Tekan untuk mengubah keterangan menjadi Pelajar" : "Tekan untuk mengubah keterangan menjadi Narasumber"}</div>
                    </div>}
                    changeValue={value => editSpeaker(value)}
                    value={classObj.speaker}
                    placeholder={"Pembicara"}/>
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col xs={12}>Deskripsi</Col>
            <Col md={12}>
                <RichText
                    onBlurAndSave={(value) => {
                        editDescription(value)
                    }
                    } value={classObj.description} />
                {/* <LagFreeTextEditor
                    title={'Deskripsi'}
                    multiline={true}
                    rows={4}
                    changeValue={value => editDescription(value)}
                    value={classObj.description}
                    placeholder={"Deskripsi"} /> */}
            </Col>
        </Row>
        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12} sm={12} style={{*/}
        {/*        fontSize: "1.2em",*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "flex-end"*/}
        {/*    }}>*/}
        {/*        <TextField*/}
        {/*            // onBlur={()=>editEventState("nama", name)}*/}
        {/*            onChange={evt => editName(evt.target.value)}*/}
        {/*            style={{*/}
        {/*                marginLeft: "0.5em",*/}
        {/*                fontSize: "1em",*/}
        {/*                fontFamily: 'OpenSans-Regular'*/}
        {/*            }}*/}
        {/*            inputProps={{*/}
        {/*                style: {*/}
        {/*                    fontSize: "1em",*/}
        {/*                    fontFamily: 'OpenSans-Regular',*/}
        {/*                    cursor: "pointer"*/}
        {/*                }*/}
        {/*            }}*/}
        {/*            value={name} fullWidth placeholder={"Nama Kelas"}/>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold',
        }}
        >
            <Col md={12} style={{ fontSize: '.9em', display: 'flex' }}><span style={{ flex: 1 }}>Topik</span> <Button
                onMouseEnter={() => setBtnHovered(true)}
                onMouseLeave={() => setBtnHovered(false)}
                onClick={() => setAddTopicModalShown(true)}
                style={{ fontSize: '.8em', fontFamily: 'OpenSans-SemiBold' }} variant={'link'}>Tambah Topik</Button></Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
        }}
        >
            <Col md={12} sm={12} style={{
                display: "flex",
                alignItems: "flex-end"
            }}>
                <Select
                    fullWidth
                    value={selectedTopic ? selectedTopic : 0}
                    onChange={evt => {
                        setClass({
                            ...classObj,
                            topic_code: evt.target.value
                        })
                        setSelectedTopic(evt.target.value)
                    }}
                    style={{
                        marginLeft: "0.5em",
                        fontFamily: 'OpenSans-Regular',
                        fontSize: "1.2em",
                    }}
                >
                    <MenuItem style={{ fontFamily: 'OpenSans-Regular', }} value={0} disabled>Pilih Topik</MenuItem>

                    {topics.map(topic => {
                        return (
                            <MenuItem style={{ fontFamily: 'OpenSans-Regular', }}
                                value={topic.code}>{topic.name}</MenuItem>
                        )
                    })}
                </Select>
            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col*/}
        {/*        md={12}>*/}
        {/*        <p*/}
        {/*            id={"materi"}*/}
        {/*            style={{*/}
        {/*                marginTop: "1em",*/}
        {/*                scrollPaddingTop: "6em", scrollMarginTop: "6em",*/}
        {/*            }}>*/}
        {/*            Materi :*/}
        {/*        </p>*/}

        {/*        <div style={{*/}
        {/*            marginLeft: "1em",*/}
        {/*            display: "flex",*/}
        {/*            flexDirection: "column"*/}
        {/*        }}>*/}

        {/*            <div style={{*/}
        {/*                marginTop: "0.5em"*/}
        {/*            }}>*/}
        {/*                <a*/}
        {/*                    style={{*/}
        {/*                        display: classObj.attachment_url ? null : "none"*/}
        {/*                    }}*/}
        {/*                    href={classObj.attachment_url} target={'_blank'} download>*/}
        {/*                    <CustomButton*/}
        {/*                        style={{*/}
        {/*                            fontSize: "0.85em",*/}
        {/*                            fontFamily: "Poppins",*/}
        {/*                            textTransform: "none",*/}
        {/*                            marginBottom: "1em",*/}
        {/*                        }}*/}
        {/*                        variant={"outlined"}>*/}
        {/*                        {classObj.attachment_url ? textFormatter.getAttachmentURL(classObj.attachment_url) : ""}*/}
        {/*                        <MdFileDownload style={{marginLeft: "0.5em"}}/>*/}
        {/*                    </CustomButton>*/}
        {/*                </a>*/}

        {/*                <FileUpload*/}
        {/*                    style={{*/}
        {/*                        marginTop: "0.5em"*/}
        {/*                    }}*/}
        {/*                    label={classObj.attachment_url ? "Ubah Lampiran" : "+ Unggah Lampiran"}*/}
        {/*                    isLoading={isUploadingAttachment}*/}
        {/*                    onDrop={addAttachment}*/}
        {/*                />*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col
                md={12}>
                <p
                    id={"materi"}
                    style={{
                        marginTop: "1em",
                        scrollPaddingTop: "6em", scrollMarginTop: "6em",
                    }}>
                    Materi :
                </p>

                <div style={{
                    marginLeft: "1em",
                    display: "flex",
                    flexDirection: "column"
                }}>

                    <div style={{
                        marginTop: "0.5em"
                    }}>

                        {
                            Array.isArray(classObj.attachment_urls) ?
                                classObj.attachment_urls.map((url, key) => {
                                    return <div style={{
                                        marginRight: 10,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginBottom: "1em",
                                    }}>
                                        <a
                                            style={{
                                                display: url ? null : "none"
                                            }}
                                            href={url} target={'_blank'} download>
                                            <CustomButton
                                                style={{
                                                    fontSize: "0.85em",
                                                    fontFamily: "Poppins",
                                                    textTransform: "none",
                                                    marginRight: 5
                                                }}
                                                variant={"outlined"}>
                                                {url ? textFormatter.getAttachmentURL(url) : ""}
                                                <MdFileDownload style={{ marginLeft: "0.5em" }} />
                                            </CustomButton>
                                        </a>
                                        <FaTrash
                                            onClick={() => {
                                                let temp = [...classObj.attachment_urls]
                                                let newTemp = temp.splice(key, 1)
                                                console.log(temp, key, newTemp)
                                                setClass({
                                                    ...classObj,
                                                    attachment_urls: temp
                                                })
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        />
                                    </div>
                                })
                                : <></>
                        }

                        <FileUpload
                            style={{
                                marginTop: "0.5em"
                            }}
                            label={"+ Unggah Lampiran"}
                            isLoading={isUploadingAttachment}
                            onDrop={addAttachmentNew}
                        />
                    </div>

                </div>
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col
                md={12}>
                <p
                    id={"documentation"}
                    style={{
                        marginTop: "1em",
                        scrollPaddingTop: "6em", scrollMarginTop: "6em",
                    }}>
                    Dokumentasi :
                </p>

                <div style={{
                    marginLeft: "1em",
                    display: "flex",
                    flexDirection: "column"
                }}>

                    <div style={{
                        marginTop: "0.5em"
                    }}>
                        <a
                            style={{
                                display: classObj.documentation_url ? null : "none"
                            }}
                            href={classObj.documentation_url} target={'_blank'} download>
                            <CustomButton
                                style={{
                                    fontSize: "0.85em",
                                    fontFamily: "Poppins",
                                    textTransform: "none",
                                    marginBottom: "1em",
                                }}
                                variant={"outlined"}>
                                {classObj.documentation_url ? textFormatter.getAttachmentURL(classObj.documentation_url) : ""}
                                <MdFileDownload style={{ marginLeft: "0.5em" }} />
                            </CustomButton>
                        </a>

                        <FileUpload
                            style={{
                                marginTop: "0.5em",
                            }}
                            text={"+ Unggah Dokumentasi"}
                            label={classObj.documentation_url ? "Ubah Dokumentasi" : "+ Unggah Dokumentasi"}
                            isLoading={isUploadingDocumentation}
                            onDrop={addDocumentation}
                        />
                    </div>

                </div>
            </Col>
        </Row>

    </>

}
