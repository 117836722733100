import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import React, {useEffect, useMemo, useState, useRef} from "react";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CustomButton from "../../reusable/CustomButton";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Form from "react-bootstrap/Form";
import FileUpload from "../../reusable/FileUpload";
import {FaSave} from "react-icons/fa";
import InstructorModel from "../../../models/Instructor";
import RichText from "../../reusable/RichText/LimitedRichText";
import {Parser} from 'html-to-react'

export default function AllInstructor(props) {
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isManageInstructorModalVisible, setManageInstructorDialogVisible] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState({});
    const [isDeleteInstructorDialogVisible, setDeleteInstructorDialogVisible] = useState(false)
    const [instructors, setInstructors] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState('');
    const inputRef = useRef(null);
    let htmlParser = new Parser()

    const columns = useMemo(
        () => [
            {
                Header: "Foto",
                accessor: "photo",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original

                    return <img
                        src={row.instructor_image_url ? row.instructor_image_url : require('../../../asset/public_image_default_user.png')}
                        style={{width: 50, height: 50, borderRadius: 25, objectFit: 'cover'}}/>
                }
            },
            {
                Header: "Nama",
                accessor: "name"
            },
            {
                Header: "Posisi",
                accessor: "position"
            },
            {
                Header: "Deskripsi",
                accessor: "description",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original

                    return <>{htmlParser.parse(row.description)}</>
                }
            }
        ]
    )

    const interactions = [
        {
            name: "Kelola",
            action: (obj) => {
                setSelectedInstructor(obj)
                setManageInstructorDialogVisible(true)
            },
            style: {width: 135},
        },
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedInstructor(obj)
                setDeleteInstructorDialogVisible(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    useEffect(() => {
        getAllInstructors()
    }, [])

    const getAllInstructors = async () => {
        const instructorModel = new InstructorModel();

        try {
            const result = await instructorModel.getAll();

            setInstructors(result)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <EditorPageWrapper
                privateClass={true}
                activeTab={"all-instructor"}
            >
                <Dialog open={isDeleteInstructorDialogVisible}
                        onClose={() => setDeleteInstructorDialogVisible(false)}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Widyaiswara
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus widyaiswara <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedInstructor.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleteInstructorDialogVisible(false)
                                        setSelectedInstructor({})
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        try {
                                            const instructorModel = new InstructorModel();

                                            const result = await instructorModel.delete(selectedInstructor.id);

                                            if (result.success) {
                                                setDeleteInstructorDialogVisible(false)
                                                setSelectedInstructor({})
                                                getAllInstructors();

                                                alert(`Widyaiswara telah terhapus!`)
                                            }

                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Dialog open={isManageInstructorModalVisible}
                        maxWidth="md"
                        fullWidth={true}
                        onClose={() => {
                            setSelectedInstructor({})
                            setManageInstructorDialogVisible(false)
                        }}
                >
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                Kelola Widyaiswara
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent>
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                        }}
                        >
                            <Col md={12}>
                                <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Foto</div>

                                <div style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                                    {selectedInstructor.instructor_image_url &&
                                    <img
                                        style={{
                                            width: 50,
                                            height: 50,
                                            borderRadius: 25,
                                            objectFit: 'cover',
                                            marginRight: 15
                                        }}
                                        src={selectedInstructor.instructor_image_url}/>
                                    }

                                    <FileUpload
                                        style={{
                                            marginTop: "0.5em",
                                        }}
                                        text={selectedInstructor.instructor_image_url ? 'Ganti Foto' : 'Unggah Foto'}
                                        // label={classObj.attachment_url ? "Ubah Lampiran" : "+ Unggah Lampiran"}
                                        // isLoading={true}
                                        onDrop={async (images) => {
                                            const instructorModel = new InstructorModel();

                                            try {
                                                const result = await instructorModel.uploadCourseImage(selectedInstructor.id, images[0]);

                                                setSelectedInstructor(
                                                    {
                                                        ...selectedInstructor,
                                                        instructor_image_url: result.location
                                                    }
                                                )
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    />

                                </div>
                            </Col>
                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Surel'}
                                    value={selectedInstructor.email}
                                    changeValue={(value) => {
                                        const selectedInstructorTemp = {...selectedInstructor};

                                        selectedInstructorTemp.email = value;

                                        setSelectedInstructor(selectedInstructorTemp);
                                    }}
                                    placeholder={"Surel"}/>
                            </Col>
                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Nama'}
                                    value={selectedInstructor.name}
                                    changeValue={(value) => {
                                        const selectedInstructorTemp = {...selectedInstructor};

                                        selectedInstructorTemp.name = value;

                                        setSelectedInstructor(selectedInstructorTemp);
                                    }}
                                    placeholder={"Nama"}/>
                            </Col>
                        </Row>

                        {!selectedInstructor.id &&
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    type={'password'}
                                    title={'Kata Sandi'}
                                    value={selectedInstructor.password}
                                    changeValue={(value) => {
                                        const selectedInstructorTemp = {...selectedInstructor};

                                        selectedInstructorTemp.password = value;

                                        setSelectedInstructor(selectedInstructorTemp);
                                    }}
                                    placeholder={"Kata Sandi"}/>
                            </Col>
                        </Row>
                        }

                        {!selectedInstructor.id &&
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    type={'password'}
                                    title={'Konfirmasi Kata Sandi'}
                                    value={confirmPassword}
                                    changeValue={(value) => {
                                        setConfirmPassword(value)
                                    }}
                                    placeholder={"Konfirmasi Kata Sandi"}/>
                            </Col>
                        </Row>
                        }

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            fontFamily: 'OpenSans-SemiBold',
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Posisi'}
                                    value={selectedInstructor.position}
                                    changeValue={(value) => {
                                        const selectedInstructorTemp = {...selectedInstructor};

                                        selectedInstructorTemp.position = value;

                                        setSelectedInstructor(selectedInstructorTemp);
                                    }}
                                    placeholder={"Posisi"}/>
                            </Col>
                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Deskripsi</span>


                                <RichText
                                    onBlurAndSave={(value) => {
                                    const selectedInstructorTemp = {...selectedInstructor};

                                    selectedInstructorTemp.description = value;

                                    setSelectedInstructor(selectedInstructorTemp);
                                }
                                } value={selectedInstructor.description}/>
                            </Col>
                        </Row>


                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '3em',
                            paddingBottom: '1em',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                        >
                            <input ref={inputRef} style={{pointerEvents: 'none', opacity: 0}}/>
                            <CustomButton
                                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => {
                                    setSelectedInstructor({})
                                    setManageInstructorDialogVisible(false)
                                }}
                                variant={"outlined"} color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                onMouseOver={() => inputRef.current.focus()}
                                onClick={async () => {
                                    if (!selectedInstructor.name || !selectedInstructor.email) {
                                        alert('Mohon mengisi formulir dengan lengkap')
                                    } else {
                                        const instructorModel = new InstructorModel();

                                        if (selectedInstructor.id) {
                                            try {
                                                const result = await instructorModel.update(selectedInstructor.id, {
                                                    ...selectedInstructor,
                                                })

                                                if (result.success) {
                                                    getAllInstructors()
                                                    setSelectedInstructor({})
                                                    setManageInstructorDialogVisible(false)
                                                    alert('Widyaiswara berhasil diperbarui!');
                                                }
                                                console.log('result', result)
                                            } catch (e) {
                                                console.log('e', e)
                                            }
                                        } else {
                                            if (!selectedInstructor.password) {
                                                alert('Mohon mengisi formulir dengan lengkap')
                                            } else if (selectedInstructor.password !== confirmPassword) {
                                                alert('Konfirmasi kata sandi salah')
                                            } else {
                                                try {
                                                    const result = await instructorModel.insert({
                                                        ...selectedInstructor
                                                    })

                                                    if (result.id) {
                                                        getAllInstructors()
                                                        setSelectedInstructor({})
                                                        setManageInstructorDialogVisible(false)
                                                        alert('Widyaiswara berhasil ditambahkan!');
                                                    }
                                                    console.log('result', result)
                                                } catch (e) {
                                                    console.log('e', e)
                                                }
                                            }
                                        }
                                    }
                                }}
                                style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"contained"} color="primary">
                                <>Simpan&nbsp;&nbsp;<FaSave/></>
                            </CustomButton>
                        </Row>
                    </DialogContent>
                </Dialog>

                <>
                    <div style={{
                        marginTop: "0.5em",
                        // marginBottom: "0.5em",
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontWeight: "800",
                        fontSize: "1.5em"
                    }}>Widyaiswara
                    </div>

                    <Row style={{marginLeft: 2, marginTop: 15}}>
                        <Button variant={'outline-primary'}
                                onClick={() => setManageInstructorDialogVisible(true)}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                    width: 180,
                                    marginLeft: 5
                                }}
                        >
                            + <span style={{marginLeft: 6}}>Tambah Widyaiswara</span>
                        </Button>
                    </Row>

                    <div>
                        <MobTable
                            columns={columns}
                            data={instructors}
                            interactions={interactions}
                        />
                    </div>
                </>
            </EditorPageWrapper>
        </>
    )
}
