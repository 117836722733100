import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Instructor {
    getAll = async () => {
        return await ApiRequest.set("/v1/instructor", "GET");
    }

    insert = async (instructor) => {
        return await ApiRequest.set("/v1/instructor", "POST", instructor);
    }

    update = async (id, instructor) => {
        return await ApiRequest.set(`/v1/instructor/${id}`, "PUT", instructor);
    }

    delete = async (id) => {
        return await ApiRequest.set(`/v1/instructor/${id}`, "DELETE");
    }

    uploadCourseImage = async (id, image) => {

        let formData = new FormData();

        formData.append('upload', image, image.name);

        if (id) {
            return await ApiRequest.setMultipart(`/v1/instructor/${id}/upload_image`, "POST", formData);
        } else {
            return await ApiRequest.setMultipart(`/v1/instructor/upload_image`, "POST", formData);
        }
    }

    getByCourseId = async (courseId) => {
        return await ApiRequest.set(`/v1/instructor/${courseId}`, "GET");
    }

    login = async (user) => {
        let userData = await ApiRequest.set("/v1/instructor/login", "POST", {
            email: user.email,
            password: user.password
        });

        GlobalData.user = userData

        if (userData.token) {
            GlobalData.token = userData.token
        }

        return userData

    }

    tokenLogin = async()=>{
        let userData = await ApiRequest.set(`/v1/instructor/tokenlogin`, "POST");
        GlobalData.user = userData
        // console.log(GlobalData.user)
        return userData
    }
}
