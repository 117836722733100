import ApiRequest from "../util/ApiRequest";

export default class Email {

    sendBatch = async (params) => {
        console.log('Params Cuwakss', params)
        let response = await ApiRequest.set("/v1/email/send", "POST", {
            ...params
        });

        return response;
    }

    sendBatch_v2 = async (params) => {
        console.log('Params Cuwakss V2', params)
        let response = await ApiRequest.set("/v1/email/send/multiple", "POST", {
            ...params
        });

        return response;
    }

    addAttachment = async (file) => {

        let formData = new FormData();

        formData.append('upload', file, file.name);
        const response = await ApiRequest.setMultipart('/v1/upload/attachment', 'POST', formData);

        return response;

    }

    addAttachmentGoogleDrive = async (file) => {

        let formData = new FormData();

        formData.append('upload', file, file.name);
        const response = await ApiRequest.setMultipart('/v1/course/upload_video', 'POST', formData);

        console.log(response)
        return response;

    }

    getTemplates = async (eventId) => {

        let response = await ApiRequest.set(`/v1/email_templates`, 'GET')
        return response
    }

    editTemplate = async (templateId, templateObject) => {
        let response = await ApiRequest.set(`/v1/email_template/${templateId}`, 'PUT', templateObject)
        return response
    }

    getLogs = async () =>{
        let response = await ApiRequest.set('/v1/email/sent_emails', 'GET')
        return response
    }

    getLogsV2 = async () =>{
        //Response api nya error karena table 'email' tidak ada
        let response = await ApiRequest.set('/v1/email/history', 'GET')
        return response
    }

    static changeTemplate = async (sessionId, templateType, templateId) => {

        let body = {
            template_type: templateType,
            template_id: templateId
        }

        let response = await ApiRequest.set(`/v2/sessions/${sessionId}/template`, 'PATCH', body)

        return response
    }

    /**
     * @param eventId
     * @param templateName
     * @param subjectTemplate
     * @param bodyTemplate
     * @param scenario
     * @returns {Promise<*>}
     */
    static addTemplate = async (templateName, subjectTemplate, bodyTemplate, scenario = "GENERAL") => {
        let templateObject = {
            template_name: templateName,
            scenario: scenario,
            body_template: bodyTemplate,
            subject_template: subjectTemplate
        }


        let response = await ApiRequest.set(`/v1/email_templates`, 'POST', templateObject)
        console.log(response)
        return response
    }
}
