import {withStyles} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Parser} from "html-to-react";

export default function QuizRadioButton (props) {
    const {question, description, options, value, html} = props;

    const [selectedOption, setSelectedOption] = useState(null);
    const htmlParser = new Parser()

    const CustomRadio = withStyles({
        root: {
            color: 'grey',
            '&$checked': {
                color: '#3882e0',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    return (
        <Row style={{
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            borderRadius: 10,
            marginLeft: 5,
            marginRight: 5,
            backgroundColor: 'white',
            maxWidth: 900,
            width: '100%',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 35,
            paddingBottom: 25,
            marginTop: 20
        }}>
            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '18px', color: '#202124'}}>
                {question}
            </Col>

            <Col md={12}
                 style={{fontFamily: 'OpenSans-Regular', fontSize: '15px', marginTop: '4px', color: '#878787'}}>
                {description}
            </Col>

            {
                options.map(option => {
                    return (
                        <Col md={12} style={{display: 'flex', alignItems: 'center', marginTop: 20, cursor: 'default'}}>
                            <CustomRadio disabled={true} checked={value === option}/>
                            <span style={{fontSize: '18px', fontFamily: 'OpenSans-Regular', marginLeft: 5, cursor: 'pointer'}}>{html ? htmlParser.parse(option) : option}</span>
                        </Col>
                    )
                })
            }

        </Row>
    )
}
