import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Admin {
    updateMyProfile = async (admin) => {
        return await ApiRequest.set("/v1/admin", "PUT", {
            email: admin.email,
            name: admin.name
        });
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/admins", "GET");
    }

    createNew = async (admin) => {
        return await ApiRequest.set("/v1/admins/create", "POST", admin);
    }

    update = async (admin, id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "PUT", admin);
    }

    updatePassword = async (params, id) => {
        return await ApiRequest.set(`/v1/admin/password`, "PUT", {
            oldPassword : params.oldPassword,
            newPassword : params.newPassword
        });
    }

    ban = async (id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "DELETE");
    }

    unban = async (id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "PATCH");
    }

    deleteAdmin = async (id) => {
        return await ApiRequest.set(`/v1/admin_hard/${id}`, "delete");
    }

    resetPassword = async (admin_id, newPassword) => {
        return await ApiRequest.set(`/v1/admin/reset/password`, "PUT", {
            admin_id, newPassword
        });
    }
}
