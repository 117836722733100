import Dropzone from "react-dropzone";
import IconButton from "@material-ui/core/IconButton";
import {FaCloudUploadAlt} from "react-icons/all";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import {Spinner} from "react-bootstrap";
import React from "react";

export default function FileUploadIcon(props) {

    const {isLoading, progress} = props


    const onDrop = (image) => {

        console.log(image)
        console.log(image[0].type)

        if (props.allowedType) {
            if (!props.allowedType.includes(image[0].type)) {
                alert("Harap Unggah File dengan Tipe  : " + props.allowedType)
                return
            }
        }

        props.onDrop(image)

    }

    return (
        <>

            <Dropzone
                noDrag={true}
                onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div
                        style={{width: 60, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        {...getRootProps()}>
                        {!isLoading ?
                            <IconButton disabled={isLoading} variant={"outlined"} style={{width: 60, height: 60}}>
                                <a target="_blank">
                                    <FaCloudUploadAlt style={{color: Palette.PRIMARY, marginBottom: 4, marginLeft: 4}}/>
                                </a>
                                <input {
                                           ...getInputProps()
                                       }
                                />
                            </IconButton> :
                            <div style={{marginLeft: " 0.5em", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Spinner
                                    size={"sm"}
                                    style={{
                                        display: "block"
                                    }}
                                    animation="border"/>
                                {progress ? <div style={{fontSize: 13, marginLeft: 5, marginTop: 3}}>{progress}%</div> : null}
                            </div>
                        }
                    </div>
                )}
            </Dropzone>

        </>

    )
}
