import ApiRequest from "../util/ApiRequest";

export default class CourseChapter {
    insert = async (courseChapter) => {
        return await ApiRequest.set("/v1/course_chapter", "POST", courseChapter);
    }

    update = async (id, courseChapter) => {
        return await ApiRequest.set(`/v1/course_chapter/${id}`, "PUT", courseChapter);
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/course_chapter/${id}`, "GET");
    }

    getByCourseId = async (id) => {
        return await ApiRequest.set(`/v1/course_chapter/${id}/course`, "GET");
    }

    delete = async (id) => {
        return await ApiRequest.set(`/v1/course_chapter/${id}`, "DELETE");
    }

    uploadVideo = async(file, setProgress) =>{

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        return await ApiRequest.setMultipartWithProgress(`/v1/course_chapter/upload`, "POST", formData, setProgress);

    }
}
