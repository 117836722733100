import ApiRequest from "../util/ApiRequest";
import no_image from "../asset/no_image.png"

export default class Merchandise {

     getAll = async () => {
        let news = await ApiRequest.set("/v1/merchandises", "GET");

        return news
    }

    create = async (body) => {
         return await ApiRequest.set('/v1/merchandise/create', 'POST', body)
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/` + id, "GET");
    }

    deleteById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/` + id, "DELETE");
    }

    restoreById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/restore/` + id, 'PUT');
    }

    updateById = async (id, body) => {
         return await ApiRequest.set(`/v1/merchandise/` + id, "PUT", body)
    }

    uploadImage = async (image) =>{
        let formData = new FormData();
        formData.append('upload', image, 'photo');
        return await ApiRequest.setMultipart(`/v1/merchandise/upload_photo/n`, "POST", formData);
    }

    reUploadImage = async (id, image) =>{
        let formData = new FormData();
        console.log('reupload initiated')
        formData.append('upload', image, 'photo');
        return await ApiRequest.setMultipart(`/v1/merchandise/upload_photo/` + id, "POST", formData);
    }
}
