import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useState} from "react";
import Palette from "../../../util/Palette";
import Select from "react-select";
import Strings from "../../../models/Strings";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Class from "../../../models/Class";
import CustomButton from "../../reusable/CustomButton";
import {FaSave} from "react-icons/fa";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";

export default function UI(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)

    const [selectedInterface, setSelectedInterface] = useState(null);

    const [initialInterfaceValue, setInitialInterfaceValue] = useState({});
    const [updateInterfaceValue, setUpdateInterfaceValue] = useState({});

    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }


    const interfaceOptions = [
        {label: 'Detail Kelas', value: 'class_detail'},
        {label: 'Profil', value: 'profile_page'},
        {label: 'Header', value: 'header_menu'},
        {label: 'Transaksi', value: 'transaction_page'},
        {label: 'Detail Transaksi', value: 'transaction_detail'},
        {label: 'Kartu Transaksi', value: 'transaction_card'},
        {label: 'Catatan Kaki', value: 'footer'},
        {label: 'Detail Kelas Daring Mandiri', value: 'private_class_detail'},
    ];

    const stringsAssignment = {
        footer: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-footer.png',
            assignment: {
                1: 'about_title',
                2: 'contact_title',
                3: 'other_title',
                4: 'about_content',
                5: 'contact_address',
                6: 'contact_telephone',
                7: 'contact_email',
                8: 'other_main_website',
                9: 'other_privacy_policy',
                10: 'complaint'
            }
        },
        class_detail: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-class-detail.png',
            assignment: {
                1: 'label_list_class',
                2: 'label_watch_recording',
                3: 'label_download_theory',
                4: 'label_final_test',
                5: 'label_certificate',
                6: 'teacher',
                7: 'investation',
                8: 'quota',
                9: 'date',
                10: 'time',
                11: 'question_title',
                12: 'rating_reviews_title'
            }
        },
        profile_page: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-profile.png',
            assignment: {
                1: 'profile',
                2: 'name',
                3: 'email',
                4: 'edit_profile',
                5: 'birth_date',
                6: 'gender',
                7: 'province',
                8: 'city',
                9: 'organization',
                10: 'phone_num',
                11: 'subscribe_newsletter',

            }
        },
        private_class_detail: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-private-class-detail.png',
            assignment: {
                1: 'investation',
                2: 'rating',
                3: 'category',
                4: 'about_class',
                5: 'lesson_list',
                6: '100%_online',
                7: 'flexible_time',
                8: 'about_widyaiswara',
                9: 'discuss_topic',
                10: 'review',
                11: 'stars',
                12: 'review_from_class',

            }
        },
        header_menu: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-header.png',
            assignment: {
                1: 'public_class_soon',
                2: 'creation',
                3: 'article',
                4: 'my_class',
                5: 'my_transaction'

            }
        },
        transaction_page: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-transaction-page.png',
            assignment: {
                1: 'waiting_payment',
                2: 'paid',
                3: 'transaction_not_found'
            }
        },
        transaction_detail: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-transaction-detail.png',
            assignment: {
                1: 'transaction_detail_title',
                2: 'subtotal',
                3: 'discount',
                4: 'total'
            }
        },
        transaction_card: {
            image: 'https://sinara.sgp1.digitaloceanspaces.com/numberedPages/numbered-transaction-card.png',
            assignment: {
                1: 'transaction_id',
                2: 'transaction_date',
                3: 'transaction_total',
                4: 'view_detail_transaction',
            }
        }
    }

    useEffect(() => {
        getStrings()
    }, [])

    useEffect(() => {
        if (selectedInterface) {
            console.log('updateInterf', JSON.parse(JSON.stringify(initialInterfaceValue)))

            setUpdateInterfaceValue(JSON.parse(JSON.stringify(initialInterfaceValue)));

            console.log(initialInterfaceValue);
        }
    }, [selectedInterface])

    const getStrings = async () => {
        try {
            const stringModel = new Strings();

            const result = await stringModel.get();
            console.log()
            setInitialInterfaceValue(JSON.parse(result.json));
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <EditorPageWrapper
            activeTab={"ui"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackBarProp.open} autoHideDuration={6000}
                    onClose={() => {
                        setSnackBarProp({
                            open: false
                        })
                    }}>
                    <Alert severity={snackBarProp.severity}>
                        {snackBarProp.message}
                    </Alert>
                </Snackbar>

                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginBottom: 30
                    }}>
                        Tampilan Antarmuka
                    </div>

                    <Select
                        onChange={(item) => {
                            setSelectedInterface(item)
                        }}
                        placeholder={"Pilih Tampilan"}
                        options={interfaceOptions}
                        value={selectedInterface}
                        style={{
                            display: "flex", width: "100%",
                        }}
                    />

                    {
                        selectedInterface &&
                        <div>
                            <div style={{marginTop: 30, display: 'flex', justifyContent: 'center', marginBottom: 30}}>
                                <img src={stringsAssignment[selectedInterface.value].image} style={{maxWidth: 900}}/>
                            </div>

                            <Row>
                                {Object.keys(stringsAssignment[selectedInterface.value].assignment).map(key => {
                                    const assignmentKey = stringsAssignment[selectedInterface.value].assignment[key];

                                    console.log('assignmentKey', assignmentKey)

                                    return (
                                        <Col xs={12}>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div style={{
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: 15,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: Palette.PRIMARY,
                                                    color: 'white',
                                                    fontFamily: 'OpenSans-Bold'
                                                }}>
                                                    {key}
                                                </div>

                                                <Form.Control as="textarea"
                                                              rows="3"
                                                    // placeholder={Strings.rating.feedback}
                                                              onChange={(e) => {
                                                                  const updateInterfaceValueTemp = {...updateInterfaceValue};

                                                                  (updateInterfaceValueTemp[selectedInterface.value])[assignmentKey] = e.target.value;

                                                                  setUpdateInterfaceValue(updateInterfaceValueTemp);
                                                              }}
                                                              value={updateInterfaceValue[selectedInterface.value] ? (updateInterfaceValue[selectedInterface.value])[assignmentKey] : null}
                                                              style={{
                                                                  border: '1px solid #6AD0F633',
                                                                  borderRadius: 0,
                                                                  resize: 'none',
                                                                  paddingLeft: 20,
                                                                  paddingTop: 15,
                                                                  fontFamily: 'OpenSans-Regular',
                                                                  marginLeft: 30,
                                                                  marginBottom: 20
                                                              }}/>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>


                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <CustomButton
                                    onClick={async () => {
                                        setIsLoadingBar(true)
                                        try {
                                            console.log();

                                            const stringModel = new Strings();

                                            const result = await stringModel.update({json: JSON.stringify(updateInterfaceValue)});

                                            showSnackBar('Tampilan antarmuka berhasil diperbarui!')

                                            setIsLoadingBar(false)
                                            console.log('result', result)
                                        } catch (e) {
                                            showSnackBar('Kesalahan Terjadi!', 'warning')

                                            setIsLoadingBar(false)
                                            console.log(e)
                                        }
                                    }}
                                    style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"contained"} color="primary">
                                    <>Simpan&nbsp;&nbsp;<FaSave/></>
                                </CustomButton>
                            </div>
                        </div>

                    }
                </div>
            </>
        </EditorPageWrapper>
    )
}
