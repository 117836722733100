import React, {useEffect, useMemo, useState} from "react";
import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import PieChart from "../../reusable/Charts/PieChart";
import {Pie, HorizontalBar, Bar} from "react-chartjs-2";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import {FaFileDownload, FaStar} from "react-icons/all"
import CustomButton from "../../reusable/CustomButton";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent, DialogTitle} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {MdAdd} from "react-icons/md";
import Card from "@material-ui/core/Card/Card";
import Class from "../../../models/Class";
import {useParams} from "react-router-dom";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor"
import {CSVLink} from "react-csv";
import moment from "moment";

export default function Feedback(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isDetailShown, setDetailShown] = useState(false)
    const [isFeedbackListShown, setIsFeedbackListShown] = useState(false)
    const [isNoteDialogShown, setIsNoteDialogShown] = useState(false)

    const [currentFeedback, setCurrentFeedback] = useState({})
    const [isFeedbackDetailDialogOpen, setIsFeedbackDetailDialogOpen] = useState(false)

    const [feedbacks, setFeedbacks] = useState([])

    const {event_url} = useParams()

    useEffect(() => {
        getFeedbacks()
        getFeedbackMetrics()
    }, [])

    const [topikSesuai, setTopikSesuai] = useState([0, 0, 0, 0, 0])
    const [topikDipahami, setTopikDipahami] = useState([0, 0, 0, 0, 0])
    const [pengajarJelas, setPengajarJelas] = useState([0, 0, 0, 0, 0])
    const [pengajarInteraktif, setPengajarInteraktif] = useState([0, 0, 0, 0, 0])
    const [pendaftaranMudah, setPendaftaranMudah] = useState([0, 0, 0, 0, 0])
    const [mekanismeJelas, setMekanismeJelas] = useState([0, 0, 0, 0, 0])

    const [interClassAverage, setInterClassAverage] = useState({})
    const [interClassData, setInterClassData] = useState([])

    const clamp = (num, min, max) => {
        return Math.min(Math.max(num, min), max)
    }

    const clampIPK = (num) => {
        return clamp(num, 1, 4)
    }

    const getFeedbackMetrics = async () => {
        try {
            let classModel = new Class()
            let feedbacks = await classModel.getFeedbackMetrics()

            console.log('feedbacks', feedbacks)

            let tempInterClass = {
                average: [],
                name: [],
                pendaftaran_mudah: [],
                pengajar_interaktif: [],
                pengajar_jelas: [],
                mekanisme_jelas: [],
                topik_dipahami: [],
                topik_sesuai: []
            }

            for (let obj of feedbacks) {
                tempInterClass.average.push((obj.pendaftaran_mudah + obj.pengajar_jelas + obj.pengajar_interaktif + obj.mekanisme_jelas + obj.topik_dipahami + obj.topik_sesuai) / 6)
                tempInterClass.name.push(obj.name)

                tempInterClass.pendaftaran_mudah.push(obj.pendaftaran_mudah)
                tempInterClass.pengajar_interaktif.push(obj.pengajar_interaktif)
                tempInterClass.pengajar_jelas.push(obj.pengajar_jelas)
                tempInterClass.mekanisme_jelas.push(obj.mekanisme_jelas)
                tempInterClass.topik_dipahami.push(obj.topik_dipahami)
                tempInterClass.topik_sesuai.push(obj.topik_sesuai)

            }


            // console.log(feedbacks)
            setInterClassAverage(tempInterClass)
            setInterClassData(feedbacks)

        } catch (e) {
            console.log(e)
        }
    }

    const getFeedbacks = async () => {
        try {
            let classModel = new Class()
            let feedbacks = await classModel.getFeedbacks(event_url)

            console.log("first feedback", feedbacks)

            feedbacks = feedbacks.map(f => {
                let total = clampIPK(f.topik_sesuai) +
                    clampIPK(f.topik_dipahami) +
                    clampIPK(f.pengajar_jelas) +
                    clampIPK(f.pengajar_interaktif) +
                    clampIPK(f.pendaftaran_mudah) +
                    clampIPK(f.mekanisme_jelas)

                let note = f.note ? f.note : ""

                return {
                    ...f,
                    note,
                    ipk: total / 6
                }
            })

            console.log("feedback", feedbacks)

            setFeedbacks(feedbacks)

            let tempTopikSesuai = [0, 0, 0, 0, 0]
            let tempTopikDipahami = [0, 0, 0, 0, 0]
            let tempPengajarJelas = [0, 0, 0, 0, 0]
            let tempPengajarInteraktif = [0, 0, 0, 0, 0]
            let tempPendaftaraMudah = [0, 0, 0, 0, 0]
            let tempMekanismeJelas = [0, 0, 0, 0, 0]

            for (let f of feedbacks) {

                tempTopikSesuai[5 - f.topik_sesuai]++;

                tempTopikDipahami[5 - f.topik_dipahami]++;

                tempPengajarJelas[5 - f.pengajar_jelas]++;

                tempPengajarInteraktif[5 - f.pengajar_interaktif]++;

                tempPendaftaraMudah[5 - f.pendaftaran_mudah]++;

                tempMekanismeJelas[5 - f.mekanisme_jelas]++;

            }

            setTopikSesuai(tempTopikSesuai)
            setTopikDipahami(tempTopikDipahami)
            setPengajarJelas(tempPengajarJelas)
            setPengajarInteraktif(tempPengajarInteraktif)
            setPendaftaranMudah(tempPendaftaraMudah)
            setMekanismeJelas(tempMekanismeJelas)


        } catch (e) {
            console.log(e)
        }
    }

    const data = {
        labels: ['Jelas (20 orang)', 'Tidak (2 orang)'],
        datasets: [
            {
                backgroundColor: [Palette.PRIMARY + 'D9', Palette.SECONDARY + 'D9'],
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 0,
                data: [20, 2]
            }
        ]
    };

    const feedbackColumns = useMemo(() => {
        return [
            {
                Header: "ID Registrasi",
                accessor: "participant_id"
            }, {
                Header: "Nama Peserta",
                accessor: "full_name"
            }, {
                Header: "IPK",
                accessor: "ipk",
                Cell: (cellInfo) =>{
                    let row = cellInfo.row.original
                    return <>{row.ipk.toFixed(2)}</>
                }
            }, {
                Header: "Catatan",
                accessor: "note"
            }]
    }, [feedbacks])

    const interactions = [
        {
            name: "Selengkapnya",
            action: (feedback) => {
                setCurrentFeedback(feedback)
                setIsFeedbackDetailDialogOpen(true)
            }
        },{
            name: "Catatan",
            action: (feedback) =>{
                setCurrentFeedback(feedback)
                console.log(feedback)
                setIsNoteDialogShown(true)
            }
        },{
            name: "Sembunyikan",
            action: async (feedback) =>{
                try{
                    let classModel = new Class();
                    let body = {
                        active: 0
                    };
                    let result = await classModel.updateFeedback(feedback.id, body);
                    console.log('Feedback hid:',feedback);
                    console.log('Feedback hid result:', result);
                    alert('Umpan balik berhasil disembunyikan!');
                    getFeedbacks();
                }catch(e){
                    return alert('Kesalahan terjadi!')
                }

            },
            variant: 'secondary',
            style: {width: 135},
            condition: (feedback) => {
                return feedback.active
            }
        },
        {
            name: "Pulihkan",
            action: async (feedback) =>{
                try{
                    let classModel = new Class();
                    let body = {
                        active: 1
                    };
                    let result = await classModel.updateFeedback(feedback.id, body);
                    console.log('Feedback restored:',feedback);
                    console.log('Feedback restored result:', result);
                    alert('Umpan balik berhasil dipulihkan!');
                    getFeedbacks();
                }catch(e){
                    return alert('Kesalahan terjadi!')
                }

            },
            variant: 'info',
            style: {width: 135},
            condition: (feedback) => {
                return !feedback.active
            }
        },

    ]

    const makeFormattedData = (values) => {
        return {
            labels: ['5', '4', '3', '2', '1'],
            datasets: [
                {
                    backgroundColor: [Palette.PRIMARY, Palette.PRIMARY, Palette.PRIMARY, Palette.PRIMARY, Palette.PRIMARY,],
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 0,
                    data: [...values]
                }
            ]
        }
    }

    const makeDetailedInterClassData = (values) => {

        let colors = Array(values.name?.length).fill(Palette.PRIMARY)
        let colors2 = Array(values.name?.length).fill(Palette.SECONDARY)
        let colors3 = Array(values.name?.length).fill('#abc111')
        let firstIdx = 0;
        let secondIdx = 0;
        let thirdIdx = 0;

        let idx = 0;
        let found = false
        for (let i of interClassData) {
            let iteratedCode = i.class_code;

            if (iteratedCode === event_url) {
                found = true;
                break;
            }
            idx++
        }

        if (interClassData.length !== 0) {
            if (!found) {
                firstIdx = interClassData.length - 3;
                secondIdx = interClassData.length - 2;
                thirdIdx = interClassData.length - 1;
            } else {
                if(idx === 0) {
                    firstIdx = 0;
                    secondIdx = 1;
                    thirdIdx = 2;
                } else if(idx === interClassData.length - 1) {
                    firstIdx = interClassData.length - 3;
                    secondIdx = interClassData.length - 2;
                    thirdIdx = interClassData.length - 1;
                } else {
                    firstIdx = idx - 1;
                    secondIdx = idx;
                    thirdIdx = idx + 1;
                }
            }
        }

        if(!values.name || (firstIdx === 0 && secondIdx === 0 && thirdIdx === 0)) return;

        console.log('interClassData', interClassData);
        return {
            labels: ['Mekanisme Jelas', 'Pendaftaran Mudah', 'Pengajar Interaktif', 'Pengajar Jelas', 'Topik Dipahami', 'Topik Sesuai'],
            datasets: [
                {
                    label: values.name[firstIdx],
                    backgroundColor: colors,
                    borderColor: 'rgb(173,0,51)',
                    borderWidth: 0,
                    data: [interClassData[firstIdx]?.mekanisme_jelas, interClassData[firstIdx]?.pendaftaran_mudah, interClassData[firstIdx]?.pengajar_interaktif, interClassData[firstIdx]?.pengajar_jelas, interClassData[firstIdx]?.topik_dipahami, interClassData[firstIdx]?.topik_sesuai]
                },
                {
                    label: values.name[secondIdx],
                    backgroundColor: colors2,
                    borderColor: 'rgb(241,31,74)',
                    borderWidth: 0,
                    data: [interClassData[secondIdx]?.mekanisme_jelas, interClassData[secondIdx]?.pendaftaran_mudah, interClassData[secondIdx]?.pengajar_interaktif, interClassData[secondIdx]?.pengajar_jelas, interClassData[secondIdx]?.topik_dipahami, interClassData[secondIdx]?.topik_sesuai]
                },
                {
                    label: values.name[thirdIdx],
                    backgroundColor: colors3,
                    borderColor: 'rgb(241,31,74)',
                    borderWidth: 0,
                    data: [interClassData[thirdIdx]?.mekanisme_jelas, interClassData[thirdIdx]?.pendaftaran_mudah, interClassData[thirdIdx]?.pengajar_interaktif, interClassData[thirdIdx]?.pengajar_jelas, interClassData[thirdIdx]?.topik_dipahami, interClassData[thirdIdx]?.topik_sesuai]
                },
            ]
        }
    }

    const makeInterClassData = (values) => {

        let colors = Array(values.name?.length).fill(Palette.PRIMARY)

        let idx = 0;
        for (let i of interClassData) {
            let iteratedCode = i.class_code;
            if (iteratedCode === event_url) {
                colors[idx] = Palette.SECONDARY
                break
            }
            idx++
        }

        return {
            labels: values.name,
            datasets: [
                {
                    backgroundColor: colors,
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 0,
                    data: values.average
                }
            ]
        }
    }

    const generateStars = (score, max) => {
        let fullStarCount = score
        let emptyStarCount = max - score
        let stars = []
        for (let i = 0; i < fullStarCount; i++) {
            stars.push(<FaStar far/>)
        }
        return <div>{stars}</div>
    }

    return (
        <EditorPageWrapper
            activeTab={"main-feedback"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            {/*DIALOG UNTUK FEEDBACKS LIST*/}
            <Dialog open={isFeedbackListShown}
                    maxWidth={"lg"}
                    onClose={() => setIsFeedbackListShown(false)}
                    fullWidth={true}>
                <DialogTitle>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Umpan Balik Peserta
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <MobTable columns={feedbackColumns} data={feedbacks} interactions={interactions}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        marginRight: 15,
                        marginBottom: 15
                    }}>
                        <CustomButton
                            onClick={() => {
                                setIsFeedbackListShown(false)
                            }}
                            style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                            variant={"outlined"}
                            color="primary">
                            Tutup
                        </CustomButton>
                    </div>
                </DialogContent>
            </Dialog>

            {/*DIALOG UNTUK FEEDBACK DETAIL*/}
            <Dialog open={isFeedbackDetailDialogOpen}
                    maxWidth={"md"}
                    onClose={() => setIsFeedbackDetailDialogOpen(false)}
                    fullWidth={true}>

                <DialogTitle>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Umpan Balik Peserta
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em', marginTop: -15}}>
                    <Row>
                        <Col xs={3}><b>Nomor Peserta</b></Col>
                        <Col xs={9}>{currentFeedback.participant_id}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Nama</b></Col>
                        <Col xs={9}>{currentFeedback.full_name}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>IPK</b></Col>
                        <Col xs={9}>{currentFeedback?.ipk?.toFixed(2)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Mekanisme Jelas</b></Col>
                        <Col xs={9}>{currentFeedback.mekanisme_jelas}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Pendaftaran Mudah</b></Col>
                        <Col xs={9}>{currentFeedback.pendaftaran_mudah}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Pengajar Jelas</b></Col>
                        <Col xs={9}>{currentFeedback.pengajar_jelas}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Topik Dipahami</b></Col>
                        <Col xs={9}>{currentFeedback.topik_dipahami}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Topik Sesuai</b></Col>
                        <Col xs={9}>{currentFeedback.topik_sesuai}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Rata-Rata (bintang)</b></Col>
                        <Col xs={9}>{currentFeedback?.average?.toFixed(2)}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Masukan</b></Col>
                        <Col xs={9}>
                            <p style={{
                                whiteSpace: 'pre-line',
                                wordWrap: "break-word",
                            }}>
                                {currentFeedback.masukan || "-"}
                            </p></Col>
                    </Row>
                    <Row>
                        <Col xs={3}><b>Kelas yang Diinginkan</b></Col>
                        <Col xs={9}>{currentFeedback.kelas_yang_diinginkan || "-"}</Col>
                    </Row>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        marginRight: 15,
                        marginBottom: 15
                    }}>
                        <CustomButton
                            onClick={() => {
                                setIsFeedbackDetailDialogOpen(false)
                            }}
                            style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                            variant={"outlined"}
                            color="primary">
                            Tutup
                        </CustomButton>
                    </div>
                </DialogContent>
            </Dialog>

            {/*DIALOG UNTUK CATATAN ADMIN*/}
            <Dialog open={isNoteDialogShown}
                    maxWidth={"md"}
                    onClose={() => setIsNoteDialogShown(false)}
                    onClose={() => setIsNoteDialogShown(false)}
                    fullWidth={true}>
                <DialogTitle>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Catatan untuk peserta {currentFeedback.name}
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <LagFreeTextEditor
                        title={'Catatan'}
                        changeValue={value => {
                            setCurrentFeedback({
                                ...currentFeedback,
                                note: value
                            })
                        }}
                        value={currentFeedback.note}
                        placeholder={"Catatan"}/>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        marginRight: 15,
                        marginBottom: 15
                    }}>
                        <CustomButton
                            onClick={() => {
                                setCurrentFeedback({})
                                setIsNoteDialogShown(false)
                            }}
                            style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                            variant={"outlined"}
                            color="primary">
                            Tutup
                        </CustomButton>
                        <CustomButton
                            onClick={() => {
                                let classAccess = new Class()
                                classAccess.updateFeedbackNote(currentFeedback.id, currentFeedback.note)
                                    .then(() =>{
                                        let nextFeedbacks = [...feedbacks]
                                        let idx = nextFeedbacks.findIndex((f)=>f.id===currentFeedback.id)
                                        nextFeedbacks[idx].note = currentFeedback.note
                                        setFeedbacks(nextFeedbacks)
                                        setCurrentFeedback({})
                                        setIsNoteDialogShown(false)
                                    })
                                    .catch((err) => {
                                        console.error(err)
                                        alert("Error! Check console. " + err.message)
                                    })
                            }}
                            style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                            variant={"outlined"}
                            color="primary">
                            Simpan
                        </CustomButton>
                    </div>
                </DialogContent>

            </Dialog>

            <div style={{
                color: Palette.PRIMARY,
                fontSize: "1.75em",
                fontFamily: "OpenSans-Bold",
                marginRight: 25,
                marginBottom: 30,
                marginTop: '1.075em'
            }}>
                Umpan Balik
            </div>

            {/*<Container>*/}
            {/*    <Row style={{boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)', padding: 30, borderRadius: 5}}>*/}
            {/*        <Col md={12}>*/}
            {/*            <div style={{fontFamily: 'OpenSans-SemiBold', marginBottom: 25}}>Dari</div>*/}
            {/*        </Col>*/}
            {/*        <Col md={12} style={{backgroundColor: '#fafafa', display: 'flex', alignItems: 'center', paddingTop: 8, paddingBottom: 8, borderRadius: 5}}>*/}
            {/*            <div style={{fontFamily: 'OpenSans-Regular', height: '100%',}}>Timotius Eka V</div>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Container>*/}

            <div>
                <Button
                    onClick={() => {
                        setIsFeedbackListShown(true)
                    }}
                    variant={"outlined"}
                    style={{marginTop: 25, marginBottom: 25, marginLeft: 10}}>
                    Buka Daftar Umpan Balik
                </Button>
                {isLoadingBar ? null : !!feedbacks.length &&
                        <CSVLink
                            style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                            separator={";"}
                            data={
                                feedbacks.map((user, key) => {

                                    return {
                                        "Nama Lengkap": user.full_name,
                                        "Surel": user.email,
                                        "IPK": user.ipk,
                                        "Mekanisme Jelas": user.mekanisme_jelas,
                                        "Pendaftaran Mudah": user.pendaftaran_mudah,
                                        "Pengajar Interaktif": user.pengajar_interaktif,
                                        "Pengajar Jelas": user.pengajar_jelas,
                                        "Topik Dipahami": user.topik_dipahami,
                                        "Topik Sesuai": user.topik_sesuai,
                                        "Rata-rata bintang": user.average,
                                        "Kelas yang diinginkan": user.kelas_yang_diinginkan,
                                        "Masukan": user.masukan,
                                    }
                                })
                            }
                            filename={`laporan-umpan-balik.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            Unduh Laporan&nbsp;&nbsp;<FaFileDownload/>
                        </CSVLink>
                }
            </div>

            <Container>
                <Row style={{marginBottom: 30}}>
                    <Col md={12} style={{
                        marginBottom: 20
                    }}
                    >
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Perbandingan Nilai Dengan Kelas Lain</div>
                            </div>
                            <Bar data={makeInterClassData(interClassAverage)}
                                 height={150}
                                 options={
                                     {
                                         maintainAspectRatio: true,
                                         tooltips: {
                                             enabled: false,
                                         },
                                         legend: {
                                             display: false,
                                             position: 'right'
                                         },
                                     }
                                 }/>
                        </div>

                    </Col>

                    <Col md={12} style={{
                        marginBottom: 20
                    }}
                    >
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Perbandingan Lengkap Dengan Kelas Lain</div>
                            </div>
                            <HorizontalBar data={makeDetailedInterClassData(interClassAverage)}
                                           height={150}
                                           options={
                                               {
                                                   maintainAspectRatio: true,
                                                   tooltips: {
                                                       enabled: true,
                                                   },
                                                   legend: {
                                                       display: true,
                                                       position: 'right'
                                                   },
                                               }
                                           }/>
                        </div>

                    </Col>

                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Topik Sesuai</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(topikSesuai)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>

                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Topik Dipahami</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(topikDipahami)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{marginBottom: 30}}>
                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Pengajar Jelas</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(pengajarJelas)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>

                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Pengajar Interaktif</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(pengajarInteraktif)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Pendaftaran Mudah</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(pendaftaranMudah)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>

                    <Col
                        md={6}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Mekanisme Jelas</div>
                            </div>

                            <HorizontalBar
                                data={makeFormattedData(mekanismeJelas)}
                                height={150}
                                options={
                                    {
                                        maintainAspectRatio: true,
                                        tooltips: {
                                            enabled: false,
                                        },
                                        legend: {
                                            display: false,
                                            position: 'right'
                                        },
                                    }
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col md={12}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            paddingRight: 0,
                            borderRadius: 5,
                            marginTop: 20,
                            fontFamily: 'OpenSans-Regular'
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                Kelas Yang Diinginkan
                            </div>

                            {
                                feedbacks.filter(obj => {
                                    return !!obj.kelas_yang_diinginkan
                                }).map((obj, key) => {
                                    return <Row style={{
                                        fontSize: '.9em',
                                        backgroundColor: '#fafafa',
                                        marginRight: 15,
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                        borderRadius: 5,
                                        marginBottom: 10,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        width: '100%'
                                    }}>

                                        <Col md={12}>
                                            {obj.kelas_yang_diinginkan}
                                        </Col>
                                        <Col md={12}
                                             style={{color: 'grey', fontFamily: 'OpenSans-Light', textAlign: 'right'}}>
                                            - {obj.full_name}
                                        </Col>
                                    </Row>
                                })
                            }
                        </div>

                    </Col>
                    <Col md={12}>
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            paddingRight: 0,
                            borderRadius: 5,
                            marginTop: 20,
                            fontFamily: 'OpenSans-Regular'
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                Masukan
                            </div>

                            {
                                feedbacks.filter(e => !!e.masukan).map((obj, key) => {
                                    return <Row style={{
                                        fontSize: '.9em',
                                        backgroundColor: '#fafafa',
                                        marginRight: 15,
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                        borderRadius: 5,
                                        marginBottom: 10,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        width: '100%'
                                    }}>

                                        <Col md={12}>
                                            {obj.masukan}
                                        </Col>
                                        <Col md={12}
                                             style={{color: 'grey', fontFamily: 'OpenSans-Light', textAlign: 'right'}}>
                                            - {obj.full_name}
                                        </Col>
                                    </Row>
                                })
                            }
                        </div>

                    </Col>

                </Row>
            </Container>
            {/*<div>*/}
            {/*    <div style={{fontFamily: 'OpenSans-SemiBold', marginBottom: 15}}>1. Topik sesuai</div>*/}
            {/*    <Pie*/}
            {/*        data={data}*/}
            {/*        height={25}*/}
            {/*        options={*/}
            {/*            {*/}
            {/*                tooltips: {*/}
            {/*                    enabled: false,*/}
            {/*                },*/}
            {/*                legend: {*/}
            {/*                    display: true,*/}
            {/*                    position: 'right'*/}
            {/*                },*/}
            {/*            }*/}
            {/*        }*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<MobTable columns={columns} data={[*/}
            {/*    {feedback: 'Kelas terlalu sebentar. Semoga durasi bisa lebih lama (Cth: 100 jam/hari).', from: 'Melita'}*/}
            {/*]}/>*/}
        </EditorPageWrapper>
    )
}
