import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {
    isSafari, isMobileSafari
} from "react-device-detect";

import {FaTimes} from "react-icons/all";
import {useCookies} from "react-cookie";
import Cookies from "js-cookie"
import LinearProgress from "@material-ui/core/LinearProgress";

export default function LoginModal(props) {

    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    const defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    useEffect(() => {
        setError(props.errorMessage)
    }, [props.errorMessage])

    const [isLoading, setIsLoading] = useState(false)
    const [loginMode, setLoginMode] = useState(true)
    const [loginCred, setLoginCred] = useState({})
    const [registerCred, setRegisterCred] = useState({
        email: "",
        name: "",
        password: "",
        confirmPassword: ""
    })
    const [registerState, setRegisterState] = useState({...defaultRegisterState})
    const [error, setError] = useState(null)

    const onHide = () => {
        setError(null)
        setRegisterState({...defaultRegisterState})
        props.onHide()
    }

    const handleSubmit = async () => {

        setError(null)

        if (loginMode) {

            let userModel = new User();

            if (!loginCred.email || !loginCred.password) {
                setError("Harap lengkapi surel dan kata sandi")
                return
            }

            try {
                setIsLoading(true)
                let result = await userModel.login(loginCred)

                // setCookie("token", result.token, {path : "/", maxAge: 60 * 60 * 24 * 30})
                // Cookies.set('token', result.token, {expires: 30});
                let date = new Date()
                date.setDate(date.getDate() + 30)
                setCookie("token", result.token, {expires : date})

                onHide(result.token)


                if (!props.onLoginDone) {
                    setTimeout(function () {
                        // window.location.reload();
                    }, 100);
                } else {
                    GlobalData.token = result.token
                    props.onLoginDone(result.token)
                }
                setIsLoading(false)

            } catch (e) {
                setIsLoading(false)

                let msg = "Terjadi Kesalahan"

                if (e.msg) {
                    if (e.code === "EMAIL_NOT_FOUND" || e.code === "PASSWORD_MISMATCH" || e.code === "CREDENTIAL_NOT_FOUND") {
                        msg = "Periksa kembali surel dan kata sandi"
                    }
                }

                setError(msg)

                console.log(e)
            }
        } else {

            let userModel = new User();

            if (registerCred.password !== registerCred.confirmPassword) {

                setRegisterState({
                    ...defaultRegisterState,
                    passwordMismatch: true
                })

                setError("Kata sandi tidak sesuai")

                return;
            }

            if (!registerCred.email || !registerCred.password || !registerCred.confirmPassword || !registerCred.name) {
                setError("Harap lengkapi semua data")
                return;
            } else {
                setError(null)

                console.log("semua keisi")
            }

            setIsLoading(true)

            try {
                let result = await userModel.register(registerCred)

                // Cookies.set('token', result.token, {expires: 30});

                let date = new Date()
                date.setDate(date.getDate() + 30)
                setCookie("token", result.token, {expires : date})

                onHide(result.token)

                if (!props.onLoginDone) {
                    setTimeout(function () {
                        // window.location.reload();
                    }, 100);
                } else {
                    GlobalData.token = result.token
                    props.onLoginDone(result.token)
                }

                setIsLoading(false)


            } catch (e) {

                setIsLoading(false)

                let msg = "Terjadi Kesalahan"
                let wasEmailRegistered = false
                console.log(msg);

                if (e.msg) {
                    if (e.code === "DUPLICATE_ACCOUNT" || e.msg.code === "ER_DUP_ENTRY") {
                        msg = "Surel Telah Terdaftar"
                        wasEmailRegistered = true
                    }

                    setError(msg)
                    setRegisterState({
                        ...defaultRegisterState,
                        emailIsRegistered: wasEmailRegistered
                    })

                    return;
                }

                setError(msg)
                console.log(e)
            }

        }


    }

    const renderLoginBody = () => {
        return <>
            <Form style={{
                marginTop: "1em",
                fontSize: "0.9em"
            }}>
                <Form.Group>
                    <Form.Label>Surel </Form.Label>
                    <Form.Control
                        value={loginCred.email}
                        onChange={evt => setLoginCred({
                            ...loginCred,
                            email: evt.target.value
                        })}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Kata Sandi</Form.Label>
                    <Form.Control
                        value={loginCred.password}
                        onChange={evt => setLoginCred({
                            ...loginCred,
                            password: evt.target.value
                        })}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
            </Form>

            <p style={{
                fontSize: "0.8em"
            }}>Belum punya akun? <a onClick={() => {
                setLoginMode(false)
                setError(null)
            }} href={"#"} style={{color : "black"}}>Daftar disini</a>
            </p>

        </>
    }

    const renderRegisterBody = () => {
        return <>
            <Form
                style={{
                    fontSize: "0.9em",
                }}>
                <Form.Group>
                    <Form.Label>Surel <a
                        style={{color: "red", display: registerState.emailIsRegistered ? "inline" : "none"}}>(Email
                        Telah Terdaftar)</a></Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({
                            ...registerCred,
                            email: evt.target.value
                        })}
                        value={registerCred.email}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nama Lengkap / Nama Organisasi</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({
                            ...registerCred,
                            name: evt.target.value
                        })}
                        value={registerCred.name}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Kata Sandi</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({
                            ...registerCred,
                            password: evt.target.value
                        })}
                        value={registerCred.password}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Konfirmasi Kata Sandi<a
                        style={{color: "red", display: registerState.passwordMismatch ? "inline" : "none"}}>(Password
                        Tidak Sama)</a></Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({
                            ...registerCred,
                            confirmPassword: evt.target.value
                        })}
                        value={registerCred.confirmPassword}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
            </Form>
            <p style={{
                fontSize: "0.8em"
            }}>Sudah punya akun?&nbsp;
                <a onClick={() => {
                    setLoginMode(true)
                    setError(null)
                }} href={"#"} style={{color : "black"}}>
                    Masuk
                </a>
            </p>
        </>
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={() => {
            setError(null)
            setRegisterState({...defaultRegisterState})
            onHide();
        }}
    >
        {/*<Modal.Title>*/}
        <Modal.Body>

            <Row>
                <Col md={12}
                     style={{marginTop: "0.2em", display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                    <img
                        style={{
                            width: "85%",
                            objectFit: "contain",
                        }}
                        src={require("../../asset/narabahasa-logo.png")}/>
                </Col>
            </Row>

            {loginMode ? renderLoginBody() : renderRegisterBody()}

            <span style={{
                color: "red",
                fontSize: "0.8em"
            }}>
                    {error ? error : props.message}
                </span>

            <div style={{position: "absolute", "right": "0.5em", "top": "0.5em",}}>
                <a href="#"
                   style={{color: 'grey'}}
                   onClick={onHide}>
                    <FaTimes/>
                </a>
            </div>
            {
                isLoading ?
                    <LinearProgress
                        style={{
                            width: "100%"
                        }}
                        color="primary"/>
                    :
                    null

            }

        </Modal.Body>
        <Modal.Footer>
            <Container style={{padding: 0}}>
                <Row>
                    <Col md={6} style={{marginTop: '10px'}}>
                        {loginMode ? <p style={{
                            fontSize: "0.8em"
                        }}>
                            <a href="#" onClick={props.showForgotPassword}>Lupa kata sandi?</a>
                        </p> : null}
                    </Col>
                    <Col md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            style={{backgroundColor: Palette.DARK_BLUE, borderWidth: 0}}
                            onClick={() => handleSubmit()}>{loginMode ? "Masuk" : "Daftar"}</Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>
    </Modal>

}
