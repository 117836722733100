import React, {useEffect, useState} from "react";
import EditorPageWrapper from "./EditorPageWrapper";
import MobTable from "../../reusable/Table/MobTable";
import {Button, Col, Modal, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import Voucher from "../../../models/Voucher";
import OrderModel from "../../../models/Order";
import CreateVoucherModal from "../../reusable/CreateVoucherModal";
import NoteVoucherModal from "../../reusable/NoteVoucherModal"
import moment from "moment";
import textFormatter from "../../../util/textFormatter";
import {useHistory} from "react-router-dom";
import {IoMdEye, IoMdEyeOff, IoMdDownload} from "react-icons/io";
import {CSVLink} from "react-csv"

export default function VoucherPage(props) {

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isBtnHovered, setBtnHovered] = useState(false);

    const [vouchers, setVouchers] = useState([])
    const [singleUseVouchers, setSingleUseVouchers] = useState([]);
    const [permanentVouchers, setPermanentVouchers] = useState([]);
    const [permanentVouchersWithInactive, setPermanentVouchersWithInactive] = useState([]);

    const [showVoucherModal, setShowVoucherModal] = useState(false)

    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState({})

    const [isOrderDetailModalVisible, setOrderDetailModalVisible] = useState(false)
    const [isOrdersModalVisible, setOrdersModalVisible] = useState(false)

    const [filteredOrdersIds, setFilteredOrdersIds] = useState([])

    const [selectedOrder, setSelectedOrder] = useState({})
    const [orderOfSelectedVoucher, setOrderOfSelectedVouchers] = useState([])

    const [isDeletedShown, setDeletedShown] = useState(false);
    const [activeVouchers, setActiveVouchers] = useState([]);
    const [inactiveVouchers, setInactiveVouchers] = useState([]);

    const columnsForOrder = [
        {
            Header: "ID Transaksi",
            accessor: "order_id"
        },
        {
            Header: "Tanggal/Waktu Transaksi",
            accessor: "created_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let created_at = rowInfo["created_at"]

                return <>
                    {created_at ? moment(created_at).format("DD MMM YYYY HH:mm") : '-'}
                </>
            }
        },
        {
            Header: "Pengguna",
            accessor: "member",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let member = rowInfo["member"]

                return <>
                    {member ? member.full_name : '-'}
                </>
            }
        },
        {
            Header: "Nominal Transaksi",
            accessor: "amount",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let amount = rowInfo["amount"]

                return <>
                    Rp{textFormatter.moneyFormatter(amount)}
                </>
            }
        },
        {
            Header: "Voucer",
            accessor: "voucher",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let voucher = rowInfo["voucher"]

                return <>
                    {!voucher ? '-' : voucher.amount ? 'Rp' + textFormatter.moneyFormatter(voucher.amount) : voucher.percentage + '%'}
                </>
            }
        },
        {
            Header: "Status",
            accessor: "paid_status",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let status = rowInfo["paid_status"]

                let statusString = "MENUNGGU PEMBAYARAN"

                //console.log(cellInfo)
                let color = "#000000"

                if (status === "settlement" || status === "capture") {

                    color = "LimeGreen"
                    statusString = "DITERIMA"


                } else if (status === "expire" || status === "cancel") {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (status === "WAIT_SNAP_NOTIFICATION") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                }

                return <p style={{color: color}}>{statusString}</p>
            }
        },
    ];

    let interactionsForOrder = [
        {
            name: "Selengkapnya",
            action: (order) => {
                console.log('selectedOrder',)
                setOrderDetailModalVisible(true)
                setSelectedOrder(order)
            }
        },
    ]

    useEffect(() => {
        getVoucherData()
    }, [])

    useEffect(() => {
        if (filteredOrdersIds.length > 0) {
            getSomeOrders()
        }
    }, [filteredOrdersIds])

    useEffect(() => {
        const activeVouchersTemp = [];
        const inactiveVouchersTemp = [];

        vouchers.forEach((voucher) => {
            if (voucher.type === 'SINGLE_USE') {
                if(!voucher.used) {
                    activeVouchersTemp.push(voucher)
                } else {
                    inactiveVouchersTemp.push(voucher)
                }
                // singleUseVouchersTemp.push(voucher)
            } else if (voucher.type === 'PERMANENT') {
                if (voucher.active) {
                    activeVouchersTemp.push(voucher)
                    // permanentVouchersTemp.push(voucher)
                } else {
                    inactiveVouchersTemp.push(voucher)
                }

                // permanentVouchersWithInactiveTemp.push(voucher)
            }
        })

        setActiveVouchers(activeVouchersTemp)
        setInactiveVouchers(inactiveVouchersTemp)
    }, [vouchers])

    const getSomeOrders = async () => {
        try {
            let orderModel = new OrderModel()
            const result = await orderModel.getSome(filteredOrdersIds);

            setOrderOfSelectedVouchers(result.orders);

        } catch (e) {
            console.log(e)
        }
    }

    const getVoucherData = async () => {
        try {
            let voucher = new Voucher();
            let result = await voucher.getAll()

            setVouchers(result)

            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }

    let selectedOrderInteractions = [
        {
            name: "Lihat Kelas",
            action: (order) => {
                if (order.class?.code) {
                    history.push(`/editor/detail/${order.class?.code}`)
                } else {
                    history.push(`/editor/privateClassDetail/${order.course_id}`)
                }
                // history.push(`/editor/detail/${}`)
                // history.push(`/editor/privateClassDetail/${}`)
                console.log('selectedOrder', order)
            }
        },
    ]
    const selectedOrderColumns = [
        {
            Header: "Nama Kelas",
            accessor: "a",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.original

                console.log('rowInfo', cellInfo.row.original)

                return <>{rowInfo.class_code ? rowInfo.class.name : rowInfo.course.title}</>
            }
        },
        {
            Header: "Harga",
            accessor: "b",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.original

                console.log('rowInfo', cellInfo.row.original)

                return <>Rp{rowInfo.price ? textFormatter.moneyFormatter(rowInfo.price) : '-'}</>
            }
        },
    ];

    let columns = [
        {
            Header: "Nama",
            accessor: "name"
        },
        {
            Header: "Kode",
            accessor: "code"
        },
        {
            Header: "Minimum Pemakaian",
            accessor: "minimum_usage",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                if (rowInfo.minimum_usage) {
                    return <>
                        <div>
                            Rp.
                            {
                                textFormatter.moneyFormatter(rowInfo.minimum_usage)
                            }
                        </div>
                    </>
                } else {
                    return <>-</>
                }

            }
        },
        {
            Header: "Besaran Diskon",
            accessor: "percentage",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                return <>
                    <div>
                        {
                            (rowInfo.percentage) ? (rowInfo.percentage + '%') : (cellInfo.row.original) ? (cellInfo.row?.original?.amount ? 'Rp' + cellInfo.row.original.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 'kosong') : 'kosong'
                        }
                    </div>
                </>
            }
        },
        {
            Header: "Status Pakai",
            accessor: "used",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values
                if (rowInfo.type === 'PERMANENT') {

                    let completeInfo = cellInfo.row.original
                    return <>Terpakai {completeInfo.transactions.length} Kali</>

                } else if (rowInfo.used) {
                    return <div style={{fontWeight: "200", color: Palette.SECONDARY}}>Terpakai</div>
                } else {
                    return <div style={{fontWeight: 200, color: "DarkGreen"}}>Belum Terpakai</div>
                }

            }
        },
        {
            Header: "Tipe",
            accessor: "type",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                return (
                    <>
                        {rowInfo.type === "SINGLE_USE" ? 'Sekali Pakai' : 'Permanen'}
                    </>
                )
            }
        }, {
            Header: "Catatan",
            accessor: "note"
        }
    ]

    let interactions = [
        {
            name: "Kegunaan",
            action: async (voucher) => {
                setOrdersModalVisible(true)
                console.log(voucher.transactions)
                setFilteredOrdersIds(voucher.transactions.map(obj => obj.id))
            },
            condition: (voucher) => {
                return voucher?.transactions?.length > 0
            },
        },
        {
            name: "Kegunaan",
            action: async (voucher) => {
                console.log("v", voucher)
                setOrdersModalVisible(true)
                setFilteredOrdersIds([voucher.order_id])
            },
            condition: (voucher) => {
                return voucher.type === 'SINGLE_USE' && voucher.order_id
            },
        },
        {
            name: "Hapus",
            action: async (voucher) => {
                setIsLoadingBar(true)

                try {
                    let voucherModel = new Voucher();
                    await voucherModel.deleteVoucher(voucher.id)
                    getVoucherData()

                    setIsLoadingBar(false)
                } catch (e) {
                    console.log(e)
                    alert("Terjadi Kesalahan, Harap hubungi developer.")

                    setIsLoadingBar(false)
                }
            },
            condition: (voucher) => {
                if (voucher.type === 'SINGLE_USE') {
                    return !voucher.used
                } else {
                    return voucher?.transactions?.length === 0
                }
            },
        },
        {
            name: "Non Aktifkan",
            action: async (voucher) => {
                try {
                    let voucherModel = new Voucher();
                    await voucherModel.deactivate(voucher.code)
                    getVoucherData()
                } catch (e) {
                    console.log(e)
                    alert("Terjadi Kesalahan, Harap hubungi developer.")
                }

            },
            condition: (voucher) => {
                return voucher.type === 'PERMANENT' && voucher.active
            },
        },
        {
            name: "Aktifkan",
            action: async (voucher) => {
                try {
                    let voucherModel = new Voucher();
                    await voucherModel.activate(voucher.code)
                    getVoucherData()
                } catch (e) {
                    console.log(e)
                    alert("Terjadi Kesalahan, Harap hubungi developer.")
                }

            },
            condition: (voucher) => {
                return voucher.type === 'PERMANENT' && !voucher.active
            },
        },
        {
            name: "Catatan",
            action: (voucher) => {
                setShowNoteModal(true)
                setSelectedVoucher(voucher)
            }
        }
    ]

    const formatDateTime = (d) => {
        return moment(d).format("DD-MM-YYYY HH:mm")
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"voucher"}
            >

                <CreateVoucherModal
                    visible={showVoucherModal}
                    hide={() => {
                        setShowVoucherModal(false)
                        getVoucherData()
                    }}
                />

                <NoteVoucherModal
                    voucher={selectedVoucher}
                    visible={showNoteModal}
                    onSave={(voucher) => {
                        let VoucherModel = new Voucher()
                        VoucherModel.updateNote(voucher).then(result => {
                            let vouchersCopy = [...vouchers]
                            let idx = vouchersCopy.findIndex(e => e.id === voucher.id)
                            vouchersCopy[idx].note = voucher.note
                            setVouchers(vouchersCopy)
                            setShowNoteModal(false)
                        }).catch(err => {
                            alert("Check console")
                            console.error(err)
                        })
                    }}
                    onHide={() => {
                        setShowNoteModal(false)
                    }}
                />

                <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25,
                    }}>
                        Voucer
                    </div>

                    {/*<CustomButton*/}
                    {/*    // onClick={() => history.push("/create-event")}*/}
                    {/*    style={{borderWidth: 0, fontFamily: 'OpenSans-SemiBold', fontWeight: '500', }}*/}
                    {/*    variant={"outlined"}>+ Buat Kelas Baru</CustomButton>*/}

                    <Row style={{marginLeft: 2, marginTop: 15, marginBottom: 30}}>
                        <Button variant={'outline-primary'}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    width: 165,
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY
                                }}
                                onClick={() => {
                                    setShowVoucherModal(true)
                                }}>
                            + <span style={{marginLeft: 6}}>Tambah Baru</span>
                        </Button>

                        <Button variant={'info'}
                                onClick={() => setDeletedShown(!isDeletedShown)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontFamily: 'OpenSans-SemiBold',
                                    fontSize: '.75em',
                                    // marginTop: 4,
                                    width: 200,
                                    color: 'white', marginLeft: 5
                                }}
                        >
                            {isDeletedShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                                <IoMdEye style={{marginRight: 10}}/>}
                            {isDeletedShown ? 'Sembunyikan Non Aktif' : 'Tampilkan Non Aktif'}
                        </Button>
                        <CSVLink
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 30,
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '.75em',
                                // marginTop: 4,
                                width: 200,
                                color: 'white', marginLeft: 5
                            }}
                            separator={";"}
                            data={
                                activeVouchers.map((obj, key) => {
                                    return {
                                        
                                        "Nama": obj.name,
                                        "Kode": obj.code,
                                        "Minimum Pemakaian": obj.minimum_usage ? `Rp.${textFormatter.moneyFormatter(obj.minimum_usage)}` : '-',
                                        "Besaran Diskon": (obj.percentage) ? (obj.percentage + '%') : obj.amount ? 'Rp' + obj.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 'kosong',
                                        "Status Pakai": obj.type === "PERMANENT" ? `Terpakai ${obj.transactions.length} kali` : obj.used? `Terpakai` : `Belum Terpakai`,
                                        "Tipe": obj.type === "SINGLE_USE" ? 'Sekali Pakai' : 'Permanen',
                                        "Catatan": obj.note,
                                        

                                    }
                                })
                            }
                            filename={`data-voucher.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            <IoMdDownload/> <span style={{marginLeft: 6}}>Export data</span>
                        </CSVLink>
                    </Row>
                </div>

                <Tabs defaultActiveKey="upcoming">
                    <Tab
                        eventKey="upcoming"
                        title={
                            "Aktif"
                        }
                    >
                        <div style={{
                            marginTop: 10
                        }}>
                            <MobTable
                                columns={columns}
                                data={activeVouchers}
                                interactions={interactions}
                            />
                        </div>
                    </Tab>
                    <Tab
                        eventKey="archive"
                        title={"Nonaktif"}
                    >
                        <div style={{
                            marginTop: 10
                        }}>
                            <MobTable
                                columns={columns}
                                data={inactiveVouchers}
                                interactions={interactions}
                            />
                        </div>
                    </Tab>
                </Tabs>


                <Modal show={isOrderDetailModalVisible} onHide={() => setOrderDetailModalVisible(false)} size={"lg"}>
                    <Modal.Header closeButton>
                        Detil Transaksi
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col lg={12}><h3>Info Transaksi</h3></Col>
                        </Row>
                        <Row>
                            <Col lg={4}>ID Transaksi</Col>
                            <Col lg={8}>{selectedOrder.order_id}</Col>
                        </Row>

                        <Row>
                            <Col lg={4}>Status Registrasi</Col>
                            <Col lg={8}
                                 style={{color: selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ? 'LimeGreen' : selectedOrder.paid_status === 'cancel' || selectedOrder.paid_status === 'expire' ? 'Red' : 'MediumTurquoise'}}>{selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ? 'DITERIMA' : selectedOrder.paid_status === 'cancel' || selectedOrder.paid_status === 'expire' ? 'DITOLAK' : 'MENUNGGU PEMBAYARAN'}</Col>
                        </Row>

                        <Row>
                            <Col lg={4}>ID Pengguna</Col>
                            <Col lg={8}>{selectedOrder.member ? selectedOrder.member.id : '-'}</Col>
                        </Row>

                        <Row>
                            <Col lg={4}>Nama Lengkap</Col>
                            <Col lg={8}>{selectedOrder.member ? selectedOrder.member.full_name : '-'}</Col>
                        </Row>

                        <Row>
                            <Col lg={4}>Surel</Col>
                            <Col lg={8}>{selectedOrder.member ? selectedOrder.member.email : '-'}</Col>
                        </Row>
                        <Row>
                            <div style={{height: "1em"}}></div>
                        </Row>

                        <Row>
                            <Col lg={4}>Waktu Registrasi Dibuat</Col>
                            <Col lg={8}>{formatDateTime(new Date(selectedOrder?.created_at))}</Col>
                        </Row>

                        {
                            selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ?
                                <Row>
                                    <Col lg={4}>Waktu Pembayaran Diproses</Col>
                                    <Col lg={8}>{formatDateTime(new Date(selectedOrder?.paid_at))}</Col>
                                </Row>
                                :
                                null
                        }

                        {
                            selectedOrder.paid_status !== 'settlement' && selectedOrder.paid_status !== 'capture' ?
                                <>
                                    <Row>
                                        <Col lg={4}>Waktu Kadaluarsa</Col>
                                        <Col lg={8}>{formatDateTime(new Date(selectedOrder?.should_expire_at))}</Col>
                                    </Row>
                                </>
                                : null
                        }

                        {
                            selectedOrder.paid_status === "expire" || selectedOrder.paid_status === "deny" ?
                                <>
                                    <Row>
                                        <Col lg={4}>Waktu Ditolak</Col>
                                        <Col lg={8}>{formatDateTime(new Date(selectedOrder?.expired_at))}</Col>
                                    </Row>
                                </>
                                : null
                        }

                        <Row>
                            <div style={{height: "1em"}}></div>
                        </Row>

                        <Row>
                            <Col lg={4}>URL pembayaran Midtrans</Col>
                            <Col lg={8}>{selectedOrder?.snap_payment_url ? selectedOrder?.snap_payment_url : "-"}</Col>
                        </Row>

                        <Row style={{marginTop: 20}}>
                            <Col lg={12}><h3>Kelas dalam Transaksi Ini</h3></Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <MobTable columns={selectedOrderColumns}
                                          data={selectedOrder.memberCourses && selectedOrder.participants ? selectedOrder.memberCourses.concat(selectedOrder.participants) : []}
                                          interactions={selectedOrderInteractions}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => setOrderDetailModalVisible(false)}>Tutup</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={isOrdersModalVisible && !isOrderDetailModalVisible}
                       onHide={() => setOrdersModalVisible(false)} size={"xl"}>
                    <Modal.Header closeButton>
                        Detil Transaksi
                    </Modal.Header>

                    <Modal.Body>
                        <MobTable columns={columnsForOrder} data={orderOfSelectedVoucher}
                                  interactions={interactionsForOrder}
                        />
                    </Modal.Body>
                </Modal>

            </EditorPageWrapper>
        </>
    )
}
