import React, {Component, useEffect, useState} from 'react';
import {BrowserRouter, HashRouter, Route, useHistory, Switch} from "react-router-dom"
import './App.css';
import Home from "./component/page/Home";
import {ParallaxProvider} from "react-scroll-parallax";
import {CookiesProvider, useCookies} from "react-cookie";
import GlobalData from "./util/GlobalData";
import CreateEvent from "./component/page/CreateEvent";
import Pricing from "./component/page/Pricing";
import NotFound from "./component/page/NotFound";
import Forbidden from "./component/page/Forbidden";
import EditorMain from "./component/page/editor/EditorMain";
import EventDetail from "./component/page/editor/EventDetail";
import UserManagement from "./component/page/editor/UserManagement/UserManagement";
import PrivateClassUserManagement from "./component/page/editor/PrivateClassUserManagement/UserManagement";
import EmailManagementForClass from "./component/page/editor/EmailManagement/EmailManagementForClass";
import EventMain from "./component/page/editor/EventMain";
import Profile from "./component/page/editor/Profile";
import Quiz from "./component/page/editor/Quiz";
import LoginPageModalOnly from "./component/page/LoginPageModalOnly";
import UserList from "./component/page/editor/UserList";
import MerchandiseList from "./component/page/editor/MerchandiseList";
import ClassDetail from "./component/page/editor/ClassDetail";
import CreateClass from "./component/page/CreateClass";
import VoucherPage from "./component/page/editor/VoucherPage";
import Admin from "./component/page/editor/Admin";
import Question from "./component/page/editor/Question";
import Feedback from "./component/page/editor/Feedback";
import DashboardPage from "./component/page/editor/DashboardPage";
import Participation from "./component/page/Participations";
import CertificatePage from "./component/page/editor/CertificatePage";
import NewCertificatePage from "./component/page/editor/NewCertificatePage";
import EmailManagementForGlobal from "./component/page/editor/EmailManagement/EmailManagementForGlobal"
import PreviewPage from "./component/page/PreviewPage";
import FeedbackMain from "./component/page/Feedback";
import Presence from "./component/page/editor/Presence";
import Help from "./component/page/editor/Help";
import Complaint from "./component/page/editor/ComplaintPage";
import ComplaintForm from "./component/page/editor/ComplaintForm";
import Instructor from "./component/page/editor/Instructor";
import Syllabus from "./component/page/editor/Syllabus";
import SyllabusManage from "./component/page/editor/SyllabusManage";
import PrivateClassEditorMain from "./component/page/editor/PrivateClassEditorMain";
import LogList from "./component/page/editor/LogList"
import Transaction from "./component/page/Transaction";
import PrivateClassFeedback from "./component/page/editor/PrivateClassFeedback";
import AdminModel from "./models/Admin";
import User from "./models/User";
import MainContainer from "./component/page/editor/MainContainer";
import Notification from "./component/page/editor/Notification";

import DocumentForm from "./component/page/editor/DocumentForm";
import UI from "./component/page/editor/UI";
import LiveStreamingPage from "./component/page/editor/LiveStreamingPage";
import LiveStreamingEditor from "./component/page/editor/LiveStreamingEditor";
import InstructorModel from "./models/Instructor";
import AllInstructor from "./component/page/editor/AllInstructor";

function App() {

    const [menu, setMenu] = useState([])
    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    useEffect(() => {
        getUserData()
        console.log("DBG GET USER DATA FROM APPJS")
    }, [])

    const getUserData = async () => {

        console.log('cookie.userType', cookie.userType)

        try {
            let menu;

            let userModel = new User()
            let instructorModel = new InstructorModel()

            if(cookie.userType === 'admin' || cookie.userType === 'superadmin') {
                menu = await userModel.tokenLogin();
            } else {
                menu = await instructorModel.tokenLogin();
            }


            console.log('token login res', menu)

            if (menu.menu) {
                setMenu(JSON.parse(menu.menu))
            }

        } catch (e) {
            console.log('token login e', e)
        }


    }

    // async startMQTT(){
    //     let mqttManager = new MQTTManager();
    //     await mqttManager.initialize()
    // }


    // const history = useHistory()

    return (
        <>
            <CookiesProvider>
                <ParallaxProvider>
                    <BrowserRouter basename="/">
                        <Switch>

                            {/*<Route exact path= "/" component={Home}/>*/}
                            <Route exact path="/" component={() => {
                                return <LoginPageModalOnly onLogin={(menu) => {

                                    if (menu.menu) setMenu(JSON.parse(menu.menu))

                                    // console.log("DBG", menu)
                                    // setMenu()
                                }}/>
                            }}/>

                            <Route exact path="/forbidden" component={() => {
                                return <LoginPageModalOnly onLogin={(menu) => {
                                    console.log("DBG", menu)
                                    // setMenu()
                                }}/>
                            }}/>

                            <Route exact path="/editor/preview/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? PreviewPage : NotFound}/>

                            <Route exact path="/editor/visitors/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? UserManagement : NotFound}/>

                            <Route exact path="/editor/quiz/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? Quiz : NotFound}/>

                            <Route exact path="/editor/instructor"
                                   component={AllInstructor}/>

                            <Route exact path="/editor/certificate/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? CertificatePage : NotFound}/>
                            <Route exact path="/editor/private-certificate/:event_url" render={() => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <CertificatePage privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>

                            <Route exact path="/editor"
                                   component={menu.includes('home') || menu.length === 0 ? EditorMain : NotFound}/>

                            <Route exact path="/create-class" render={() => {
                                return (
                                    <CreateClass privateClass={false}/>
                                )
                            }}/>


                            <Route exact path="/create-private-class" render={() => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <CreateClass privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>
                            <Route exact path="/private-class-editor"
                                   component={menu.includes('private-class') || menu.length === 0 ? PrivateClassEditorMain : NotFound}/>
                            <Route exact path="/editor/privateClassVisitors/:event_url"
                                   component={menu.includes('private-class') || menu.length === 0 ? PrivateClassUserManagement : NotFound}/>
                            <Route exact path="/editor/privateClassDetail/:event_url" render={() => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <ClassDetail privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>
                            <Route exact path="/editor/new-certificate/:event_url" component={NewCertificatePage}/>


                            <Route exact path="/dashboard" component={DashboardPage}/>
                            <Route exact path="/admins" component={Admin}/>
                            <Route exact path="/formulir-dokumen" component={menu.includes('formulir-dokumen') || menu.length === 0  ? DocumentForm : NotFound}/>
                            <Route exact path="/tampilan-antarmuka" component={menu.includes('tampilan-antarmuka') || menu.length === 0 ? UI : NotFound}/>

                            <Route exact path="/profile" component={Profile}/>

                            <Route exact path="/users"
                                   component={menu.includes('users') || menu.length === 0 ? UserList : NotFound}/>
                            <Route exact path="/merchandise" component={MerchandiseList}/>

                            <Route exact path="/loglist" component={menu.includes('loglist') || menu.length === 0 ? LogList : NotFound}/>

                            <Route exact path="/participation"
                                   component={menu.includes('participation') || menu.length === 0 ? Participation : NotFound}/>
                            <Route exact path="/editor/email"
                                   component={menu.includes('email') || menu.length === 0 ? EmailManagementForGlobal : NotFound}/>
                            <Route exact path="/editor/email/:event_url"
                                   component={menu.includes('home') ? EmailManagementForClass : NotFound}/>
                            <Route exact path="/editor/private-email/:event_url" render={() => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <EmailManagementForClass privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>

                            <Route exact path="/presence"
                                   component={menu.includes('presence') || menu.length === 0 ? Presence : NotFound}/>
                            <Route exact path="/editor/live-streaming" component={menu.includes('liveStreaming') ? LiveStreamingPage : NotFound}/>
                            <Route exact path="/editor/create-streaming" component={menu.includes('liveStreaming') ? LiveStreamingEditor : NotFound}/>
                            <Route exact path="/editor/detail-streaming/:code" component={menu.includes('liveStreaming') ? LiveStreamingEditor : NotFound}/>

                            <Route exact path="/help"
                                   component={menu.includes('help') || menu.length === 0 ? Help : NotFound}/>
                            <Route exact path="/complaint"
                                   component={menu.includes('complaint') || menu.length === 0 ? ComplaintForm : NotFound}/>

                            <Route exact path="/editor/voucher"
                                   component={menu.includes('voucher') || menu.length === 0 ? VoucherPage : NotFound}/>

                            <Route exact path="/editor/question/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? Question : NotFound}/>

                            <Route exact path="/editor/classDetail/:event_url" render={() => {
                                if (menu.includes('home') || menu.length === 0) {
                                    return (
                                        <ClassDetail privateClass={false}/>
                                    )
                                } else {
                                    return <NotFound/>
                                }
                            }}/>

                            <Route exact path="/feedback"
                                   component={menu.includes('main-feedback') || menu.length === 0 ? FeedbackMain : NotFound}/>
                            <Route exact path="/editor/feedback/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? Feedback : NotFound}/>
                            <Route exact path="/editor/privateClassFeedback/:event_url" render={(props) => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <PrivateClassFeedback {...props} privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>

                            <Route exact path="/editor/privateClassInstructors/:event_url" render={(props) => {
                                if (menu.includes('private-class') || menu.length === 0) {
                                    return (
                                        <Instructor {...props} privateClass={true}/>
                                    )
                                } else {
                                    return (
                                        <NotFound/>
                                    )
                                }
                            }}/>

                            <Route exact path="/editor/instructor/:event_url"
                                   component={menu.includes('home') || menu.length === 0 ? Instructor : NotFound}/>
                            <Route exact path="/editor/syllabus/:event_url"
                                   component={menu.includes('private-class') || menu.length === 0 ? Syllabus : NotFound}/>
                            <Route exact path="/editor/syllabus/:event_url/create"
                                   component={menu.includes('private-class') || menu.length === 0 ? SyllabusManage : NotFound}/>
                            <Route exact path="/editor/syllabus/:event_url/:syllabus_id/manage"
                                   component={menu.includes('private-class') || menu.length === 0 ? SyllabusManage : NotFound}/>

                            <Route exact path="/transaction"
                                   component={menu.includes('transaction') || menu.length === 0 ? Transaction : NotFound}/>
                            <Route exact path="/notification"
                                   component={menu.includes('notification') || menu.length === 0 ? Notification : NotFound}/>

                            <Route path="/not-found/" component={NotFound}/>

                            <Route path={"/"} component={MainContainer}/>

                            <Route path="*" component={NotFound}/>
                        </Switch>
                    </BrowserRouter>
                </ParallaxProvider>
            </CookiesProvider>
        </>

    );
}


export default App;
