import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import MobTable from "../../reusable/Table/MobTable";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import {Dialog, DialogContent, DialogTitle, Input, Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Form from "react-bootstrap/Form";
import Strings from "../../../util/Strings";
import FileUpload from "../../reusable/FileUpload";
import CustomButton from "../../reusable/CustomButton";
import textFormatter from "../../../util/textFormatter";
import {MdClose, MdFileDownload} from "react-icons/md";
import {FaChevronRight, FaKey, FaSave} from "react-icons/fa";
import FormFieldModal from "../../reusable/modals/PrivateClassQuizModal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import CourseChapter from '../../../models/CourseChapter';
import CourseSubChapter from '../../../models/CourseSubChapter';
import MemberCourseProgress from '../../../models/MemberCourseProgress';
import MemberCourse from '../../../models/MemberCourse';
import {useHistory, useLocation, useParams} from "react-router-dom";
import Email from "../../../models/Email";
import FileUploadIcon from "../../reusable/FileUploadNew";
import Course from "../../../models/Course";
import {Parser} from 'html-to-react'
import RichText from "../../reusable/RichText/LimitedRichText";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import QuizTextInput from "../../reusable/QuizTextInput";
import QuizRadioButton from "../../reusable/QuizRadioButton";

export default function SyllabusManage(props) {
    let history = useHistory()
    let htmlParser = new Parser()
    const inputRef = useRef(null);

    const [isBtnHovered, setBtnHovered] = useState(false);

    const TYPES = [
        "Prolog",
        "Video",
        "Teks",
        "Kuis",
        "Dokumen",
        "Glosarium",
        "Latihan",
        // "Uraian"
    ];

    const [isManageSyllabusContentDialogShown, setManageSyllabusContentDialogShown] = useState(false);
    const [isCommentDialogShown, setCommentDialogShown] = useState(false);
    const [isManageGlossaryShown, setManageGlossaryShown] = useState(false)
    const [isManageQuizDialogShown, setManageQuizDialogShown] = useState(false);
    const [isManageEssayDialogShown, setManageEssayDialogShown] = useState(false);
    const [snackBarProp, setSnackBarProp] = useState({});
    const [isDeleteSyllabusContentDialogShown, setDeleteSyllabusContentDialogShown] = useState(false);
    const [selectedSyllabusContent, setSelectedSyllabusContent] = useState({});

    const [isDeleteGlossaryDialogShown, setDeleteGlossaryDialogShown] = useState(false);

    const [isDeleteQuestionDialogShown, setDeleteQuestionDialogShown] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [syllabus, setSyllabus] = useState({});
    const [glossary, setGlossary] = useState({});
    const [quiz, setQuiz] = useState({});

    const [selectedGlossaryIdx, setSelectedGlossaryIdx] = useState(-1);
    const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(-1);

    const [syllabusContent, setSyllabusContent] = useState([])

    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)
    const [isUploadingAttachment2, setIsUploadingAttachment2] = useState(false)

    const [anyParticipantRegistered, setAnyParticipantRegistered] = useState(true);
    const [progress, setProgress] = useState(0);

    const [isUpdatingSequence, setUpdatingSequence] = useState(false);
    const [course, setCourse] = useState(null);
    const [isSelectParticipantModalShown, setSelectParticipantModalShown] = useState(false)

    const [participantAnswers, setParticipantAnswers] = useState([])

    const [selectedAnswer, setSelectedAnswer] = useState({});
    const [isShowAnswerModalShown, setShowAnswerModalShown] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "Nama",
                accessor: "name",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {htmlParser.parse(rowInfo.name)}
                    </>
                })
            },
            {
                Header: "Tipe",
                accessor: "type"
            },
            {
                Header: "Durasi",
                accessor: "duration",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {pad(Math.floor(rowInfo?.duration / 60))}:{pad(rowInfo?.duration % 60)}
                    </>
                })
            },
        ]
    )

    const getCourseById = async () => {
        try {
            const courseModel = new Course();

            const result = await courseModel.getById(props.match.params.event_url);

            setCourse(result);
        } catch (e) {
            console.log(e)
        }
    }

    const updatingSequenceColumns = useMemo(
        () => [
            {
                Header: `Urutan`,
                accessor: "idx",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    console.log()

                    const {idx, id} = rowInfo;

                    return <>
                        <Input
                            style={{width: 40, textAlign: 'center'}}
                            inputProps={{style: {textAlign: 'center'}}}
                            onChange={(e) => {
                                console.log(!e.target.value)

                                if (!e.target.value || !isNaN(parseInt(e.target.value))) {
                                    const syllabusContentTemp = [...syllabusContent];

                                    syllabusContentTemp.forEach((syllabusContent, idx2) => {
                                        if (syllabusContent.id === cellInfo.row.original.id) {
                                            if (e.target.value) {
                                                syllabusContentTemp[idx2].idx = parseInt(e.target.value)
                                            } else {
                                                syllabusContentTemp[idx2].idx = null
                                            }
                                        }
                                    })

                                    setSyllabusContent(syllabusContentTemp)
                                }
                            }}
                            value={idx}/>
                    </>
                })
            },
            {
                Header: "Nama",
                accessor: "name",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {htmlParser.parse(rowInfo.name)}
                    </>
                })
            },
            {
                Header: "Tipe",
                accessor: "type"
            },
            {
                Header: "Durasi",
                accessor: "duration",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {pad(Math.floor(rowInfo?.duration / 60))}:{pad(rowInfo?.duration % 60)}
                    </>
                })
            },
        ]
    )

    const glossaryColumns = useMemo(
        () => [
            {
                Header: "Kata",
                accessor: "word",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {htmlParser.parse(rowInfo.word)}
                    </>
                })
            },
            {
                Header: "Definisi",
                accessor: "definition",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {htmlParser.parse(rowInfo.definition)}
                    </>
                })
            },

        ]
    )

    const questionColumns = useMemo(
        () => [
            {
                Header: "Pertanyaan",
                accessor: "question",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    return <>
                        {htmlParser.parse(rowInfo.question)}
                    </>
                })
            },
        ]
    )

    const syllabusInteractions = [
        {
            name: "Kelola",
            action: (obj) => {
                console.log('obj', obj)
                setSelectedSyllabusContent({
                    ...obj,
                    content: JSON.parse(obj.content)
                });
                setManageSyllabusContentDialogShown(true)
            },
            style: {width: 135},
        },
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedSyllabusContent(obj);
                setDeleteSyllabusContentDialogShown(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    const questionInteractions = [
        {
            name: "Kelola",
            action: (obj, i) => {
                console.log('selectedSyllabusContent', selectedSyllabusContent)

                setSelectedQuestionIdx(i)

                console.log('obj', {
                    fieldName: obj.question,
                    options: obj.choices,
                    description: obj.explanation,
                    correctAnswer: obj.correct_answer
                })

                setQuiz({
                    fieldName: obj.question,
                    options: obj.choices,
                    description: obj.explanation,
                    correctAnswer: obj.correct_answer,
                    type: obj.type
                })
                // setGlossary(obj);

                // if (selectedSyllabusContent.type === 'Uraian' || selectedSyllabusContent.type === 'Latihan') {
                //     setManageEssayDialogShown(true)
                // } else {
                setManageQuizDialogShown(true)
                // }
            },
            style: {width: 135},
        },
        {
            name: "Hapus",
            action: (obj, i) => {
                setSelectedQuestionIdx(i)
                setDeleteQuestionDialogShown(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    const glossaryInteractions = [
        {
            name: "Kelola",
            action: (obj, i) => {
                setSelectedGlossaryIdx(i)
                setGlossary(obj);
                setManageGlossaryShown(true)
            },
            style: {width: 135},
        },
        {
            name: "Hapus",
            action: (obj, i) => {
                setSelectedGlossaryIdx(i)
                setDeleteGlossaryDialogShown(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    const pad = (n) => {
        n = n + '';
        return n.length >= 2 ? n : new Array(2 - n.length + 1).join('0') + n;
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    useEffect(() => {
        if (course) {
            if (course.active) {
                getNoOfAcceptedParticipants();
            } else {
                setAnyParticipantRegistered(false)
            }
        }
    }, [course])

    useEffect(() => {
        getCourseById();

        if (props.match.params.syllabus_id) {
            getCourseChapter()
        }
    }, [])


    useEffect(() => {
        if (syllabus.id) {
            getCourseSubChapter()
        }
    }, [syllabus])

    const redirectIfParticipantRegistered = () => {
        alert('Tidak bisa membuat bab baru karena sudah ada peserta terdaftar')
        history.push(`/editor/syllabus/${props.match.params.event_url}`)
    }

    const getNoOfAcceptedParticipants = async () => {
        const memberCourseModel = new MemberCourse();

        try {
            const result = await memberCourseModel.getNoOfAcceptedParticipants(props.match.params.event_url);

            if (result.accepted_participants === 0) {
                setAnyParticipantRegistered(false)
            } else if (!props.match.params.syllabus_id) {
                redirectIfParticipantRegistered()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getCourseChapter = async () => {
        const courseChapterModel = new CourseChapter();

        try {
            const result = await courseChapterModel.getById(props.match.params.syllabus_id);

            setSyllabus(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getCourseSubChapter = async () => {
        const courseSubChapterModel = new CourseSubChapter();

        try {
            let customSubChapterSequence = null;

            const result = await courseSubChapterModel.getByCourseChapterId(props.match.params.syllabus_id);

            if (syllabus.custom_sub_chapter_sequence) {
                customSubChapterSequence = JSON.parse(syllabus.custom_sub_chapter_sequence)

                console.log('customSubChapterSequence',)
            }

            result.forEach((datum, idx) => {
                result[idx].idx = idx + 1;
            })

            const sortedSyllabusContent = [];

            if (customSubChapterSequence) {
                for (let idx = 0; idx < result.length; idx++) {
                    sortedSyllabusContent.push(null);
                }

                result.forEach((syllabusContent, idx) => {
                    customSubChapterSequence.forEach((item, idx2) => {
                        if (syllabusContent.id === item) {
                            sortedSyllabusContent[idx2] = syllabusContent;
                        }
                    })
                })

                sortedSyllabusContent.forEach((syllabus, idx) => {
                    sortedSyllabusContent[idx].idx = idx + 1;
                })
            }

            setSyllabusContent(customSubChapterSequence ? sortedSyllabusContent : result)
        } catch (e) {
            console.log(e)
        }
    }

    const addAttachment = async function (addedFile, customIsUploadingAttachment) {

        if(customIsUploadingAttachment) {
            customIsUploadingAttachment(true)
        } else {
            setIsUploadingAttachment(true)
        }

        try {
            let result = await new Email().addAttachment(addedFile[0])

            if(customIsUploadingAttachment) {
                customIsUploadingAttachment(false)
            } else {
                setIsUploadingAttachment(false)
            }

            if(customIsUploadingAttachment) {
                return result.location
            } else {
                setSelectedSyllabusContent({
                    ...selectedSyllabusContent,
                    content: {
                        url: result.location
                    }
                })
            }
        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            if(customIsUploadingAttachment) {
                customIsUploadingAttachment(false)
            } else {
                setIsUploadingAttachment(false)
            }

        }

    }

    const addAttachmentGdrive = async function (addedFile) {
        if (addedFile[0].type !== 'video/mp4') {
            return alert('Harap hanya unggah video dengan format MP4');
        }

        /***/
        let myVideos = [addedFile[0]]
        let video = document.createElement('video');
        video.preload = 'metadata';

        let durationGlobal = 0

        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            let durationInSecond = Math.floor(video.duration);

            let temp = {...selectedSyllabusContent}
            temp.duration = durationInSecond
            durationGlobal = durationInSecond
            setSelectedSyllabusContent(temp)

            // console.log("duration", duration)

        }

        video.src = URL.createObjectURL(addedFile[0]);
        /***/


        setIsUploadingAttachment(true)

        try {
            let result = await new CourseChapter().uploadVideo(addedFile[0], setProgress)

            console.log(result)
            // let result = await new Email().addAttachmentGoogleDrive(addedFile[0])
            // // console.log('isinya' + result.web_url_download)
            //
            setSelectedSyllabusContent({
                ...selectedSyllabusContent,
                content: {
                    ...selectedSyllabusContent.content,
                    url: result.location
                },
                duration: durationGlobal
            })


            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }

    return (
        <>
            <EditorPageWrapper
                privateClass
                activeTab={"syllabus"}
            >
                <Dialog open={isShowAnswerModalShown}
                        maxWidth="md"
                        onClose={() => {
                            setShowAnswerModalShown(false)
                        }}
                        fullWidth={true}
                >
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Lihat Jawaban ({selectedAnswer.full_name})
                            </Col>
                        </Row>
                        <hr/>
                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "grey",
                        }} onClick={() => {
                            setShowAnswerModalShown(false)
                        }}>
                            <MdClose/>
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {
                            Array.isArray(selectedSyllabusContent?.content) && selectedSyllabusContent?.content?.map((quiz, idx) => {
                                console.log('quiz', quiz)

                                if(selectedAnswer.additional_information) {
                                    const additionalInformation = JSON.parse(selectedAnswer?.additional_information);

                                    if(quiz.choices) {
                                        return <QuizRadioButton question={htmlParser.parse(quiz.question)}
                                                                options={quiz.choices}
                                                                html={true}
                                                                value={additionalInformation.answers[idx]}
                                        />
                                    } else {
                                        return <QuizTextInput question={htmlParser.parse(quiz.question)}
                                                              value={htmlParser.parse(additionalInformation.answers[idx])}
                                        />
                                    }
                                }
                            })
                        }
                        <div style={{marginBottom: 40}}/>
                    </DialogContent>
                </Dialog>

                <input type={"text"} ref={inputRef} style={{position: 'absolute', pointerEvents: 'none', opacity: 0}}/>

                <Dialog open={isDeleteGlossaryDialogShown}
                        onClose={() => {
                            setSelectedQuestion({})
                            setDeleteQuestionDialogShown(false)
                        }}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Konten Glosarium
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus definisi dari <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedSyllabusContent.content && selectedSyllabusContent.content[selectedGlossaryIdx]?.word}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleteGlossaryDialogShown(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={() => {
                                        const selectedSyllabusContentTemp = {
                                            ...selectedSyllabusContent
                                        }

                                        selectedSyllabusContentTemp.content.splice(selectedGlossaryIdx, 1)

                                        setDeleteGlossaryDialogShown(false)
                                        setSelectedSyllabusContent(selectedSyllabusContentTemp)
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Dialog open={isDeleteQuestionDialogShown}
                        onClose={() => {
                            setSelectedQuestion({})
                            setDeleteQuestionDialogShown(false)
                        }}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Pertanyaan
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus pertanyaan ini?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setSelectedQuestion({})
                                        setDeleteQuestionDialogShown(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={() => {
                                        const selectedSyllabusContentTemp = {
                                            ...selectedSyllabusContent
                                        }

                                        selectedSyllabusContentTemp.content.splice(selectedGlossaryIdx, 1)

                                        setDeleteGlossaryDialogShown(false)
                                        setSelectedSyllabusContent(selectedSyllabusContentTemp)
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Dialog open={isDeleteSyllabusContentDialogShown}
                        onClose={() => {
                            setSelectedSyllabusContent({})
                            setDeleteSyllabusContentDialogShown(false)
                        }}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Subbab
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus subbab <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedSyllabusContent.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setSelectedSyllabusContent({})
                                        setDeleteSyllabusContentDialogShown(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        const courseSubChapterModel = new CourseSubChapter();

                                        try {
                                            const result = await courseSubChapterModel.delete(selectedSyllabusContent.id)

                                            if (result.affectedRows === 1) {
                                                alert('Subbab berhasil terhapus!');

                                                getCourseChapter();

                                                setDeleteSyllabusContentDialogShown(false)
                                            }
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackBarProp.open} autoHideDuration={6000}
                    onClose={() => {
                        setSnackBarProp({
                            open: false
                        })
                    }}>
                    <Alert severity={snackBarProp.severity}>
                        {snackBarProp.message}
                    </Alert>
                </Snackbar>

                <Dialog open={isSelectParticipantModalShown}
                        maxWidth="sm"
                        onClose={() => setSelectParticipantModalShown(false)}
                        fullWidth={true}
                >
                    <DialogTitle onClose={() => setSelectParticipantModalShown(false)}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Lihat Jawaban
                            </Col>
                        </Row>

                        <hr/>

                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "grey",
                        }} onClick={() => {
                            setSelectParticipantModalShown(false)
                        }}>
                            <MdClose/>
                        </IconButton>

                    </DialogTitle>

                    <DialogContent style={{paddingBottom: 20}}>
                        {
                            participantAnswers.map(answer => {
                                const additionalInformation = JSON.parse(answer.additional_information)

                                return (
                                    <Row style={{
                                        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        borderRadius: 10,
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: 'white',
                                        maxWidth: 900,
                                        width: '100%',
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        paddingTop: 20,
                                        paddingBottom: 15,
                                        fontFamily: 'OpenSans-SemiBold',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        cursor: 'pointer',
                                        marginBottom: 10
                                    }} onClick={() => {
                                        console.log(answer)
                                        setSelectedAnswer(answer)
                                        setShowAnswerModalShown(true)
                                    }}>
                                        <div style={{flex: 1}}>
                                            {answer.full_name} <b>({additionalInformation.score}/100)</b>
                                        </div>
                                        <div>
                                        </div>

                                        <FaChevronRight color={'#d1d1d1'}/>
                                    </Row>
                                )
                            })
                        }
                    </DialogContent>
                </Dialog>

                <FormFieldModal
                    answerRichText={true}
                    explanationRichText={true}
                    show={isManageQuizDialogShown || isManageEssayDialogShown}
                    questionTypeOption={selectedSyllabusContent.type === 'Latihan'}
                    questionRichText={true}
                    essay={isManageEssayDialogShown}
                    selectedSurvey={quiz}
                    onSubmit={(fieldName, type, description, options, index, correctAnswer) => {
                        console.log('type', type)

                        const selectedSyllabusContentTemp = {
                            ...selectedSyllabusContent
                        }

                        let quiz = {}

                        if (selectedSyllabusContentTemp.type === 'Uraian') {
                            quiz = {
                                question: fieldName,
                                explanation: description,
                                type
                            };
                        } else {
                            quiz = {
                                question: fieldName,
                                choices: options,
                                correct_answer: correctAnswer,
                                explanation: description,
                                type
                            };
                        }

                        if (selectedQuestionIdx === -1) {
                            selectedSyllabusContentTemp.content.push(quiz)
                        } else {
                            selectedSyllabusContentTemp.content[selectedQuestionIdx] = quiz;
                        }

                        setQuiz({})
                        setSelectedQuestionIdx(-1)
                        setSelectedSyllabusContent(selectedSyllabusContentTemp)

                        setManageQuizDialogShown(false)
                        setManageEssayDialogShown(false)

                        console.log(fieldName, type, description, options, index, correctAnswer)
                    }}
                    onClose={() => {
                        setQuiz({})
                        setSelectedQuestionIdx(-1)
                        setManageQuizDialogShown(false)
                        setManageEssayDialogShown(false)
                    }}/>

                <>
                    <Dialog open={isManageSyllabusContentDialogShown}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => {
                                setSelectedSyllabusContent({});
                                setManageSyllabusContentDialogShown(false)
                            }}
                    >
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    Kelola Subbab
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent>
                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                            }}
                            >
                                <Col md={12}>
                                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold', marginBottom: '.5em'}}>
                                        Nama
                                    </div>

                                    <RichText onBlurAndSave={(value) => {
                                        const selectedSyllabusTemp = {...selectedSyllabusContent};

                                        selectedSyllabusTemp.name = value;

                                        setSelectedSyllabusContent(selectedSyllabusTemp)
                                    }
                                    } value={selectedSyllabusContent.name}/>

                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                        Tipe
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{
                                marginInlineStart: "2%",
                                marginInlineEnd: "2%",
                            }}>
                                <Col md={12}>
                                    <Select
                                        value={selectedSyllabusContent.type ? selectedSyllabusContent.type : 0}
                                        onChange={e => {
                                            setSelectedSyllabusContent({
                                                ...selectedSyllabusContent,
                                                type: e.target.value,
                                                content: e.target.value === 'Glosarium' || e.target.value === 'Kuis' || e.target.value === 'Latihan' || e.target.value === 'Uraian' ? [] : {}
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            Harap Pilih Tipe
                                        </MenuItem>

                                        {
                                            TYPES.map(type => {
                                                return (
                                                    <MenuItem value={type}>
                                                        {type}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                        Durasi
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <TextField
                                            onChange={(e) => {
                                                if (isNaN(e.target.value)) {
                                                    showSnackBar('Harap hanya masukan angka', 'warning')
                                                } else if (e.target.value > 99) {
                                                    showSnackBar('Menit harus di antara 0 - 99', 'warning')
                                                } else {
                                                    const selectedSyllabusTemp = {...selectedSyllabusContent};

                                                    if (selectedSyllabusTemp.duration) {
                                                        const seconds = selectedSyllabusTemp.duration % 60;

                                                        selectedSyllabusTemp.duration = e.target.value * 60 + seconds;
                                                    } else {
                                                        selectedSyllabusTemp.duration = e.target.value * 60
                                                    }

                                                    setSelectedSyllabusContent(selectedSyllabusTemp)
                                                }
                                            }}
                                            value={!isNaN(pad(Math.floor(selectedSyllabusContent.duration / 60))) ? pad(Math.floor(selectedSyllabusContent.duration / 60)) : ''}
                                            placeholder={'Menit'}
                                            style={{
                                                marginLeft: "0.5em",
                                                fontSize: "1em",
                                                maxWidth: 50,
                                                textAlign: 'center'
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    fontSize: "1.2em",
                                                    cursor: "pointer",
                                                    textAlign: 'center'
                                                },
                                            }}
                                        />

                                        <div style={{marginLeft: 15, marginRight: 5}}>
                                            :
                                        </div>

                                        <TextField
                                            onChange={(e) => {
                                                if (isNaN(e.target.value)) {
                                                    showSnackBar('Harap hanya masukan angka', 'warning')
                                                } else if (e.target.value > 60) {
                                                    showSnackBar('Detik harus di antara 0 - 60', 'warning')
                                                } else {
                                                    const selectedSyllabusTemp = {...selectedSyllabusContent};

                                                    if (selectedSyllabusTemp.duration) {
                                                        const minutes = Math.floor(selectedSyllabusTemp.duration / 60);

                                                        selectedSyllabusTemp.duration = minutes * 60 + parseInt(e.target.value);
                                                    } else {
                                                        selectedSyllabusTemp.duration = e.target.value
                                                    }

                                                    setSelectedSyllabusContent(selectedSyllabusTemp)
                                                }
                                            }}
                                            value={!isNaN(pad(selectedSyllabusContent.duration % 60)) ? pad(selectedSyllabusContent.duration % 60) : ''}
                                            placeholder={'Detik'}
                                            style={{
                                                marginLeft: "0.5em",
                                                fontSize: "1em",
                                                maxWidth: 50,
                                                textAlign: 'center'
                                            }}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    fontSize: "1.2em",
                                                    cursor: "pointer",
                                                    textAlign: 'center'
                                                },
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    {selectedSyllabusContent.type === 'Prolog' &&
                                    <>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold', flex: 1}}>
                                                Pertanyaan
                                            </div>

                                            <div>
                                                {/*<Button*/}
                                                {/*    onClick={() => setCommentDialogShown(true)}*/}
                                                {/*    style={{fontSize: '.8em', fontFamily: 'OpenSans-SemiBold'}}*/}
                                                {/*    variant={'link'}>Lihat Komentar (2)</Button>*/}
                                            </div>
                                        </div>

                                        <div style={{fontFamily: 'OpenSans-Regular'}}>
                                            <RichText onBlurAndSave={(value) => {
                                                setSelectedSyllabusContent({
                                                    ...selectedSyllabusContent,
                                                    content: {
                                                        question: value
                                                    }
                                                })
                                            }
                                            } value={selectedSyllabusContent.content.question}/>
                                        </div>

                                        {/*<Form.Control as="textarea" rows="5"*/}
                                        {/*              placeholder={'Pertanyaan'}*/}
                                        {/*              value={selectedSyllabusContent.content.question}*/}
                                        {/*              onChange={(e) => {*/}
                                        {/*                  setSelectedSyllabusContent({*/}
                                        {/*                      ...selectedSyllabusContent,*/}
                                        {/*                      content: {*/}
                                        {/*                          question: e.target.value*/}
                                        {/*                      }*/}
                                        {/*                  })*/}
                                        {/*              }}*/}
                                        {/*              style={{*/}
                                        {/*                  border: '1px solid #6AD0F633',*/}
                                        {/*                  borderRadius: 0,*/}
                                        {/*                  resize: 'none',*/}
                                        {/*                  paddingLeft: 20,*/}
                                        {/*                  paddingTop: 15,*/}
                                        {/*                  fontFamily: 'OpenSans-Regular'*/}
                                        {/*              }}/>*/}
                                    </>
                                    }

                                    {selectedSyllabusContent.type === 'Dokumen' &&
                                    <>
                                        <div>
                                            <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Dokumen
                                            </div>
                                            <a
                                                style={{
                                                    display: selectedSyllabusContent.content?.url ? null : "none"
                                                }}
                                                href={selectedSyllabusContent.content?.url} target={'_blank'} download>
                                                <CustomButton
                                                    style={{
                                                        fontSize: "0.85em",
                                                        fontFamily: "Poppins",
                                                        textTransform: "none",
                                                        marginBottom: "1em",
                                                    }}
                                                    variant={"outlined"}>
                                                    {selectedSyllabusContent.content?.url ? textFormatter.getAttachmentURL(selectedSyllabusContent.content?.url) : ""}
                                                    <MdFileDownload style={{marginLeft: "0.5em"}}/>
                                                </CustomButton>
                                            </a>

                                            <FileUpload
                                                style={{
                                                    marginTop: "0.5em"
                                                }}
                                                text={selectedSyllabusContent.content?.url ? "Ubah Dokumen" : "+ Unggah Dokumen"}
                                                isLoading={isUploadingAttachment}
                                                onDrop={addAttachment}
                                            />
                                        </div>
                                        {/*<LagFreeTextEditor*/}
                                        {/*    title={'Tautan Dokumen'}*/}
                                        {/*    value={selectedSyllabusContent.content?.url}*/}
                                        {/*    changeValue={(value) => setSelectedSyllabusContent({*/}
                                        {/*        ...selectedSyllabusContent,*/}
                                        {/*        content: {*/}
                                        {/*            url: value*/}
                                        {/*        }*/}
                                        {/*    })}*/}
                                        {/*    placeholder={"Tautan Dokumen"}/>*/}
                                        {/*<a*/}
                                        {/*    style={{*/}
                                        {/*        // display: 'Test.pdf', null : "none"*/}
                                        {/*    }}*/}
                                        {/*    href={'#'} target={'_blank'} download>*/}
                                        {/*    <CustomButton*/}
                                        {/*        style={{*/}
                                        {/*            fontSize: "0.85em",*/}
                                        {/*            fontFamily: "Poppins",*/}
                                        {/*            textTransform: "none",*/}
                                        {/*            marginBottom: "1em",*/}
                                        {/*        }}*/}
                                        {/*        variant={"outlined"}>*/}
                                        {/*        Test.pdf*/}
                                        {/*        <MdFileDownload style={{marginLeft: "0.5em"}}/>*/}
                                        {/*    </CustomButton>*/}
                                        {/*</a>*/}

                                        {/*<FileUpload*/}
                                        {/*    style={{*/}
                                        {/*        marginTop: "0.5em"*/}
                                        {/*    }}*/}
                                        {/*    text={'Ubah Dokumen'}*/}
                                        {/*    // label={classObj.attachment_url ? "Ubah Lampiran" : "+ Unggah Lampiran"}*/}
                                        {/*    // isLoading={isUploadingAttachment}*/}
                                        {/*    // onDrop={addAttachment}*/}
                                        {/*/>*/}
                                    </>
                                    }

                                    {(selectedSyllabusContent.type === 'Kuis' || selectedSyllabusContent.type === 'Latihan' || selectedSyllabusContent.type === 'Uraian') &&
                                    <>
                                        <Row style={{marginTop: 30}}>
                                            <Col md={12}
                                                 style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <p style={{
                                                    color: Palette.PRIMARY,
                                                    fontFamily: "OpenSans-Bold",
                                                    fontSize: "1.2em",
                                                    flex: 1
                                                }}>Daftar Pertanyaan</p>

                                                <CustomButton
                                                    color={'primary'}
                                                    variant={'contained'}
                                                    onClick={async () => {
                                                        try {
                                                            const memberCourseProgressModel = new MemberCourseProgress();

                                                            const result = await memberCourseProgressModel.getUsersProgress(selectedSyllabusContent.id);

                                                            if(result.length === 0) {
                                                                alert('Tidak ada data yang tersedia')
                                                            } else {
                                                                setParticipantAnswers(result)
                                                                setSelectParticipantModalShown(true)
                                                            }
                                                            console.log(result)
                                                        } catch (e) {
                                                            console.log(e)
                                                        }

                                                        // if (selectedSyllabusContent.type === 'Uraian' || selectedSyllabusContent.type === 'Latihan') {
                                                        //     setManageEssayDialogShown(true)
                                                        // } else {
                                                        // }
                                                    }}
                                                    style={{
                                                        fontSize: "0.8em",
                                                        fontFamily: "Poppins",
                                                        textTransform: "none",
                                                        marginBottom: 15,
                                                        marginRight: 10
                                                    }}>
                                                    Lihat Jawaban
                                                </CustomButton>

                                                <CustomButton
                                                    onClick={() => {
                                                        // if (selectedSyllabusContent.type === 'Uraian' || selectedSyllabusContent.type === 'Latihan') {
                                                        //     setManageEssayDialogShown(true)
                                                        // } else {
                                                        setManageQuizDialogShown(true)
                                                        // }
                                                    }}
                                                    style={{
                                                        fontSize: "0.8em",
                                                        fontFamily: "Poppins",
                                                        textTransform: "none",
                                                        marginBottom: 15
                                                    }}
                                                    variant={"outlined"}>
                                                    + Tambah Pertanyaan
                                                </CustomButton>
                                            </Col>
                                        </Row>

                                        <MobTable
                                            columns={questionColumns}
                                            data={selectedSyllabusContent.content}
                                            interactions={questionInteractions}
                                        />
                                    </>}


                                    {selectedSyllabusContent.type === 'Glosarium' &&
                                    <>
                                        <Row style={{marginTop: 30}}>
                                            <Col md={12}
                                                 style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                <p style={{
                                                    color: Palette.PRIMARY,
                                                    fontFamily: "OpenSans-Bold",
                                                    fontSize: "1.2em",
                                                    flex: 1
                                                }}>Konten Glosarium</p>

                                                <CustomButton
                                                    onClick={() => {
                                                        setGlossary({})

                                                        setManageGlossaryShown(true)
                                                    }}
                                                    style={{
                                                        fontSize: "0.8em",
                                                        fontFamily: "Poppins",
                                                        textTransform: "none",
                                                        marginBottom: 15
                                                    }}
                                                    variant={"outlined"}>
                                                    + Tambah Glosarium
                                                </CustomButton>
                                            </Col>
                                        </Row>

                                        <MobTable
                                            columns={glossaryColumns}
                                            data={selectedSyllabusContent.content}
                                            interactions={glossaryInteractions}
                                        />
                                    </>}

                                    {selectedSyllabusContent.type === 'Video' &&
                                    <>
                                        <div style={{display: 'flex', width: '100%'}}>
                                            <div style={{flex: 1}}>
                                                <LagFreeTextEditor
                                                    title={'Tautan Video'}
                                                    value={selectedSyllabusContent.content?.url}
                                                    changeValue={(value) => setSelectedSyllabusContent({
                                                        ...selectedSyllabusContent,
                                                        content: {
                                                            ...selectedSyllabusContent.content,
                                                            url: value
                                                        }
                                                    })}
                                                    style={{flex: 1}}
                                                    placeholder={"Tautan Video"}/>
                                            </div>

                                            <div>
                                                <FileUploadIcon
                                                    style={{
                                                        marginTop: "0.5em"
                                                    }}
                                                    isLoading={isUploadingAttachment}
                                                    onDrop={addAttachmentGdrive}
                                                    progress={progress}
                                                />

                                            </div>
                                        </div>

                                        <div style={{marginTop: '1em'}}>
                                            <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Dokumen (Opsional)
                                            </div>
                                            <a
                                                style={{
                                                    display: selectedSyllabusContent.content?.document ? null : "none"
                                                }}
                                                href={selectedSyllabusContent.content?.document} target={'_blank'} download>
                                                <CustomButton
                                                    style={{
                                                        fontSize: "0.85em",
                                                        fontFamily: "Poppins",
                                                        textTransform: "none",
                                                        marginBottom: "1em",
                                                    }}
                                                    variant={"outlined"}>
                                                    {selectedSyllabusContent.content?.document ? textFormatter.getAttachmentURL(selectedSyllabusContent.content?.document) : ""}
                                                    <MdFileDownload style={{marginLeft: "0.5em"}}/>
                                                </CustomButton>
                                            </a>

                                            <FileUpload
                                                style={{
                                                    marginTop: "0.5em"
                                                }}
                                                text={selectedSyllabusContent.content?.document ? "Ubah Dokumen" : "+ Unggah Dokumen"}
                                                isLoading={isUploadingAttachment2}
                                                onDrop={async (file) => {
                                                    const document = await addAttachment(file, setIsUploadingAttachment2)

                                                    setSelectedSyllabusContent({
                                                        ...selectedSyllabusContent,
                                                        content: {
                                                            ...selectedSyllabusContent.content,
                                                            document
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </>
                                    }

                                    {selectedSyllabusContent.type === 'Teks' &&
                                    <>
                                        <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                            Konten
                                        </div>

                                        <div style={{fontFamily: 'OpenSans-Regular'}}>
                                            <RichText onBlurAndSave={(value) => {
                                                setSelectedSyllabusContent({
                                                    ...selectedSyllabusContent,
                                                    content: {
                                                        text: value
                                                    }
                                                })
                                            }
                                            } value={selectedSyllabusContent.content.text}/>
                                        </div>
                                    </>
                                    }
                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '3em',
                                paddingBottom: '1em',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            >
                                <CustomButton
                                    style={{
                                        borderWidth: 0,
                                        marginRight: 10,
                                        width: 120,
                                        fontFamily: 'OpenSans-SemiBold'
                                    }}
                                    onClick={() => {
                                        setSelectedSyllabusContent({})
                                        setManageSyllabusContentDialogShown(false)
                                    }}
                                    variant={"outlined"} color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    onMouseOver={() => inputRef.current.focus()}
                                    onClick={async () => {
                                        if(!selectedSyllabusContent.name) {
                                            alert('Harap isi nama subbab!')
                                        } else if(!selectedSyllabusContent.duration) {
                                            alert('Harap isi durasi subbab!')
                                        } else if(!selectedSyllabusContent.type) {
                                            alert('Harap pilih durasi subbab!')
                                        } else {
                                            const courseSubChapterModel = new CourseSubChapter();

                                            try {
                                                let result;
                                                if (selectedSyllabusContent.id) {
                                                    result = await courseSubChapterModel.update(selectedSyllabusContent.id, {
                                                        ...selectedSyllabusContent,
                                                        content: JSON.stringify(selectedSyllabusContent.content),
                                                        course_chapter_id: props.match.params.syllabus_id
                                                    });

                                                    if (result.success) {
                                                        alert('Subbab berhasil diperbarui!');

                                                        getCourseSubChapter();

                                                        setManageSyllabusContentDialogShown(false)

                                                        setSelectedSyllabusContent({})
                                                    }
                                                } else {
                                                    result = await courseSubChapterModel.insert({
                                                        ...selectedSyllabusContent,
                                                        content: JSON.stringify(selectedSyllabusContent.content),
                                                        course_chapter_id: props.match.params.syllabus_id,
                                                        course_id: props.match.params.event_url
                                                    });

                                                    if (result.id) {
                                                        alert('Subbab berhasil ditambahkan!');

                                                        getCourseChapter();

                                                        setManageSyllabusContentDialogShown(false)

                                                        setSelectedSyllabusContent({})
                                                    }
                                                }

                                                console.log(result);
                                            } catch (e) {
                                                if (e.code === 'PARTICIPANT_ALREADY_REGISTERED') {
                                                    alert('Tidak bisa membuat subbab baru karena sudah ada peserta terdaftar')
                                                    setManageSyllabusContentDialogShown(false)

                                                    setSelectedSyllabusContent({})

                                                    getNoOfAcceptedParticipants()
                                                }

                                                console.log(e)
                                            }
                                            console.log(selectedSyllabusContent)
                                        }
                                    }}
                                    style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"contained"} color="primary">
                                    <>Simpan&nbsp;&nbsp;<FaSave/></>
                                </CustomButton>
                            </Row>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={isManageGlossaryShown}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => setManageGlossaryShown(false)}
                    >
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    Kelola Glosarium
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>
                        <DialogContent>
                            <Row>
                                <Col>
                                    <div style={{
                                        fontSize: ".9rem",
                                        fontFamily: 'OpenSans-SemiBold',
                                        flex: 1,
                                        marginTop: '1em'
                                    }}>
                                        Kata
                                    </div>
                                    <RichText onBlurAndSave={(value) => {
                                        setGlossary({
                                            ...glossary,
                                            word: value
                                        })
                                    }
                                    } value={glossary.word}/>

                                    <div style={{
                                        fontSize: ".9rem",
                                        fontFamily: 'OpenSans-SemiBold',
                                        flex: 1,
                                        marginTop: '1em'
                                    }}>
                                        Definisi
                                    </div>


                                    <RichText onBlurAndSave={(value) => {
                                        setGlossary({
                                            ...glossary,
                                            definition: value
                                        })
                                    }
                                    } value={glossary.definition}/>
                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '3em',
                                paddingBottom: '1em',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            >
                                <CustomButton
                                    style={{
                                        borderWidth: 0,
                                        marginRight: 10,
                                        width: 120,
                                        fontFamily: 'OpenSans-SemiBold'
                                    }}
                                    onClick={() => {
                                        setGlossary({})
                                        setSelectedGlossaryIdx(-1)
                                        setManageGlossaryShown(false)
                                    }}
                                    variant={"outlined"} color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    onMouseOver={() => inputRef.current.focus()}
                                    onClick={() => {
                                        const selectedSyllabusContentTemp = {
                                            ...selectedSyllabusContent
                                        }

                                        if (selectedGlossaryIdx === -1) {
                                            selectedSyllabusContentTemp.content.push(glossary)
                                        } else {
                                            selectedSyllabusContentTemp.content[selectedGlossaryIdx] = glossary;
                                        }

                                        setSelectedGlossaryIdx(-1)
                                        setSelectedSyllabusContent(selectedSyllabusContentTemp)

                                        setManageGlossaryShown(false)
                                    }}
                                    style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"contained"} color="primary">
                                    <>Simpan&nbsp;&nbsp;<FaSave/></>
                                </CustomButton>
                            </Row>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={isCommentDialogShown}
                            maxWidth="md"
                            fullWidth={true}
                            onClose={() => setCommentDialogShown(false)}
                    >
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    Komentar (2)
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent>
                            <Row>
                                <Col>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 30
                                    }}>
                                        <div style={{
                                            flex: 1,
                                        }}>
                                            <div style={{display: 'flex'}}>
                                                <img
                                                    style={{
                                                        width: "2.5em",
                                                        objectFit: "contain",
                                                        borderRadius: "50%",
                                                        height: "2.5em",
                                                        padding: 1,
                                                        backgroundColor: Palette.SECONDARY
                                                    }}
                                                    src={require("../../../asset/public_image_default_user.png")}/>

                                                <div style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    paddingLeft: 15,
                                                    flexDirection: 'column',
                                                }}>
                                                    <div style={{
                                                        fontFamily: 'OpenSans-Bold',
                                                        fontSize: '1.15em'
                                                    }}>
                                                        Timotius
                                                    </div>

                                                    <div style={{
                                                        marginLeft: 2,
                                                        color: '#5F5555A6',
                                                        fontFamily: 'OpenSans-Regular',
                                                        marginTop: 10,
                                                        maxLines: 5,
                                                        lineHeight: "1.5em",
                                                        fontSize: '1.05em',
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                        Lorem ipsum
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 30
                                    }}>
                                        <div style={{
                                            flex: 1,
                                        }}>
                                            <div style={{display: 'flex'}}>
                                                <img
                                                    style={{
                                                        width: "2.5em",
                                                        objectFit: "contain",
                                                        borderRadius: "50%",
                                                        height: "2.5em",
                                                        padding: 1,
                                                        backgroundColor: Palette.SECONDARY
                                                    }}
                                                    src={require("../../../asset/public_image_default_user.png")}/>

                                                <div style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    paddingLeft: 15,
                                                    flexDirection: 'column',
                                                }}>
                                                    <div style={{
                                                        fontFamily: 'OpenSans-Bold',
                                                        fontSize: '1.15em'
                                                    }}>
                                                        Timotius
                                                    </div>

                                                    <div style={{
                                                        marginLeft: 2,
                                                        color: '#5F5555A6',
                                                        fontFamily: 'OpenSans-Regular',
                                                        marginTop: 10,
                                                        maxLines: 5,
                                                        lineHeight: "1.5em",
                                                        fontSize: '1.05em',
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                        Lorem ipsum
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </DialogContent>

                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: 15, marginBottom: 15}}>
                            <Button variant="secondary" onClick={() => setCommentDialogShown(false)}>
                                Tutup
                            </Button>
                        </div>
                    </Dialog>

                    <div style={{
                        marginTop: "0.5em",
                        // marginBottom: "0.5em",
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontWeight: "800",
                        fontSize: "1.5em"
                    }}>Kelola Bab
                    </div>

                    <div>
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            marginTop: "1em",
                            fontFamily: 'OpenSans-SemiBold'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Nama Bab'}
                                    value={syllabus.name}
                                    changeValue={(value) => {
                                        setSyllabus({
                                            ...syllabus,
                                            name: value
                                        })
                                    }}
                                    placeholder={"Nama Bab"}/>
                            </Col>

                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em',
                            paddingBottom: '1em',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: "row"
                        }}
                        >
                            {anyParticipantRegistered && !props.match.params.syllabus_id &&
                            <span style={{
                                marginTop: 8,
                                marginRight: 7,
                                fontSize: '.7em',
                                color: 'red',
                                fontFamily: 'OpenSans-Regular'
                            }}>Tidak bisa membuat bab baru karena sudah ada peserta terdaftar.</span>
                            }

                            <CustomButton
                                onClick={async () => {
                                    if (!syllabus.name) {
                                        showSnackBar('Harap mengisi nama bab', 'warning')
                                    } else {
                                        const courseChapterModel = new CourseChapter();

                                        try {
                                            let result;

                                            if (props.match.params.syllabus_id) {
                                                result = await courseChapterModel.update(parseInt(props.match.params.syllabus_id), {
                                                    name: syllabus.name,
                                                    course_id: parseInt(props.match.params.event_url)
                                                })

                                                if (result.success) {
                                                    showSnackBar('Bab berhasil diperbarui!')
                                                }
                                            } else {
                                                result = await courseChapterModel.insert({
                                                    name: syllabus.name,
                                                    course_id: parseInt(props.match.params.event_url)
                                                })
                                            }

                                            if (result.id) {
                                                alert('Bab berhasil tersimpan!')

                                                history.push(`/editor/syllabus/${props.match.params.event_url}/${result.id}/manage`)
                                            }

                                            console.log(result)
                                        } catch (e) {
                                            if (e.code) {
                                                if (e.code === 'PARTICIPANT_ALREADY_REGISTERED') {
                                                    redirectIfParticipantRegistered();
                                                }
                                            }
                                            console.log('e', e)
                                        }
                                    }
                                }}
                                disabled={anyParticipantRegistered && !props.match.params.syllabus_id}
                                style={{color: 'white', fontFamily: 'OpenSans-SemiBold'}}
                                variant={"contained"} color="primary">
                                <>Simpan&nbsp;&nbsp;<FaSave/></>
                            </CustomButton>
                        </Row>

                        {props.match.params.syllabus_id &&
                        <>
                            <Row style={{marginTop: 30}}>
                                <Col md={12}
                                     style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <p style={{
                                        color: Palette.PRIMARY,
                                        fontFamily: "OpenSans-Bold",
                                        fontSize: "1.2em",
                                        flex: 1
                                    }}>Subbab</p>


                                    {anyParticipantRegistered &&
                                    <span style={{
                                        marginRight: 7,
                                        fontSize: '.7em',
                                        color: 'red',
                                        fontFamily: 'OpenSans-Regular',
                                        marginBottom: 15
                                    }}>Tidak bisa membuat subbab baru karena sudah ada peserta terdaftar.</span>
                                    }

                                    {isUpdatingSequence ? null :
                                        <CustomButton
                                            disabled={anyParticipantRegistered}
                                            onClick={() => setManageSyllabusContentDialogShown(true)}
                                            style={{
                                                fontSize: "0.8em",
                                                fontFamily: "Poppins",
                                                textTransform: "none",
                                                marginBottom: 15
                                            }}
                                            variant={"outlined"}>
                                            + Tambah Konten
                                        </CustomButton>
                                    }
                                </Col>
                            </Row>

                            {syllabusContent.length > 1 ?
                                <Row>
                                    <Col md={12}
                                         style={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             alignItems: 'center',
                                             justifyContent: 'flex-end'
                                         }}>
                                        <CustomButton
                                            onClick={async () => {
                                                if (!isUpdatingSequence) {
                                                    setUpdatingSequence(true)
                                                } else {
                                                    const customSequence = [];

                                                    for (let idx = 0; idx < syllabusContent.length; idx++) {
                                                        customSequence.push(null);
                                                    }

                                                    let isNotify = false;

                                                    syllabusContent.forEach((item, idx) => {

                                                        if (!syllabusContent[idx].idx) {
                                                            if (!isNotify) {
                                                                alert('Terdapat urutan yang belum terisi');
                                                            }

                                                            isNotify = true
                                                        } else if (syllabusContent[idx].idx > syllabusContent.length) {
                                                            if (!isNotify) {
                                                                alert('Terdapat urutan yang melebihi jumlah bab yang tersedia');
                                                            }

                                                            isNotify = true
                                                        } else {
                                                            customSequence[syllabusContent[idx].idx - 1] = item.id;
                                                        }
                                                    })

                                                    let isDuplicate = false;

                                                    customSequence.forEach(item => {
                                                        if (!item) {
                                                            isDuplicate = true;
                                                            if (!isNotify) {
                                                                alert('2 atau lebih bab memiliki urutan yang sama');
                                                            }

                                                            isNotify = true
                                                        }
                                                    })

                                                    console.log(customSequence)

                                                    if (!isNotify) {
                                                        const courseChapterModel = new CourseChapter()

                                                        try {
                                                            const result = await courseChapterModel.update(props.match.params.syllabus_id, {
                                                                custom_sub_chapter_sequence: JSON.stringify(customSequence)
                                                            })

                                                            alert('Urutan berhasil diperbarui!')
                                                            window.location.reload();
                                                            console.log(result)
                                                        } catch (e) {
                                                            console.log(e)
                                                        }
                                                    }
                                                }
                                            }}
                                            style={{
                                                fontSize: "0.8em",
                                                fontFamily: "Poppins",
                                                textTransform: "none",
                                                marginBottom: 15
                                            }}
                                            color={'primary'}
                                            variant={"contained"}>
                                            {isUpdatingSequence ? 'Simpan Urutan' : 'Sunting Urutan Subbab'}
                                        </CustomButton>

                                        {syllabusContent.length > 1 && isUpdatingSequence ?
                                            <CustomButton
                                                onClick={() => setUpdatingSequence(false)}
                                                style={{
                                                    fontSize: "0.8em",
                                                    fontFamily: "Poppins",
                                                    textTransform: "none",
                                                    marginBottom: 15,
                                                    marginLeft: 15
                                                }}
                                                color={'secondary'}
                                                variant={"contained"}>
                                                Batal Sunting
                                            </CustomButton> : null
                                        }
                                    </Col>
                                </Row> : null
                            }

                            <div>
                                <MobTable
                                    columns={isUpdatingSequence ? updatingSequenceColumns : columns}
                                    data={syllabusContent}
                                    interactions={syllabusInteractions}
                                />
                            </div>
                        </>
                        }
                    </div>
                </>
            </EditorPageWrapper>
        </>
    )
}
