import {Line} from "react-chartjs-2";
import React from "react";
import Palette from "../../../util/Palette";
import Card from "react-bootstrap/Card";

export default function LineChart(props) {

    let formattedData = {
        labels: props.labels,
        datasets: props.data.map((obj,key)=>{
            return {
                beginAtZero: true,
                label: props.variables[key],
                backgroundColor: "rgba(0,0,0,.0)",
                borderColor: `rgba(255,255,255,${1 / (0.5 * key + 1)})`,
                data: obj,
                lineTension: 0,
                borderDash: key===0 ? null : [10,5]
            }
        })
    };

    let formattedOptions = {
        tooltips: {
            enabled: true,
        },
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        zeroLineColor: "rgba(0,0,0,0)",
                        color: "rgba(255,255,255,0.0)",
                    },
                    ticks: {
                        fontColor: "white"
                    }
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        zeroLineColor: "rgba(0,0,0,0)",
                        color: "rgba(255,255,255,0.1)",
                    },
                    ticks: {
                        suggestedMin : 0,
                        beginAtZero : true,
                        fontColor: "white"
                    }
                },
                {
                    display: false
                }
            ]
        }
    };

    return <>
        <Card className="text-white" style={{
            backgroundColor: props.backgroundColor ? props.backgroundColor : Palette.PRIMARY
        }}>
            <Card.Body className="pb-0">
                <div style={{
                    height: props.captionHeight ? props.captionHeight : "3rem" //KALO BISA JANGAN DI OVERRIDE
                }}>
                    {props.caption}
                </div>
            </Card.Body>
            <div style={{height: "250px"}}>
                <Line
                    data={formattedData}
                    options={formattedOptions}
                    height={"500px"}
                />
            </div>
        </Card>

    </>

}
