import {Route, Switch, useHistory} from "react-router-dom";
import DashboardPage from "./DashboardPage";
import EventMain from "./EventMain";
import CreateClass from "../CreateClass";
import ClassDetail from "./ClassDetail";
import PrivateClassEditorMain from "./PrivateClassEditorMain";
import PrivateClassUserManagement from "./PrivateClassUserManagement/UserManagement";
import FeedbackMain from "../Feedback";
import Feedback from "./Feedback";
import PrivateClassFeedback from "./PrivateClassFeedback";
import EmailManagementForClass from "./EmailManagement/EmailManagementForClass";
import EmailManagementForGlobal from "./EmailManagement/EmailManagementForGlobal";
import UserList from "./UserList";
import Question from "./Question";
import Participation from "../Participations";
import Transaction from "../Transaction";
import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import User from "../../../models/User";

export default function MainContainer(props) {

    const [menu, setMenu] = useState([])
    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    useEffect(() => {
        // getUserData()
        console.log("DBG GET USER DATA FROM APPJS")
    }, [])

    const getUserData = async () => {

        try{
            let userModel = new User()
            let menu = await userModel.tokenLogin();

            if(menu.menu){
                setMenu(JSON.parse(menu.menu))
            }

        }catch(e) {
            console.log(e)
        }



    }

    if(menu.length > 0){
        return <>
            <Switch>
                {
                    menu.includes('dashboard') && <>
                        <Route exact path="/dashboard" component={DashboardPage}/>
                        <Route exact path="/editor/dashboard" component={DashboardPage}/>
                        <Route exact path="/editor/dashboard/:event_url" component={EventMain}/>
                    </>
                }
                {
                    menu.includes('class') && <>
                        <Route exact path="/create-class" render={() => {
                            return (
                                <CreateClass privateClass={false}/>
                            )
                        }}/>
                        <Route exact path="/editor/detail/:event_url" component={ClassDetail}/>
                    </>
                }
                {
                    menu.includes('private-class') && <>

                        <Route exact path="/create-private-class" render={() => {
                            return (
                                <CreateClass privateClass={true}/>
                            )
                        }}/>
                        <Route exact path="/private-class-editor" component={PrivateClassEditorMain}/>
                        <Route exact path="/editor/privateClassVisitors/:event_url"
                               component={PrivateClassUserManagement}/>
                    </>
                }


                {
                    menu.includes('feedback') && <>
                        <Route exact path="/feedback" component={FeedbackMain}/>
                        <Route exact path="/editor/feedback/:event_url" component={Feedback}/>
                        <Route exact path="/editor/privateClassFeedback/:event_url" render={(props) => {
                            return (
                                <PrivateClassFeedback {...props} privateClass={true}/>
                            )
                        }}/>

                    </>
                }

                {
                    menu.includes('email') && <>
                        <Route exact path="/editor/email/:event_url" component={EmailManagementForClass}/>
                        <Route exact path="/editor/private-email/:event_url" render={() => {
                            return (
                                <EmailManagementForClass privateClass={true}/>
                            )
                        }}/>
                        <Route exact path="/editor/email" component={EmailManagementForGlobal}/>
                    </>
                }

                {
                    menu.includes('users') && <>
                        <Route exact path="/users" component={UserList}/>
                    </>
                }
                {
                    menu.includes('question') && <>
                        <Route exact path="/editor/question/:event_url" component={Question}/>
                    </>
                }
                {
                    menu.includes('participation') && <>
                        <Route exact path="/participation" component={Participation}/>
                    </>
                }
                {
                    menu.includes('transaction') && <>
                        <Route exact path="/transaction" component={Transaction}/>
                    </>
                }
            </Switch>
        </>
    }else{
        return <></>
    }

}
