import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col, Collapse,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap"
import {FaEnvelope} from "react-icons/all";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from 'prop-types'

import 'moment/locale/id'
import {TextField} from "@material-ui/core"
import Email from "../../../../../models/Email"
import moment from "moment"
import Palette from "../../../../../util/Palette"
import ApiRequest from "../../../../../util/ApiRequest"
import MemberCourse from "../../../../../models/MemberCourse";


export default function RegistrantInspectorDialog({registrant, show, handleClose, onAccept, onReject, onRejectWithoutEmail, onSendScenarioEmail, activeSession}) {
    const marginPreset = {marginLeft: "10px", marginRight: "10px", color:"#FFFFFF"}
    console.log(registrant)

    RegistrantInspectorDialog.propTypes = {
        registrant: PropTypes.object,
        show: PropTypes.bool,
        handleClose: PropTypes.func,
        onAccept: PropTypes.func,
        onReject: PropTypes.func,
        onSendScenarioEmail: PropTypes.func,
        activeSession: PropTypes.object
    }

    let [showProof, setShowProof] = useState(false)
    let [showTransactionInfo, setShowTransactionInfo] = useState(false)
    let [showSendEmailScenarioDialog, setShowSendEmailScenarioDialog] = useState(false)

    const sendScenarioEmail = (scenario) =>{
        onSendScenarioEmail(scenario)
        setShowSendEmailScenarioDialog(false)
    }

    /**
     *
     * @param {Date} date
     */
    const formatDate = (d) => {
        return moment(d).format("DD-MM-YYYY")
    }

    const formatDateTime = (d) => {
        return moment(d).format("DD-MM-YYYY HH:mm:ss")
    }


    const renderButtons = () => {

        if (registrant.status === "ACCEPTED" || registrant.status === "REJECTED") {
            return <>
                <Container>
                    <Row>
                        <Col sm={6}>
                            <Button onClick={async ()=>{
                                try {
                                    const memberCourseModel = new MemberCourse();

                                    const result = await memberCourseModel.resendAcceptedEmail(registrant.id)

                                    if(result.success) {
                                        alert('Pengiriman surel berhasil!')
                                    }
                                    console.log('result', result)
                                } catch (e) {
                                    console.log(e)
                                }
                            }} style={marginPreset}>Kirim Ulang Surel Diterima</Button>
                        </Col>
                        <Col sm={6} style={{textAlign:"right"}}>
                            {onRejectWithoutEmail ? <Button onClick={onRejectWithoutEmail} style={marginPreset}>Tolak Tanpa Surel</Button> : null}
                            {onReject ? <Button onClick={onReject} style={marginPreset}>Tolak</Button> : null}
                            <Button onClick={handleClose} style={marginPreset}>Tutup</Button>
                        </Col>
                    </Row>
                </Container>

            </>
        } else {
            return <>
                {onRejectWithoutEmail ? <Button onClick={onRejectWithoutEmail}>Tolak Tanpa Email</Button> : null}
                {onReject ? <Button onClick={onReject}>Tolak</Button> : null}
                {onAccept ? <Button onClick={onAccept}>Terima</Button> : null}
                {handleClose ? <Button onClick={handleClose}>Tutup</Button> : null}
            </>
        }
    }

    const renderSendEmailConfirmDialog = () =>{

        return <Modal size={"sm"} show={showSendEmailScenarioDialog} onHide={()=>{setShowSendEmailScenarioDialog(false)}}>
            <Modal.Header closeButton>
                Kirim Surel
            </Modal.Header>
            <Modal.Body>
                Pilih Skenario
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>{sendScenarioEmail("ACCEPTED")}} style={marginPreset}><FaEnvelope/> Diterima</Button>
                <Button onClick={()=>{sendScenarioEmail("REMINDER")}} style={marginPreset}><FaEnvelope/> Pengingat</Button>
            </Modal.Footer>
        </Modal>
    }

    const renderImageProofCollapse = () => {
        if (activeSession.price > 0 && !activeSession.online_payment) {
            if (registrant.payment_proof_url) {
                let buttonMessage = showProof ? "Tutup Bukti Pembayaran" : "Tampilkan Bukti Pembayaran"
                return <div>
                    <Button onClick={() => setShowProof(!showProof)}>{buttonMessage}</Button>
                    <Collapse in={showProof}>
                        <div>
                            <img src={registrant.payment_proof_url} style={{width: "100%"}}/>
                        </div>
                    </Collapse>
                </div>
            } else {
                return <div><Button disabled>Peserta belum mengunggah bukti pembayaran</Button></div>
            }

        }
        return <></>
    }

    const renderTransactionInfo = () => {
        let msg = showTransactionInfo ? "Tutup Informasi Tranasksi" : "Buka Informasi Transaksi"
        if (registrant.transaction_info) {
            let content = Object.keys(registrant.transaction_info).map((key) =>
                <Row>
                    <Col lg={4}>{key}</Col>
                    <Col lg={8}>{registrant.transaction_info[key]}</Col>
                </Row>)

            return <>
                <span
                    onClick={() => {
                        setShowTransactionInfo(!showTransactionInfo)
                    }}
                    style={{
                        color: Palette.PRIMARY,
                        fontSize: "0.8em",
                        cursor: "pointer"
                    }}>{msg}</span>
                <Collapse in={showTransactionInfo}>
                    <div>
                        <h3>Transaction Info</h3>
                        {content}
                    </div>

                </Collapse>

            </>
        }
        return <></>

    }

    return (<>
        {renderSendEmailConfirmDialog()}
        <Modal show={show && !showSendEmailScenarioDialog} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
                Periksa Registrasi
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12}><h3>Info Registrasi</h3></Col>
                </Row>
                <Row>
                    <Col lg={4}>ID Registrasi</Col>
                    <Col lg={8}>{registrant.id}</Col>
                </Row>
                <Row>
                    <Col lg={4}>Status Registrasi</Col>
                    <Col lg={8}>{registrant.status}</Col>
                </Row>
                <Row>
                    <Col lg={4}>ID Pengguna</Col>
                    <Col lg={8}>{registrant.member?.id}</Col>
                </Row>
                <Row>
                    <Col lg={4}>Nama Lengkap</Col>
                    <Col lg={8}>{registrant.member?.full_name}</Col>
                </Row>
                <Row>
                    <Col lg={4}>Email</Col>
                    <Col lg={8}>{registrant.member?.email}</Col>
                </Row>
                <Row>
                    <div style={{height : "1em"}}></div>
                </Row>
                <Row>
                    <Col lg={4}>Waktu Registrasi Dibuat</Col>
                    <Col lg={8}>{formatDateTime(new Date(registrant?.order?.created_at))}</Col>
                </Row>
                {
                    registrant.status === "ACCEPTED" ?
                        <Row>
                            <Col lg={4}>Waktu Pembayaran Diproses</Col>
                            <Col lg={8}>{formatDateTime(new Date(registrant?.order?.paid_at))}</Col>
                        </Row>
                        :
                        null
                }

                {
                    registrant.status === "WAITING" || registrant.status === "REJECTED" ?
                        <>
                            <Row>
                                <Col lg={4}>Waktu Kadaluarsa</Col>
                                <Col lg={8}>{formatDateTime(new Date(registrant?.order?.should_expire_at))}</Col>
                            </Row>
                        </>
                        : null
                }
                {
                    registrant.status === "REJECTED" ?
                        <>
                            <Row>
                                <Col lg={4}>Waktu Ditolak</Col>
                                <Col lg={8}>{formatDateTime(new Date(registrant?.rejected_at))}</Col>
                            </Row>
                        </>
                        : null
                }

                <Row>
                    <div style={{height : "1em"}}></div>
                </Row>

                <Row>
                    <Col lg={4}>ID di Midtrans</Col>
                    <Col lg={8}>{registrant?.order?.order_id ? registrant?.order?.order_id : "-" }</Col>
                </Row>
                <Row>
                    <Col lg={4}>URL pembayaran Midtrans</Col>
                    <Col lg={8}>{registrant?.order?.snap_payment_url ? registrant?.order?.snap_payment_url : "-" }</Col>
                </Row>
                <Row>
                    <Col lg={4}>Catatan Admin</Col>
                    <Col lg={8}>{registrant.participant_note || "-" }</Col>
                </Row>


                {
                    registrant.status === "REJECTED" ?
                        <>
                            <Row>
                                <Col lg={4}>Alasan Penolakan</Col>
                                <Col lg={8}>{registrant.status_change_info ? registrant.status_change_info : '-'}</Col>
                            </Row>
                        </>
                        : null
                }
                {/*<Row>*/}
                {/*    <Col lg={4}>Catatan Partisipan</Col>*/}
                {/*    <Col lg={8}>{registrant.participant_note || "-" }</Col>*/}
                {/*</Row>*/}

                <hr/>
                {/*{renderTransactionInfo()}*/}

            </Modal.Body>
            <Modal.Footer>
                {renderButtons()}
            </Modal.Footer>
        </Modal>
    </>)

}
