import Dialog from "@material-ui/core/Dialog";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rating from "react-rating";
import {IoMdStar} from "react-icons/io";
import FeedbackCard from "./FeedbackCard";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PageWrapper from "../page/PageWrapper";
import React, {useState} from "react";
import Session from "../../models/Session";
import Strings from "../../util/Strings";
import moment from "moment";

export default function FeedbackModal(props) {
    const {isOpen, onClose, session} = props

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [rating, setRating] = useState(0);
    const [wantedClass, setWantedClass] = useState("")
    const [feedback, setFeedback] = useState("")
    const [question, setQuestion] = useState("")

    const [feedbackFields, setFeedbackFields] = useState({
        'topik_sesuai': null,
        'topik_dipahami': null,
        'pengajar_jelas': null,
        'pengajar_interaktif': null,
        'pendaftaran_mudah': null,
        'mekanisme_jelas': null,
    });

    const submit = async () => {

        console.log(session)
        console.log(feedbackFields, Object.keys(feedbackFields))

        for (let field of Object.keys(feedbackFields)) {

            console.log(field)

            if (feedbackFields[field] === null) {
                alert("Harap Isi " + field.charAt(0).toUpperCase() + field.replace('_', ' ').slice(1))
                return
            }
        }

        try {
            let sessionModal = new Session()

            console.log("participant id " + session.participant_id)

            let result = await sessionModal.sendFeedbackAndQuestion({
                participant_id: session.participant_id,
                question: question,
                topik_sesuai: feedbackFields.topik_sesuai,
                topik_dipahami: feedbackFields.topik_dipahami,
                pengajar_jelas: feedbackFields.pengajar_jelas,
                pengajar_interaktif: feedbackFields.pengajar_interaktif,
                pendaftaran_mudah: feedbackFields.pendaftaran_mudah,
                mekanisme_jelas: feedbackFields.mekanisme_jelas,
                kelas_yang_diinginkan: wantedClass,
                masukan: feedback,
            })

            console.log(result)

            alert(Strings.rating.thanks_for_response)

            onClose(true)

        } catch (e) {
            console.log(e)
        }


        /*
        this.participant_id = pf.participant_id
        // this.topik_sesuai = pf.topik_sesuai
        // this.topik_dipahami = pf.topik_dipahami
        // this.pengajar_jelas = pf.pengajar_jelas
        // this.pengajar_interaktif = pf.pengajar_interaktif
        // this.pendaftaran_mudah = pf.pendaftaran_mudah
        // this.mekanisme_jelas = pf.mekanisme_jelas
         */

        // this.kelas_yang_diinginkan = pf.kelas_yang_diinginkan
        // this.masukan = pf.masukan

    }

    return <Dialog open={isOpen} fullWidth={true} onClose={() => onClose()}>
        <Container>
            <Row style={{marginTop: 25, marginBottom: 15}}>
                <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: 18, marginLeft: 5, color: '#302a28'}}>
                    {Strings.rating.class_review}
                </Col>
            </Row>

            <Row>
                <Col style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#e9eaec',
                    marginTop: 5,
                    marginBottom: 40
                }}/>
            </Row>

            <Row style={{
                borderRadius: 8,
                // padding: 25,
                paddingBottom: 15,
                maxWidth: '700px',
                marginLeft: 0,
                marginRight: 0
            }}>
                <Container>
                    <Row style={{
                        marginBottom: 30
                    }}>
                        <Col md={12}>
                            {Strings.rating.please_fill_review_part1} {session.name}{Strings.rating.please_fill_review_part2}
                        </Col>
                    </Row>

                    <Row>
                        {
                            Object.keys(feedbackFields).map(field => {
                                return (
                                    <FeedbackCard
                                        rating={feedbackFields[field]}
                                        setRating={(rating) => {
                                            const feedbackFieldsTemp = {...feedbackFields};

                                            feedbackFieldsTemp[field] = rating;

                                            setFeedbackFields(feedbackFieldsTemp)
                                        }}
                                        windowWidth={windowWidth}
                                        name={field.charAt(0).toUpperCase() + field.replace('_', ' ').slice(1)}
                                    />
                                )
                            })
                        }

                    </Row>

                    <Row style={{marginTop: 10, marginBottom: 10}}>
                        <Form.Group style={{width: '100%'}}>
                            <Form.Label style={{fontFamily: 'OpenSans-SemiBold', fontSize: 14}}>
                                {Strings.rating.class_wanted}
                            </Form.Label>
                            <Form.Control
                                value={wantedClass}
                                onChange={(e) => {
                                    setWantedClass(e.target.value)
                                }}
                                placeholder={Strings.rating.class_wanted_placeholder}
                                style={{fontSize: "1em", border: '1px solid #6AD0F633'}}/>
                        </Form.Group>
                    </Row>

                    {moment().isBefore(moment(session.question_end_time)) ?
                        <Row style={{marginTop: 10, marginBottom: 10}}>
                            <Form.Group style={{width: '100%'}}>
                                <Form.Label style={{fontFamily: 'OpenSans-SemiBold', fontSize: 14}}>
                                    {Strings.rating.class_question}
                                </Form.Label>
                                <Form.Control
                                    value={question}
                                    onChange={(e) => {
                                        setQuestion(e.target.value)
                                    }}
                                    placeholder={Strings.rating.class_question_placeholder}
                                    style={{fontSize: "1em", border: '1px solid #6AD0F633'}}/>
                            </Form.Group>
                        </Row> : null
                    }

                    <Row>
                        <Col style={{paddingLeft: 0, paddingRight: 0}}>
                            <Form.Control as="textarea" rows="5"
                                          placeholder={Strings.rating.feedback}
                                          value={feedback}
                                          onChange={(e) => {
                                              setFeedback(e.target.value)
                                          }}
                                          style={{
                                              border: '1px solid #6AD0F633',
                                              borderRadius: 0,
                                              resize: 'none',
                                              paddingLeft: 20,
                                              paddingTop: 15,
                                              fontFamily: 'OpenSans-Regular'
                                          }}/>
                        </Col>
                    </Row>
                </Container>

                <Container style={{paddingLeft: 0, paddingRight: 0, marginTop: 10}}>
                    <Row>
                        <Col style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 50,
                            marginBottom: 22,
                            fontFamily: 'OpenSans-SemiBold'
                        }}>
                            <Button variant={'link'} style={{marginRight: 20}}
                                    onClick={() => onClose()}>
                                {Strings.rating.cancel}
                            </Button>
                            <Button style={{width: 100, height: 40}} onClick={submit}>
                                {Strings.rating.send}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>
    </Dialog>


}
