import React, {useState, useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import {MdAdd, MdClear} from "react-icons/all";
import Palette from "../../util/Palette";
import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdEvent, MdCheckCircle} from "react-icons/md";
import Button from "@material-ui/core/Button";
import RichText from "./RichText/LimitedRichText";
import {Parser} from 'html-to-react'

export default function OptionsFieldsEditor(props) {
    const inputRef = useRef(null)
    let htmlParser = new Parser()
    let [isAddingNew, setIsAddingNew] = useState(false)
    let [newField, setNewField] = useState("")

    const example = [
        "Doremifasol",
        "Remifamire",
        "Midoremifa",
        "Midoremifa",
        "Midoremifa",
    ]

    const addNew = (field) => {
        let temp = [...props.options]
        temp.push(field)
        props.onChange(temp)
    }

    const clearField = (index) => {
        let temp = [...props.options]
        temp.splice(index, 1)
        props.onChange(temp)
    }

    return <>
        <Container>
            <Row style={{
                fontSize: "0.9em",
                marginTop: "1em",
                fontFamily: 'OpenSans-SemiBold'
            }}>
                Daftar Opsi :
            </Row>
        </Container>

        <Row style={{
            marginInlineStart: "4%",
            marginInlineEnd: "4%",
            marginTop: "1em"
        }}>

            {props.options.map((obj, key) => {
                return <Card style={{
                    marginRight: "0.5em",
                    marginBottom: "0.5em"
                }} key={key}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '100%',
                        marginLeft: '0.5em',
                        marginRight: '0.5em'
                    }}>
                        {htmlParser.parse(obj)}&nbsp;
                        <IconButton
                            onClick={() => {
                                clearField(key)
                            }}
                            onMouseDown={e => e.preventDefault()}
                            size={"small"}>
                            <MdClear/>
                        </IconButton>
                    </div>
                </Card>
            })}

            {
                isAddingNew ?
                    <Card>
                        <div style={{
                            margin: "0.5em",
                            color: Palette.SECONDARY,
                            fontStyle: "bold",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: 15,
                            paddingRight: 15,
                            alignItems: 'flex-end'
                        }}>
                            <input ref={inputRef} style={{position: 'absolute', pointerEvents: 'none', opacity: 0}}/>

                            {
                                props.answerRichText ?
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <IconButton
                                            onMouseOver={() => inputRef.current.focus()}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => {
                                                addNew(newField)
                                                setNewField("")
                                            }}
                                        >
                                            <MdCheckCircle style={{color: Palette.PRIMARY}}/>
                                        </IconButton>

                                        <IconButton
                                            onMouseOver={() => inputRef.current.focus()}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={() => {
                                                setIsAddingNew(false)
                                            }}
                                        >
                                            <MdClear style={{color: Palette.PRIMARY}}/>
                                        </IconButton>
                                    </div>
                                    : null
                            }

                            {props.answerRichText ?
                                <RichText onBlurAndSave={setNewField} value={newField}/> :
                                <TextField
                                    InputProps={{
                                        style: {
                                            fontFamily: "OpenSans-Regular"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    newField.length > 0 ?
                                                        <IconButton
                                                            onMouseDown={e => e.preventDefault()}
                                                            onClick={() => {
                                                                addNew(newField)
                                                                setNewField("")
                                                            }}
                                                        >
                                                            <MdCheckCircle style={{color: Palette.PRIMARY}}/>
                                                        </IconButton>
                                                        :
                                                        <IconButton
                                                            onMouseDown={e => e.preventDefault()}
                                                            onClick={() => {
                                                                setIsAddingNew(false)
                                                            }}
                                                        >
                                                            <MdClear style={{color: Palette.PRIMARY}}/>
                                                        </IconButton>
                                                }

                                            </InputAdornment>
                                        )
                                    }}
                                    value={newField}
                                    onChange={evt => setNewField(evt.target.value)}
                                />
                            }

                        </div>
                    </Card>
                    :
                    <Card
                        onClick={() => {
                            setIsAddingNew(true)
                        }}
                        style={{
                            marginRight: "0.5em",
                            marginBottom: "0.5em",
                            cursor: "pointer"
                        }}>
                        <div style={{
                            margin: "0.5em",
                            marginLeft: 20,
                            marginRight: 20,
                            color: Palette.SECONDARY,
                            fontStyle: "bold",
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <IconButton
                                onMouseDown={e => e.preventDefault()}
                                size={"small"}>
                                <MdAdd style={{
                                    color: Palette.SECONDARY
                                }}/>
                            </IconButton>
                            <span style={{fontFamily: 'OpenSans-SemiBold', fontSize: '.9em'}}>Tambah</span>
                        </div>
                    </Card>
            }


        </Row>

    </>
}
