import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {Col, Row} from "react-bootstrap";
import PresenceForm from "../../../models/PresenceForm";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import {FaFileDownload} from "react-icons/fa";
import {CSVLink} from "react-csv";
import ContactForm from "../../../models/ContactForm";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";

export default function Help(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState({label: "Semua Kelas", value: null});
    const [presenceData, setPresenceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "Kelas",
                accessor: "class_name",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {rowInfo.class_name.split()}
                    </>
                }
            },
            {
                Header: "Nama Lengkap",
                accessor: "name"
            },
            {
                Header: "Surel",
                accessor: "email"
            },
            {
                Header: "Pertanyaan",
                accessor: "question",
                Cell: (cellInfo) =>{
                    let row = cellInfo.row.original
                    return <div style={{whiteSpace: 'pre'}}>{row.question}</div>
                }
            },
            {
                Header: "Waktu Bertanya",
                accessor: "created_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
        ]
    )

    const getData = async () => {
        const contactFormModel = new ContactForm();
        const classesTemp = [];

        try {
            const result = await contactFormModel.getAll();

            setPresenceData(result)
            setFilteredData(result)

            let filteredClass = _.uniqBy(result, (obj)=>obj.class_name)

            console.log(filteredClass)

            let classesTemp = filteredClass.map(presenceData => {
                return {label: presenceData.class_name, value: presenceData.class_name}
            })

            setClasses([{label: "Semua Kelas", value: null}, ...classesTemp])

        } catch (e) {
            console.log('getAll presence err', e)
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const filteredClassTemp = [];

        if (selectedClass === null) {
            return setFilteredData(presenceData)
        }

        presenceData.map(aClass => {
            console.log('aClass', aClass)

            if (aClass.class_name === selectedClass) filteredClassTemp.push(aClass)
        })

        setFilteredData(filteredClassTemp)
    }, [selectedClass])

    return (
        <EditorPageWrapper
            activeTab={"help"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <>

                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25,
                        marginBottom: 30
                    }}>
                        Pertanyaan
                    </div>

                    <Select
                        onChange={(session) => {
                            setSelectedClass(session.value)
                        }}
                        value={selectedClass}
                        placeholder={"Pilih Kelas"}
                        options={classes}
                        style={{
                            display: "flex", width: "100%"
                        }}
                    />


                    {selectedClass !== 0 &&
                    <MobTable columns={columns}
                              data={filteredData}
                    />
                    }
                </div>
            </>

        </EditorPageWrapper>
    )
}
