import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Col, Row, Spinner} from "react-bootstrap";
import Palette from "../../../util/Palette";
import FileUpload from "../FileUpload";
import Papa from "papaparse";
import QuestionModel from "../../../models/Question";
import MobTable from "../Table/MobTable";
import CustomButton from "../CustomButton";
import React, {useMemo, useState} from "react";
import moment from "moment";
import {FaFileDownload} from "react-icons/all";
import {CSVLink} from "react-csv";
import Class from "../../../models/Class";
import MemberCourse from "../../../models/MemberCourse";

export default function BatchInvitationModal(props) {

    const [uploadedUsers, setUploadedUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const stagedColumnsForNormalClass = useMemo(() => [
        {
            Header: "No.",
            accessor: "idx"
        },
        {
            Header: "Surel",
            accessor: "email"
        },
        {
            Header: "Nama",
            accessor: "nama"
        },
        {
            Header: "Catatan",
            accessor: "catatan"
        },
    ])

    const stagedColumnsForKDM = useMemo(() => [
        {
            Header: "No.",
            accessor: "idx"
        },
        {
            Header: "Surel",
            accessor: "email"
        },
        {
            Header: "Nama",
            accessor: "nama"
        },
        {
            Header: "Catatan",
            accessor: "catatan"
        },
    ])

    const formatJSONArray = (arr) => {
        return arr.map((obj, key) => {
            let formatted = {
                ...obj,
                full_name: obj.nama,
                idx: key + 1
            }

            formatted.note = obj.catatan
            formatted.participant_note = obj.catatan

            return formatted
        })
    }

    const onClose = (refresh) => {

        if (props.onClose) {
            props.onClose(refresh)
        }

        setUploadedUsers([])

    }

    return <Dialog
        open={props.open}
        maxWidth={"xl"}
        onClose={onClose}>
        <DialogTitle title={"Unggah CSV"}>
            <Row>
                <Col style={{
                    fontFamily: "OpenSans-Bold",
                    color: Palette.PRIMARY,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    Unggah CSV
                </Col>
            </Row>
            <hr/>
        </DialogTitle>
        <DialogContent>
            {/*Display upload button only when stagedQuestion is empty*/}
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row"
            }}>

                <CSVLink
                    style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                    separator={";"}
                    data={
                        `email;nama;catatan
contoh@email.com;nama contoh;catatan contoh
contoh@email.com;nama contoh;catatan contoh`
                    }
                    filename={`contoh-undangan.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Contoh Format &nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <FileUpload
                    allowedType={["text/csv", "application/vnd.ms-excel"]}
                    hideSpinner={true}
                    text={"Unggah File CSV"}
                    // isLoading={isLoadingBar}
                    buttonStyle={{
                        fontSize: "2e0m"
                    }}
                    onDrop={async (result) => {

                        let reader = new FileReader();
                        reader.readAsText(result[0], "UTF-8");
                        reader.onload = async (e) => {

                            console.log(Papa.BAD_DELIMITERS)
                            let stringCSV = e.target.result

                            let parseResult = Papa.parse(stringCSV, {
                                delimiter: ";",
                                header: true,
                                skipEmptyLines: true
                            });
                            console.log(parseResult)

                            setUploadedUsers(parseResult.data)

                        }


                    }}
                />

            </div>


            {
                uploadedUsers.length > 0 ?
                    <MobTable
                        columns={props.privateClass ? stagedColumnsForKDM : stagedColumnsForNormalClass} data={formatJSONArray(uploadedUsers)}/>
                    : null
            }


            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 30,
                marginBottom: 15
            }}>
                <CustomButton
                    onClick={() => {
                        onClose()
                    }}
                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                    variant={"outlined"}
                    color="primary">
                    Batal
                </CustomButton>
                <CustomButton
                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                    onClick={async () => {
                        let result;

                        try {
                            setIsLoading(true)
                            if (props.privateClass) {
                                let memberCourseModel = new MemberCourse();

                                result = await memberCourseModel.inviteBatch(props.eventCode, formatJSONArray(uploadedUsers))
                            } else {
                                const classModel = new Class()
                                result = await classModel.inviteBatch(props.eventCode, formatJSONArray(uploadedUsers))
                            }


                            console.log(result)

                            let allCount = uploadedUsers.length
                            let dupeCount = result.length
                            let invitedCount = allCount - dupeCount

                            if (dupeCount > 0) {

                                let allNotInvited = "";

                                for (let i of result) {
                                    console.log(i)
                                    let formattedToBeAppended = i.email + "\n"
                                    console.log(formattedToBeAppended)
                                    allNotInvited += formattedToBeAppended
                                }

                                alert(`Sukses mengundang ${invitedCount} orang baru. ${dupeCount} orang tidak diundang lagi karena surel sudah terdaftar di kelas ini.\n\nSurel yang telah terdaftar : \n${allNotInvited}`)
                            } else {
                                alert(`Sukses mengundang ${invitedCount} orang.`)
                            }


                            onClose(true)
                            setIsLoading(false)
                        } catch (err) {
                            alert("Kesalahan terjadi. Periksa console")
                            setIsLoading(false)

                        }
                    }}
                    disabled={uploadedUsers.length === 0 || isLoading}
                    variant={"contained"}
                    color="secondary">
                    Simpan{isLoading ? <>&nbsp;<Spinner animation="border" size={"sm"} /></> : null }
                </CustomButton>


            </div>
        </DialogContent>
    </Dialog>

}
