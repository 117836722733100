import ApiRequest from "../util/ApiRequest";

export default class Order {
    getAll = async() => {
        return await ApiRequest.set("/v1/order", "GET");
    }

    getSome = async(params) => {
        return await ApiRequest.set("/v1/order/some", "POST", {ids : params});
    }

    getSomeByDate = async(month, year) => {
        return await ApiRequest.set(`/v1/order/someByDate?year=${year?year:""}&month=${month?month:""}`, "GET");
    }
}
