import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chartjs-plugin-datalabels';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";

import {
    FaPlus,
    FaClock,
    FaPen,
    FaSave,
    FaTimes,
    FaTrash,
    FaCaretUp,
    FaRegClone,
    FaExternalLinkAlt
} from "react-icons/fa";

import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import 'moment/locale/id'


import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "react-bootstrap/Card";

import Divider from "@material-ui/core/Divider";
import {CopyToClipboard} from "react-copy-to-clipboard";

import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";

import {withStyles} from "@material-ui/core";
import moment from "moment";

import {FaMoneyBillWave, FaPlaneArrival, FaUserFriends, FiAlertTriangle} from "react-icons/all";
import {Bar, HorizontalBar, Line} from "react-chartjs-2";
import EditorPageWrapper from "./editor/EditorPageWrapper";
import CustomButton from "../reusable/CustomButton";
import PieChart from "../reusable/Charts/PieChart";
import Palette from "../../util/Palette";
import Class from "../../models/Class";
import textFormatter from "../../util/textFormatter";
import Tab from "react-bootstrap/Tab";

let anchor
let popperFadeTimer;

let currentDate = new moment();
currentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

let dates = []
dates.push(currentDate.format("D MMMM"))

let formattedCurrentDate = currentDate.format("D MMMM")

for (let i = 0; i < 6; i++) {
    currentDate.subtract(1, "days")
    dates.push(currentDate.format("D MMMM"))
}


dates.reverse()

export default function EventDetail(props) {

    let history = useHistory()
    const {event_url} = useParams()

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isPopperVisible, setIsPopperVisible] = useState(false)

    const [waitingCount, setIsWaitingCount] = useState(10)
    const [paymentType, setPaymentType] = useState("FREE")
    const [eventStatus, setEventStatus] = useState("FINISHED")

    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeEventURL, setActiveEventURL] = useState(event_url)

    const popPopper = () => {
        setIsPopperVisible(true)
        clearTimeout(popperFadeTimer)
        popperFadeTimer = setTimeout(() => {
            setIsPopperVisible(false)
        }, 1500)
    }

    //Page Params
    const [currentEvent, setCurrentEvent] = useState({
        name: event_url ? event_url : "",
        iconUrl: "",
        shortDescription: "",
        eventRoute: "",
        additionalInformation: {
            registrationFormIntroduction: "",
        },
        metrics: {}

    })
    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker: "",
        quota: -1,
        price: 0,
        requirePayment: false,
        description: "",
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        selectedPaymentPreset: 0,
        additionalInformation: {}
    })

    const [eventMetrics, setEventMetrics] = useState({
        categorizedRegistrantCount: {}
    })

    const [registrationFields, setRegistrationFields] = useState([])

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [upcomingClass, setUpcomingClass] = useState([])
    const [pastClass, setPastClass] = useState([])

    const routerLocation = useLocation()

    const [registrationGraph, setRegistrationGraph] = useState({});
    const [top5CitiesGraph, setTop5CitiesGraph] = useState({});
    const [ageDemographicData, setAgeDemographicData] = useState([]);

    const [privateClassAverage, setPrivateClassAverage] = useState({})
    const [interClassAverage, setInterClassAverage] = useState({})
    const [interClassData, setInterClassData] = useState([])

    useEffect(() => {
        scroll.scrollTo(0, {
            smooth: false,
            duration: 0
        })
        getFeedbackMetrics()
        getPrivateClassAverage()

    }, [routerLocation.pathname])

    const getPrivateClassAverage = async () => {
        try {
            let classModel = new Class()
            let feedbacks = await classModel.getPrivateClassFeedbacks()

            const privateClassAverageTemp = {average: [], name: []}

            feedbacks.forEach(feedback => {
                privateClassAverageTemp.average.push(feedback.avg_rating)
                privateClassAverageTemp.name.push(feedback.title)
            })
            setPrivateClassAverage(privateClassAverageTemp)
        } catch (e) {
            console.log(e)
        }
    }

    const getFeedbackMetrics = async () => {
        try {
            let classModel = new Class()
            let feedbacks = await classModel.getFeedbackMetrics()

            console.log('feedbacks', feedbacks)

            let tempInterClass = {
                average: [],
                name: [],
                pendaftaran_mudah: [],
                pengajar_interaktif: [],
                pengajar_jelas: [],
                mekanisme_jelas: [],
                topik_dipahami: [],
                topik_sesuai: []
            }

            for (let obj of feedbacks) {
                tempInterClass.average.push((obj.pendaftaran_mudah + obj.pengajar_jelas + obj.pengajar_interaktif + obj.mekanisme_jelas + obj.topik_dipahami + obj.topik_sesuai) / 6)
                tempInterClass.name.push(obj.name)

                tempInterClass.pendaftaran_mudah.push(obj.pendaftaran_mudah)
                tempInterClass.pengajar_interaktif.push(obj.pengajar_interaktif)
                tempInterClass.pengajar_jelas.push(obj.pengajar_jelas)
                tempInterClass.mekanisme_jelas.push(obj.mekanisme_jelas)
                tempInterClass.topik_dipahami.push(obj.topik_dipahami)
                tempInterClass.topik_sesuai.push(obj.topik_sesuai)

            }


            // console.log(feedbacks)
            setInterClassAverage(tempInterClass)
            setInterClassData(feedbacks)

        } catch (e) {
            console.log(e)
        }
    }

    const makeInterClassData = (values) => {
        console.log('values', values)

        let colors = Array(values.name?.length).fill(Palette.PRIMARY)

        return {
            labels: values?.name?.map((obj) => textFormatter.truncateText(obj, 40)),
            datasets: [
                {
                    backgroundColor: colors,
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 0,
                    data: values.average
                }
            ]
        }
    }

    const makeDetailedInterClassData = (values) => {

        let colors = Array(values.name?.length).fill(Palette.PRIMARY)
        let colors2 = Array(values.name?.length).fill(Palette.SECONDARY)
        let colors3 = Array(values.name?.length).fill('#abc111')
        let firstIdx = 0;
        let secondIdx = 0;
        let thirdIdx = 0;

        let idx = 0;
        let found = false
        for (let i of interClassData) {
            let iteratedCode = i.class_code;

            if (iteratedCode === event_url) {
                found = true;
                break;
            }
            idx++
        }

        if (interClassData.length !== 0) {
            firstIdx = interClassData.length - 3;
            secondIdx = interClassData.length - 2;
            thirdIdx = interClassData.length - 1;
        }

        if (!values.name || (firstIdx === 0 && secondIdx === 0 && thirdIdx === 0)) return;

        console.log('interClassData', interClassData);
        return {
            labels: ['Mekanisme Jelas', 'Pendaftaran Mudah', 'Pengajar Interaktif', 'Pengajar Jelas', 'Topik Dipahami', 'Topik Sesuai'],
            datasets: [
                {
                    label: textFormatter.truncateText(values.name[firstIdx], 20),
                    backgroundColor: colors,
                    borderColor: 'rgb(173,0,51)',
                    borderWidth: 0,
                    data: [interClassData[firstIdx]?.mekanisme_jelas, interClassData[firstIdx]?.pendaftaran_mudah, interClassData[firstIdx]?.pengajar_interaktif, interClassData[firstIdx]?.pengajar_jelas, interClassData[firstIdx]?.topik_dipahami, interClassData[firstIdx]?.topik_sesuai]
                },
                {
                    label: textFormatter.truncateText(values.name[secondIdx], 20),
                    backgroundColor: colors2,
                    borderColor: 'rgb(241,31,74)',
                    borderWidth: 0,
                    data: [interClassData[secondIdx]?.mekanisme_jelas, interClassData[secondIdx]?.pendaftaran_mudah, interClassData[secondIdx]?.pengajar_interaktif, interClassData[secondIdx]?.pengajar_jelas, interClassData[secondIdx]?.topik_dipahami, interClassData[secondIdx]?.topik_sesuai]
                },
                {
                    label: textFormatter.truncateText(values.name[thirdIdx], 20),
                    backgroundColor: colors3,
                    borderColor: 'rgb(241,31,74)',
                    borderWidth: 0,
                    data: [interClassData[thirdIdx]?.mekanisme_jelas, interClassData[thirdIdx]?.pendaftaran_mudah, interClassData[thirdIdx]?.pengajar_interaktif, interClassData[thirdIdx]?.pengajar_jelas, interClassData[thirdIdx]?.topik_dipahami, interClassData[thirdIdx]?.topik_sesuai]
                },
            ]
        }
    }

    const renderContent = () => {
        return <>

            <Container fluid style={{
                minHeight: "100vh"
            }}>
                <Row>
                    <Col md={12} style={{
                        marginTop: 60
                    }}
                    >
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Perbandingan Nilai Seluruh Kelas</div>
                            </div>
                            <Tabs defaultActiveKey="KP" id="uncontrolled-tab-example">
                                <Tab
                                    eventKey="KP"
                                    title={"Kelas Publik"}
                                >
                                    <div style={{marginTop: 50}}>
                                        <Bar data={makeInterClassData(interClassAverage)}
                                             height={150}
                                             options={
                                                 {
                                                     maintainAspectRatio: true,
                                                     tooltips: {
                                                         enabled: false,
                                                     },
                                                     legend: {
                                                         display: false,
                                                         position: 'right'
                                                     },
                                                 }
                                             }/>
                                    </div>
                                </Tab>

                                <Tab
                                    eventKey="KDM"
                                    title={"Kelas Daring Mandiri"}>
                                    <div style={{marginTop: 50}}>
                                        <Bar data={makeInterClassData(privateClassAverage)}
                                             height={150}
                                             options={
                                                 {
                                                     maintainAspectRatio: true,
                                                     tooltips: {
                                                         enabled: false,
                                                     },
                                                     legend: {
                                                         display: false,
                                                         position: 'right'
                                                     },
                                                     scales: {
                                                         yAxes: [
                                                             {
                                                                 ticks: {
                                                                     min: 0,
                                                                     max: 5
                                                                 }
                                                             }
                                                         ]
                                                     }
                                                 }
                                             }/>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{
                        marginTop: 20
                    }}
                    >
                        <div style={{
                            boxShadow: '0px 0px 4px -2px rgba(0,0,0,0.75)',
                            padding: 30,
                            borderRadius: 5
                        }}>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.1em',
                                marginBottom: 25,
                                display: 'flex',
                                width: '100%'
                            }}>
                                <div style={{flex: 1}}>Perbandingan Lengkap Tiga Kelas Terbaru</div>
                            </div>
                            <HorizontalBar data={makeDetailedInterClassData(interClassAverage)}
                                           height={150}
                                           options={
                                               {
                                                   maintainAspectRatio: true,
                                                   tooltips: {
                                                       enabled: true,
                                                   },
                                                   legend: {
                                                       display: true,
                                                       position: 'right'
                                                   },
                                               }
                                           }/>
                        </div>

                    </Col>

                </Row>
            </Container>

        </>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"main-feedback"}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
