import {Button, Modal, Row} from "react-bootstrap";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Palette from "../../../../../util/Palette";
import MiniTooltip from "../../../../reusable/MiniTooltip";
import moment from "moment";
import Participation from "../../../../../models/Participation";
import React from "react";
import {MdEvent, MdSchedule} from "react-icons/md";

export default function BroadcastReminderDialog (props) {
    const {isOpen, onClose, onSave, classStartDate, setClassStartDate, classStartTime, setClassStartTime, classEndTime, setClassEndTime} = props;

    return (
        <Modal show={isOpen} onHide={onClose} size={"lg"}>
            <Modal.Header closeButton>
                Broadcast Pengingat kelas
            </Modal.Header>
            <Modal.Body>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                    }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 10,
                        }}>
                    <span style={{
                        fontSize: ".9rem",
                        fontFamily: 'OpenSans-SemiBold'
                    }}>Tanggal Mulai Kelas *</span>
                            <DatePicker
                                placeholder={'Tanggal Mulai Kelas *'}
                                allowKeyboardControl={false}
                                disablePast={true}
                                autoOk={true}
                                // disabled={publishStatus === PUBLISH_STATUS.DONE}
                                // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                                style={{
                                    marginLeft: "0.5em",
                                    width: "15em",
                                    cursor: "pointer",
                                    fontSize: '1.2em'
                                }}
                                format="D MMM YYYY"
                                InputProps={{
                                    style: {
                                        fontSize: "1em",
                                        fontFamily: "OpenSans-Regular",
                                        cursor: "pointer"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <MdEvent style={{color: Palette.PRIMARY}}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={classStartDate}
                                onChange={date => setClassStartDate(date)}
                            />

                        </div>
                    </Row>

                    <Row style={{
                        marginTop: "1em",
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                    }}
                    >
                        <div style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "row",
                            padding: 10
                        }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                        <span style={{
                            fontSize: ".9rem",
                            fontFamily: 'OpenSans-SemiBold'
                        }}>Waktu Mulai Kelas *</span>
                        <TimePicker
                            okLabel={"Simpan"}
                            cancelLabel={"Batal"}
                            ampm={false}
                            allowKeyboardControl={false}
                            autoOk={true}
                            style={{
                                marginLeft: "0.5em",
                                width: "15em",
                                cursor: "pointer",
                                fontSize: '1.2em'
                            }}
                            format="HH:mm"
                            InputProps={{
                                style: {
                                    fontSize: "1em",
                                    fontFamily: "OpenSans-Regular",
                                    cursor: "pointer"
                                },
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <MdSchedule style={{color: Palette.PRIMARY}}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={classStartTime}
                            onChange={(value) => {
                                setClassStartTime(value)
                            }}
                            placeholder={`Waktu Mulai Kelas *`}/>
                    </span>
                        </div>


                        <div style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "row",
                            padding: 10
                        }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                    <span style={{
                        fontSize: ".9rem",
                        fontFamily: 'OpenSans-SemiBold'
                    }}>     Waktu Selesai Kelas * &nbsp;
                        {<MiniTooltip content={"Samakan dengan waktu mulai jika waktu selesai tentatif"}/>}</span>
                    <TimePicker
                        placeholder={`Waktu Selesai Kelas *`}
                        okLabel={"Simpan"}
                        cancelLabel={"Batal"}
                        ampm={false}
                        allowKeyboardControl={false}
                        autoOk={true}
                        style={{
                            marginLeft: "0.5em",
                            width: "15em",
                            cursor: "pointer",
                            fontSize: "1.2em",
                            fontFamily: "OpenSans-Bold",
                            // cursor: "pointer"
                        }}
                        format="HH:mm"
                        InputProps={{
                            style: {
                                fontSize: "1em",
                                fontFamily: "OpenSans-Regular",
                                cursor: "pointer"
                            },
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <MdSchedule style={{color: Palette.PRIMARY}}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={classEndTime}
                        onChange={(value) => {
                            setClassEndTime(value)
                        }}/>

                    </span>


                        </div>
                    </Row>
                </MuiPickersUtilsProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Tutup</Button>
                <Button onClick={onSave}>Broadcast</Button>
            </Modal.Footer>
        </Modal>
    )
}
