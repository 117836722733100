import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown, Card} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import {withCookies, Cookies} from 'react-cookie';
import Container from "react-bootstrap/Container";
import EditProfileModal from "./EditProfileModal";
import NewViewEventDialog from "./modals/NewViewEventDialog";
import LoginModal from "./LoginModal";
import {
    FaBars,
} from "react-icons/all";

export default function Header(props) {

    let [scrollPosition, setScrollPosition] = useState(0)
    const [editProfileShow, setEditProfileShow] = useState(false)
    let history = useHistory()
    let user = GlobalData.user
    let [loginModalShow, setLoginModalShow] = useState(false)
    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    let [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)

    useEffect(() => {
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    useEffect(() => window.addEventListener('scroll', listenToScroll))

    function onLoginPress() {
        setLoginModalShow(true)
    }

    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    let header;

    if (isMobile) {
        header = <Slide top when={
            !props.shy || scrollPosition > 0.1
        }>
            <div style={{
                width: "100%",
                height: "4.5em",
                position: "fixed",
                top: 0,
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                background: "white",
                boxShadow: "0px 4px 8px 1px #888888"
            }}>
                {/*<Helmet>*/}
                {/*    <title>patjarmerah</title>*/}
                {/*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*/}
                {/*</Helmet>*/}

                <Row style={{
                    alignItems: "center"
                }}>
                    <div>
                        <img
                            style={{
                                width: "100%",
                                objectFit: "contain",
                                maxHeight: "3.5em"
                            }}
                            src={require("../../asset/narabahasa-logo.png")}
                        />
                    </div>

                    <div style={{
                        margin: "absolute",
                        position: "absolute",
                        right: "8%",
                        width: "7em",
                        display: GlobalData.user ? "none" : "flex"
                    }}>
                        <Button
                            variant={"danger"}
                            style={{
                                background: Palette.PRIMARY
                            }}
                            onClick={() => {
                                onLoginPress()
                                // console.log("caserole")
                            }}
                            size={"sm"} block>
                            Login
                        </Button>
                    </div>

                    <div style={{
                        margin: "absolute",
                        position: "absolute",
                        right: "8%",
                        width: "12%",
                        display: GlobalData.user ? "flex" : "none",
                        flexDirection: "row-reverse"
                    }}>

                        <Dropdown
                            onSelect={(eventKey, event) => {
                                if (eventKey === "3") {
                                    console.log("this is chosen")
                                    GlobalData.token = null
                                    GlobalData.user = null

                                    removeCookie("token", {path: '/'})
                                    // console.log("cookie " + cookie.token)
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 500);
                                }

                                if (eventKey === "1") {
                                    history.push("/editor")
                                }
                            }}
                            alignRight>
                            <Dropdown.Toggle
                                variant={"light"}
                                id="dropdown-custom-1">

                                <FaBars/>

                            </Dropdown.Toggle>
                            <Dropdown.Menu className="super-colors">
                                <Dropdown.Item eventKey="1">Editor</Dropdown.Item>
                                <Dropdown.Item eventKey="3">Keluar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>{' '}

                    </div>


                </Row>

            </div>
        </Slide>
    } else {

        if (scrollPosition < 0.05 && props.shy) {
            header = null;
        } else {
            header = <Slide top when={
                !props.shy || scrollPosition > 0.1
            }>
                <div style={{
                    display: GlobalData.token ? "relative" : "fixed",
                    flexDirection: "column"
                }}>
                    <div style={{
                        width: "100%",
                        position: "fixed",
                        top: 0,
                        zIndex: 1000,
                        display: "flex",
                        background: Palette.PRIMARY,
                        // boxShadow: "1px 1px 1px 1px #eeeeee",
                        padding: '10px'
                    }}>
                        <Container fluid style={{width: "80%"}}>
                            {/*<Helmet>*/}
                            {/*    <title>patjarmerah</title>*/}
                            {/*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*/}
                            {/*    <meta name="keywords"*/}
                            {/*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*/}
                            {/*</Helmet>*/}

                            <Row style={{
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>

                                <Col md={8} xs={10} style={{
                                    display: 'flex', alignItems: 'center', justifyContent: "flex-start"
                                }}>
                                    <a href={"/"}>
                                        <img
                                            style={{
                                                maxHeight: "5em",
                                                objectFit: "contain",
                                                padding : "4%",
                                            }}
                                            src={require("../../asset/narabahasa-logo.png")}
                                        />
                                    </a>
                                    {/*}
                                    <a href={"#"} style={{
                                        color: Palette.PRIMARY,
                                        fontFamily: "Montserrat",
                                        fontStyle: "Black",
                                        fontSize: "1em",
                                        marginLeft: "1.5em",
                                        // fontWeight: props.active === 'my-session' ? 'bold' : 'normal',
                                    }} onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/")
                                    }}>
                                        Produk Kami
                                    </a>

                                    <a href={""} style={{
                                        color: Palette.PRIMARY,
                                        fontFamily: "Montserrat",
                                        fontSize: "1em",
                                        marginLeft: "1.5em",
                                        fontWeight: props.active === 'my-session' ? 'bold' : 'normal',
                                    }} onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/list")
                                    }}>
                                        Daftar Acara
                                    </a>

                                    < a href={""} style={{
                                        color: Palette.PRIMARY,
                                        fontFamily: "Montserrat",
                                        fontSize: "1em",
                                        marginLeft: "1.5em",
                                    }} onClick={(e) => {
                                        e.preventDefault()
                                        history.push("/pricing")
                                    }}>
                                        Pricing
                                    </a>

                                    {*/}

                                </Col>

                                <Col md={4} style={{
                                    marginBottom: "0.5em",
                                    display: 'flex', alignItems: 'flex-end', justifyContent: "flex-end"
                                }}>

                                    <div style={{
                                        position: "absolute",
                                        right: "1em",
                                        bottom: "3em"
                                    }}>
                                        {GlobalData.user ? <>Hi, <b>{GlobalData.user.name}</b></> : ""}
                                    </div>

                                    <Button
                                        style={{
                                            fontFamily: "Montserrat",
                                            display: props.active === "create-event" ? "none" : "inline",
                                        }}
                                        onClick={() => {
                                            setIsCreateEventModalOpen(true)
                                        }}
                                        variant={"outline-secondary"}
                                        size={"md"}>
                                        Buat Acara
                                    </Button>

                                    {
                                        GlobalData.user ? <Button
                                                style={{
                                                    background: Palette.PRIMARY,
                                                    fontFamily: "Montserrat",
                                                    marginLeft: "1em"
                                                }}
                                                onClick={() => {
                                                    history.push("/editor")
                                                }}
                                                variant={"outline-secondary"}
                                                size={"md"}>
                                                Editor
                                            </Button> :
                                            <Button
                                                style={{
                                                    fontFamily: "Montserrat",
                                                    marginLeft: "1em"
                                                }}
                                                onClick={() => {
                                                    onLoginPress()
                                                    // console.log("caserole")
                                                }}
                                                variant={"outline-secondary"}
                                                size={"md"}>
                                                Masuk
                                            </Button>
                                    }

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Slide>
        }

    }

    return <>
        {header}
        <LoginModal
            onHide={() => setLoginModalShow(false)}
            show={loginModalShow}/>
        <EditProfileModal
            onHide={() => {
                setEditProfileShow(false)
            }}
            show={editProfileShow}
        />
        <NewViewEventDialog
            closeDialog={() => {
                setIsCreateEventModalOpen(false)
            }}
            show={isCreateEventModalOpen}/>
    </>

}
