import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

export default function Profile(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)

    const [user, setUser] = useState({})

    const [editMode, setEditMode] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [passwordData, setPasswordData] = useState({...defaultPasswordData})

    useEffect(() => {
        getProfile()
    }, [])

    const getProfile = async () => {
        try {
            let userModel = new User()
            let tempUser = await userModel.getProfile()
            console.log("tu", tempUser)
            setUser(tempUser)
        } catch (e) {
            console.log(e)
        }

    }

    const editUser = (key, value) => {
        let tempUser = {
            ...user
        }

        tempUser[key] = value
        setUser(tempUser)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const updateUser = async () => {
        setIsLoadingBar(true)
        const userModel = new User()

        let formattedUser = {
            name: user.name,
            email: user.email,
            address: user.address
        }

        setIsLoadingBar(true)

        try {
            let result = await userModel.updateUser(formattedUser)
            promptSuccess("Profil berhasil diubah")
            setIsLoadingBar(false)
            setEditMode(false)
        } catch (e) {

            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }

            setIsLoadingBar(false)

            promptError(tempMessage)

            console.log(e)
        }
    }

    const resetPasswordData = () => {
        setPasswordData({
            ...defaultPasswordData
        })
    }

    const editEditPasswordData = (key, value) => {

        let tempData = {
            ...passwordData
        }

        tempData[key] = value
        setPasswordData(tempData)
    }


    const renderButtons = () => {
        return <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "2em"
        }}>
            <CustomButton
                onClick={() => {
                    if (editMode) {
                        updateUser()
                    } else {
                        setEditMode(true)
                    }
                }}
                variant={editMode ? "contained" : "outlined"} color="primary">
                {editMode ? <>Simpan&nbsp;&nbsp;<FaSave/></> : <>Edit&nbsp;&nbsp;<FaPen/></>}
            </CustomButton>

            <CustomButton
                style={{
                    marginLeft: "1em"
                }}
                onClick={() => {
                    setIsEditingPassword(true)
                }}
                variant={"outlined"} color="primary">
                Ubah Password&nbsp;&nbsp;<FaKey/>
            </CustomButton>
        </div>
    }

    const renderPasswordEditor = () => {
        return <>
            <div
                style={{
                    fontSize: "2em",
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    color: Palette.PRIMARY
                }}
            >
                Ganti Password
            </div>
            <Row style={{
                alignItems: "center",
                marginTop: "1em"
            }}>
                <Col md={12} style={{
                    fontFamily: "Montserrat",
                    paddingLeft: "2em"
                }}
                >
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{marginTop: "1em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <b>Password Sebelumnya : </b>
                        </div>
                        <LagFreeTextEditor
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontFamily: "Montserrat"
                                }
                            }}
                            type={"password"}
                            style={{
                                marginLeft: "1em",
                                marginTop: "1em",
                                width: "30em"
                            }}
                            changeValue={(value) => editEditPasswordData("oldPassword", value)}
                            value={passwordData.oldPassword}
                        />
                        <div style={{marginTop: "1em"}}>
                            <b>Password Baru : </b>
                        </div>
                        <LagFreeTextEditor
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontFamily: "Montserrat"
                                }
                            }}
                            type={"password"}
                            style={{
                                marginLeft: "1em",
                                width: "30em",
                                marginTop: "1em"
                            }}
                            changeValue={(value) => editEditPasswordData("newPassword", value)}
                            value={passwordData.newPassword}
                        />
                        <div style={{marginTop: "1em"}}>
                            <b>Konfirmasi Password Baru : </b>
                        </div>
                        <LagFreeTextEditor
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontFamily: "Montserrat"
                                }
                            }}
                            type={"password"}
                            style={{
                                marginLeft: "1em",
                                width: "30em",
                                marginTop: "1em"
                            }}
                            changeValue={(value) => editEditPasswordData("newPasswordConfirmation", value)}
                            value={passwordData.newPasswordConfirmation}
                        />
                    </div>
                </Col>
            </Row>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: "3em",
                paddingLeft: "2em"
            }}>
                <CustomButton
                    onClick={() => {
                        setIsEditingPassword(false)
                        resetPasswordData()
                    }}
                    variant={"outlined"} color="primary">
                    Batal
                </CustomButton>

                <CustomButton
                    style={{
                        marginLeft: "1em"
                    }}
                    onClick={async () => {
                        setIsLoadingBar(true)
                        let userModel = new User()
                        try {
                            let result = await userModel.updatePassword({
                                oldPassword: passwordData.oldPassword,
                                newPassword: passwordData.newPassword
                            })
                            setIsLoadingBar(false)
                            promptSuccess("Password berhasil diubah")
                            setIsEditingPassword(false)
                            resetPasswordData()
                        } catch (e) {

                            let tempMessage = "Kesalahan Terjadi"
                            if (e.msg) {
                                tempMessage = e.msg
                            }

                            setIsLoadingBar(false)

                            promptError(tempMessage)

                            console.log(e)

                        }
                    }}
                    variant={"outlined"} color="primary">
                    Simpan&nbsp;&nbsp;<FaSave/>
                </CustomButton>
            </div>
        </>
    }

    const renderEditMode = () => {
        return <Row style={{
            alignItems: "center",
            marginTop: "1em"
        }}>
            <Col lg={8} style={{
                fontFamily: "Montserrat"
            }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{marginTop: "1em", display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <b>Nama : </b>
                        <LagFreeTextEditor
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontFamily: "Montserrat"
                                }
                            }}
                            style={{
                                marginLeft: "1em",
                                width: "30em"
                            }}
                            changeValue={(value) => editUser("name", value)}
                            value={user?.name}
                        />
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Email Akun : </b>{user?.email}
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Intellivent ID : </b>{user?.email}
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Alamat : </b>
                        <LagFreeTextEditor
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontFamily: "Montserrat"
                                }
                            }}
                            style={{
                                marginLeft: "1em",
                                width: "30em"
                            }}
                            multiline
                            changeValue={(value) => editUser("address", value)}
                            value={user?.address}
                        />
                    </div>

                </div>
            </Col>
        </Row>
    }

    const renderShowMode = () => {
        return <Row style={{
            alignItems: "center",
            marginTop: "1em"
        }}>
            {/*}
                <CustomDropzone
                    width={"4.5em"}
                    height={"4.5em"}
                    aspect={1}
                    imageSrc={require("../../../asset/mob-cropped.PNG")}
                    loading={iconLoading}
                    prompt={"Tambah Logo"}
                />
                {*/}

            <Col md={12} style={{
                fontFamily: "Montserrat"
            }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{marginTop: "1em"}}>
                        <b>Nama : </b>{user?.name}
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Email Akun : </b>{user?.email}
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Intellivent ID : </b>{user?.email}
                    </div>
                    <div style={{marginTop: "1em"}}>
                        <b>Alamat : </b>{user?.address}
                    </div>

                </div>
            </Col>
        </Row>
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderGeneralMode = () => {
        return <>
            <div
                style={{
                    fontSize: "2em",
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    color: Palette.PRIMARY
                }}
            >
                {user?.name}
            </div>
            {
                editMode ?
                    renderEditMode()
                    :
                    renderShowMode()
            }
            {renderButtons()}


        </>
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"profile"}
            >
                {renderAlertBox()}
                {renderSuccessBox()}
                {
                    isEditingPassword ?
                        renderPasswordEditor()
                        :
                        renderGeneralMode()
                }
            </EditorPageWrapper>
        </>
    );

}
