import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CustomButton from "../../reusable/CustomButton";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Form from "react-bootstrap/Form";
import FileUpload from "../../reusable/FileUpload";
import {FaSave} from "react-icons/fa";
import InstructorModel from "../../../models/Instructor";
import ClassInstructors from "../../../models/ClassInstructors";
import CourseInstructors from "../../../models/CourseInstructors";
import {Parser} from "html-to-react";

export default function Instructor(props) {
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isManageInstructorModalVisible, setManageInstructorDialogVisible] = useState(false);
    const [selectedInstructor, setSelectedInstructor] = useState({});
    const [isDeleteInstructorDialogVisible, setDeleteInstructorDialogVisible] = useState(false)
    const [instructors, setInstructors] = useState([]);
    const [allInstructors, setAllInstructors] = useState([]);
    const [unaddedInstructors, setUnaddedInstructors] = useState([]);
    let htmlParser = new Parser()

    const columns = useMemo(
        () => [
            {
                Header: "Foto",
                accessor: "photo",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original

                    return <img
                        src={row.instructor_image_url ? row.instructor_image_url : require('../../../asset/public_image_default_user.png')}
                        style={{width: 50, height: 50, borderRadius: 25, objectFit: 'cover'}}/>
                }
            },
            {
                Header: "Nama",
                accessor: "name"
            },
            {
                Header: "Posisi",
                accessor: "position"
            },
            {
                Header: "Deskripsi",
                accessor: "description",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original

                    return <>{htmlParser.parse(row.description)}</>
                }
            }
        ]
    )

    const interactions = [
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedInstructor(obj)
                setDeleteInstructorDialogVisible(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    const addInteractions = [
        {
            name: "Tambah",
            action: async (obj) => {
                try {
                    if (props.privateClass) {
                        const courseInstructorModel = new CourseInstructors();

                        const result = await courseInstructorModel.create({
                            course_id: parseInt(props.match.params.event_url),
                            instructor_id: obj.id
                        })

                    } else {
                        console.log()

                        const classInstructorModel = new ClassInstructors();

                        const result = await classInstructorModel.create({
                            class_code: props.match.params.event_url,
                            instructor_id: obj.id
                        })

                        console.log(result)
                    }

                    getInstructors()
                } catch (e) {
                    console.log(e)
                }
            },
            variant: 'primary',
            style: {width: 135},
        }
    ]

    useEffect(() => {
        getInstructors()

        getAllInstructors()
    }, [])

    const getInstructors = () => {
        if (props.privateClass) {
            getCourseInstructors()
        } else {
            getClassInstructors()
        }
    }

    useEffect(() => {
        const unaddedInstructorsTemp = []

        allInstructors.forEach((instructor) => {

            let found = false;

            instructors.forEach(instructorToCheck => {
                console.log('uWu', instructorToCheck)

                if(instructor.id === instructorToCheck.instructor_id) {
                    found = true;
                }
            })

            if(!found) {
                unaddedInstructorsTemp.push(instructor);
            }
        })

        setUnaddedInstructors(unaddedInstructorsTemp)
    }, [instructors, allInstructors])

    const getAllInstructors = async () => {
        const instructorModel = new InstructorModel();

        try {
            const result = await instructorModel.getAll(parseInt(props.match.params.event_url));

            console.log('thirst', result)

            setAllInstructors(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getClassInstructors = async () => {
        try {
            const classInstructorModel = new ClassInstructors()

            const result = await classInstructorModel.getByClassCode(props.match.params.event_url)

            setInstructors(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getCourseInstructors = async () => {
        try {
            const courseInstructorModel = new CourseInstructors()

            const result = await courseInstructorModel.getByCourseId(parseInt(props.match.params.event_url))

            setInstructors(result)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <EditorPageWrapper
                privateClass={props.privateClass}
                activeTab={"instructor"}
            >
                <Dialog open={isDeleteInstructorDialogVisible}
                        onClose={() => setDeleteInstructorDialogVisible(false)}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Widyaiswara
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus widyaiswara <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedInstructor.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleteInstructorDialogVisible(false)
                                        setSelectedInstructor({})
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        console.log(selectedInstructor.id)

                                        try {
                                            if (props.privateClass) {
                                                const courseInstructorModel = new CourseInstructors();

                                                await courseInstructorModel.delete(selectedInstructor.id)

                                                getInstructors()
                                            } else {
                                                const classInstructorsModel = new ClassInstructors();

                                                await classInstructorsModel.delete(selectedInstructor.id)

                                                getInstructors()
                                            }

                                            getInstructors()

                                            setDeleteInstructorDialogVisible(false)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Dialog open={isManageInstructorModalVisible}
                        maxWidth="md"
                        fullWidth={true}
                        onClose={() => {
                            setSelectedInstructor({})
                            setManageInstructorDialogVisible(false)
                        }}
                >
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                Tambah Widyaiswara
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent>
                        <MobTable
                            columns={columns}
                            data={unaddedInstructors}
                            interactions={addInteractions}
                        />

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '3em',
                            paddingBottom: '1em',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                        >
                            <CustomButton
                                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => {
                                    setManageInstructorDialogVisible(false)
                                }}
                                variant={"outlined"} color="primary">
                                Tutup
                            </CustomButton>
                        </Row>
                    </DialogContent>
                </Dialog>

                <>
                    <div style={{
                        marginTop: "0.5em",
                        // marginBottom: "0.5em",
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontWeight: "800",
                        fontSize: "1.5em"
                    }}>Widyaiswara
                    </div>

                    <Row style={{marginLeft: 2, marginTop: 15}}>
                        <Button variant={'outline-primary'}
                                onClick={() => setManageInstructorDialogVisible(true)}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                    width: 180,
                                    marginLeft: 5
                                }}
                        >
                            + <span style={{marginLeft: 6}}>Tambah Widyaiswara</span>
                        </Button>
                    </Row>

                    <div>
                        <MobTable
                            columns={columns}
                            data={instructors}
                            interactions={interactions}
                        />
                    </div>
                </>
            </EditorPageWrapper>
        </>
    )
}
