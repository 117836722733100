export default class Palette {
    static PRIMARY = "#0F4C82"
    static SECONDARY = "#a52620"

    static SKY_BLUE = "#0F4C82"
    static RED = "#a52620"

    //DAPTAR
    static PURPLE = "#6a2c91"
    static ORANGE = "#f58b1e"
    static DARK_BLUE = "#0d004c"
    static NAVY_BLUE = "#1a355b"
    static YORANGE = "#fdb137"

    //


    // static SECONDARY = "#ffba0c"
    // static YELLOW = "#fbd701"
}
