import ApiRequest from "../util/ApiRequest";

export default class Member {

    register = async(body) => {
        return await ApiRequest.set("/v1/member/register", "POST", body);
    }


    update = async(param) => {
        return await ApiRequest.set("/v1/members", "POST", param);
    }

    activate = async(id) => {
        return await ApiRequest.set(`/v1/member/${id}/activate`, "PUT");
    }

    activationNawala = async(id) => {
        return await ApiRequest.set(`/v1/member/${id}/activate-newsletter`, "PUT");
    }

    inactiveNawala = async(id) => {
        return await ApiRequest.set(`/v1/member/${id}/inactive-newsletter`, "PUT");
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/members", "GET");
    }

    registerBatch = async (body) => {
        return await ApiRequest.set("/v1/member/batch", "POST", body);
    }

    memberSearch = async (searchquery) =>{
        return await ApiRequest.set("/v1/members/search", "POST", {search:searchquery})
    }

    delete = async(id) => {
        return await ApiRequest.set("/v1/members", "DELETE", {id : id});
    }

    unDelete = async(id) => {
        return await ApiRequest.set("/v1/members", "PATCH", {id : id});
    }


    getMemberParticipation = async (id) =>{
        return await ApiRequest.set(`/v1/member/${id}/participation`, "GET");
    }

    getParticipationByMonthAndYear = async (month, year) => {
        return await ApiRequest.set(`/v1/participant/some?year=${year?year:""}&month=${month?month:""}`, "GET");
    }

    getParticipationClassCourseByMonthAndYear = async (month,year) => {
        return await ApiRequest.set(`/v1/participantClassCourse/some?year=${year?year:""}&month=${month?month:""}`, "GET");
    }

    getAllParticipationClassCourse = async () => {
        return await ApiRequest.set(`/v1/participants/class_course`, "GET");
    }

    

}
