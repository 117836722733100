import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Dropdown, ListGroup, Nav, Navbar, NavDropdown, Row, Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";

import {
    FaPlus,
    FaClock,
    FaPen,
    FaSave,
    FaTimes,
    FaTrash,
    FaCaretUp,
    FaRegClone,
    FaExternalLinkAlt
} from "react-icons/fa";
import EditorPageWrapper from "../editor/EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import 'moment/locale/id'

import Event, {EVENT_STATUS, PAYMENT_TYPE, PUBLISH_STATUS} from "../../../models/Event"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "react-bootstrap/Card";
import Palette from "../../../util/Palette";
import LineChart from "../../reusable/Charts/LineChart";
import Divider from "@material-ui/core/Divider";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CustomButton from "../../reusable/CustomButton";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import ImageThumbnail from "../../reusable/ImageThumbnail";
import {withStyles} from "@material-ui/core";
import moment from "moment";
import GlobalData from "../../../util/GlobalData";

let anchor
let popperFadeTimer;

let currentDate = new moment();
currentDate.set({hour:0,minute:0,second:0,millisecond:0})

let dates = []
dates.push(currentDate.format("D MMMM"))

let formattedCurrentDate = currentDate.format("D MMMM")

for(let i = 0; i < 6; i++){
    currentDate.subtract(1,"days")
    dates.push(currentDate.format("D MMMM"))
}


dates.reverse()

export default function EventDetail(props) {

    let history = useHistory()
    const {event_url} = useParams()

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isPopperVisible, setIsPopperVisible] = useState(false)

    const [waitingCount, setIsWaitingCount] = useState(10)
    const [paymentType, setPaymentType] = useState("FREE")
    const [eventStatus, setEventStatus] = useState("FINISHED")

    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeEventURL, setActiveEventURL] = useState(event_url)

    const popPopper = () => {
        setIsPopperVisible(true)
        clearTimeout(popperFadeTimer)
        popperFadeTimer = setTimeout(() => {
            setIsPopperVisible(false)
        }, 1500)
    }

    //Page Params
    const [currentEvent, setCurrentEvent] = useState({
        name: event_url ? event_url : "",
        iconUrl: "",
        shortDescription: "",
        eventRoute: "",
        additionalInformation: {
            registrationFormIntroduction: "",
        },
        metrics: {}

    })
    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker: "",
        quota: -1,
        price: 0,
        requirePayment: false,
        description: "",
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        selectedPaymentPreset: 0,
        additionalInformation: {}
    })

    const [eventMetrics, setEventMetrics] = useState({
        categorizedRegistrantCount : {}
    })

    const [registrationFields, setRegistrationFields] = useState([])

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const routerLocation = useLocation()

    useEffect(() => {
        scroll.scrollTo(0, {
            smooth: false,
            duration: 0
        })
        getEventData()
    }, [routerLocation.pathname])

    console.log("active Event url " + activeEventURL)

    const getEventMetrics = async function (eventId, sessionId){

        try {
            const event = new Event();
            const eventMetrics = await event.getEventMetrics(eventId, sessionId)
            console.log("metrics", eventMetrics)

            let tempMetrics = {
                categorizedRegistrantCount : {}
            }

            if(eventMetrics.view_count){

                let viewCount = eventMetrics.view_count
                let formattedViewCount = {
                    labels : [],
                    data : [],
                    lastDay : 0
                }

                for(let d of dates){

                    let isDataThere = false

                    formattedViewCount.labels.push(d)

                    let diff = moment(formattedCurrentDate).diff(d, 'days') // -8 (days)

                    for(let vc of viewCount){
                        let vcMoment = moment(vc.date)

                        if(vcMoment.format("D MMMM") === d){

                            console.log(moment(d))


                            console.log("diff is " + diff + " / " + formattedCurrentDate + " / " + vcMoment.format("D MMMM"))

                            isDataThere = true
                            formattedViewCount.data.push(vc.count)
                            if(Math.abs(diff) < 1){
                                formattedViewCount.lastDay = vc.count
                            }
                        }
                    }

                    if(!isDataThere){
                        formattedViewCount.data.push(0)
                    }

                }

                tempMetrics.viewCount = formattedViewCount
                console.log(tempMetrics)

            }

            if(eventMetrics.other_registration_metrics){
                let registrationCount = eventMetrics.other_registration_metrics
                let formattedRegistration = {
                    labels : [],
                    data : [],
                    lastDay : 0
                }

                for(let d of dates){

                    let isDataThere = false

                    formattedRegistration.labels.push(d)

                    let diff = moment(formattedCurrentDate).diff(d, 'days') // -8 (days)

                    for(let vc of registrationCount){
                        let vcMoment = moment(vc.date)

                        if(vcMoment.format("D MMMM") === d){

                            console.log("diff is " + diff + " / " + formattedCurrentDate)

                            isDataThere = true
                            formattedRegistration.data.push(vc.count)
                            if(Math.abs(diff) < 1){
                                formattedRegistration.lastDay = vc.count
                            }
                        }
                    }

                    if(!isDataThere){
                        formattedRegistration.data.push(0)
                    }

                }

                tempMetrics.registrationCount = formattedRegistration
                console.log(tempMetrics)
            }

            if(eventMetrics.accepted_registration_metrics){
                let registrationCount = eventMetrics.accepted_registration_metrics
                let formattedAccepted = {
                    labels: [],
                    data: [],
                    lastDay: 0,
                    total: 0
                }

                for(let d of dates){

                    let isDataThere = false

                    formattedAccepted.labels.push(d)

                    let diff = moment(formattedCurrentDate).diff(d, 'days') // -8 (days)

                    for(let vc of registrationCount){
                        let vcMoment = moment(vc.date)

                        if(vcMoment.format("D MMMM") === d){

                            console.log("diff is " + diff + " / " + formattedCurrentDate)
                            isDataThere = true
                            formattedAccepted.data.push(vc.count)
                            if(Math.abs(diff) < 1){
                                formattedAccepted.lastDay = vc.count
                            }
                            formattedAccepted.total += vc.count
                        }
                    }

                    if(!isDataThere){
                        formattedAccepted.data.push(0)
                    }

                }

                tempMetrics.acceptedCount = formattedAccepted


            }


            if(eventMetrics.categorized_registration_metrics){
                let categorizedRegistrantMetrics = eventMetrics.categorized_registration_metrics
                let categorizedRegistrant = categorizedRegistrantMetrics[0]
                tempMetrics.categorizedRegistrantCount = categorizedRegistrant || {}

            }
            setEventMetrics(tempMetrics)

        }
        catch(e){
            console.log(e)
        }

    }

    const getEventData = async function () {

        setIsLoading(true)

        const event = new Event();
        try {

            const receivedEvent = await event.getEventByURL(event_url)

            setIsLoading(false)

            const receivedSession = receivedEvent.sessions[0]

            let tempEvent = {...currentEvent}
            let tempSession = {...currentSession}

            console.log("receivedEvent", receivedEvent)

            tempEvent = {
                ...receivedEvent,
                id: receivedEvent.id,
                name: receivedEvent.name,
                iconUrl: receivedEvent.event_icon_url,
                startTime: new Date(receivedSession.start_time),
                endTime: new Date(receivedSession.end_time),
                shortDescription: receivedEvent.short_description,
                eventRoute: receivedEvent.event_url,
                sessions: receivedSession,
                publishStatus : receivedEvent.publish_status,
                additionalInformation: receivedEvent.additional_information ? JSON.parse(receivedEvent.additional_information) : {}
            }

            tempSession = {
                ...receivedSession,
                id: receivedSession.id,
                longDescription: receivedSession.description,
                startTime: new Date(receivedSession.start_time),
                endTime: new Date(receivedSession.end_time),
                bannerUrl: receivedSession.image_url,
                price: receivedSession.price,
                quota: receivedSession.quota,
                speaker: receivedSession.session_speaker,
                online_payment: receivedSession.online_payment,
                selectedPaymentPreset: 0,
                additionalInformation: receivedSession.additional_information ? JSON.parse(receivedSession.additional_information) : {}
            }

            if (tempSession.price === 0) {
                tempSession.selectedPaymentPreset = 0
            } else if (tempSession.online_payment && tempSession.price > 0) {
                tempSession.selectedPaymentPreset = 1
            } else if (!tempSession.online_payment && tempSession.price > 0) {
                tempSession.selectedPaymentPreset = 2
            }


            if (tempSession.additionalInformation.streamType) {
                if (tempSession.additionalInformation.streamType === "Google Meet") {
                    tempSession.selectedStreamingMediaIndex = 1
                } else if (tempSession.additionalInformation.streamType === "Zoom") {
                    tempSession.selectedStreamingMediaIndex = 2
                } else {
                    tempSession.selectedStreamingMediaIndex = 3
                }
            }

            if (tempSession.price === 0) {
                setPaymentType(PAYMENT_TYPE.FREE)
                // pt = PAYMENT_TYPE.FREE
            } else if (tempSession.online_payment) {
                setPaymentType(PAYMENT_TYPE.PAY_ONLINE)
                // pt = PAYMENT_TYPE.PAY_ONLINE
            } else {
                setPaymentType(PAYMENT_TYPE.PAY_OFFLINE)
                // pt = PAYMENT_TYPE.PAY_OFFLINE
            }

            let currentTime = new moment()

            if (currentTime.isAfter(tempSession.endTime)) {
                setEventStatus(EVENT_STATUS.FINISHED)
            } else if(currentTime.isAfter(tempSession.startTime)) {
                setEventStatus(EVENT_STATUS.LIVE)
            } else {
                setEventStatus(EVENT_STATUS.COUNTING_DOWN)
            }

            let requiredVisitorInformation = JSON.parse(receivedEvent.required_visitor_information)
            console.log(requiredVisitorInformation)
            setRegistrationFields(requiredVisitorInformation)


            setCurrentEvent({...tempEvent})
            setCurrentSession({...tempSession})

            await getEventMetrics(tempEvent.id, tempSession.id)

            scroll.scrollTo(0, {
                smooth: false,
                duration: 0
            })

        } catch (e) {
            console.log(e)
            if(e.http_status === 403){
                console.log("redirecingto")
                history.push({
                    pathname: "/not-found",
                    state: {
                        route: routerLocation.pathname.replace("/", "")
                    }
                })
            }
            setIsLoading(false)
        }

    }

    const renderCountdownAlert = function () {

        let currentTime = new moment()

        if (currentTime.isAfter(currentSession.endTime)) {
            return <>
                Eventmu telah berakhir.
            </>
        }

        if (currentTime.isAfter(currentSession.startTime)) {
            return <>
                Eventmu sedang berjalan!
            </>
        }

        let dayTo = Math.floor(Math.abs(moment.duration(currentTime.diff(currentSession.startTime)).get('days')));
        let hourTo = Math.floor(Math.abs(moment.duration(currentTime.diff(currentSession.startTime)).get('hours')));
        let minuteTo = Math.floor(Math.abs(moment.duration(currentTime.diff(currentSession.startTime)).get('minutes')));

        return <>
            Eventmu akan mulai dalam
            {dayTo ? ` ${dayTo} hari` : null}
            {hourTo ? ` ${hourTo} jam` : null}
            {minuteTo ? ` ${minuteTo} menit` : null}
        </>


    }

    console.log(GlobalData.token)

    const renderContent = () => {
        return <>

            <Container fluid style={{
                minHeight: "100vh"
            }}>

                <Row>
                    <Col md={12}>
                        <Collapse in={isMobile}>
                            <Alert
                                severity="warning"
                                action={
                                    <></>
                                }
                                style={{
                                    marginTop: "1em"
                                }}
                            >
                                Kami sedang mengembankan optimisasi untuk situs mobile ikutan. Untuk experience terbaik, kami menyarankan untuk membuka editor di komputermu.
                            </Alert>
                        </Collapse>
                    </Col>
                </Row>

                <Row>

                    <Col md={12} style={{
                        fontFamily: "Montserrat",
                        marginTop: "1em",
                        marginBottom: "0.5em"
                    }}>

                        <Card style={{
                            width: "100%"
                        }}>

                            <Card.Body style={{
                                fontFamily: "Montserrat"
                            }}>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <ImageThumbnail
                                            iconUrl={currentEvent.event_icon_url}
                                            name={currentEvent.name}
                                            style={{
                                                height: "3em",
                                                width: "3em"
                                            }}
                                        />
                                    </div>

                                    <div style={{
                                        flex: 1,
                                        fontFamily: "Poppins",
                                        marginLeft: "1em"
                                    }}>
                                        <div style={{
                                            fontSize: "2em",
                                            fontFamily: "Montserrat",
                                            fontWeight: "700"
                                        }}>
                                            {currentEvent.name}
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}
                                        >
                                            Lokasi : &nbsp;
                                            narabahasa.id/daftar/{currentEvent.event_url}
                                            &nbsp;

                                            <a href={`https://narabahasa.id/daftar/${currentEvent.event_url}`} target={"_blank"}>
                                                <IconButton
                                                    onMouseDown={e => e.preventDefault()}
                                                    size={"small"}>
                                                    <FaExternalLinkAlt style={{color: Palette.PRIMARY}}/>
                                                </IconButton>
                                            </a>
                                            &nbsp;
                                            <CopyToClipboard
                                                onCopy={popPopper}
                                                text={`https://narabahasa.id/daftar/${currentEvent.event_url}`}>
                                                <IconButton
                                                    ref={ref => anchor = ref}
                                                    onMouseDown={e => e.preventDefault()}
                                                    size={"small"}>
                                                    <FaRegClone style={{color: Palette.PRIMARY}}/>
                                                </IconButton>
                                            </CopyToClipboard>
                                            <Popper
                                                placement={"right"}
                                                open={isPopperVisible} anchorEl={anchor} transition>
                                                {({TransitionProps}) => (
                                                    <Fade {...TransitionProps} timeout={500}>
                                                        <div style={{
                                                            backgroundColor: "white",
                                                            paddingRight: "1em",
                                                            paddingLeft: "1em",
                                                            paddingTop: "0.5em",
                                                            paddingBottom: "0.5em"
                                                        }}>URL telah disalin!
                                                        </div>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </div>

                                    </div>

                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>

                                    <CustomButton variant={"outlined"} color={"success"}>
                                        {
                                            currentEvent.publishStatus === PUBLISH_STATUS.DRAFT ? "Pendaftaran belum dibuka" :
                                                currentEvent.publishStatus === PUBLISH_STATUS.DONE ? "Event telah selesai" :
                                                    currentEvent.publishStatus === PUBLISH_STATUS.LIVE ? "Live !" :
                                                        currentEvent.publishStatus === PUBLISH_STATUS.HIDDEN ? "Pendaftaran sedang ditutup" :
                                                            currentEvent.publishStatus === PUBLISH_STATUS.OPEN ? "Pendaftaran Buka" : null
                                        }
                                    </CustomButton>

                                    <CustomButton
                                        style={{
                                            marginLeft: "0.5em"
                                        }}
                                        variant={"outlined"} color={"success"}>
                                        Basic Plan
                                    </CustomButton>

                                </div>

                                {/*{renderURLLocationEditor()}*/}

                            </Card.Body>

                        </Card>

                    </Col>

                </Row>

                <Row>
                    <Col md={12} style={{
                        marginTop: "1em"
                    }}>
                        <Collapse in={true}>
                            <Alert
                                severity="info"
                            >
                                {renderCountdownAlert()}
                            </Alert>
                        </Collapse>
                    </Col>
                    <Col md={12}>
                        <Collapse in={currentEvent.publish_status === PUBLISH_STATUS.DRAFT || currentEvent.publish_status === PUBLISH_STATUS.HIDDEN}>
                            <Alert
                                severity="warning"
                                action={
                                    <></>
                                }
                                style={{
                                    marginTop: "1em"
                                }}
                            >
                                Pendaftaran acara mu {currentEvent.publish_status === PUBLISH_STATUS.DRAFT ? "belum" : "sedang tidak"} dibuka. Aktifkan pendaftaran acaramu&nbsp;
                                <a
                                    onClick={() => {
                                        history.push("/editor/detail/" + activeEventURL)
                                    }}
                                    href={""}>disini</a>.
                            </Alert>
                        </Collapse>
                    </Col>
                    <Col style={{
                        marginTop: "1em"
                    }} md={12}>
                        <Collapse in={eventMetrics.categorizedRegistrantCount && eventMetrics.categorizedRegistrantCount.waiting > 0}>
                            <Alert
                                severity="warning"
                                action={
                                    <></>
                                }
                            >

                                {
                                    paymentType === PAYMENT_TYPE.PAY_OFFLINE ?
                                        <>
                                            Ada {eventMetrics.categorizedRegistrantCount.waiting} pendaftar baru yang membutuhkan konfirmasi !
                                            <a
                                                style={{
                                                    marginLeft: "0.5em"
                                                }}
                                                href={""}
                                                onClick={() => {
                                                    history.push("/editor/visitors/" + activeEventURL)
                                                }}>
                                                SELENGKAPNYA
                                            </a>
                                        </>
                                        :
                                        <>
                                            Sistem sedang menunggu pembayaran dari {eventMetrics.categorizedRegistrantCount.waiting} peserta.
                                        </>
                                }

                            </Alert>
                        </Collapse>
                    </Col>
                </Row>

                <Row style={{
                    marginTop: "1em"
                }}>
                    <Col md={6}>
                        <Card className="text-white" style={{
                            backgroundColor: Palette.PRIMARY,
                            height: "100%"
                        }}>
                            <Card.Body className="pb-0">
                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    height: "100%",
                                    paddingBottom: "1em",
                                    flexDirection: "column"
                                }}>
                                    <span style={{
                                        fontSize: "1.2em",
                                    }}>
                                        Data Pendaftaran
                                    </span>
                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <a>
                                            Pendaftar Terkonfimasi : {eventMetrics.acceptedCount ? eventMetrics.acceptedCount.total : 0} &nbsp;&nbsp;
                                        </a>
                                        <span style={{
                                            position: "inline",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center", color: "GreenYellow"
                                        }}>
                                            {
                                                eventMetrics.acceptedCount && eventMetrics.acceptedCount.lastDay > 0 ?
                                                    <><FaCaretUp/> {eventMetrics.acceptedCount ? eventMetrics.acceptedCount.lastDay : 0}</>
                                                    :
                                                    null
                                            }
                                        </span>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <a>
                                            Total Pendaftar : {currentEvent.metrics.check_in_count} &nbsp;&nbsp;
                                        </a>
                                        <span style={{
                                            position: "inline",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center", color: "GreenYellow"
                                        }}>
                                           {
                                               eventMetrics.registrationCount && eventMetrics.registrationCount.lastDay > 0 ?
                                                   <><FaCaretUp/> {eventMetrics.registrationCount ? eventMetrics.registrationCount.lastDay : 0}</>
                                                   :
                                                   null
                                           }

                                        </span>
                                    </div>

                                    <br/>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <a>
                                            Halaman Dilihat : {currentEvent.metrics.view_count} orang &nbsp;&nbsp;
                                        </a>
                                        <span style={{
                                            position: "inline",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center", color: "GreenYellow"
                                        }}>
                                            {
                                                eventMetrics.viewCount && eventMetrics.viewCount.lastDay > 0 ?
                                                    <><FaCaretUp/> {eventMetrics.viewCount ? eventMetrics.viewCount.lastDay : 0}</>
                                                    :
                                                    null
                                            }

                                        </span>
                                    </div>

                                    <span style={{
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "flex-end",
                                    }}>

                                    </span>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <LineChart
                            caption={
                                <div style={{fontFamily: "Poppins"}}>
                                    <span style={{
                                        fontSize: "1.2em",
                                    }}>
                                        Pendaftaran Dalam 7 Hari Terakhir
                                    </span>
                                </div>
                            }
                            backgroundColor={Palette.SECONDARY}
                            variables={["Pendaftaran Terkonfirmasi", "Pendaftaran", "Dilihat"]}
                            labels={eventMetrics.viewCount ? eventMetrics.viewCount.labels : []}
                            data={[
                                eventMetrics.acceptedCount ? eventMetrics.acceptedCount.data : [],
                                eventMetrics.registrationCount ? eventMetrics.registrationCount.data : [],
                                eventMetrics.viewCount ? eventMetrics.viewCount.data : []
                            ]}
                        />
                    </Col>
                </Row>
            </Container>

        </>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"dashboard"}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
