import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button, Col, Row } from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, { useEffect, useState } from "react";
import Topic from "../../models/Topic";
import { FaSave, FaTimes } from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import MiniTooltip from "./MiniTooltip";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Question from "../../models/Question";
import RichText from "../reusable/RichText/LimitedRichText"
export default function ManageTopicModal(props) {

    const { selectedQuestion } = props

    const topicModel = new Topic();
    const [errorMsg, setErrorMsg] = useState('')

    const [users, setUsers] = useState([])
    const [userName, setUserName] = useState("");

    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")

    useEffect(() => {

        let selectedQuestion = props.selectedQuestion

        setQuestion(selectedQuestion.question)
        setAnswer(selectedQuestion.answer ? selectedQuestion.answer : "")
        setUserName(selectedQuestion.full_name)

    }, [props.selectedQuestion])

    const answerQuestion = async () => {
        try {

            let questionModel = new Question()
           await questionModel.updateQuestion(selectedQuestion.id, {
                question: question,
                answer: answer,
                answered_at: new Date()
            })

         

        } catch (e) {
            console.log(e)
            throw e
        }
    }

    return (
        <Dialog open={props.visible}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle onClose={() => this.hide()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Jawab Pertanyaan
                    </Col>
                </Row>
                <hr />
            </DialogTitle>
            <DialogContent>
                <Collapse in={errorMsg.length > 0}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col style={{ fontFamily: 'OpenSans-SemiBold' }} xs={12}>Pertanyaan</Col>
                    <Col md={12}>
                        <RichText
                            onBlurAndSave={(value) => {
                                setQuestion(value)
                            }
                            } value={question} />
                        {/* <LagFreeTextEditor
                            title={'Pertanyaan'}
                            multiline={true}
                            rows={4}
                            changeValue={value => {
                                setQuestion(value)
                            }}
                            defaultValue={props.question ? props.question : null}
                            value={question}
                            placeholder={"Pertanyaan"}/> */}
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    
                    <Col md={12} sm={12}>
                        <LagFreeTextEditor
                            title={'Ditanyakan Oleh'}
                            changeValue={value => {
                                setQuestion(value)
                            }}
                            disabled={true}
                            defaultValue={selectedQuestion.full_name}
                            value={selectedQuestion.full_name}
                            placeholder={"Pertanyaan"} />

                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col style={{ fontFamily: 'OpenSans-SemiBold' }} xs={12}>Jawaban</Col>
                    <Col md={12}>
                        <RichText
                            onBlurAndSave={(value) => {
                                setAnswer(value)
                            }
                            } value={answer} />
                        {/* <LagFreeTextEditor
                            title={'Jawaban'}
                            multiline={true}
                            rows={4}
                            changeValue={value => {
                                setAnswer(value)
                            }}
                            defaultValue={answer}
                            value={answer}
                            placeholder={"Jawaban"} /> */}
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{ borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold' }}
                        onClick={props.hide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={async () => {
                            try {
                                let result = await answerQuestion()
                                setQuestion("")
                                props.hide()
                                props.onSubmit()
                            } catch (e) {
                                setErrorMsg(e.msg ? JSON.stringify(e.msg) : "Terjadi Kesalahan")
                            }

                        }}
                        style={{ color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold' }}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave /></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}
