import moment from "moment"
import ApiRequest from "../util/ApiRequest";


export default class Registration {

    static states = {
        PENDING: "PENDING",
        WAITING: "WAITING",
        ACCEPTED: "ACCEPTED",
        REJECTED: "REJECTED",
        CANCELLED: "CANCELLED"
    }

    /**
     * To change a regitration state to accepted/rejected
     * @param sessionId
     * @param userId
     * @param command
     * @param [rejectionMessage]
     * @returns {Promise<void>}
     */
    static changeRegistrationState = async (sessionId, userId, command, rejectionMessage) => {
        let result = await ApiRequest.set(`/v2/sessions/${sessionId}/registration/${userId}/${command}`, "POST", {
            rejection_message: rejectionMessage ? rejectionMessage : null
        })

        return result
    }

}
