import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {Col, Row} from "react-bootstrap";
import PresenceForm from "../../../models/PresenceForm";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import {FaFileDownload} from "react-icons/fa";
import {CSVLink} from "react-csv";
import Complaint from "../../../models/Complaint";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import ComplaintModel from "../../../models/Complaint";
import {useHistory, useParams, useLocation} from "react-router-dom";
import NoteComplaintModal from "../../reusable/NoteComplaintModal";
export default function ComplaintForm(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState({label: "Semua Kelas", value: null});
    const [presenceData, setPresenceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [complaint, setComplaint] = useState([])
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedComplaint, setSelectedComplaint] = useState({})
    let history = useHistory()


    const columns = useMemo(
        () => [
            {
                Header: "Kelas",
                accessor: "class_name",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values
                    //console.log(rowInfo)

                    return <>
                        {rowInfo.class_name.split()}
                    </>
                }
            },
            {
                Header: "Nama Lengkap",
                accessor: "name"
            },
            {
                Header: "Surel",
                accessor: "email"
            },
            {
                Header: "Aduan",
                accessor: "complaint",
                Cell: (cellInfo) =>{
                    let row = cellInfo.row.original
                    return <div style={{whiteSpace: 'pre'}}>{row.complaint}</div>
                }
            },
            {
                Header: "Waktu Bertanya",
                accessor: "created_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
        ]
    )
    let interactions = [
        {
            name: "Balas",
            action: (data) => {
                let email = data.email;
                let id = data.id;
                let createdAt = data.created_at
                let message = data.complaint
                return history.push("/editor/email?complaint_id=" + id + "&complaint_email=" + email + "&created_at=" + createdAt + "&complaint_message=" + message)

            }
        },
        {
            name: "Catatan",
            action: (data) => {
                console.log("OPEN DIALOG VOUCHER", data)
                setShowNoteModal(true)
                setSelectedComplaint(data)
            }
        }
    ]


    const getData = async () => {
        const complaintModel = new Complaint();
        const classesTemp = [];

        try {
            const result = await complaintModel.getAll();

            setPresenceData(result)
            setFilteredData(result)

            let filteredClass = _.uniqBy(result, (obj)=>obj.class_name)

            console.log(filteredClass)

            let classesTemp = filteredClass.map(presenceData => {
                return {label: presenceData.class_name, value: presenceData.class_name}
            })

            setClasses([{label: "Semua Kelas", value: null}, ...classesTemp])

        } catch (e) {
            console.log('getAll presence err', e)
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const filteredClassTemp = [];

        if (selectedClass === null) {
            return setFilteredData(presenceData)
        }

        presenceData.map(aClass => {
            console.log('aClass', aClass)

            if (aClass.class_name === selectedClass) filteredClassTemp.push(aClass)
        })

        setFilteredData(filteredClassTemp)
    }, [selectedClass])

    return (
        <EditorPageWrapper
            activeTab={"complaint"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <>

                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25,
                        marginBottom: 30
                    }}>
                        Aduan
                    </div>
                    <div>
                    <CSVLink
                                style={{color: 'white', marginBottom: 30}}
                                separator={";"}
                                data={
                                    filteredData.map((datum, key) => {
                                        return {
                                            nama_kelas: datum.class_name,
                                            nama: datum.name,
                                            email:datum.email,
                                            complaint: datum.complaint
                                        }
                                    })
                                }
                                filename={`aduan.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Unduh File CSV&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                    </div>

                    <Select
                        onChange={(session) => {
                            setSelectedClass(session.value)
                        }}
                        value={selectedClass}
                        placeholder={"Pilih Kelas"}
                        options={classes}
                        style={{
                            display: "flex", width: "100%"
                        }}
                    />


                    {selectedClass !== 0 &&
                    <MobTable columns={columns}
                              data={filteredData}
                              interactions={interactions}
                    />
                    }
                </div>
                <NoteComplaintModal
                    complaint={selectedComplaint}
                    visible={showNoteModal}
                    onSave={(complaint_save) => {

                        let ComplaintModelData = new ComplaintModel()
                        ComplaintModelData.updateNote(complaint_save).then(result => {
                            let complaintCopy = [...complaint]
                            setComplaint(complaintCopy)
                            setShowNoteModal(false)
                        }).catch(err => {
                            alert("Check console")
                            console.error(err)
                        })
                    }}
                    onHide={() => {
                        setShowNoteModal(false)
                    }}
                />

            </>

        </EditorPageWrapper>
    )
}
