import ApiRequest from "../util/ApiRequest";

export default class CourseInstructors {
    getByInstructorId = async () => {
        return await ApiRequest.set(`/v1/course_instructors`, "GET");
    }

    create = async (body) => {
        return await ApiRequest.set(`/v1/course_instructors/create`, "POST", body);
    }

    getByCourseId = async (courseId) => {
        return await ApiRequest.set(`/v1/course_instructors/${courseId}/course`, "GET");
    }

    delete = async (id) => {
        return await ApiRequest.set(`/v1/course_instructors/${id}`, "DELETE");
    }
}
