import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Dropdown, ListGroup, Nav, Navbar, NavDropdown, Row, Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import PageWrapper from "./PageWrapper";

import moment from "moment"

import {useCookies} from 'react-cookie';
import GlobalData from "../../util/GlobalData";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {TextField, Button as MaterialButton, ButtonGroup} from "@material-ui/core";
import Palette from "../../util/Palette";
import Dropzone from "react-dropzone";

import {FaPlus, FaClock, FaPen, FaSave, FaTimes, FaTrash, FaAngleUp, FaAngleDown} from "react-icons/fa";
import {MdEvent, MdSchedule, MdEdit} from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditorPageWrapper from "./editor/EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import MomentUtils from '@date-io/moment';
import {
    KeyboardDatePicker,
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'moment/locale/id'
import CustomDropzone from "../reusable/CustomDropzone";

import Event, {PUBLISH_STATUS} from "../../models/Event"
import CustomButton from "../reusable/CustomButton";
import MiniTooltip from "../reusable/MiniTooltip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import FormFieldModal from "../reusable/modals/FormFieldModal";
import EventEditor from "../reusable/EventEditor";
import PreviewModal from "../reusable/modals/PreviewModal";
import LoginModal from "../reusable/LoginModal";
import DemoEventEditor from "../reusable/DemoEventEditor";
import DemoPrivateEventEditor from "../reusable/DemoPrivateEventEditor";

export default function CreateEvent(props) {

    let history = useHistory()
    let routerLocation = useLocation()

    let event_name = ""
    if (props.location) {
        if (props.location.state) {
            if (props.location.state.event_name) {
                event_name = props.location.state.event_name
            }
        }
    }
    // console.log("rp", routerParams)
    const {event_url} = useParams()

    const [isDataChanged, setIsDataChanged] = useState(false)

    //State Params
    const [isPermanent, setIsPermanent] = useState(false)
    const [loginModalShow, setLoginModalShow] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isHomePagePreviewVisible, setIsHomePagePreviewVisible] = useState(false)
    const [registrationFields, setRegistrationFields] = useState([
        {
            field: "Email",
            type: "email"
        },
        {
            field: "Nama Lengkap",
            type: "isian",
        }
    ])

    //Page Params
    const [currentEvent, setCurrentEvent] = useState({
        name: event_name ? event_name : "",
        iconUrl: "",
        shortDescription: "",
        eventRoute: "",
        startTime: moment(),
        endTime: moment(),
        additionalInformation: {
            registrationFormIntroduction: ""
        },
        publishStatus: PUBLISH_STATUS.INITIAL
    })

    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker: "",
        quota: -1,
        price: 0,
        requirePayment: false,
        longDescription: "",
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        additionalInformation: {}
    })

    const [classObj, setClass] = useState({});

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const processSubmit = async () => {


        // setErrorMessage("")
        //
        // if (!currentEvent.name) {
        //     promptError("Harap isi nama acara.")
        //     return
        // }
        //
        // if (!currentEvent.shortDescription.length > 200) {
        //     promptError("Deskripsi singkat acara tidak boleh melebihi 200 huruf.")
        //     return
        // }
        //
        // if (currentSession.startTime > currentSession.endTime) {
        //     promptError("Waktu selesai harus setelah waktu mulai.")
        //     return
        // }
        //
        // if (currentSession.quota < 0 || isNaN(currentSession.quota)) {
        //     promptError("Harap mengisi kuota acara.")
        //     return
        // }
        //
        // if (!currentSession.additionalInformation.streamType || !currentSession.additionalInformation.streamingURL) {
        //     promptError("Harap mengisi informasi streaming.")
        //     return
        // }
        //
        // if (!cookie.token && !GlobalData.token) {
        //     setLoginModalShow(true)
        //     return
        // }
        //
        // let eventParams = {
        //     name: currentEvent.name,
        //     short_description: currentEvent.shortDescription,
        //     description: currentEvent.longDescription,
        //     start_time: currentEvent.startTime,
        //     end_time: currentEvent.endTime,
        //     event_banner_url: currentSession.bannerUrl,
        //     event_icon_url: currentEvent.iconUrl,
        //     required_visitor_information: JSON.stringify(registrationFields),
        //     event_type: "WEBINAR",
        //     additional_information: JSON.stringify(currentEvent.additionalInformation),
        //     event_url: currentEvent.eventRoute ? currentEvent.eventRoute : currentEvent.name.toLowerCase().replace(/ /g, "-")
        // }
        //
        // let sessionParams = {
        //     session_name: currentSession.name,
        //     session_speaker: currentSession.speaker,
        //     quota: currentSession.quota,
        //     price: currentSession.price,
        //     require_payment: currentSession.price > 0,
        //     description: currentSession.longDescription,
        //     startTime: currentSession.startTime,
        //     endTime: currentSession.endTime,
        //     image_url: currentSession.bannerUrl,
        //     online_mode: true,
        //     stream_url: "",
        //     additional_information: JSON.stringify(currentSession.additionalInformation),
        //     online_payment: currentSession.onlinePayment,
        //     allow_registration: true,
        //     selectedPaymentPreset: 0
        // }
        //
        // let event = new Event();
        //
        // try {
        //     let result = await event.createSingleSessionEvent({
        //         event: eventParams,
        //         session: sessionParams
        //     })
        //     console.log(result)
        //     history.push("/editor/classDetail/" + eventParams.event_url)
        // } catch (e) {
        //     console.log(e)
        //     promptError(e.msg ? JSON.stringify(e.msg) : "")
        // }


    }

    const renderContent = function () {
        return <>

            {renderAlertBox()}
            {renderSuccessBox()}

            <span style={{
                marginTop: "1em",
                marginBottom: "0.5em",
            }}>
                <a>Kelas</a> >&nbsp;
                <a>Buat Kelas</a>
            </span>

            <b style={{
                marginTop: "0.5em",
                marginBottom: "0.3em",
                color: Palette.PRIMARY,
                fontSize: "1.75em",
                fontFamily: "OpenSans-Bold",
            }}>Buat Kelas</b>

            {props.privateClass ?
                <DemoPrivateEventEditor
                    classObj={classObj}
                    setClass={setClass}
                    setSuccessMessage={msg => promptSuccess(msg)}
                    setErrorMessage={(msg) => promptError(msg)}
                    activeEvent={(msg) => setCurrentEvent(msg)}
                    activeSession={(msg) => setCurrentSession(msg)}
                    currentEvent={currentEvent}
                    currentSession={currentSession}
                    setCurrentEvent={(newEvent) => setCurrentEvent(newEvent)}
                    setCurrentSession={(newSession) => setCurrentSession(newSession)}
                    registrationInformation={registrationFields}
                    setRegistrationInformation={(rf) => setRegistrationFields(rf)}
                    isLoading={isLoadingBar}
                    setIsLoadingBar={(isLoading) => setIsLoadingBar(isLoading)}
                    processSubmit={() => processSubmit()}
                    isDataChanged={isDataChanged}
                    setIsDataChanged={setIsDataChanged}/> :
                <DemoEventEditor
                    setSuccessMessage={(msg) => promptSuccess(msg)}
                    setErrorMessage={(msg) => promptError(msg)}
                    activeEvent={(msg) => setCurrentEvent(msg)}
                    activeSession={(msg) => setCurrentSession(msg)}
                    currentEvent={currentEvent}
                    currentSession={currentSession}
                    setCurrentEvent={(newEvent) => setCurrentEvent(newEvent)}
                    setCurrentSession={(newSession) => setCurrentSession(newSession)}
                    registrationInformation={registrationFields}
                    setRegistrationInformation={(rf) => setRegistrationFields(rf)}
                    isLoading={isLoadingBar}
                    setIsLoadingBar={(isLoading) => setIsLoadingBar(isLoading)}
                    processSubmit={() => processSubmit()}
                    isDataChanged={false}
                    setIsDataChanged={() => {
                    }}
                />
            }

            <PreviewModal
                show={isHomePagePreviewVisible}
                fields={registrationFields}
                onClose={() => setIsHomePagePreviewVisible(false)}
                event={currentEvent}
                session={currentSession}
            />

            <LoginModal
                errorMessage={"Harap Login Terlebih Dahulu"}
                onHide={() => setLoginModalShow(false)}
                onLoginDone={(result) => {
                    processSubmit()
                }}
                show={loginModalShow}/>

        </>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    return (
        <>
            {
                cookie.token || GlobalData.token ?
                    <EditorPageWrapper>
                        {renderContent()}
                    </EditorPageWrapper> :
                    <PageWrapper
                        headerActive={"create-event"}
                    >
                        <Container style={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column",
                            marginTop: isMobile ? "-6em" : '0em',
                            fontFamily: "Montserrat"
                        }}>
                            {renderContent()}
                        </Container>
                    </PageWrapper>

            }
        </>
    );

}
