import React, {useEffect, useMemo, useState} from "react";
import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import CourseRating from "../../../models/CourseRating";
import MobTable from "../../reusable/Table/MobTable";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {FaFileDownload} from "react-icons/all";
import {CSVLink} from "react-csv";

export default function PrivateClassFeedback(props) {
    const [feedback, setFeedback] = useState([]);
    const [isDeletedShown, setDeletedShown] = useState(false);
    const [feedbackWithoutDeleted, setFeedbackWithoutDeleted] = useState([])

    const interactions = [
        {
            name: "Sembunyikan",
            action: (feedback) => {
                toggleFeedback(feedback.id);
            },
            style: {width: 140},
            variant: 'secondary',
            condition: (question) => {
                return question.active
            }
        },
        {
            name: "Pulihkan",
            action: (feedback) => {
                toggleFeedback(feedback.id);
            },
            variant: 'info',
            style: {width: 120},
            condition: (quiz) => {
                return !quiz.active
            },
        },
    ]

    const columns = useMemo(
        () => [
            {
                Header: "Nama Peserta",
                accessor: "full_name"
            },
            {
                Header: "Nilai",
                accessor: "rating",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original

                    var num = Number(row.rating) // The Number() only visualizes the type and is not needed
                    var roundedString = num.toFixed(2);

                    return (
                        <>
                            {roundedString}
                        </>
                    )
                }
            },
            {
                Header: "Ulasan",
                accessor: "comment",
                Cell: (cellInfo) => {
                    let row = cellInfo.row.original
                    return (
                        <>
                            {row.comment ? row.comment : '-'}
                        </>
                    )
                }
            }
        ]
    )

    useEffect(() => {
        getAllFeedback()
    }, [])

    const toggleFeedback = async (id) => {
        try {
            const courseRatingModel = new CourseRating();

            let result = await courseRatingModel.toggle(id)

            getAllFeedback()

            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllFeedback = async () => {
        const courseRatingModel = new CourseRating();

        const result = await courseRatingModel.getByCourseId(props.match.params.event_url)

        const feedbackWithoutDeletedTemp = [];

        result.forEach(feedback => {
            if (feedback.active) feedbackWithoutDeletedTemp.push(feedback)
        })

        setFeedbackWithoutDeleted(feedbackWithoutDeletedTemp)

        setFeedback(result)
        console.log(result);
    }

    return (
        <EditorPageWrapper
            privateClass
            activeTab={"main-feedback"}
        >
            <>
                <div style={{
                    marginTop: "0.5em",
                    // marginBottom: "0.5em",
                    color: Palette.PRIMARY,
                    fontFamily: "OpenSans-Bold",
                    fontWeight: "800",
                    fontSize: "1.5em"
                }}>
                    Umpan Balik
                </div>

                <Row style={{marginLeft: 2, marginTop: 15}}>
                    <Button variant={'info'}
                            onClick={() => setDeletedShown(!isDeletedShown)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 30,
                                fontSize: '.8em',
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: 5,
                                width: 200,
                                color: 'white', marginRight: 5
                            }}
                    >
                        {isDeletedShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                            <IoMdEye style={{marginRight: 10}}/>}
                        {isDeletedShown ? 'Sembunyikan Terhapus' : 'Tampilkan Terhapus'}
                    </Button>
                    <CSVLink
                      style={{color: 'white'}}
                      separator={";"}
                      data={feedback.map((obj)=>{
                          return {
                              "Nama Peserta" : obj.full_name,
                              "Nilai" : obj.rating,
                              "comment" : obj.comment,
                          }
                      })}
                      filename={`umpan-balik.csv`}
                      className="btn btn-primary"
                      target="_blank"
                    >
                        Unduh Data&nbsp;&nbsp;<FaFileDownload/>
                    </CSVLink>
                </Row>

                <div>
                    <MobTable
                        columns={columns}
                        data={isDeletedShown ? feedback : feedbackWithoutDeleted}
                        interactions={interactions}
                    />
                </div>
            </>
        </EditorPageWrapper>
    )
}
