import React, {useEffect, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {Col, Container, ListGroup, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import CustomButton from "../CustomButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

import {FaTimes, MdAdd, MdClear} from "react-icons/all";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Palette from "../../../util/Palette";
import OptionsFieldsEditor from "../OptionsFieldsEditor";
import LagFreeTextEditor from "../LagFreeTextEditor";
import {MdEdit} from "react-icons/md";
import {FaPlus, FaTrash} from "react-icons/fa";
import FormFieldModal from "./FormFieldModal";
import Survey from "../../../models/Survey";
import SurveyResultModal from "./SurveyResultModal";
import Switch from "@material-ui/core/Switch/Switch";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSurveyModal(props) {

    let [title, setTitle] = useState("")
    let [url, setURL] = useState("")
    let [questions, setQuestions] = useState([])
    let [survey, setSurvey] = useState({})
    let [introduction, setIntroduction] = useState("")
    const [isSurveyEnabled, setSurveyEnabled] = useState(false);

    let [errorMessage, setErrorMessage] = useState("")
    let [isAddFieldModalVisible, setIsAddFieldModalVisible] = useState(false)
    let [isEditFieldModalVisible, setIsEditFieldModalVisible] = useState(false)
    let [activeQuestionParam, setActiveQuestionParam] = useState({})

    let currentEvent = props.event

    useEffect(() => {

        if (props.survey) {

            let currentSurvey = props.survey
            setSurvey({...currentSurvey})
            setIntroduction(currentSurvey.introduction)
            setIntroduction(currentSurvey.introduction)
            setQuestions(currentSurvey.questions)
            setURL(currentSurvey.url)
            setTitle(currentSurvey.title)
            setSurveyEnabled(currentSurvey.enabled);

            console.log(currentSurvey.questions)

        }

    }, [props.survey])

    const onClose = () => {

        setSurvey({})
        setIntroduction("")
        setQuestions([])
        setURL("")
        setTitle("")
        setURL("")
        setErrorMessage("")

        props.onClose()

    }

    const onSubmit = async () => {

        try{

            await props.onSubmit({
                id : survey.id,
                introduction : introduction,
                question_json : JSON.stringify(questions),
                title : title,
                url : url ? url : title?.toLowerCase().replace(/ /g, "-"),
                enabled : isSurveyEnabled
            })

            onClose()

        }catch(e){
            if(e.msg){
                return setErrorMessage(e.msg)
            }
            console.log(e)
            setErrorMessage("Terjadi Kesalahan")
        }


    }

    const editQuestion = (fieldName, type, description, options, index) => {

        let temp = [
            ...questions
        ]

        let newField = {
            field: fieldName,
            type: type,
            description: description
        }

        if (options) {
            newField.options = options
        }

        temp[index] = newField

        setQuestions(temp)

    }

    const addQuestion = (fieldName, type, description, options) => {
        let temp = [
            ...questions
        ]

        let newField = {
            field: fieldName,
            type: type,
            description: description
        }

        if (options) {
            newField.options = options
        }

        temp.push(newField)

        setQuestions(temp)

    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderModalFooter = () => {

        return <DialogActions>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <CustomButton
                color="primary"
                onClick={() => {
                    onClose()
                }}>
                Batal
            </CustomButton>
            <CustomButton
                color="primary"
                disabled={false}
                onClick={() => {
                    onSubmit()
                }}>
                Simpan
            </CustomButton>
        </DialogActions>
    }

    const renderContent = () => {
        return <>
            <Row style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                {isSurveyEnabled ? "Aktif" : "Disembuyikan"}
                <Switch
                    checked={isSurveyEnabled}
                    onChange={() => {
                        setSurveyEnabled(!isSurveyEnabled)
                    }}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
            </Row>

            <Row style={{
                fontSize: "0.9em"
            }}
            >
                <Col md={12}>Judul Survei * </Col>
            </Row>
            <Row>
                <Col md={12}>

                    <LagFreeTextEditor
                        fullWidth
                        inputProps={{
                            style: {
                                fontFamily: "Montserrat"
                            }
                        }}
                        value={title}
                        placeholder={"Judul Survey"}
                        changeValue={value => setTitle(value)}

                    />
                </Col>
            </Row>


            <Row style={{
                marginTop: "1em",
                fontSize: "0.9em"
            }}
            >
                <Col md={12}>URL Survei</Col>
            </Row>
            <Row>
                <Col md={12} style={{
                    display : "flex",
                    flexDirection : "row",
                    alignItems : "center"
                }}>
                    narabahasa.id/daftar/q/{currentEvent?.event_url}/
                    <LagFreeTextEditor
                        style={{
                            flex : 1,
                            marginLeft : "0.2em"
                        }}
                        inputProps={{
                            style: {
                                fontFamily: "Montserrat"
                            }
                        }}
                        value={url ? url : title?.toLowerCase().replace(/ /g, "-")}
                        placeholder={"URL Survey"}
                        changeValue={value => setURL(value)}

                    />
                </Col>
            </Row>

            <Row style={{
                marginTop: "1em",
                fontSize: "0.9em"
            }}
            >
                <Col md={12}>Kalimat Pembuka</Col>
            </Row>

            <Row>
                <Col md={12}>

                    <LagFreeTextEditor
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{
                            style: {
                                fontFamily: "Montserrat"
                            }
                        }}
                        value={introduction}
                        placeholder={"Kalimat Pembuka\nContoh : Survei ini dibuat untuk mengetahui feedback dari komunitas mengenai acara kami."}
                        changeValue={value => setIntroduction(value)}
                    />
                </Col>
            </Row>

            <Row style={{marginTop: "1em"}}>
                <Col md={12}>Daftar Pertanyaan</Col>
                <Col md={12}>

                    <ListGroup style={{
                        width: "100%",
                        marginTop: "1em"
                    }}
                    >
                        {
                            questions?.map((obj, key) => {
                                console.log(obj)
                                return <>
                                    <ListGroup.Item
                                        key={key}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center"
                                        }}
                                    >

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                width: "2em"
                                            }}>
                                                {key + 1}.
                                            </span>
                                            <span style={{
                                                flex: 1
                                            }}>
                                                {obj.field}
                                            </span>

                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                color: "gray",
                                                flex: 1,
                                                marginLeft: "2em"
                                            }}>
                                                {obj.description}
                                            </span>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end"
                                        }}>
                                            <IconButton
                                                onClick={() => {
                                                    setIsEditFieldModalVisible(true)
                                                    setActiveQuestionParam({
                                                        fieldName: obj.field,
                                                        type: obj.type,
                                                        description: obj.description,
                                                        options: obj.options ? obj.options : [],
                                                        activeIndex: key
                                                    })
                                                }}
                                                onMouseDown={e => e.preventDefault()}
                                                size={"small"}>
                                                <MdEdit/>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {

                                                }}
                                                onMouseDown={e => e.preventDefault()}
                                                size={"small"}>
                                                <FaTrash/>
                                            </IconButton>
                                        </div>
                                    </ListGroup.Item>
                                </>
                            })
                        }
                        <ListGroup.Item
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems : "center",
                                cursor : "pointer"
                            }}
                            onClick={()=>{setIsAddFieldModalVisible(true)}}
                        >
                            Tambah&nbsp;&nbsp;<FaPlus/>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </>
    }

    return (
        <Dialog
            open={props.show}
            maxWidth="md"
            style={{
                fontFamily: "Montserrat"
            }}
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <ModalHeader>
                    <ModalTitle
                        style={{
                            fontFamily: "Montserrat"
                        }}
                        onClose={() => onClose()}>
                        <span style={{
                            fontFamily: "Montserrat",
                            fontWeight: "bold"
                        }}>{!props.survey ? 'Buat Survey Baru' : 'Edit Survey'}</span>
                    </ModalTitle>
                </ModalHeader>

                <ModalBody>

                    <Container>

                        {renderAlertBox()}

                        {renderContent()}

                        <FormFieldModal
                            show={isAddFieldModalVisible}
                            onClose={() => {
                                setIsAddFieldModalVisible(false)
                            }}
                            onSubmit={(fieldName, type, description, options) => {
                                addQuestion(fieldName, type, description, options)
                            }}
                        />

                        <FormFieldModal
                            show={isEditFieldModalVisible}
                            onClose={() => {
                                setIsEditFieldModalVisible(false)
                            }}
                            onSubmit={(fieldName, type, description, options, index) => {
                                editQuestion(fieldName, type, description, options, index)
                            }}
                            params={activeQuestionParam}
                        />



                    </Container>

                </ModalBody>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

