import ApiRequest from "../util/ApiRequest";

export default class MemberCourse {
    invite = async (courseId, user) => {
        return await ApiRequest.set(`/v1/member_course/${courseId}/invite`, "POST", [
            user
        ]);
    }

    inviteBatch = async (courseId, users) => {
        return await ApiRequest.set(`/v1/member_course/${courseId}/invite`, "POST", users);
    }

    getAllCertificates = async (courseId) => {
        return await ApiRequest.set(`/v1/member_course/${courseId}/certificates`, "GET");
    }

    changePredicate = async (memberCourseId, predicate) => {
        return await ApiRequest.set(`/v1/member_course/${memberCourseId}/predicate`, "POST", {
            predicate : predicate
        });
    }

    updatePredicates = async (participants, predicate) => {
        console.log('predicate',{
            memberCourses : participants,
            predicate : predicate
        })
        let result = await ApiRequest.set(`/v1/member_course/predicate`, "PUT", {
            memberCourses : participants,
            predicate : predicate
        });
        return
    }

    uploadSingleCertificate = async(file, memberCourseId, courseId) =>{

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        return await ApiRequest.setMultipart(`/v1/member_course/${courseId}/${memberCourseId}/certificate`, "POST", formData);

    }

    getAcceptedParticipants = async (courseId) => {
        return await ApiRequest.set(`/v1/member_course/${courseId}/accepted-participants`, "GET");
    }

    getNoOfAcceptedParticipants = async (courseId) => {
        return await ApiRequest.set(`/v1/member_course/${courseId}/no_of_accepted_participants`, "GET");
    }

    update = async (id, body) => {
        return await ApiRequest.set(`/v1/member_course/${id}`, "PUT", body);
    }

    resendAcceptedEmail = async (id) => {
        return await ApiRequest.set(`/v1/member_course/${id}/resendAcceptedEmail`, "POST");
    }

    rejectWithEmail = async (id, body) => {
        return await ApiRequest.set(`/v1/member_course/${id}/rejectWithEmail`, "POST", body);
    }

    acceptMemberCourse = async (memberCourseId) => {

        console.log("SENDING", memberCourseId)

        return await ApiRequest.set(`/v1/member_course/accept_member_course`, "POST", {
            memberCourseId
        });
    }
}
