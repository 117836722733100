import React, {Component, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useCookies} from 'react-cookie';
import GlobalData from "../../../util/GlobalData";
import {useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import EditorPageWrapper from "./EditorPageWrapper";
import Event from "../../../models/Event";
import Course from "../../../models/Course"
import {Button, Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import textFormatter from "../../../util/textFormatter";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import moment from "moment";
import CustomButton from "../../reusable/CustomButton";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Topic from "../../../models/Topic";
import {FaSave} from "react-icons/fa";
import ManageTopicModal from "../../reusable/ManageTopicModal";
import CustomDropzone from "../../reusable/CustomDropzone";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {FaMailBulk} from "react-icons/all";
import CourseInstructors from "../../../models/CourseInstructors";
import UpcomingEventEditPriceModal from "../../reusable/UpcomingEventEditPriceModal";

export default function EditorMain(props) {
    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    const courseModel = new Course();
    const courseInstructorModel = new CourseInstructors();
    const topicModel = new Topic();
    let winWidth = window.innerWidth
    let [events, setEvents] = useState([])
    let [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)


    const [courses, setCourses] = useState([]);
    const [coursesWithoutDeleted, setCoursesWithoutDeleted] = useState([])
    const [upcomingClass, setUpcomingClass] = useState([])
    const [upcomingClassWithoutDeleted, setUpcomingClassWithoutDeleted] = useState([])

    const [archiveClass, setArchiveClass] = useState([])
    const [archiveClassWithoutDeleted, setArchiveClassWithoutDeleted] = useState([])

    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isModalBtnHovered, setModalBtnHovered] = useState(false);

    let history = useHistory()
    const [isDelete, setDelete] = useState(false)
    const [isHardDelete, setHardDelete] = useState(false)
    const [selectedClass, setSelectedClass] = useState({});
    const [batchUpdateModalVisible, setBatchUpdateModalVisible] = useState(false)
    const [isDeletedClassShown, setDeletedClassShown] = useState(true);
    const [isDeletedShown, setDeletedShown] = useState(true);
    let [bannerUrl, setBannerUrl] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    let [iconLoading, setIconLoading] = useState("");

    useEffect(() => {
        // getAllEvents()
        getAllClass()
    }, [])

    const getAllClass = async () => {

        setIsLoading(true)

        try {
            let courseList = await courseModel.getAll();

            if (cookie.userType === 'instructor') {
                const myClass = await courseInstructorModel.getByInstructorId();

                console.log('myClass', myClass)

                const classListTemp = [];

                myClass.forEach((classObj) => {
                    courseList.forEach((classToCheck) => {
                        console.log(classToCheck)
                        if (classObj.course_id === classToCheck.id) {
                            classListTemp.push(classToCheck)
                        }
                    })
                })

                courseList = classListTemp;
            }

            setCourses(courseList)

            setIsLoading(false);

            const coursesWithoutDeletedTemp = [];

            courseList.forEach(course => {
                if (course.active) {
                    coursesWithoutDeletedTemp.push(course)
                }
            })

            setCoursesWithoutDeleted(coursesWithoutDeletedTemp);

            console.log('courseList', courseList)
            // const classList = await classModel.getAll();
            //
            // const classesWithoutDeletedTemp = [];
            //
            // const archiveAll = []
            // const archiveNoDeleted = []
            //
            // const upcomingAll = []
            // const upcomingNoDeleted = []
            //
            // classList.forEach(classObj => {
            //     if (classObj.active) classesWithoutDeletedTemp.push(classObj)
            // })
            //
            // for (let classObj of classList) {
            //
            //     classObj.acceptedCount = classObj.metrics?.accepted
            //     classObj.waitingCount = classObj.metrics?.waiting
            //
            //     if (classObj.permanent) {
            //         if (classObj.active) {
            //             upcomingNoDeleted.push(classObj)
            //         }
            //         upcomingAll.push(classObj)
            //     }
            //
            //     if (new Date(classObj.start_time) > new Date()) {
            //         if (classObj.active) {
            //             upcomingNoDeleted.push(classObj)
            //         }
            //         upcomingAll.push(classObj)
            //     } else {
            //         if (classObj.active) archiveNoDeleted.push(classObj)
            //         archiveAll.push(classObj)
            //     }
            //
            // }
            //
            // let upcomingAllReversed = upcomingAll.reverse()
            // let upcomingNDReversed = upcomingNoDeleted.reverse()
            //
            // setUpcomingClass(upcomingAllReversed)
            // setUpcomingClassWithoutDeleted(upcomingNDReversed)
            //
            // console.log(archiveAll)
            //
            // setArchiveClass(archiveAll)
            // setArchiveClassWithoutDeleted(archiveNoDeleted)
            // setIsLoading(false)

        } catch (e) {
            console.log(e)
            setIsLoading(false)
        }

    }

    const interactions = [
        {
            name: "Kelola",
            action: (obj) => {
                if(cookie.userType === 'admin' || cookie.userType === 'superadmin') {
                    history.push("/editor/privateClassDetail/" + obj.id)
                } else {
                    history.push("/editor/privateClassVisitors/" + obj.id)
                }

            },
            style: {width: 135},
            condition: (classObj) => {
                return true
                // return classObj.active
            },
        },
        {
            name: "Sembunyikan",
            action: (obj) => {
                setSelectedClass(obj)
                setDelete(true)
            },
            variant: 'secondary',
            style: {width: 135},
            condition: (classObj) => {
                return classObj.active && (cookie.userType === 'admin' || cookie.userType === 'superadmin')
            },
        },
        {
            name: "Pulihkan",
            action: (obj) => {
                setSelectedClass(obj)
                toggleDelete(obj)
            },
            variant: 'info',
            style: {width: 135},
            condition: (topic) => {
                return !topic.active && (cookie.userType === 'admin' || cookie.userType === 'superadmin')
            },
        },
        {
            name: "Hapus",
            action: (obj) => {
                setHardDelete(true)
                setSelectedClass(obj)
            },
            variant: 'secondary',
            style: {width: 135},
            condition: () => {
                return cookie.userType === 'admin' || cookie.userType === 'superadmin'
            },
        },
    ];

    let columns = [
        {
            Header: "Nama",
            accessor: "title",
            Cell: (cellInfo) => {
                return <p>{cellInfo.row.values.title} {cellInfo.row.original.active ? null : <span style={{
                    backgroundColor: Palette.SECONDARY,
                    color: 'white',
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 20,
                    marginLeft: 5,
                    fontFamily: 'OpenSans-SemiBold',
                    fontSize: '.9em'
                }}>Disembunyikan</span>}</p>
            }
        },
        {
            Header: "Harga",
            accessor: "price",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                return <p>Rp{textFormatter.moneyFormatter(rowInfo.price)}</p>
                // return null;
            }
        },
        {
            Header: "Daftar",
            accessor: "waiting",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                return <p>{rowInfo.accepted?.length + rowInfo.waiting?.length}</p>
            }
        },
        {
            Header: "Bayar",
            accessor: "accepted",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                // console.log("byr", rowInfo)

                return <p>{rowInfo.accepted?.length}</p>
            }
        },
        // {
        //     Header: "Pemasukan",
        //     accessor: "metrics",
        //     Cell: (cellInfo) => {
        //
        //         let rowInfo = cellInfo.row.values
        //
        //         let total = rowInfo.price * rowInfo.metrics?.accepted
        //
        //         return <p>Rp{textFormatter.moneyFormatter(total)}</p>
        //     }
        // }

    ];

    const undeleteClass = async (classObj) => {
        // try {
        //     const result = await classModel.undelete(classObj.code);
        //
        //     console.log(result)
        //
        //     if (result.success) {
        //         getAllClass()
        //
        //         alert('Kelas ' + classObj.name + ' berhasil dipulihkan!')
        //     }
        // } catch (e) {
        //     if (e.msg && typeof e.msg === 'string') {
        //         alert(e.msg);
        //     } else {
        //         alert('Kesalahan terjadi');
        //     }
        // }
    }

    const toggleDelete = async (obj) => {
        try {
            const result = await courseModel.toggle(obj.id ? obj.id : selectedClass.id);

            console.log(result)

            if (result.success) {
                if (obj.title) {
                    alert('Kelas ' + obj.title + ' berhasil terpulihkan!')
                } else {
                    alert('Kelas ' + selectedClass.title + ' berhasil disembunyikan!')
                }


                getAllClass()

                setDelete(false);
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const toggleHardDelete = async (obj) => {
        try {
            const result = await courseModel.hardDelete(obj.id ? obj.id : selectedClass.id);

            console.log(result)

            if (result.success) {
                alert('Kelas ' + selectedClass.title + ' berhasil terhapus!')

                getAllClass()

                setHardDelete(false);
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const onBannerPicked = async function (image) {
        console.log(image)
        try {
            let result = await new Event().uploadBanner(image, 0)

            console.log(result)

            setBannerUrl(result.location)
        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            setIconLoading(false)

            console.log(e)
        }
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingFull={isLoading}
                activeTab={"private-class"}
            >
                <Dialog open={isDelete || isHardDelete}
                        maxWidth="sm"
                        fullWidth={true}
                >
                    <>
                        <DialogTitle onClose={() => this.onClose()}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi {isDelete ? 'Sembunyikan' : 'Hapus'} Kelas
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin {isDelete ? 'menyembunyikan' : 'menghapus'} kelas <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedClass.title}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDelete(false)
                                        setHardDelete(false)
                                    }}
                                    style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                    onClick={isDelete ? toggleDelete : toggleHardDelete}
                                    variant={"contained"}
                                    color="secondary">
                                    {isDelete ? 'Sembunyikan' : 'Hapus'}
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <>

                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                        <div style={{
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            marginRight: 25
                        }}>
                            Daftar Kelas Daring Mandiri
                        </div>

                        <Row style={{marginLeft: 2, marginTop: 15}}>
                            <Button variant={'info'}
                                    onClick={() => setDeletedClassShown(!isDeletedClassShown)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.8em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        // marginTop: 4,
                                        width: 300,
                                        color: 'white', marginRight: 5
                                    }}
                            >
                                {isDeletedClassShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                                    <IoMdEye style={{marginRight: 10}}/>}
                                {isDeletedClassShown ? 'Jangan Tampilkan Kelas Tersembunyi' : 'Tampilkan Tersembunyi'}
                            </Button>

                            {cookie.userType === 'admin' &&
                            <Button variant={'outline-primary'}
                                    onMouseEnter={() => setBtnHovered(true)}
                                    onMouseLeave={() => setBtnHovered(false)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.8em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                        width: 140,
                                        marginLeft: 5
                                    }}
                                    onClick={() => history.push("/create-private-class")}
                            >
                                + <span style={{marginLeft: 6}}>Buat Kelas Baru</span>
                            </Button>
                            }
                        </Row>

                        <Row style={{marginLeft: 2, marginTop: 5}}>
                            <Button
                                onClick={() => {
                                    setBatchUpdateModalVisible(true)
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    width: 300,
                                    color: "white"
                                }}
                            >
                                Ubah Harga Kelas
                            </Button>
                        </Row>

                        {/*<CustomButton*/}
                        {/*    onClick={() => setAdminManagementModalVisible(true)}*/}
                        {/*    style={{marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold'}}*/}
                        {/*    variant={"outlined"}>+ Tambah Admin</CustomButton>*/}
                    </div>

                    {/*<div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>*/}
                    {/*    <div style={{*/}
                    {/*        marginTop: "1em",*/}
                    {/*        marginBottom: "0.5em",*/}
                    {/*        fontFamily: "OpenSans-Bold",*/}
                    {/*        flex: 1*/}
                    {/*    }}>Daftar Kelas</div>*/}

                    {/*    <CustomButton*/}
                    {/*        onClick={() => history.push("/create-event")}*/}
                    {/*        style={{marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold', fontWeight: '500'}}*/}
                    {/*        variant={"outlined"}>+ Buat Kelas Baru</CustomButton>*/}
                    {/*</div>*/}

                    {/*<b style={{*/}
                    {/*    marginTop: "1em",*/}
                    {/*    marginBottom: "0.5em",*/}
                    {/*    fontFamily: "Montserrat"*/}
                    {/*}}>Daftar Kelas</b>*/}

                    <br/>


                    {/*<Tabs defaultActiveKey="private-class">*/}
                    {/*    <Tab*/}
                    {/*        eventKey="private-class"*/}
                    {/*        title={"Kelas Mandiri"}*/}
                    {/*    >*/}
                    <div style={{
                        marginTop: 10
                    }}>
                        <MobTable
                            columns={columns}
                            data={isDeletedClassShown ? courses : coursesWithoutDeleted}
                            interactions={interactions}
                        />
                    </div>

                    <UpcomingEventEditPriceModal
                        privateClass
                        classes={courses}
                        isOpen={batchUpdateModalVisible}
                        onClose={(refresh) => {
                            setBatchUpdateModalVisible(false)
                            if (refresh) {
                                getAllClass()
                            }
                        }}
                    />
                    {/*    </Tab>*/}
                    {/*</Tabs>*/}

                </>
            </EditorPageWrapper>
        </>

    );

}
