import ApiRequest from "../util/ApiRequest";

export default class CourseRating {
    getByCourseId = async (courseId) => {
        return await ApiRequest.set(`/v1/course_rating/${courseId}/course`, "GET");
    }

    toggle = async (id) => {
        return await ApiRequest.set(`/v1/course_rating/${id}/toggle`, "PUT");
    }
}
