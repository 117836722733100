import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../../models/Member";
import UserParticipationModal from "../../reusable/modals/UserParticipationModal";
import EditMerchandiseModal from "../../reusable/EditMerchandiseModal";
import {CSVLink} from "react-csv";
import Notification from "../../../models/Notification";
import moment from "moment";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let notification;

export default function Profile(props) {
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const [notifications, setNotifications] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [isAddNotificationModalVisible, setAddNotificationModalVisible] = useState(false)

    const [selectedMerchandise, setSelectedMerchandise] = useState({});

    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [isDelete, setIsDelete] = useState(false)

    const [willSendNotification, setWillSendNotification] = useState({})

    const [errorMsg, setErrorMsg] = useState('');

    const [isSendingNotification, setIsSendingNotification] = useState(false);

    let columns = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Judul",
            accessor: "title"
        },
        {
            Header: "Konten",
            accessor: "body"
        },
        {
            Header: "Tautan",
            accessor: "data",
            Cell: cellInfo => {
                return (
                    <div>
                        {JSON.parse(cellInfo.value).url ? JSON.parse(cellInfo.value).url : '-'}
                    </div>
                )
            }
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: cellInfo => {
                return (
                    <div style={{fontWeight: '600', color: cellInfo.value === 'SUCCESS' ? 'green' : 'red'}}>
                        {cellInfo.value === 'SUCCESS' ? 'Terkirim' : 'Gagal Terkirim'}
                    </div>
                )
            }
        },
        {
            Header: "Dikirim Pada",
            accessor: "created_at",
            Cell: cellInfo => {
                return (
                    <>
                        {moment(cellInfo.value).format("DD MMM YYYY, HH:mm")}
                    </>
                )
            }
        }
    ]

    notification = new Notification();

    useEffect(() => {
        getNotificationData()
    }, [])

    const getNotificationData = async () => {
        setIsLoadingBar(true)
        try {
            let result = await notification.getAll()
            console.log('Notification List', result);

            setNotifications(result)

            setIsLoadingBar(false)


        } catch (e) {
            setIsLoadingBar(false)
            console.log(e)
        }
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"notification"}
            >
                <Dialog
                    open={isAddNotificationModalVisible}
                    maxWidth="lg"
                    fullWidth={true}
                >
                    <>
                        <DialogTitle onClose={() => {
                            setWillSendNotification({})
                            setAddNotificationModalVisible(false)
                        }}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    // fontWeight: "bold",
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: Palette.PRIMARY
                                }}>
                                    Tambah Notifikasi
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>
                        <DialogContent>
                            <Collapse
                                in={errorMsg.length > 0}
                                style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                            >
                                <Alert
                                    severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setErrorMsg("");
                                            }}
                                        >
                                            <FaTimes fontSize="inherit"/>
                                        </IconButton>
                                    }
                                >
                                    {errorMsg}
                                </Alert>
                            </Collapse>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                            }}
                            >
                                <Col md={12} style={{fontFamily: 'OpenSans-SemiBold'}}>
                                    <LagFreeTextEditor
                                        title={'Judul'}
                                        changeValue={value => setWillSendNotification({
                                            ...willSendNotification,
                                            title: value
                                        })}
                                        value={willSendNotification.title}
                                        placeholder={"Judul"}/>
                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <LagFreeTextEditor
                                        title={'Konten'}
                                        changeValue={value => setWillSendNotification({
                                            ...willSendNotification,
                                            body: value
                                        })}
                                        value={selectedMerchandise.body}
                                        placeholder={"Konten"}/>

                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <LagFreeTextEditor
                                        title={'Tautan (Opsional)'}
                                        changeValue={value => setUrl(value)}
                                        value={url}
                                        placeholder={"Tautan (Opsional)"}/>

                                </Col>
                            </Row>

                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '3em',
                                paddingBottom: '1em',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                            >
                                <CustomButton
                                    style={{
                                        borderWidth: 0,
                                        marginRight: 10,
                                        width: 120,
                                        fontFamily: 'OpenSans-SemiBold'
                                    }}
                                    onClick={() => {
                                        setUrl('')
                                        setWillSendNotification({})
                                        setAddNotificationModalVisible(false)
                                    }}
                                    variant={"outlined"} color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    disabled={isSendingNotification}
                                    onClick={async () => {
                                        if (!willSendNotification.title) {
                                            setErrorMsg('Harap isi judul!')
                                        } else if (!willSendNotification.body) {
                                            setErrorMsg('Harap isi konten!')
                                        } else {
                                            setIsSendingNotification(true)

                                            try {
                                                const result = await notification.create({
                                                    ...willSendNotification,
                                                    data: url ? JSON.stringify({url}) : '{}'
                                                })

                                                alert('Notifikasi berhasil ditambahkan!')
                                                setUrl('')
                                                setWillSendNotification({})
                                                setAddNotificationModalVisible(false)
                                                await getNotificationData()

                                                console.log('result', result)
                                            } catch (e) {
                                                setErrorMsg(JSON.stringify(e))
                                                console.log(e)
                                            }

                                            setIsSendingNotification(false)


                                        }
                                    }}
                                    style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"contained"} color="primary">
                                    <>Simpan&nbsp;&nbsp;<FaSave/></>
                                </CustomButton>
                            </Row>
                        </DialogContent>
                    </>
                </Dialog>

                <>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 0}}>
                        <div style={{
                            marginTop: "0",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Notifikasi
                        </div>
                    </div>


                    <Row style={{marginLeft: 0, marginTop: 20}}>
                        <Button variant={'outline-primary'}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 40,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                    width: 170,
                                    marginLeft: 0,
                                    marginRight: 15
                                }}
                                onClick={() => {
                                    setAddNotificationModalVisible(true)
                                }}
                        >
                            + <span style={{marginLeft: 6}}>Notifikasi Baru</span>
                        </Button>

                        {/*<CSVLink*/}
                        {/*    style={{color: 'white', fontFamily: 'OpenSans-SemiBold', fontSize: '.8em',  display: 'flex',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        justifyContent: 'center',}}*/}
                        {/*    separator={";"}*/}
                        {/*    data={getNewsletterRegistrant()}*/}
                        {/*    filename={`data-log.csv`}*/}
                        {/*    className="btn btn-primary"*/}
                        {/*    target="_blank"*/}
                        {/*>*/}
                        {/*    Download Data Log&nbsp;&nbsp;<FaFileDownload/>*/}
                        {/*</CSVLink>*/}
                    </Row>

                    <MobTable
                        columns={columns}
                        data={notifications}
                    />


                </>
            </EditorPageWrapper>
        </>
    );

}
