import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import { Button, Col, Row } from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, { useEffect, useState } from "react";
import Topic from "../../models/Topic";
import { FaSave, FaTimes } from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import MiniTooltip from "./MiniTooltip";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Class from "../../models/Class";
import CreatableSelect from "react-select/creatable/dist/react-select.esm"
import RichText from "../reusable/RichText/LimitedRichText"
export default function ManageQuestionModal(props) {
    const topicModel = new Topic();
    const [errorMsg, setErrorMsg] = useState('')

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState("");

    const [selectableParticipants, setSelectableParticipants] = useState([])

    const [question, setQuestion] = useState("")

    function isObjEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    useEffect(() => {
        getClassParticipant()
    }, [props.visible])

    const getClassParticipant = async () => {
        try {
            let classModel = new Class();
            let participants = await classModel.getClassParticipant(props.eventCode)
            console.log(participants)

            let formattedParticipants = participants.map((obj, key) => {
                return {
                    id: obj.id,
                    full_name: obj?.member?.full_name,
                    email: obj?.member?.email
                }
            })

            setUsers(formattedParticipants)

            let preparedSelectableParticipants = formattedParticipants.map((obj) => {
                return {
                    value: obj.id,
                    label: `${obj.email} | ${obj.full_name}`
                }
            })

            setSelectableParticipants(preparedSelectableParticipants)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Dialog open={props.visible}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle onClose={() => this.hide()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {isObjEmpty(props.question) || !props.question ? 'Tambah' : 'Sunting'} Pertanyaan
                    </Col>
                </Row>
                <hr />
            </DialogTitle>
            <DialogContent>
                <Collapse in={errorMsg.length > 0}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em',

                }}
                >

                    <Col style={{ fontFamily: 'OpenSans-SemiBold' }} xs={12}>Pertanyaan</Col>
                    <Col md={12}>
                        <RichText
                            onBlurAndSave={(value) => {
                                setQuestion(value)
                            }
                            } value={question} />
                        {/* <LagFreeTextEditor
                            title={'Pertanyaan'}
                            multiline={true}
                            rows={4}
                            changeValue={value => {
                                setQuestion(value)
                            }}
                            defaultValue={props.question ? props.question : null}
                            value={question}
                            placeholder={"Pertanyaan"}/> */}
                    </Col>
                </Row>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: "1em",
                    fontFamily: 'OpenSans-SemiBold',
                }}
                >
                    <Col md={12} style={{ fontSize: '.9em', display: 'flex' }}><span style={{ flex: 1 }}>Peserta</span>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12} sm={12} style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <CreatableSelect
                            fullwidth
                            formatCreateLabel={(inputValue) => {
                                return "Masukkan nama non-peserta: " + inputValue
                            }}
                            value={selectedUser}
                            onChange={(newValue) => {
                                setSelectedUser(newValue)
                            }}
                            options={selectableParticipants}
                        />
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>

                    </Col>
                </Row>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{ borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold' }}
                        onClick={props.hide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={async () => {
                            try {
                                let submitMethod = props.onSubmit
                                if (selectedUser.__isNew__) {
                                    console.log("Is new!")
                                    submitMethod = props.onSubmitNoParticipant
                                }
                                let result = await submitMethod(question, selectedUser.value)
                                setQuestion("")
                                props.hide()
                            } catch (e) {
                                setErrorMsg(e.msg ? JSON.stringify(e.msg) : "Terjadi Kesalahan")
                            }

                        }}
                        style={{ color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold' }}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave /></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}
