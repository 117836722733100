import React, {useMemo, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';

// import {ButtonGroup} from "react-bootstrap";
import Palette from "../../../util/Palette";
import CustomButton from "../CustomButton";
import moment from "moment";
import MobTable from "../Table/MobTable";
import {CSVLink} from "react-csv";
import {FaFileDownload} from "react-icons/fa";
import {Row} from "react-bootstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserListModal(props) {

    const {members, show, showClass} = props

    console.log('members', members)

    const onClose = () => {
        props.onClose()
    }

    let columns = useMemo(
        () => {
            if (showClass) {
                return [
                    {
                        Header: "ID",
                        accessor: "id"
                    },
                    {
                        Header: "Kelas",
                        accessor: "name",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.name ? rowInfo.name : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Surel",
                        accessor: "email"
                    },
                    {
                        Header: "Nama Lengkap",
                        accessor: "full_name"
                    },
                    {
                        Header: "Nomor Ponsel",
                        accessor: "phone_num",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.phone_num ? rowInfo.phone_num : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Organisasi",
                        accessor: "organization",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.organization ? rowInfo.organization : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Provinsi Tempat Tinggal",
                        accessor: "province",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.province ? rowInfo.province : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Kota Tempat Tinggal",
                        accessor: "city",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.city ? rowInfo.city : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Tanggal Lahir",
                        accessor: "date_of_birth",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.date_of_birth ? moment(rowInfo.date_of_birth).format('DD MMMM YYYY') : '-'}
                            </>
                        }
                    },
                    {
                        Header: "Jenis Kelamin",
                        accessor: "gender",
                        Cell: (cellInfo) => {
                            let rowInfo = cellInfo.row.values

                            return <>
                                {rowInfo.gender && rowInfo.gender === 'M' ? 'Pria' : rowInfo.gender && rowInfo.gender === 'F' ? 'Wanita' : '-'}
                            </>
                        }
                    }
                ]
            }


            return [
                {
                    Header: "ID",
                    accessor: "id"
                },
                {
                    Header: "Surel",
                    accessor: "email"
                },
                {
                    Header: "Nama Lengkap",
                    accessor: "full_name"
                },
                {
                    Header: "Nomor Ponsel",
                    accessor: "phone_num",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.phone_num ? rowInfo.phone_num : '-'}
                        </>
                    }
                },
                {
                    Header: "Organisasi",
                    accessor: "organization",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.organization ? rowInfo.organization : '-'}
                        </>
                    }
                },
                {
                    Header: "Provinsi Tempat Tinggal",
                    accessor: "province",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.province ? rowInfo.province : '-'}
                        </>
                    }
                },
                {
                    Header: "Kota Tempat Tinggal",
                    accessor: "city",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.city ? rowInfo.city : '-'}
                        </>
                    }
                },
                {
                    Header: "Tanggal Lahir",
                    accessor: "date_of_birth",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.date_of_birth ? moment(rowInfo.date_of_birth).format('DD MMMM YYYY') : '-'}
                        </>
                    }
                },
                {
                    Header: "Jenis Kelamin",
                    accessor: "gender",
                    Cell: (cellInfo) => {
                        let rowInfo = cellInfo.row.values

                        return <>
                            {rowInfo.gender && rowInfo.gender === 'M' ? 'Pria' : rowInfo.gender && rowInfo.gender === 'F' ? 'Wanita' : '-'}
                        </>
                    }
                }
            ]
        }
    )



    const renderModalFooter = () => {

        return <DialogActions>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <CustomButton
                color="primary"
                onClick={() => {
                    onClose()
                }}>
                Tutup
            </CustomButton>
        </DialogActions>
    }

    return (
        <Dialog
            open={show}
            maxWidth="md"
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle onClose={() => this.onClose()}>

                    <div style={{display: 'flex'}}>
                    <span style={{
                        fontWeight: "bold",
                        flex: 1
                    }}>Daftar Peserta</span>

                        {members.length > 0 &&
                        <CSVLink
                            style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                            separator={";"}
                            data={
                                members.map(member => {
                                    if(member.name) {
                                        return {
                                            ID: member.id,
                                            "Kelas": member.name,
                                            "Nama Lengkap": member.full_name,
                                            "Nomor Ponsel": member.phone_num,
                                            "Organisasi": member.organization,
                                            "Provinsi": member.province,
                                            "Kota": member.city,
                                            "Tanggal Lahir": member.date_of_birth ? new moment(member.date_of_birth).format("MM/DD/YYYY") : '',
                                            "Jenis Kelamin": member.gender,
                                        }
                                    }

                                    return {
                                        ID: member.id,
                                        "Surel": member.email,
                                        "Nama Lengkap": member.full_name,
                                        "Nomor Ponsel": member.phone_num,
                                        "Organisasi": member.organization,
                                        "Provinsi": member.province,
                                        "Kota": member.city,
                                        "Tanggal Lahir": member.date_of_birth ? new moment(member.date_of_birth).format("MM/DD/YYYY") : '',
                                        "Jenis Kelamin": member.gender,
                                    }
                                })
                            }
                            filename={`daftar-peserta.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            Unduh Semua Data&nbsp;&nbsp;<FaFileDownload/>
                        </CSVLink>
                        }
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div style={{}}>
                        <MobTable
                            columns={columns}
                            data={members}
                        />
                    </div>

                </DialogContent>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

