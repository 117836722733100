import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../../models/Member";
import UserParticipationModal from "../../reusable/modals/UserParticipationModal";
import UserInfoModal from "../../reusable/modals/UserInfoModal";
import EditUserModal from "../../reusable/EditUserModal";
import EditUserModalSingle from "../../reusable/EditUserModalSingle";
import {CSVLink} from "react-csv";
import ProvincesWithCities from "../../../models/ProvincesWithCities";
import BatchInvitationModal from "../../reusable/modals/BatchInvitationModal";
import BatchMemberRegisterModal from "../../reusable/modals/BatchMemberRegisterModal";
import Button from "react-bootstrap/Button";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let member;

export default function Profile(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)

    const [userList, setUserList] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [isClassModalVisible, setClassModalVisible] = useState(false)

    const [selectedUser, setSelectedUser] = useState({});

    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [isDelete, setIsDelete] = useState(false)
    const [newsLetterDialog, setNewsLetterDialog] = useState(false)
    const [activationNewsLetter, setActivationNewsLetter] = useState(false)

    const [provinces, setProvinces] = useState([]);

    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [showInviteDialogSingle, setShowInviteDialogSingle] = useState(false);

    let columns = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Surel",
            accessor: "email"
        },
        {
            Header: "Nama Lengkap",
            accessor: "full_name"
        },
        {
            Header: "Nawala",
            accessor: "subscribe_newsletter",
            Cell : (cellInfo)=>{

                let rowInfo = cellInfo.row.values
                let subscribeNewsletter = rowInfo["subscribe_newsletter"]

                // console.log(memberData)

                return <>{subscribeNewsletter? "Ya" : "Tidak"}</>


            }
        },
        {
            Header: "Bisa Diaktivasi",
            accessor: "is_claimed",
            Cell : (cellInfo)=>{

                let rowInfo = cellInfo.row.values
                let isClaimed = rowInfo["is_claimed"]

                // console.log(memberData)

                return <>{isClaimed? "Ya" : "Tidak"}</>


            }
        },
        {
            Header: "Status Aktivasi",
            accessor: "activated",
            Cell : (cellInfo)=>{

                let rowInfo = cellInfo.row.values
                let activated = rowInfo["activated"]

                // console.log(memberData)

                return <>{activated? "Ya" : "Tidak"}</>


            }
        }
    ]

    member = new Member();

    const interactions = [
        {
            name: "Sunting",
            action: (registrant) => {
                setSelectedUserInfo(registrant)
                setUserInfoModalVisible(true)
                if(provinces.length === 0) getProvinces()
            },
            style: {width: 100}
        },
        {
            name: "Kelas",
            action: (registrant) => {
                setClassModalVisible(true)
                setSelectedUser(registrant)
            },
            style: {width: 100}
        },
        {
            name: "Hapus",
            action: (member) => {
                setSelectedUser(member)
                setIsDelete(true)
            },
            style: {width: 100}
        },
        {
            name: "Aktivasi",
            action: (member) => {
               activateUser(member.id)
            },
            condition: (member) => {
                return !member.activated && member.is_claimed
            },
            style: {width: 100},

        },
        {
            name: "Aktifkan Nawala",
            action: (member) => {
                setActivationNewsLetter(true);
                setNewsLetterDialog(true);
                setSelectedUser(member)
            },
            condition: (member) => {
                let subscribeNewsletter = member.subscribe_newsletter
                return !subscribeNewsletter
            },
            style: {width: 200},

        },
        {
            name: "Nonaktifkan Nawala",
            action: (member) => {
                setActivationNewsLetter(false);
                setNewsLetterDialog(true);
                setSelectedUser(member)
            },
            condition: (member) => {
                let subscribeNewsletter = member.subscribe_newsletter
                return subscribeNewsletter
            },
            style: {width: 200},

        }
    ];


    const deletedUserInteraction = [
        {
            name: "Pulihkan",
            action: (member) => {
                unDeleteUser(member.id)
            },
            style: {width: 100}
        }
    ];

    const getProvinces = async () => {
        const provincesWithCities = new ProvincesWithCities();

        const provinces = await provincesWithCities.getProvinces();

        setProvinces(provinces)
    }

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        setIsLoadingBar(true)
        try {
            let result = await member.getAll()

            let tempUsers = []
            let tempDeletedUsers = []

            for(let r of result){
                if(r.active){
                    tempUsers.push(r)
                }else{
                    tempDeletedUsers.push(r)
                }
            }

            setUserList(tempUsers)
            setDeletedUsers(tempDeletedUsers)

            setIsLoadingBar(false)


        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const closeUserParticipationModal = () => {
        setClassModalVisible(false)
        setSelectedUser({})
    }

    const activateUser = async(id) =>{

        try{
            let member = new Member();
            let result = await member.activate(id)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const activateNawala = async() =>{

        try{
            let member = new Member();
            let result;
            if(activationNewsLetter){
                result = await member.activationNawala(selectedUser.id)
            }else{
                result = await member.inactiveNawala(selectedUser.id)
            }

            getUserData()
            setNewsLetterDialog(false)
            console.log(result)
        }catch(e){
            console.log(e)
        }

    }

    const deleteUser = async() =>{

        try{
            let member = new Member();
            let result = await member.delete(selectedUser.id)

            setIsDelete(false)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const unDeleteUser = async(id) =>{

        try{
            let member = new Member();
            let result = await member.unDelete(id)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const getNewsletterRegistrant = () =>{
        let filteredRegistrant = userList.filter((obj)=>{return obj.subscribe_newsletter && obj.active})
        return filteredRegistrant.map((obj, key) => {
            return {
                "Email" : obj.email,
                "FullName" : obj.full_name
            }
        })
    }

    const getUsersInfo = () =>userList.map((obj, key) => {
            return {
                "id": obj.id,
                "Email" : obj.email,
                "Nawala": obj.subscribe_newsletter,
                "FullName" : obj.full_name,
                "phone_num":obj.phone_num,
                "organization":obj.organization,
                "province": obj.province,
                "city": obj.city,
                "gender": obj.gender,
                "date_of_birth": obj.date_of_birth,
                "active": obj.active

            }
        })


    return (
        <>


            <Dialog open={newsLetterDialog}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi {activationNewsLetter ? "Aktifkan" : "Nonaktifkan"} nawala Peserta
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin {activationNewsLetter ? "Mengaktifkan" : "Menonaktifkan"} nawala <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedUser.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setNewsLetterDialog(false)
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={activateNawala}
                                variant={"contained"}
                                color="secondary">
                                {activationNewsLetter ? "Aktifkan" : "Nonaktifkan"}
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <Dialog open={isDelete}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Peserta
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus peserta <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedUser.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setIsDelete(false)
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={deleteUser}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditUserModal
                isOpen={isUserInfoModalVisible}
                provinces={provinces}
                onClose={() => {
                    setUserInfoModalVisible(false)
                    setSelectedUserInfo({})
                    getUserData()
                }}
                user={selectedUserInfo}
            />

            <EditUserModalSingle
                isOpen={showInviteDialogSingle}
                provinces={provinces}
                onClose={() => {
                    setShowInviteDialogSingle(false)
                }}
            />

            <UserParticipationModal
                isOpen={isClassModalVisible}
                onClose={closeUserParticipationModal}
                user={selectedUser}
            />

            {/*<UserInfoModal*/}
            {/*    show={isUserInfoModalVisible}*/}
            {/*    onClose={() => setUserInfoModalVisible(false)}*/}
            {/*    member={selectedUserInfo}*/}
            {/*/>*/}

            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"users"}
            >
                <>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>
                        <div style={{
                            marginTop: "1em",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Pengguna
                        </div>

                        <CustomButton
                            disabled
                            style={{
                                opacity: 0, marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold'
                            }}
                            variant={"outlined"}>+ Buat Kelas Baru</CustomButton>
                    </div>


                    <Tabs defaultActiveKey="active">
                        <Tab
                            eventKey="active"
                            title={
                                "Aktif"
                            }
                        >
                            <div style={{
                                display : "flex",
                                flexDirection : "row",
                                alignItems :"center",
                                marginTop : 10
                            }}>
                                <CSVLink
                                    style={{color: 'white'}}
                                    separator={";"}
                                    data={getNewsletterRegistrant()}
                                    filename={`email-peserta.csv`}
                                    className="btn btn-primary"
                                    target="_blank"
                                >
                                    Download Data Untuk Surel&nbsp;&nbsp;<FaFileDownload/>
                                </CSVLink>

                                <div style={{
                                display : "flex",
                                flexDirection : "row",
                                alignItems :"center",
                                marginLeft : 10
                            }}></div>

                                <CSVLink
                                    style={{color: 'white'}}
                                    separator={";"}
                                    data={getUsersInfo()}
                                    filename={`seluruh-pengguna.csv`}
                                    className="btn btn-primary"
                                    target="_blank"
                                >
                                    Download Data Untuk Seluruh Pengguna&nbsp;&nbsp;<FaFileDownload/>
                                </CSVLink>

                                <Button
                                    style={{
                                        color : "white",
                                        height : "100%",
                                        marginLeft : 10,
                                    }}
                                    onClick={()=>setShowUploadDialog(true)}>Undang Banyak Pengguna</Button>

                                <Button
                                    style={{
                                        color : "white",
                                        height : "100%",
                                        marginLeft : 10,
                                    }}
                                    onClick={()=>setShowInviteDialogSingle(true)}>Undang Satu Pengguna</Button>

                            </div>

                            <MobTable
                                columns={columns}
                                data={userList}
                                interactions={interactions}
                            />
                        </Tab>
                        <Tab
                            eventKey="deleted"
                            title={
                                "Terhapus"
                            }
                        >
                            <MobTable
                                columns={columns}
                                data={deletedUsers}
                                interactions={deletedUserInteraction}
                            />
                        </Tab>
                    </Tabs>



                </>
            </EditorPageWrapper>

            <BatchMemberRegisterModal
                open={showUploadDialog}
                onClose={(refresh) => {
                    if (refresh) {
                        getUserData()
                    }
                    setShowUploadDialog(false)
                }}
            />

        </>
    );

}
