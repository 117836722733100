import {ButtonGroup, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Col, Container, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, {useState} from "react";
import Topic from "../../models/Topic";
import {FaSave, FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import MiniTooltip from "./MiniTooltip";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Voucher from "../../models/Voucher";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function CreateVoucherModal(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }

    const VOUCHER_TYPE = {
        SINGLE_USE: 'SINGLE_USE',
        PERMANENT: 'PERMANENT'
    }

    const voucherModel = new Voucher();

    const [errorMsg, setErrorMsg] = useState('')
    const [voucher, setVoucher] = useState({count: 1, type: VOUCHER_TYPE.SINGLE_USE});
    const [discountType, setDiscountType] = useState(DISCOUNT_TYPE.PERCENT)
    const addNewTopic = async () => {
        setErrorMsg('');

        if ((isNaN(voucher.amount) && discountType === DISCOUNT_TYPE.AMOUNT) || (isNaN(voucher.percentage) && discountType === DISCOUNT_TYPE.PERCENT)) return setErrorMsg('Harap mengisi angka di kolom besaran diskon');
        if (isNaN(voucher.count))
            if (voucher.amount <= 0) return setErrorMsg('Besaran diskon tidak boleh 0 atau negatif');
        if ((voucher.percentage > 100 || voucher.percentage <= 0) && discountType === DISCOUNT_TYPE.PERCENT) return setErrorMsg('Harap mengisi angka diantara 1 - 100 di kolom diskon');
        if (!voucher.name) return setErrorMsg('Harap mengisi nama voucher');
        if (voucher.count <= 0) return setErrorMsg('Jumlah voucer tidak boleh 0 atau negatif');

        try {
            const result = await voucherModel.createBatch(voucher);

            if (result.name === voucher.name) {
                props.hide();
                alert('Voucher baru berhasil ditambahkan!')
            }
            console.log(result)
        } catch (e) {
            console.log('error', e);

            if (e.msg && typeof e.msg === 'string') {
                return setErrorMsg(e.msg);
            } else {
                return setErrorMsg('Kesalahan terjadi');
            }
        }
    }

    return (
        <Dialog open={props.visible}
                maxWidth="md"
                fullWidth={true}
        >
            <DialogTitle onClose={() => this.onClose()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Buat Voucer
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Collapse in={errorMsg.length > 0}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit"/>
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                {/*<Row style={{*/}
                {/*    marginTop: '1.5em',*/}
                {/*    paddingInlineStart: "2%",*/}
                {/*    paddingInlineEnd: "2%",*/}
                {/*}}*/}
                {/*>*/}
                {/*    <Col md={12}>*/}
                {/*        <LagFreeTextEditor*/}
                {/*            title={'Kode'}*/}
                {/*            changeValue={value => {*/}
                {/*                setVoucher({*/}
                {/*                    ...voucher,*/}
                {/*                    code: value*/}
                {/*                })*/}
                {/*            }}*/}
                {/*            value={voucher.code}*/}
                {/*            subtitle={'(akan dibuat secara acak jika dibiarkan kosong)'}*/}
                {/*            placeholder={"Kode"}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Nama'}
                            changeValue={value => {
                                setVoucher({
                                    ...voucher,
                                    name: value
                                })
                            }}
                            value={voucher.name}
                            placeholder={"Nama"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>

                        <LagFreeTextEditor
                            title={'Kode'}
                            changeValue={value => {

                                let temp = {
                                    ...voucher,
                                    code: value
                                }

                                if(value !== ""){
                                    temp.count = 1
                                }

                                setVoucher(temp)
                            }}
                            value={voucher.code}
                            placeholder={"Kode"}/>

                    </Col>
                    <Col md={12}>
                        <small>Kode akan dibuat secara otomatis jika tidak diisi, maksimal 20 Huruf</small>
                    </Col>
                </Row>


                <Row style={{
                    marginTop: "1em",
                    fontSize: "0.9em",
                    marginBottom: "0.5em",
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Jenis Potongan</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{
                    marginInlineStart: "2%",
                    marginInlineEnd: "2%"
                }}>
                    <ButtonGroup
                        style={{
                            marginLeft: "0.5em"
                        }}
                        color="primary" aria-label="outlined primary button group">

                        <CustomButton
                            onClick={() => {
                                setVoucher({
                                    ...voucher,
                                    amount: null
                                })
                                setDiscountType(DISCOUNT_TYPE.PERCENT)
                            }}
                            style={{
                                fontFamily: "OpenSans-SemiBold",
                                marginTop: 5
                            }}
                            variant={discountType === DISCOUNT_TYPE.PERCENT ? "contained" : "outlined"}
                        >Persen</CustomButton>

                        <CustomButton
                            onClick={() => {
                                setVoucher({
                                    ...voucher,
                                    percentage: null
                                })
                                setDiscountType(DISCOUNT_TYPE.AMOUNT)
                            }}
                            style={{
                                fontFamily: "OpenSans-SemiBold",
                                marginTop: 5
                            }}
                            variant={discountType === DISCOUNT_TYPE.AMOUNT ? "contained" : "outlined"}
                        >Rupiah</CustomButton>

                    </ButtonGroup>

                </Row>

                <Row style={{
                    marginTop: "1em",
                    fontSize: "0.9em",
                    marginBottom: "0.5em",
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Tipe</span>
                        </div>
                    </Col>
                </Row>
                <Row style={{
                    marginInlineStart: "2%",
                    marginInlineEnd: "2%"
                }}>
                    <ButtonGroup
                        style={{
                            marginLeft: "0.5em"
                        }}
                        color="primary" aria-label="outlined primary button group">

                        <CustomButton
                            onClick={() => {
                                setVoucher({
                                    ...voucher,
                                    type: VOUCHER_TYPE.SINGLE_USE
                                })
                            }}
                            style={{
                                fontFamily: "OpenSans-SemiBold",
                                marginTop: 5
                            }}
                            variant={voucher.type === VOUCHER_TYPE.SINGLE_USE ? "contained" : "outlined"}
                        >Sekali Pakai</CustomButton>

                        <CustomButton
                            onClick={() => {
                                setVoucher({
                                    ...voucher,
                                    type: VOUCHER_TYPE.PERMANENT
                                })
                            }}
                            style={{
                                fontFamily: "OpenSans-SemiBold",
                                marginTop: 5
                            }}
                            variant={voucher.type === VOUCHER_TYPE.PERMANENT ? "contained" : "outlined"}
                        >Permanen</CustomButton>

                    </ButtonGroup>

                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Besaran Diskon'}
                            changeValue={value => {
                                if (discountType === DISCOUNT_TYPE.PERCENT) {
                                    setVoucher({
                                        ...voucher,
                                        percentage: value
                                    })
                                } else {
                                    setVoucher({
                                        ...voucher,
                                        amount: value
                                    })
                                }
                            }}
                            value={voucher.percentage}
                            placeholder={"Besaran Diskon"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            InputProps={{
                                style: {
                                    fontSize: "1em",
                                    fontFamily: "Montserrat",
                                    cursor: "pointer"
                                },
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <span
                                        style={{
                                            fontFamily: "Montserrat",
                                            color: "grey"
                                        }}>Rp.</span>
                                    </InputAdornment>
                                ),

                            }}
                            title={'Minimum Pemakaian'}
                            changeValue={value => {

                                setVoucher({
                                    ...voucher,
                                    minimum_usage: value
                                })

                            }}
                            value={voucher.minimum_usage}
                            placeholder={"0"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            disabled={voucher.code}
                            title={'Jumlah Voucer'}
                            subtitle={"(Hanya bisa 1 jika kode voucer terisi)"}
                            defaultValue={1}
                            changeValue={value => {
                                setVoucher({
                                    ...voucher,
                                    count: value
                                })
                            }}
                            value={voucher.count}
                            placeholder={"Jumlah Voucer"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Catatan'}
                            defaultValue={""}
                            changeValue={value => {
                                setVoucher({
                                    ...voucher,
                                    note: value
                                })
                            }}
                            value={voucher.note}
                            placeholder={"Catatan"}/>
                    </Col>
                </Row>


                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={props.hide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={addNewTopic}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}
