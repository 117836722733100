import EditorPageWrapper from "./editor/EditorPageWrapper";
import React, {useEffect, useMemo, useState, useRef} from "react";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes, FaUserPlus} from "react-icons/all";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import MobTable from "../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../models/Member";

import UserParticipationModal from "../reusable/modals/UserParticipationModal";
import User from "../../models/User";
import ParticipationModel from "../../models/Participation";
import moment from "moment";
import {useHistory} from "react-router-dom";
import UserInfoModal from "../reusable/modals/UserInfoModal";
import RegistrantInspectorDialog from "./editor/UserManagement/specifics/ReigstrantInspectorDialog";
import {CSVLink} from "react-csv";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ProvincesWithCities from "../../models/ProvincesWithCities";
import {AiOutlineClose} from "react-icons/ai";
import _ from "lodash";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let member;
let allParticipantsFetchDONE = false;

export default function Participation(props) {

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)

    const [allParticipants, setallParticipants] = useState([])
    const [userList, setUserList] = useState([])
    const [acceptedUserList, setAcceptedUserList] = useState([]);

    const [isClassModalVisible, setClassModalVisible] = useState(false)

    const [selectedUser, setSelectedUser] = useState({});
    const [filter, setFilter] = useState({});

    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [activeRegistrant, setActiveRegistrant] = useState({})
    const [registrantInspectorOpen, setRegistrantInspectorOpen] = useState(false)

    const [provinces, setProvinces] = useState([]);

    const [cities, setCities] = useState([]);

    const [monthSelection, setMonthSelection] = useState([])
    const [selectedMonthYear, setSelectedMonthYear] = useState(moment().format("YYYYM"))

    const [filteredUserList, setFilteredUserList] = useState([]);

    const allParticipantCSVDownloadButton = useRef()

    useEffect(() => {
        const filteredUsers = [];

        userList.forEach((user) => {
            if (filter.city) {
                if (filter.city === user.member?.city) {
                    filteredUsers.push(user);
                }
            } else if (filter.province) {
                if (filter.province === user.member?.province) {
                    filteredUsers.push(user);
                }
            }
        });

        if (filteredUsers.length > 0) {
            filteredUsers.map((obj) => {
                return {
                    ...obj,
                    class_name: obj.class.name,
                    email: obj.member?.email
                }
            })
        }

        // setUserList(filteredUsers);
        setFilteredUserList(filteredUsers)
        //console.log(userList)
    }, [filter])

    const getCitiesByProvince = async (province) => {
        const provincesWithCities = new ProvincesWithCities();

        const cities = await provincesWithCities.getCitiesByProvince(province);

        setCities(cities)
    }

    const getProvinces = async () => {
        const provincesWithCities = new ProvincesWithCities();

        const provinces = await provincesWithCities.getProvinces();

        setProvinces(provinces)
    }

    let columns = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Surel",
            accessor: "email",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values
                let email = rowInfo["email"]

                // console.log(memberData)

                return <>{email}</>

            }
        },
        {
            Header: "Nama Sesi",
            accessor: "class_name",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values
                let classData = rowInfo.class_name
                console.log(classData);
                // console.log(memberData)

                return <>{classData}</>

            }
        },
        {
            Header: "Tanggal",
            accessor: "registered_at",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values
                let classData = rowInfo.registered_at

                return <>{moment(classData).format("DD MMM YYYY HH:mm")}</>
            }
        },
        {
            Header: "Berbayar/Undangan",
            accessor: "order",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values
                let classData = rowInfo.registered_at

                console.log("rinf", rowInfo.order)

                return <>{(rowInfo?.order?.order_id && rowInfo?.order?.status_change_info !== "Invited, status changed from WAITING to ACCEPTED") ? "Berbayar" : "Undangan"}</>
            }
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: (cellInfo) => {

                let rowInfo = cellInfo.row.values

                let statusString = "MENUNGGU PEMBAYARAN"

                // console.log("STATUS " + rowInfo.status)
                //console.log(cellInfo)
                let color = "#000000"

                if (rowInfo.status === "ACCEPTED") {

                    color = "LimeGreen"
                    statusString = "DITERIMA"


                } else if (rowInfo.status === "REJECTED") {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (rowInfo.status === "WAITING") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                } else if (rowInfo.status === "PENDING") {
                    color = "DarkOrange"
                    statusString = "DALAM ANTREAN"

                }
                return <p style={{color: color}}>{statusString}</p>

            }
        }
    ]

    member = new Member();

    const interactions = [
        {
            name: "Lihat Pengguna",
            action: async (registrant) => {
                console.log(registrant)
                // let participation = new ParticipationModel()
                //
                // let result = await participation.getOne(registrant.id)
                //
                // let formattedRegistrant = {
                //     ...registrant,
                //     ...result
                // }

                setActiveRegistrant(registrant)
                setRegistrantInspectorOpen(true)

            },
            style: {width: 100}
        },
        {
            name: "Lihat Kelas",
            action: (registrant) => {

                //    console.log(registrant)

                history.push("/editor/visitors/" + registrant?.class?.code)

                setClassModalVisible(true)
                setSelectedUser(registrant)
            },
            style: {width: 100}
        }
    ];

    useEffect(() => {
        getProvinces()
        generateMonthSelection()
    }, [])

    useEffect(() => {
        getParticipations()
    }, [selectedMonthYear])

    const generateMonthSelection = async () => {

        let temp = 0;

        let iteratedDate = new moment()

        const monthYearBottomLimit = {
            month: 9,
            year: 2020
        }

        let months = []

        let currentMonthYear = {
            month: moment().format("M"),
            year: moment().format("YYYY"),
            label: iteratedDate.format("MMM YYYY"),
        }

        while (iteratedDate.isAfter("2020-09-01")) {
            months.push({
                label: iteratedDate.format("MMM YYYY"),
                month: iteratedDate.format("M"),
                year: iteratedDate.format("YYYY"),
                value: iteratedDate.format("YYYYM")
            })
            iteratedDate.subtract(1, "month")
        }

        setMonthSelection(months)
        console.log("months", months)

    }

    const getParticipations = async () => {
        setIsLoadingBar(true)
        try {
            let month, year;

            let findResult = monthSelection.find(obj => {
                return obj.value === selectedMonthYear
            })

            if (findResult) {
                month = findResult.month
                year = findResult.year
            }

            console.log("months", month, year)

            let result = await member.getParticipationClassCourseByMonthAndYear(month, year)

            console.log(result);
            let formattedResult = result.map((obj, key) => {
                return {
                    ...obj,
                    class_name: obj.class.name,
                    email: obj.member?.email
                }
            })

            let sortedResult = _.orderBy(formattedResult, ["registered_at"], ["desc"])

            console.log('obj a', formattedResult);

            setUserList(sortedResult)

            const acceptedUserTemp = [];
            formattedResult.map(user => {
                if (user.status === 'ACCEPTED') acceptedUserTemp.push(user)
            })

            setAcceptedUserList(acceptedUserTemp.reverse())
            setIsLoadingBar(false)

        } catch (e) {
            setIsLoadingBar(false)
            console.log(e)
        }
    }

    const getAllParticipations = async () => {

        if (allParticipantsFetchDONE) return

        try {
            setIsIconLoading(true)
            let all = await member.getAllParticipationClassCourse()
            allParticipantsFetchDONE = true

            setIsIconLoading(false)

            all = all.map((user, key) => {

                return {
                    timestamp: user.accepted_at ? new moment(user.accepted_at).format("MM/DD/YYYY HH:mm:ss") : '',
                    "Nama Lengkap": user.member.full_name,
                    "Organisasi": user.member.organization,
                    "Surel": user.member.email,
                    "Nomor Ponsel": user.member.phone_num ? `${user.member.phone_num}` : "",
                    "Tanggal Lahir": new moment(user.member.date_of_birth).year() !== 1970 ? new moment(user.member.date_of_birth).format("MM/DD/YYYY HH:mm:ss") : "-",
                    "Topik Pelatihan": user.class.name,
                    "Sumber Informasi": user.class.source,
                    "Kota": user.member.city,
                    "Nawala": user.member.subscribe_newsletter,
                    "Bukti Bayar": "",
                    "Kuitansi": "",
                    "#Daftar": user.id,
                    "Tanggal Daftar": new moment(user.registered_at).format("MM/DD/YYYY HH:mm:ss"),
                    "Biaya": user.class.price
                }

            })

            setallParticipants(all)
            setTimeout(() => {
                allParticipantCSVDownloadButton.current.link.click()
            })

        } catch (e) {
            setIsIconLoading(false)
            console.log(e)
        }
    }

    const closeUserParticipationModal = () => {
        setClassModalVisible(false)
        setSelectedUser({})
    }

    return (
        <>

            <RegistrantInspectorDialog show={registrantInspectorOpen}
                                       registrant={activeRegistrant}
                                       handleClose={() => setRegistrantInspectorOpen(false)}
                                       activeSession={activeRegistrant["class"] ? activeRegistrant["class"] : {}}/>

            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"participation"}
            >
                <>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{
                            marginTop: "1em",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Partisipasi
                        </div>

                    </div>

                    {isLoadingBar ? null :
                        <Row
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                            <CSVLink
                                style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                                separator={";"}
                                data={
                                    acceptedUserList.map((user, key) => {
                                        // if (user.status === 'ACCEPTED') {
                                        return {
                                            timestamp: user.accepted_at ? new moment(user.accepted_at).format("MM/DD/YYYY HH:mm:ss") : '',
                                            "Nama Lengkap": user.member.full_name,
                                            "Organisasi": user.member.organization,
                                            "Surel": user.member.email,
                                            "Nomor Ponsel": user.member.phone_num ? `${user.member.phone_num}` : "",
                                            "Tanggal Lahir": new moment(user.member.date_of_birth).year() !== 1970 ? new moment(user.member.date_of_birth).format("MM/DD/YYYY HH:mm:ss") : "-",
                                            "Topik Pelatihan": user.class.name,
                                            "Sumber Informasi": user.class.source,
                                            "Kota": user.member.city,
                                            "Nawala": user.member.subscribe_newsletter,
                                            "Bukti Bayar": "",
                                            "Kuitansi": "",
                                            "Undangan atau Berbayar": user.order ? "Berbayar" : "Undangan" ,
                                            "#Daftar": user.id,
                                            "Tanggal Daftar": new moment(user.registered_at).format("MM/DD/YYYY HH:mm:ss"),
                                            "Biaya": user.class.price,
                                        }
                                        // }
                                    })
                                }
                                filename={`laporan-partisipasi.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Download Laporan&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                            {/*{*/}
                            {/*    iconLoading ? <Spinner*/}
                            {/*            style={{fontSize: "1em", display: true ? "inline" : "hidden"}}*/}
                            {/*            animation="border"/>*/}
                            {/*        :*/}

                            {/*        <Button*/}
                            {/*            style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}*/}
                            {/*            onClick={getAllParticipations}*/}
                            {/*        >*/}
                            {/*            Download Seluruh Laporan&nbsp;&nbsp;<FaFileDownload/>*/}
                            {/*        </Button>*/}


                            {/*}*/}


                        </Row>
                    }

                    {userList.length > 0 &&
                    <>
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em',
                        }}
                        >
                            <Col md={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                        Filter Provinsi
                                    </div>

                                    <Select
                                        style={{marginLeft: 10, marginTop: 6}}
                                        value={filter.province ? filter.province : 0}
                                        onChange={e => {
                                            setFilter({
                                                province: e.target.value,
                                                city: ''
                                            })

                                            getCitiesByProvince(e.target.value)
                                            // setCorrectAnswer(e.target.value)
                                        }}>
                                        <MenuItem value={0} key={-1}>
                                            Pilih Provinsi
                                        </MenuItem>
                                        {provinces.map((option, key) => (
                                            <MenuItem value={option} key={key}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                {filter.province &&
                                <div style={{width: 30, height: 30, marginLeft: 5, marginBottom: 10}}>
                                    <IconButton aria-label="Close" onClick={e => {
                                        setFilter({
                                            province: '',
                                        })
                                    }}>
                                        <AiOutlineClose size={20}/>
                                    </IconButton>
                                </div>
                                }


                            </Col>
                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em',
                        }}
                        >
                            <Col md={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                        Filter Kota
                                    </div>

                                    <Select
                                        style={{marginLeft: 10, marginTop: 6}}
                                        value={filter.city ? filter.city : 0}
                                        onChange={e => {
                                            setFilter({
                                                ...filter,
                                                city: e.target.value,
                                            })
                                        }}>
                                        <MenuItem value={0} key={-1}>
                                            Pilih Kota
                                        </MenuItem>
                                        {cities.map((option, key) => (
                                            <MenuItem value={option} key={key}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>

                                {filter.city &&
                                <div style={{width: 30, height: 30, marginLeft: 5, marginBottom: 10}}>
                                    <IconButton aria-label="Close" onClick={e => {
                                        setFilter({
                                            ...filter,
                                            city: '',
                                        })
                                    }}>>
                                        <AiOutlineClose size={20}/>
                                    </IconButton>
                                </div>
                                }
                            </Col>
                        </Row>
                    </>
                    }

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginBottom: '3em',
                        marginTop: '1em'
                    }}
                    >
                        <Col md={12}>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                                    Filter Bulan / Tahun
                                </div>

                                <Select
                                    style={{marginLeft: 10, marginTop: 6}}
                                    value={selectedMonthYear}
                                    onChange={e => {
                                        console.log("months", e.target.value)
                                        setSelectedMonthYear(e.target.value)
                                    }}>
                                    {monthSelection.map((option, key) => (
                                        <MenuItem value={option.value} key={key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                    </Row>


                    <MobTable
                        columns={columns}
                        data={filter.city || filter.province ? filteredUserList : userList}
                        interactions={interactions}
                    />
                </>
            </EditorPageWrapper>
        </>
    );

}
