import React, {useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';

import Event from '../../../models/Event'
// import {ButtonGroup} from "react-bootstrap";
import Palette from "../../../util/Palette";
import CustomButton from "../CustomButton";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EventSummaryModal(props) {

    const {show, event, session, onSubmit} = props

    const onClose = () => {
        props.onClose()
    }

    const renderModalFooter = () => {

        return <DialogActions>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <CustomButton
                color="primary"
                onClick={() => {
                    onClose()
                }}>
                Batal
            </CustomButton>
            <CustomButton
                color="primary"
                onClick={() => {
                    onSubmit()
                }}>
                Buka Pendaftaran
            </CustomButton>
        </DialogActions>
    }

    const summaryPoints = [
        {
            heading: true,
            label : "Informasi Umum"
        },
        {
            data: event.name,
            label: "Nama Event",
        },
        {
            data: "narabahasa.id/daftar/" + event.event_url,
            label: "URL Pendaftaran *"
        },
        {
            data: event.short_description,
            label: "Deskripsi Singkat"
        },
        {
            data: event.description,
            label: "Deskripsi Lengkap",
        },
        {
            heading: true,
            breakpoint : true,
            label : "Waktu Event"
        },
        {
            data: moment(session.startDate).format("DD MMM YYYY"),
            label: "Tanggal Event"
        },
        {
            data: moment(session.startDate).format("HH:mm"),
            label: "Waktu Mulai *"
        },
        {
            data: moment(session.endTime).format("HH:mm"),
            label: "Waktu Selesai *"
        },
        {
            heading: true,
            breakpoint : true,
            label : "Informasi Pendaftaran * "
        },
        {
            label: "Harga Tiket *",
            data: session.price === 0 ? "Gratis" : "Rp" + session.price
        },
        {
            label: "Kuota *",
            data: (session.price === 0 ? "Tidak ada kuota" : session.quota) + " orang"
        },
    ]

    return (
        <Dialog
            open={show}
            maxWidth="md"
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle onClose={() => this.onClose()}>
                    <span style={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold"
                    }}>Ringkasan Event</span>
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        fontFamily: "Poppins"
                    }}>
                        <p>
                            Harap mengkonfirmasi data event mu. Data yang ditandai (*) tidak dapat diubah / hanya bisa diubah dengan kondisi tertentu.
                        </p>

                        <table style={{width : "100%"}}>

                            {
                                summaryPoints.map((obj, key) => {
                                    return <tr>
                                        <td style={{
                                            width : "25%", verticalAlign : "top",
                                            paddingTop  : obj.breakpoint ? "2em" : "0.5em",
                                        }} colSpan={obj.heading ? 2 : 1}>
                                            <b style={{
                                                fontFamily: obj.heading ? "Montserrat" : "Nunito Sans",
                                                fontSize : obj.heading ? "1em" : null,
                                                color : obj.heading ? Palette.PRIMARY : "black"
                                            }}>{obj.label}</b><br/>
                                        </td>
                                        <td style={{
                                            width : "75%", verticalAlign : "top",
                                            paddingTop  : obj.breakpoint ? "2em" : "0.5em"
                                        }} colSpan={obj.heading ? 0 :1}>
                                            {obj.data}
                                        </td>
                                    </tr>
                                })
                            }

                        </table>
                    </div>

                </DialogContent>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

