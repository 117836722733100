import React from "react";

import {withStyles} from "@material-ui/core/styles";

import {DialogTitle, DialogContent, DialogActions} from "./Dialog"

import {Dialog, Grid} from "@material-ui/core";
import ReactCrop from "react-image-crop";
import {Button} from "react-bootstrap";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
});

class ImageCropperDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageUrl : "",
            imageBlob : "",
            crop : {
                unit:"%",
                width:100,
                height:100
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.src != this.props.src){
            console.log("thissss", this.props.src)

            // this.setState({
            //     crop : this.props.crop
            // })
        }
    }

    onCropComplete(crop){
        try{
            let result = this.makeClientCrop(crop);
        }catch(e){
            console.log("error is ",e)
        }
        // console.log("this is ", result)
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;

                console.log("process is done")
                // window.URL.revokeObjectURL(this.fileUrl);
                // this.fileUrl = window.URL.createObjectURL(blob);

                this.setState({
                    imageBlob: blob,
                    imageUrl: URL.createObjectURL(blob),
                })

            }, 'image/png');
        });
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            try {
                const croppedImageUrl = await this.getCroppedImg(
                    this.imageRef,
                    crop,
                    'cropped.png'
                );
            } catch (e) {
                console.log("found ya" , e)
            }

        }
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });

        console.log(percentCrop)

        this.setState({
            crop : crop
        })
    };


    onImageLoaded = image => {
        this.imageRef = image;
    };

    render() {

        const classes = this.props.classes

        return <Dialog
            open={this.props.isOpen}
            maxWidth="xl"
            fullWidth={true}>
            <DialogTitle onClose={this.props.onClose}>
               Potong Gambar
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} style={{
                    display: "flex",
                    flexDirection: "column",
                    height : "90vh"
                }}>
                    <ReactCrop
                        src={require("../../asset/logo-instagram.png")}
                        // src={this.props.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        style={{
                            overflow: false,
                            flex: 1
                        }}
                        imageStyle={{
                            objectFit: 'contain',
                            width: "100%"
                        }}
                        onComplete={crop=>this.onCropComplete(crop)}
                        onChange={this.onCropChange}
                        onImageLoaded={this.onImageLoaded}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={this.props.onNegativeButtonPressed}>
                    Batal
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={true}
                    onClick={()=>this.props.onSubmit()}>
                    Lanjutkan
                </Button>
            </DialogActions>
        </Dialog>
    }
}

export default ImageCropperDialog;
