import ApiRequest from "../util/ApiRequest";

export default class ProvincesWithCities {
    getProvinces = async () => {
        return await ApiRequest.set(`/v1/provinces`, "GET");
    }

    getCitiesByProvince = async (province) => {
        return await ApiRequest.set(`/v1/${province}/cities`, "GET");
    }
}
