import ApiRequest from "../util/ApiRequest";

export default class Strings {
    get = async () => {
        return await ApiRequest.set(`/v1/strings`, "GET");
    }

    update = async (body) => {
        return await ApiRequest.set(`/v1/strings/1`, "PUT", body);
    }
}
