import {ButtonGroup, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core"
import PropTypes from 'prop-types'
import {Col, Container, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, {useState, useEffect} from "react";
import {FaSave, FaTimes} from "react-icons/fa";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Voucher from "../../models/Voucher";


export default function NoteComplaintModal(props) {

    NoteComplaintModal.propTypes = {
        complaint: PropTypes.object.isRequired,
        visible: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired
    }

    const [currentComplaint, setCurrentComplaint] = useState({})

    useEffect(() =>{
        setCurrentComplaint({...props.complaint})
    }, [props.complaint])


    return (
        <Dialog open={props.visible}
                maxWidth="md"
                fullWidth={true}
        >
            <DialogTitle onClose={() => props.onHide()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Ubah Catatan
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>


                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Catatan'}
                            changeValue={value => {
                                setCurrentComplaint({
                                    ...currentComplaint,
                                    note: value
                                })
                            }}
                            value={currentComplaint.note}
                            placeholder={"Catatan"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={props.onHide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={() => {props.onSave(currentComplaint)}}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}
