import React, {useEffect, useRef, useState} from "react";
import Class from "../../models/Class";
import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FiAlignJustify, FiClock, FiInfo, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import {BsMic} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import {AiFillTag, AiOutlineTag} from "react-icons/ai";
import Palette from "../../util/Palette";
import SimpleLineIcon from "react-simple-line-icons";
import Dialog from '@material-ui/core/Dialog';
import Form from "react-bootstrap/Form";
import Rating from "react-rating";
import {FaStar, FaFileDownload} from "react-icons/fa";
import {IoMdStar} from "react-icons/io";
import moment from "moment";
import FeedbackCard from "../reusable/FeedbackCard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import GlobalData from "../../util/GlobalData";
import {CookiesProvider, useCookies} from 'react-cookie';
import {isMobile} from "react-device-detect";
import textFormatter from "../../util/textFormatter";
import Strings from "../../util/Strings";
import FeedbackModal from "../reusable/FeedbackModal";
import QnASection from "../reusable/QnASection";
import {Helmet} from "react-helmet";
import {useHistory, useLocation} from "react-router-dom";
import EditorPageWrapper from "./editor/EditorPageWrapper";
import PreviewComponent from "./PreviewComponent";

var momentTz = require('moment-timezone');

const BUTTON_STATUS = {
    REGISTER: 'REGISTER',
    REGISTERED: 'REGISTERED',
    BUY_TICKET: 'BUY_TICKET',
    REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',
    PAY: 'PAY',
    IN_QUEUE: 'IN_QUEUE',
    OUT_OF_QUOTA: 'OUT_OF_QUOTA'
}

const WATCH_STATUS = {
    AVAILABLE_AFTER_LOGIN: 'AVAILABLE_AFTER_LOGIN',
    AVAILABLE_AFTER_REGISTER: 'AVAILABLE_AFTER_REGISTER',
    WATCH: 'WATCH',
    TICKET_REQUIRED: 'TICKET_REQUIRED',
    COUNTDOWN: 'COUNTDOWN',
}

export default function PreviewPage(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }

    const waitingStatusRef = useRef(null);

    const classModel = new Class();
    const [loginShow, setLoginShow] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [customOnHide, setCustomOnHide] = useState(null);
    const [session, setSession] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [buttonStatus, setButtonStatus] = useState('REGISTERED');
    const [watchStatus, setWatchStatus] = useState('WATCH');
    const [isButtonHovered, setButtonHovered] = useState(false);
    const [isCloseHovered, setCloseHovered] = useState(false);
    const [isImagePreview, setImagePreview] = useState(false)
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [user, setUser] = useState({...GlobalData.user})
    const [isCheckoutModalShown, setCheckoutModalShown] = useState(false);
    const [isRegistrationOpen, setRegistrationOpen] = useState(false);

    const [isFeedbackModalShown, setFeedbackModalShown] = useState(false);
    const [needFeedback, setNeedFeedback] = useState(false)
    const [isFeedbackExtended, setFeedbackExtended] = useState(false)

    const [isWaitingStatusHovered, setWaitingStatusHovered] = useState(false);

    const [cookie, setCookie, removeCookie] = useCookies()
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)

    const [voucherCode, setVoucherCode] = useState("")
    const [voucherMessage, setVoucherMessage] = useState("")
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [feedbacks, setFeedbacks] = useState([]);
    const [discountType, setDiscountType] = useState(null);
    const [noOfFeedback, setNoOfFeedback] = useState(0);

    const [completeProfileModalMessage, setCompleteProfileModalMessage] = useState("")
    const [isCompleteProfileModalShown, setCompleteProfileModalShown] = useState(false)

    const [isJoiningClass, setJoiningClass] = useState(false)
    const history = useHistory()


    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        if (props.match.params.event_url) {
            getSessionByCode()
        }


        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, [])

    const [averageRating, setAverageRating] = useState(0)

    const [count5, setCount5] = useState(0)
    const [count4, setCount4] = useState(0)
    const [count3, setCount3] = useState(0)
    const [count2, setCount2] = useState(0)
    const [count1, setCount1] = useState(0)

    const getSessionByCode = async () => {
        try {
            const sessionObj = await classModel.getByCode(props.match.params.event_url);

            const isRegistrationOpen = sessionObj.status === 'REGISTRATION';

            sessionObj.classStatus = sessionObj.status

            // console.log("status " + sessionObj.classStatus)

            if (sessionObj.participant_info) {

                if (sessionObj.classStatus === "ENDED") {
                    // console.log(sessionObj.participant_info)
                    if (sessionObj.participant_info.participant_feedback_id === null) {
                        setFeedbackModalShown(true)
                        setNeedFeedback(true)
                    } else {
                        setNeedFeedback(false)
                    }
                }

                setRegistrationOpen(true)
                sessionObj.participant_id = sessionObj.participant_info.id

                sessionObj.status = sessionObj.participant_info.status;


            } else {
                sessionObj.status = null;
                setRegistrationOpen(isRegistrationOpen)
            }

            // console.log("session", sessionObj)
            setSession(sessionObj);

        } catch (e) {
            console.log(e)
        }
    }


    return (
        <EditorPageWrapper>
            <Helmet>
                <title>{session.name ? session.name : "Kelas Daring Narabahasa"}</title>
                <meta name="description" content={`Kelas Daring Narabahasa ${session.name ? session.name : ''}`}/>

            </Helmet>

            <PreviewComponent
                session={session}
                onBack={()=>{
                    history.push(`/editor/classDetail/${props.match.params.event_url}`)
                }}
            />
        </EditorPageWrapper>
    )

}
