import ApiRequest from "../util/ApiRequest";

export default class CourseSubChapter {
    insert = async (courseSubChapter) => {
        return await ApiRequest.set("/v1/course_sub_chapter", "POST", courseSubChapter);
    }

    update = async (id, courseSubChapter) => {
        return await ApiRequest.set(`/v1/course_sub_chapter/${id}`, "PUT", courseSubChapter);
    }

    delete = async (id,) => {
        return await ApiRequest.set(`/v1/course_sub_chapter/${id}`, "DELETE");
    }

    getByCourseChapterId = async (id) => {
        return await ApiRequest.set(`/v1/course_sub_chapter/${id}`, "GET");
    }
}
