import ApiRequest from "../util/ApiRequest";

export default class Course {
    insert = async (course) => {
        return await ApiRequest.set("/v1/course", "POST", course);
    }

    update = async (id, course) => {
        delete course.memberCourses
        return await ApiRequest.set(`/v1/course/${id}`, "PUT", course);
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/course", "GET");
    }

    toggle = async (id) => {
        return await ApiRequest.set(`/v1/course/${id}/toggle`, "PUT");
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/course/${id}`, "GET");
    }

    updateCourseImage = async (id, image) =>{

        let formData = new FormData();

        formData.append('upload', image, image.name);

        if(id){
            return await ApiRequest.setMultipart(`/v1/course/${id}/upload_image`, "POST", formData);
        }else{
            return await ApiRequest.setMultipart(`/v1/course/upload_image`, "POST", formData);
        }
    }

    hardDelete = async (id) => {
        return await ApiRequest.set(`/v1/course/${id}`, "DELETE");
    }

    uploadVideo = async(file, setProgress) =>{

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        return await ApiRequest.setMultipartWithProgress(`/v1/course/uploadVideo`, "POST", formData, setProgress);

    }

    updatePriceBatch = async (ids, price) => {
        return await ApiRequest.set(`/v1/course/update_batch_price`, "POST",{
            ids : ids,
            price : price
        });
    }
}
