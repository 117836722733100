import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {Col, Row} from "react-bootstrap";
import PresenceForm from "../../../models/PresenceForm";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import {FaFileDownload} from "react-icons/fa";
import {CSVLink} from "react-csv";
import moment from "moment";
import Select from "react-select"
import _ from "lodash"

export default function Presence(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState({label: "Semua Kelas", value: null});
    const [presenceData, setPresenceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "Kelas",
                accessor: "class_name",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {rowInfo.class_name.split()}
                    </>
                }
            },
            {
                Header: "Nama Lengkap",
                accessor: "full_name"
            },
            {
                Header: "Surel",
                accessor: "email"
            },
            {
                Header: "Nomor Telepon",
                accessor: "phone_num",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {rowInfo.phone_num ? rowInfo.phone_num : '-'}
                    </>
                }
            },
            {
                Header: "Waktu Presensi",
                accessor: "created_at",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm")}
                    </>
                }
            },
        ]
    )

    const getData = async () => {
        const presenceFormModel = new PresenceForm();
        const classesTemp = [];

        try {
            const result = await presenceFormModel.getAll();

            console.log('presence data', result)

            setPresenceData(result)
            setFilteredData(result)

            let filteredClass = _.uniqBy(result, (obj)=>obj.class_name)

            console.log(filteredClass)

            let classesTemp = filteredClass.map(presenceData => {
                return {label: presenceData.class_name, value: presenceData.class_name}
            })

            setClasses([{label: "Semua Kelas", value: null}, ...classesTemp])

        } catch (e) {
            console.log('getAll presence err', e)
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const filteredClassTemp = [];

        if (selectedClass === null) {
            return setFilteredData(presenceData)
        }

        presenceData.map(aClass => {
            console.log('aClass', aClass)

            if (aClass.class_name === selectedClass) filteredClassTemp.push(aClass)
        })

        setFilteredData(filteredClassTemp)

    }, [selectedClass])

    return (
        <EditorPageWrapper
            activeTab={"presence"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <>

                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25,
                        marginBottom: 30
                    }}>
                        Presensi
                    </div>

                    <Select
                        onChange={(session) => {
                            setSelectedClass(session.value)
                        }}
                        placeholder={"Pilih Kelas"}
                        options={classes}
                        value={selectedClass}
                        style={{
                            display: "flex", width: "100%"
                        }}
                    />
                    {/*<Select value={selectedClass}*/}
                    {/*        style={{marginBottom: 30}}*/}
                    {/*        onChange={e => {*/}
                    {/*            console.log(e.target.value)*/}
                    {/*            setSelectedClass(e.target.value)*/}
                    {/*            // setCorrectAnswer(e.target.value)*/}
                    {/*        }}>*/}
                    {/*    <MenuItem value={0} key={-1}>*/}
                    {/*        Pilih Kelas*/}
                    {/*    </MenuItem>*/}

                    {/*    {classes.map((option, key) => (*/}
                    {/*        <MenuItem value={option} key={key}>*/}
                    {/*            {option}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}


                    <>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop :5}}>
                            {filteredData.length > 0 &&
                            <CSVLink
                                style={{color: 'white', marginBottom: 30}}
                                separator={";"}
                                data={
                                    filteredData.map((datum, key) => {
                                        return {
                                            email: datum.email,
                                            nama: datum.full_name,
                                            nomor_ponsel: datum.phone_num
                                        }
                                    })
                                    // feedbacks.map((user, key) => {
                                    //
                                    //     return {
                                    //         "Nama Lengkap": user.full_name,
                                    //         "Surel": user.email,
                                    //         "IPK": user.ipk,
                                    //         "Mekanisme Jelas": user.mekanisme_jelas,
                                    //         "Pendaftaran Mudah": user.pendaftaran_mudah,
                                    //         "Pengajar Interaktif": user.pengajar_interaktif,
                                    //         "Pengajar Jelas": user.pengajar_jelas,
                                    //         "Topik Dipahami": user.topik_dipahami,
                                    //         "Topik Sesuai": user.topik_sesuai,
                                    //         "Rata-rata bintang": user.average,
                                    //         "Kelas yang diinginkan": user.kelas_yang_diinginkan,
                                    //         "Masukan": user.masukan,
                                    //     }
                                    // })
                                }
                                filename={`data-presensi.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Unduh File CSV&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                            }
                        </div>

                        <MobTable columns={columns}
                                  data={filteredData}
                        />
                    </>
                </div>
            </>

        </EditorPageWrapper>
    )
}
