import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import {Button, Col, Row} from "react-bootstrap";
import DocumentFormModel from "../../../models/DocumentForm";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import {FaCheckSquare, FaFileDownload, FaSave} from "react-icons/fa";
import {CSVLink} from "react-csv";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import apiConfig from "../../../util/apiConfig";
import GlobalData from "../../../util/GlobalData";
import Cookies from "js-cookie";
import Checkbox from "@material-ui/core/Checkbox";
import {Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import textFormatter from "../../../util/textFormatter";
import CustomButton from "../../reusable/CustomButton";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";

export default function DocumentForm(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState({label: "Semua Kelas", value: null});
    const [documentForms, setDocumentForms] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [allDocumentSelected, setAllDocumentSelected] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [queuedFile, setQueuedFile] = useState(null)
    const [selectedDocument, setSelectedDocument] = useState({});
    const [isEditDocumentDialogShown, setEditDocumentDialogShown] = useState(false);
    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    useEffect(() => {
        if (allDocumentSelected) {
            setSelectedIds(documentForms.map(document => document.id))
        } else {
            setSelectedIds([])
        }
        console.log('documentForms', documentForms)
    }, [allDocumentSelected])

    const columns = useMemo(
        () => [
            {
                Header: <></>,
                filterable: false,
                accessor: "id",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    selectedIds.includes(rowInfo.id)

                    return <>
                        <Checkbox
                            checked={selectedIds.includes(rowInfo.id)}
                            onChange={() => {
                                const selectedIdsTemp = [...selectedIds];

                                if (selectedIds.includes(rowInfo.id)) {
                                    selectedIds.forEach((id, idx) => {
                                        if (id === rowInfo.id) selectedIdsTemp.splice(idx, 1)
                                    })

                                } else {
                                    selectedIdsTemp.push(rowInfo.id)
                                }

                                setSelectedIds(selectedIdsTemp)
                            }}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </>
                }
            },
            {
                Header: "Kelas",
                accessor: "class_name",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    //console.log(rowInfo)

                    return <>
                        {rowInfo.class_name.split()}
                    </>
                }
            },
            {
                Header: "Nama Lengkap",
                accessor: "full_name"
            },
            {
                Header: "Surel",
                accessor: "email"
            },
            {
                Header: "Divisi",
                accessor: "division"
            },
            {
                Header: "Jabatan",
                accessor: "rank"
            },
            {
                Header: "Jenis Dokumen",
                accessor: "document_type"
            },
            {
                Header: "Waktu Unggah",
                accessor: "created_at",
                Cell: (cellInfo => {
                    let rowInfo = cellInfo.row.values

                    // console.log(rowInfo.created_at)

                    return <>
                        {new moment(rowInfo.created_at).format("DD MMM YYYY HH:mm")}
                    </>
                })
            },
            {
                Header: "",
                accessor: "document_url",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    // console.log(rowInfo)

                    return <>
                        <Button variant={'primary'}
                                onClick={() => {
                                    setSelectedDocument(rowInfo)
                                    setEditDocumentDialogShown(true);
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.65em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    width: 150,
                                    marginRight: 5,
                                    marginBottom: 5,
                                    color: 'white'
                                }}
                        >
                            Sunting
                        </Button>

                        <a href={rowInfo.document_url} download={true}>
                            <Button variant={'outline-primary'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.65em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        width: 150,
                                        marginRight: 5
                                    }}
                            >
                                Unduh Dokumen
                            </Button>
                        </a>
                    </>
                }
            },
        ]
    )

    const getData = async () => {
        const documentFormModel = new DocumentFormModel();
        const classesTemp = [];

        try {
            const result = await documentFormModel.getAll();

            const orderedResult = _.orderBy(result, ["created_at", "desc"])

            setDocumentForms(orderedResult)
            setFilteredData(orderedResult)

            let filteredClass = _.uniqBy(orderedResult, (obj) => obj.class_name)

            console.log(filteredClass)

            let classesTemp = filteredClass.map(presenceData => {
                return {label: presenceData.class_name, value: presenceData.class_name}
            })

            setClasses([{label: "Semua Kelas", value: null}, ...classesTemp])

        } catch (e) {
            console.log('getAll presence err', e)
        }
    }

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function downloadDocuments(all) {
        let ids = null;

        if (all) ids = JSON.stringify(documentForms.map(document => document.id))
        else ids = JSON.stringify(selectedIds)

        let token = GlobalData.token ? GlobalData.token : Cookies.get("token") ? Cookies.get("token") : null

        setIsLoadingBar(true)

        fetch(`${apiConfig.base_url}/v1/document_forms/attachment?ids=${ids}`, {
            method: 'GET',
            headers: {
                Authorization: token ? `bearer ${token}` : null,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                setIsLoadingBar(false)
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `dokumen.zip`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).catch((e) => {
            setIsLoadingBar(false)
            alert("error")
            console.error(e)
        });
    }

    const updateDocument = async () => {
        const documentFormModel = new DocumentFormModel();

        try {
            const result = await documentFormModel.update(selectedDocument.id, selectedDocument);

            if (result.id) {
                getData()

                showSnackBar('Dokumen berhasil diperbarui!')

                setEditDocumentDialogShown(false)

            }
            console.log('updateDocument res', result)
        } catch (e) {
            console.log('updateDocument err', e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const filteredClassTemp = [];

        if (selectedClass.value === null) {
            return setFilteredData(documentForms)
        }

        documentForms.map(aClass => {
            console.log('aClass', aClass)

            if (aClass.class_name === selectedClass.value) filteredClassTemp.push(aClass)
        })

        setFilteredData(filteredClassTemp)
    }, [selectedClass])

    return (
        <EditorPageWrapper
            activeTab={"document-form"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={snackBarProp.open} autoHideDuration={6000}
                onClose={() => {
                    setSnackBarProp({
                        open: false
                    })
                }}>
                <Alert severity={snackBarProp.severity}>
                    {snackBarProp.message}
                </Alert>
            </Snackbar>

            <Dialog
                open={isEditDocumentDialogShown}
                maxWidth="md"
                fullWidth={true}
                onClose={() => {
                    setEditDocumentDialogShown(false)
                    setSelectedDocument({})
                }}
            >
                <>
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Sunting Dokumen
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent>
                        <LagFreeTextEditor
                            title={'Kelas'}
                            style={{marginBottom: '1em'}}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.class_name}
                            placeholder={"Kelas"}
                            disabled
                            changeValue={value => {

                            }}

                        />

                        <LagFreeTextEditor
                            style={{marginBottom: '1em'}}
                            title={'Nama Lengkap'}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.full_name}
                            placeholder={"Nama Lengkap"}
                            disabled
                            changeValue={value => {

                            }}

                        />

                        <LagFreeTextEditor
                            title={'Surel'}
                            style={{marginBottom: '1em'}}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.email}
                            placeholder={"Surel"}
                            disabled
                            changeValue={value => {

                            }}

                        />

                        <LagFreeTextEditor
                            title={'Divisi'}
                            style={{marginBottom: '1em'}}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.division}
                            placeholder={"Divisi"}
                            changeValue={value => {
                                const selectedDocumentTemp = {...selectedDocument};

                                selectedDocumentTemp.division = value;

                                setSelectedDocument(selectedDocumentTemp);
                            }}

                        />

                        <LagFreeTextEditor
                            title={'Jenis Dokumen'}
                            style={{marginBottom: '1em'}}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.document_type}
                            placeholder={"Jenis Dokumen"}
                            changeValue={value => {
                                const selectedDocumentTemp = {...selectedDocument};

                                selectedDocumentTemp.document_type = value;

                                setSelectedDocument(selectedDocumentTemp);
                            }}

                        />

                        <LagFreeTextEditor
                            title={'Jabatan'}
                            style={{marginBottom: '1em'}}
                            fullWidth
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            value={selectedDocument.rank}
                            placeholder={"Jabatan"}
                            changeValue={value => {
                                const selectedDocumentTemp = {...selectedDocument};

                                selectedDocumentTemp.rank = value;

                                setSelectedDocument(selectedDocumentTemp);
                            }}

                        />

                        <div style={{marginTop: 30, marginBottom: 15, display: 'flex', justifyContent: 'flex-end'}}>
                            <CustomButton
                                onClick={() => {
                                    setEditDocumentDialogShown(false)
                                    setSelectedDocument({})
                                }}
                                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"outlined"} color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                onClick={updateDocument}
                                style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"contained"} color="primary">
                                <>Simpan&nbsp;&nbsp;<FaSave/></>
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <>
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25,
                        marginBottom: 30
                    }}>
                        Formulir Dokumen
                    </div>

                    <Select
                        onChange={(session) => {
                            setSelectedClass(session)
                        }}
                        value={selectedClass}
                        placeholder={"Pilih Kelas"}
                        options={classes}
                        style={{
                            display: "flex", width: "100%"
                        }}
                    />

                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 5, marginBottom: 30}}>
                        {filteredData.length > 0 &&
                        <>
                            {selectedIds.length > 0 && <Button
                                onClick={() => downloadDocuments()}
                                style={{color: "white", marginRight: 10}}>Unduh Berkas Terpilih&nbsp;&nbsp;
                                <FaCheckSquare/></Button>
                            }

                            <CSVLink
                                style={{color: 'white',}}
                                separator={";"}
                                data={
                                    filteredData.map((datum, key) => {
                                        return {
                                            timestamp: datum.created_at,
                                            "Nama Lengkap": datum.full_name,
                                            "Divisi": datum.division,
                                            "Jabatan": datum.rank,
                                            "Berkas Unggahan": datum.document_url,
                                            "Kelas": datum.class_name
                                        }
                                    })
                                    // feedbacks.map((user, key) => {
                                    //
                                    //     return {
                                    //         "Nama Lengkap": user.full_name,
                                    //         "Surel": user.email,
                                    //         "IPK": user.ipk,
                                    //         "Mekanisme Jelas": user.mekanisme_jelas,
                                    //         "Pendaftaran Mudah": user.pendaftaran_mudah,
                                    //         "Pengajar Interaktif": user.pengajar_interaktif,
                                    //         "Pengajar Jelas": user.pengajar_jelas,
                                    //         "Topik Dipahami": user.topik_dipahami,
                                    //         "Topik Sesuai": user.topik_sesuai,
                                    //         "Rata-rata bintang": user.average,
                                    //         "Kelas yang diinginkan": user.kelas_yang_diinginkan,
                                    //         "Masukan": user.masukan,
                                    //     }
                                    // })
                                }
                                filename={`data-dokumen.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Unduh File CSV&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                        </>
                        }

                    </div>

                    {selectedClass !== 0 &&
                    <MobTable
                        columns={columns}
                        data={filteredData}
                    />
                    }
                </div>
            </>

        </EditorPageWrapper>
    )
}
