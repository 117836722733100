import React, {useEffect, useMemo, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import MobTable from "../Table/MobTable";
import Member from "../../../models/Member";
import textFormatter from "../../../util/textFormatter";
import moment from "moment";
import Palette from "../../../util/Palette";

const member = new Member();

export default function UserParticipationModal(props) {

    const {isOpen, onClose, user} = props

    const [participation, setParticipation] = useState([])
    const [classes, setClasses] = useState([])

    useEffect(() => {
        console.log("woi", user)
        if(props.user && props.user.id){
            getMemberParticipationData()
        }
    }, [props.user])

    const classColumns = useMemo(
        () => [
            {
                Header: "Nama Kelas",
                accessor: "class",
                Cell : (cellInfo => {

                    let rowInfo = cellInfo.row.values
                    let classData = rowInfo["class"]

                    return <>
                        {classData?.name}
                    </>
                })
            },
            {
                Header: "Tanggal & Waktu",
                accessor: "start_time",
                Cell : (cellInfo)=>{

                    let rowInfo = cellInfo.row.values
                    let classData = rowInfo["class"]

                    if(!classData){
                        return<></>
                    }

                    let date = classData.start_time ? classData.start_time : ""
                    return <>
                        {moment(date).format("dddd, DD MMMM YYYY")}
                    </>
                }
            },
            {
                Header: "Biaya",
                accessor: "price",
                Cell : (cellInfo)=>{

                    let rowInfo = cellInfo.row.values
                    let classData = rowInfo["class"]

                    let price  = classData?.price ? classData?.price : 0

                    return <>
                        Rp{textFormatter.moneyFormatter(price)}
                    </>
                }
            },
            {
                Header: "Status Pendaftaran",
                accessor: "status",
                Cell: (cellInfo) => {

                    let rowInfo = cellInfo.row.values

                    console.log(rowInfo)

                    let statusString = "MENUNGGU PEMBAYARAN"

                    //console.log(cellInfo)
                    let color = "#000000"

                    if (rowInfo.status === "ACCEPTED") {
                        color = "LimeGreen"
                        statusString = "DITERIMA"

                    } else if (rowInfo.status === "REJECTED") {
                        color = "Red"
                        statusString = "DITOLAK"

                    } else if (rowInfo.status === "WAITING") {
                        color = "MediumTurquoise"
                        statusString = "MENUNGGU KONFIRMASI"

                    }
                    return <p style={{color: color}}>{statusString}</p>
                }
            }
        ]
    )

    const getMemberParticipationData = async() =>{
        try{

            let result = await member.getMemberParticipation(props.user.id)
            setParticipation(result)

            let formattedResult = result.map(obj=>obj.class)
            setClasses(formattedResult)

            console.log(formattedResult)

        }catch(e){
            console.log(e)
        }

    }

    return <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth={true}
        onClose={() => onClose()}
    >
        <>
            <DialogTitle onClose={() => this.onClose()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Daftar Kelas
                    </Col>


                    <Col style={{
                        fontFamily: 'OpenSans-Regular',
                        fontSize: '.8em',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        color: '#b8b8b8',
                        display: 'flex',
                        flexDirection : "column",
                        textAlign : "right"
                    }}>
                        <b style={{color : "black"}}>{user.full_name}</b>
                        {user.email} <br/>
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                {
                    participation.length > 0 ?
                        <MobTable columns={classColumns} data={participation}/>
                        :
                        <div style={{
                            height : "3em",
                            display : "flex",
                            flexDirection : "column",
                            alignItems : 'center',
                            justifyContent : "center",
                            marginBottom : "2em"
                        }}>
                            Pengguna ini tidak terdaftar di acara manapun.
                        </div>
                }

            </DialogContent>
            {/*{renderModalFooter()}*/}
        </>
    </Dialog>
}
