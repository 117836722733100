import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Container, ListGroup, Row, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {
    FaChartBar,
    FaEdit,
    FaEye,
    FaKey,
    FaPen,
    FaSave,
    FaTimes,
    FaUserFriends,
    FaListUl,
    FaPlus
} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import {useParams} from "react-router-dom";
import Card from "react-bootstrap/Card";
import FormFieldModal from "../../reusable/modals/FormFieldModal";
import EditSurveyModal from "../../reusable/modals/EditSurveyModal";
import MiniTooltip from "../../reusable/MiniTooltip";
import Event, {EVENT_STATUS, PAYMENT_TYPE} from "../../../models/Event";
import moment from "moment";
import SurveyResultModal from "../../reusable/modals/SurveyResultModal";
import Switch from "@material-ui/core/Switch";
import MobTable from "../../reusable/Table/MobTable"
import Quiz from "../../../models/Quiz";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {FaChevronRight, FaFileDownload} from "react-icons/fa";
import {MdClose} from "react-icons/md";
import QuizTextInput from "../../reusable/QuizTextInput";
import QuizRadioButton from "../../reusable/QuizRadioButton";
import Class from "../../../models/Class";
import Participation from "../../../models/Participation";
import {CSVLink} from "react-csv";
import Tab from "react-bootstrap/Tab";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

export default function QuizEditor(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [isAddSurveyModal, setIsAddSurveyModal] = useState(false)
    const [isSurveyResultModalShown, setIsSurveyResultModalShown] = useState(false)

    const [selectedSurvey, setSelectedSurvey] = useState({})

    const [isBtnHovered, setBtnHovered] = useState(false);

    const [questions, setQuestions] = useState({});
    // const [questionsWithoutDeleted, setQuestionsWithoutDeleted] = useState([]);

    const [isDeletedShown, setDeletedShown] = useState(false);

    const [isDeleting, setDeleting] = useState(false);

    const [participantAnswers, setParticipantAnswers] = useState([]);
    const [additionalAnswers, setAdditionalAnswers] = useState([]);

    const [participantAnswersColumns, setParticipantAnswersColumns] = useState([]);

    const [notYetAnswered, setNotYetAnswered] = useState([])

    const [selectedAnswer, setSelectedAnswer] = useState({});

    const [isSelectParticipantModalShown, setSelectParticipantModalShown] = useState(false)
    const [isShowAnswerModalShown, setShowAnswerModalShown] = useState(false)
    const [isQuestionOnlyModalShown, setQuestionOnlyModalShown] = useState(false);

    const [isQuizURLShown, setIsQuizURLShown] = useState(false)
    const [quizURL, setQuizURL] = useState("")

    const [deletingAdditionalAnswers, setDeletingAdditionalAnswers] = useState({})

    useEffect(() => {
        getQuizByClassCode()
        getAdditionalAnswersByClassCode()
    }, [])

    const deleteQuiz = async (idx) => {
        const questionJsonTemp = [...questions.question_json];

        questionJsonTemp.splice(idx, 1)

        try {
            const result = await new Quiz().update(questions.id, {
                question_json: questionJsonTemp,
                class_code: props.match.params.event_url
            });


            if (result.success) {
                getQuizByClassCode();
                setDeleting(false);
                alert('Pertanyaan berhasil terhapus!')
            }
            console.log(result)
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }

        // try {
        //     const result = await new Quiz().delete(id);
        //
        //     if(result.success) {
        //         setDeleting(false)
        //         getQuizByClassCode()
        //         alert('Pertanyaan berhasil terhapus!');
        //     }
        //     console.log(result)
        // } catch (e) {
        //     if (e.msg && typeof e.msg === 'string') {
        //         alert(e.msg);
        //     } else {
        //         alert('Kesalahan terjadi');
        //     }
        // }
    }

    const undeleteQuiz = async (id) => {
        try {
            const result = await new Quiz().undelete(id);

            if (result.success) {
                alert('Pertanyaan berhasil dipulihkan!');
                getQuizByClassCode()
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const processCSV = () => {

    }

    const getAdditionalAnswersByClassCode = async () => {
        const additionalAnswers = await new Quiz().getAllFromFormClassCode(props.match.params.event_url);

        setAdditionalAnswers(additionalAnswers)
    }

    const getQuizByClassCode = async () => {
        try {
            const result = await new Quiz().getAllByClassCode(props.match.params.event_url)
            const participantAnswers = await new Quiz().getParticipantAnswers(result.id);

            setParticipantAnswers(participantAnswers)


            result.question_json.map((question, idx) => {
                result.question_json[idx].idx = idx;
            })

            /*
            Object.keys(result).forEach(key => {
                console.log(result[key])
                tempQuestions.push({
                    id: result[key].id,
                    question: result[key].question_json.fieldName,
                    question_json: result[key].question_json,
                    active: result[key].active
                })

                if(result[key].active) {
                    tempQuestionsWithoutDeleted.push({
                        id: result[key].id,
                        question: result[key].question_json.fieldName,
                        question_json: result[key].question_json,
                        active: result[key].active
                    })
                }
            })
            */

            let classModel = new Class()
            let participants = await classModel.getClassParticipant(props.match.params.event_url)

            let acceptedParticipantNotYetAnswered = []
            let listStatus = [];
            let unfinishedColumn = [];

            for (let r of participants) {
                if (r.status === "ACCEPTED" && participantAnswers.findIndex((obj, key) => {
                    return obj.participant_id === r.id
                }) === -1) {
                    let status = {

                        "member": r.member.full_name,
                        "created_at": null,
                        "score": null,
                        "status": "Belum Dikerjakan"
                    }

                    listStatus.push(status);
                    unfinishedColumn.push(status);
                    acceptedParticipantNotYetAnswered.push(r)
                }
            }

            let row_participantAnswers = participantAnswers.map((value, index) => {
                let status = 'Sudah Dikerjakan';


                let data = {
                    "member": value.member.full_name,
                    "created_at": value.created_at,
                    "score": value.score,
                    "status": status
                }
                return data;
            });
            row_participantAnswers.concat(unfinishedColumn);

            let getAllParticipant = [...row_participantAnswers, ...unfinishedColumn];

            setNotYetAnswered(acceptedParticipantNotYetAnswered)

            // setQuestionsWithoutDeleted(tempQuestionsWithoutDeleted);
            setParticipantAnswersColumns(getAllParticipant)

            setQuestions(result)
        } catch (e) {
            console.log(e)
        }
    }

    let interactions = [
        {
            name: "Sunting",
            style: {width: 100},
            action: (survey, idx) => {
                console.log(idx)
                // console.log(survey)
                setSelectedSurvey(survey)
                setIsAddSurveyModal(true)
            }
        },
        {
            name: "Hapus",
            variant: 'secondary',
            style: {width: 100},
            action: (survey) => {
                setSelectedSurvey(survey);
                setDeleting(true)
                // deleteQuiz(survey.id)

                /**
                 * todo: integrate with delete survey API
                 */
            }
        }
    ]

    const columns = useMemo(
        () => [
            {
                Header: "Pertanyaan",
                accessor: "fieldName"
            },
            {
                Header: "Kunci Jawaban",
                accessor: "correctAnswer"
            }
        ]
    )

    //add new column for list answered quiz
    const columnsAnswer = [
        {
            Header: "Peserta",
            accessor: "member",

        },
        {
            Header: "Status",
            accessor: "status",

        },
        {
            Header: "Nilai",
            accessor: "score",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values;
                let score = rowInfo.score || rowInfo.score === 0 ? Math.floor(rowInfo.score * 100) : '-';
                return <>
                    {score}
                </>
            }
        },
        {
            Header: "Waktu Kirim",
            accessor: "created_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values;
                let created_at = rowInfo.score || rowInfo.score === 0 ? moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm") : '-';
                return <>
                    {created_at}
                </>
            }
        },

    ]

    const columnsAdditionalAnswer = [
        {
            Header: "Nama",
            accessor: "name",
        },
        {
            Header: "Email",
            accessor: "email",

        },
        {
            Header: "Nilai",
            accessor: "score",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values;
                let score = rowInfo.score !== null ? Math.floor(rowInfo.score * 100) : '-';
                return <>
                    {score}
                </>
            }
        },
        {
            Header: "Waktu Kirim",
            accessor: "created_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values;
                let created_at = rowInfo.created_at !== null ? moment(rowInfo.created_at).format("DD MMM YYYY - HH:mm") : '-';
                return <>
                    {created_at}
                </>
            }
        },
        {
            Header: "",
            accessor: "answer_json",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values;
                let answerJSON = rowInfo.answer_json


                return <>
                    <CustomButton
                        variant={"outlined"}
                        style={{marginRight: 10, marginBottom: 15, width: 150}}
                        color={Palette.PRIMARY}
                        onClick={() => {
                            console.log(answerJSON)

                            setShowAnswerModalShown(true)
                            setSelectedAnswer({
                                member: {
                                    full_name: rowInfo.name
                                },
                                answer_json: answerJSON
                            })
                        }
                        }>
                        Lihat Jawaban
                    </CustomButton>

                    <CustomButton
                        variant={"contained"}
                        color={"secondary"}
                        style={{width: 150, marginBottom: 10}}
                        onClick={async () => {
                            try {
                                console.log(cellInfo.row.original)

                                setDeletingAdditionalAnswers(cellInfo.row.original)
                            } catch (e) {
                                console.log(e)
                            }
                        }}>
                        Hapus
                    </CustomButton>
                </>
            }
        },

    ]


    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    function isObjEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    //function daftar soal
    const renderTabs = () => {
        return <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="Daftar Soal">
                <MobTable columns={columns} data={questions.question_json ? questions.question_json : []}
                          interactions={interactions}/>
            </Tab>
            <Tab eventKey="profile" title="Jawaban Peserta">
                <Button
                    onClick={() => {
                        setSelectParticipantModalShown(true)
                    }}
                    variant={'primary'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 30,
                        fontSize: '.8em',
                        fontFamily: 'OpenSans-SemiBold',
                        // marginTop: 4,
                        width: 150,
                        color: 'white', marginRight: 5,
                        marginTop: 10,
                    }}
                >
                    Lihat Jawaban
                </Button>
                <MobTable columns={columnsAnswer} data={participantAnswersColumns ? participantAnswersColumns : []}
                />
            </Tab>
            <Tab eventKey="additional" title="Jawaban Tambahan">

                <div style={{
                    marginTop: 10
                }}>
                    URL Untuk Peserta : <a
                    href={`https://sinara.narabahasa.id/tes-akhir/${props.match.params.event_url}`}>https://sinara.narabahasa.id/tes-akhir/{props.match.params.event_url}</a>
                </div>

                {
                    additionalAnswers.length > 0 &&
                    <div>
                        <CSVLink
                            style={{color: 'white', marginBottom: 30}}
                            separator={";"}
                            data={
                                additionalAnswers.map((answer, key) => {
                                    const obj = {
                                        "Dikumpulkan Pada": new moment(answer.created_at).format("MM/DD/YYYY HH:mm:ss"),
                                        "Nama Lengkap": answer.name,
                                        "Surel": answer.email,
                                        Nilai: Math.round(answer.score * 100),

                                    }

                                    answer.answer_json.forEach((json, idx) => {
                                        const no = idx + 1;

                                        obj["Jawaban " + no] = json;
                                    })

                                    return obj;
                                })
                            }
                            filename={`tambahan-tes-akhir.csv`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            Unduh Jawaban&nbsp;&nbsp;<FaFileDownload/>
                        </CSVLink>
                    </div>

                }

                <MobTable columns={columnsAdditionalAnswer} data={additionalAnswers ? additionalAnswers : []}
                />
            </Tab>

        </Tabs>
    }

    const renderContent = () => {
        return <div style={{
            display: "flex",
            flexDirection: "column"
        }}>
            <FormFieldModal
                show={isAddSurveyModal}
                selectedSurvey={selectedSurvey}
                onClose={() => {
                    setSelectedSurvey({})
                    setIsAddSurveyModal(false)
                }}
                onSubmit={async (fieldName, type, description, options, index, correctAnswer) => {
                    console.log("correct answer" + correctAnswer)
                    if (isObjEmpty(questions)) {
                        try {
                            const result = await new Quiz().addNew({
                                question_json: [{
                                    fieldName,
                                    type,
                                    description,
                                    options,
                                    correctAnswer: correctAnswer
                                }],
                                class_code: props.match.params.event_url
                            });

                            if (result.id) {
                                getQuizByClassCode();
                                setIsAddSurveyModal(false);
                                setSelectedSurvey({})
                                alert('Pertanyaan berhasil ditambahkan!')
                            }
                            console.log(result)
                        } catch (e) {
                            console.log(e)
                        }
                    } else {
                        const questionsTemp = {...questions};

                        if (isObjEmpty(selectedSurvey)) {
                            questionsTemp.question_json.push({
                                fieldName,
                                type,
                                description,
                                options,
                                correctAnswer: correctAnswer
                            })
                        } else {
                            questionsTemp.question_json[selectedSurvey.idx] = {
                                fieldName,
                                type,
                                description,
                                options,
                                correctAnswer: correctAnswer
                            };
                        }


                        console.log(questionsTemp)

                        try {
                            const result = await new Quiz().update(questions.id, {
                                question_json: questionsTemp.question_json,
                                class_code: props.match.params.event_url
                            });

                            if (result.success) {
                                getQuizByClassCode();
                                setIsAddSurveyModal(false);
                                setSelectedSurvey({})
                                alert('Pertanyaan berhasil diperbaharui!')
                            }
                            console.log(result)
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }}
            />

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    fontFamily: "OpenSans-Bold",
                    marginRight: 25,
                }}>
                    Tes Akhir
                </div>

                {/*<CustomButton*/}
                {/*    // onClick={() => history.push("/create-event")}*/}
                {/*    style={{borderWidth: 0, fontFamily: 'OpenSans-SemiBold', fontWeight: '500', }}*/}
                {/*    variant={"outlined"}>+ Buat Kelas Baru</CustomButton>*/}

                <Row style={{marginLeft: 2, marginTop: 15}}>
                    {/*<Button variant={'info'}*/}
                    {/*        onClick={() => setDeletedShown(!isDeletedShown)}*/}
                    {/*        style={{*/}
                    {/*            display: 'flex',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            justifyContent: 'center',*/}
                    {/*            height: 30,*/}
                    {/*            fontSize: '.8em',*/}
                    {/*            fontFamily: 'OpenSans-SemiBold',*/}
                    {/*            // marginTop: 4,*/}
                    {/*            width: 200,*/}
                    {/*            color: 'white', marginRight: 5*/}
                    {/*        }}*/}
                    {/*>*/}
                    {/*    {isDeletedShown ? <IoMdEyeOff style={{marginRight: 10}}/> : <IoMdEye style={{marginRight: 10}}/>}*/}
                    {/*    {isDeletedShown ? 'Sembunyikan Terhapus' : 'Tampilkan Terhapus'}*/}
                    {/*</Button>*/}

                    <Button
                        onClick={() => {
                            setQuestionOnlyModalShown(true)
                        }}
                        variant={'info'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            fontSize: '.8em',
                            fontFamily: 'OpenSans-SemiBold',
                            // marginTop: 4,
                            width: 150,
                            color: 'white', marginRight: 5
                        }}
                    >
                        Tampilan Pengguna
                    </Button>

                    <Button variant={'outline-primary'}
                            onMouseEnter={() => setBtnHovered(true)}
                            onMouseLeave={() => setBtnHovered(false)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 30,
                                fontSize: '.8em',
                                width: 165,
                                fontFamily: 'OpenSans-SemiBold',
                                color: isBtnHovered ? 'white' : Palette.PRIMARY
                            }}
                            onClick={() => {
                                setIsAddSurveyModal(true)
                            }}>
                        + <span style={{marginLeft: 6}}>Tambah Pertanyaan</span>
                    </Button>
                </Row>
            </div>

            {/*<Col xl={6}>*/}

            <div style={{
                paddingTop: "1em",
                paddingBottom: "1em",
                height: "15em"
            }}>
                {renderTabs()}

            </div>
        </div>
    }

    const renderQuizURL = () => {
        return <Dialog open={isQuizURLShown}
                       maxWidth="sm"
                       onClose={() => setIsQuizURLShown(false)}
                       fullWidth={true}
        >
            <DialogTitle onClose={() => setIsQuizURLShown(false)}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        URL Sekali Pakai
                    </Col>

                    <IconButton aria-label="close" style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "grey",
                    }} onClick={() => {
                        setIsQuizURLShown(false)
                    }}>
                        <MdClose/>
                    </IconButton>

                </Row>
            </DialogTitle>

            <DialogContent style={{paddingBottom: 20}}>

                <p>
                    Pakai URL ini hanya jika pengguna kesulitan untuk mengakses tes akhir.
                </p>
                <p style={{
                    color: "grey",
                    fontSize: "1.2em",
                    wordWrap: "break-word"
                }}>
                    https://sinara.narabahasa.id/tes/masuk/{quizURL}
                </p>
            </DialogContent>
        </Dialog>
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"quiz"}
            >

                <Dialog open={isSelectParticipantModalShown && !isQuizURLShown}
                        maxWidth="sm"
                        onClose={() => setSelectParticipantModalShown(false)}
                        fullWidth={true}
                >
                    <DialogTitle onClose={() => setSelectParticipantModalShown(false)}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Lihat Jawaban
                            </Col>
                        </Row>

                        <hr/>

                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "grey",
                        }} onClick={() => {
                            setSelectParticipantModalShown(false)
                        }}>
                            <MdClose/>
                        </IconButton>

                    </DialogTitle>

                    <DialogContent style={{paddingBottom: 20}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {participantAnswers.length > 0 &&
                            <CSVLink
                                style={{color: 'white', marginBottom: 30}}
                                separator={";"}
                                data={
                                    participantAnswers.map((answer, key) => {
                                        const obj = {
                                            "Dikumpulkan Pada": new moment(answer.created_at).format("MM/DD/YYYY HH:mm:ss"),
                                            "Nama Lengkap": answer.member.full_name,
                                            Nilai: Math.round(answer.score * 100),

                                        }

                                        answer.answer_json.forEach((json, idx) => {
                                            const no = idx + 1;

                                            obj["Jawaban " + no] = json;
                                        })

                                        return obj;
                                    })
                                }
                                filename={`data-tes-akhir.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Unduh Jawaban&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                            }
                        </div>

                        {
                            participantAnswers.map(answer => {
                                return (
                                    <Row style={{
                                        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        borderRadius: 10,
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: 'white',
                                        maxWidth: 900,
                                        width: '100%',
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        paddingTop: 20,
                                        paddingBottom: 15,
                                        fontFamily: 'OpenSans-SemiBold',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        cursor: 'pointer',
                                        marginBottom: 10
                                    }} onClick={() => {
                                        console.log(answer)
                                        setShowAnswerModalShown(true)
                                        setSelectedAnswer(answer)
                                    }}>
                                        <div style={{flex: 1}}>
                                            {answer.member.full_name} <b>({Math.round(answer.score * 100)}/100)</b>
                                            <br/>
                                            <div style={{
                                                fontFamily: "OpenSans-Regular",
                                                fontSize: "0.9em"
                                            }}>
                                                Dikumpulkan pada&nbsp;
                                                {new moment(answer.created_at).format("DD MMM YYYY HH:mm")}
                                            </div>
                                        </div>
                                        <div>
                                        </div>

                                        <FaChevronRight color={'#d1d1d1'}/>
                                    </Row>
                                )
                            })
                        }
                        <b>Belum Menjawab</b>
                        {
                            notYetAnswered.map((obj, key) => {
                                return (
                                    <Row style={{
                                        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                        borderRadius: 10,
                                        marginLeft: 5,
                                        marginRight: 5,
                                        backgroundColor: 'white',
                                        maxWidth: 900,
                                        width: '100%',
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        paddingTop: 20,
                                        paddingBottom: 15,
                                        fontFamily: 'OpenSans-SemiBold',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        cursor: 'pointer',
                                        marginBottom: 10
                                    }} onClick={async () => {
                                        try {
                                            let participantModel = new Participation();
                                            let participantToken = await participantModel.getParticipationToken(obj.id);

                                            console.log(participantToken)

                                            setIsQuizURLShown(true)
                                            setQuizURL(participantToken.token)

                                        } catch (e) {
                                            console.log(e)
                                            alert("Terjadi Kesalahan")
                                        }

                                        console.log(obj)
                                    }}>
                                        <div style={{flex: 1}}>
                                            {obj.member.full_name}
                                            <br/>
                                        </div>
                                        <div>
                                        </div>
                                        <FaChevronRight color={'#d1d1d1'}/>
                                    </Row>
                                )
                            })
                        }
                    </DialogContent>
                </Dialog>

                <Dialog open={isShowAnswerModalShown || isQuestionOnlyModalShown}
                        maxWidth="md"
                        onClose={() => {
                            setQuestionOnlyModalShown(false)
                            setShowAnswerModalShown(false)
                        }}
                        fullWidth={true}
                >
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {isQuestionOnlyModalShown ? 'Tampilan Pengguna' : `Lihat Jawaban (${selectedAnswer.member?.full_name})`}
                            </Col>
                        </Row>
                        <hr/>
                        <IconButton aria-label="close" style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: "grey",
                        }} onClick={() => {
                            setQuestionOnlyModalShown(false)
                            setShowAnswerModalShown(false)
                        }}>
                            <MdClose/>
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {
                            questions.question_json ? questions.question_json.map(
                                (quiz, idx) => {
                                    switch (quiz.type) {
                                        case "isian":
                                            return <QuizTextInput question={quiz.fieldName}
                                                                  description={quiz.description}
                                                                  value={!isQuestionOnlyModalShown && selectedAnswer.answer_json ? selectedAnswer.answer_json[idx] : null}
                                            />

                                        case "option":
                                            return <QuizRadioButton question={quiz.fieldName}
                                                                    description={quiz.description}
                                                                    options={quiz.options}
                                                                    value={!isQuestionOnlyModalShown && selectedAnswer.answer_json ? selectedAnswer.answer_json[idx] : null}
                                            />
                                    }
                                }) : null
                        }

                        <div style={{marginBottom: 40}}/>
                    </DialogContent>
                </Dialog>

                <Dialog open={Object.keys(deletingAdditionalAnswers).length > 0}
                        maxWidth="sm">
                    <>
                        <DialogTitle onClose={() => setDeletingAdditionalAnswers({})}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Jawaban Tambahan
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah Anda yakin ingin menghapus jawaban tambahan oleh "<span
                            style={{fontFamily: 'OpenSans-Bold'}}>{deletingAdditionalAnswers.name} ({deletingAdditionalAnswers.email})</span>"?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeletingAdditionalAnswers({})
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        try {
                                            let result = await new Quiz().deleteAdditionalAnswer(deletingAdditionalAnswers.id);

                                            if (result.success) {
                                                getAdditionalAnswersByClassCode();
                                                setDeletingAdditionalAnswers({});
                                                alert('Jawaban tambahan berhasil terhapus!')
                                            }
                                        } catch (e) {
                                            if (e.msg && typeof e.msg === 'string') {
                                                alert(e.msg);
                                            } else {
                                                alert('Kesalahan terjadi');
                                            }
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <Dialog open={isDeleting}
                        maxWidth="sm">
                    <>
                        <DialogTitle onClose={() => this.onClose()}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Pertanyaan
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah Anda yakin ingin menghapus pertanyaan "<span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedSurvey.fieldName}</span>"?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleting(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={() => deleteQuiz(selectedSurvey.idx)}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                {renderAlertBox()}
                {renderSuccessBox()}

                {
                    renderQuizURL()
                }

                {
                    renderContent()
                }

                {/*<SurveyResultModal*/}
                {/*    survey={selectedSurvey}*/}
                {/*    show={isSurveyResultModalShown}*/}
                {/*    onClose={() => setIsSurveyResultModalShown(false)}*/}
                {/*/>*/}

            </EditorPageWrapper>
        </>
    );

}
