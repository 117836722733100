import ApiRequest from "../util/ApiRequest";

export default class Complaint {
    getAll = async () => {
        return await ApiRequest.set("/v1/document_forms", "GET");
    }

    downloadMultipleDocuments = async() =>{
        return await ApiRequest.set("/v1/document_forms", "POST");

    }

    update = async(id, body) => {
        return await ApiRequest.set(`/v1/document_forms/${id}/update`, "PUT", body);
    }
}
