export default class EmailDisplayConfiguration{
    static pending = {
        all: {
            title: "Pendaftaran Penuh",
            instruction: "Pengguna yang mendaftar setelah kuota acara telah penuh akan mendapatkan email bahwa mereka masuk kedalam daftar antrian. Jika kuota ditambah atau jika ada yang membatalkan pendaftaran, secara otomatis sistem akan mendaftarkan pengguna secara otomatis menjadi seorang peserta acara."
        }
    }

    static waiting = {
        online: {
            title: "Menunggu Pembayaran",
            instruction: "Peserta yang mengikuti sesi berbayar akan mendapatkan email yang menunjukan bahwa pendaftaran telah diterima beserta instruksi untuk melakukan pembayaran."
        },
        manual: {
            title: "Menunggu Bukti Pembayaran",
            instruction: "Peserta yang mengikuti sesi berbayar akan mendapatkan email yang menunjukan bahwa pendaftaran telah diterima beserta instruksi untuk mengunggah bukti pmebayaran untuk di konfirmasi oleh pengurus acara"
        }
    }

    static accepted = {
        all: {
            title: "Pendaftaran Berhasil",
            instruction: "Pengguna yang berhasil mendaftar ke sebuah acara akan menjadi peserta acara dan akan menerima surel ini."
        }
    }

    static rejected = {
        online: {
            title: "Pembayaran Gagal",
            instruction: "Bila calon peserta tidak berhasil melakukan pembayaran tepat waktu, calon peserta akan mendapat surel ini. Pada surel ini, disarankan untuk menginformasikan calon peserta untuk melakukan proses pendaftaran kembali."
        },
        manual: {
            title: "Bukti Pembayaran Ditolak",
            instruction: "Bila calon peserta tidak mengunggah bukti transaksi tepat waktu atau mengunggah bukti yang tidak valid, pengurus acara dapat menolak pendaftaran calon peserta. Calon peserta akan menerima surel ini bila ditolak."
        },
        free: {
            title: "Pendaftaran Ditolak",
            instruction: "Bila calon peserta tidak diperkenankan untuk mengikuti acara ini, pengurus acara dapat menolak pendaftaran calon peserta. Calon peserta akan menerima surel ini bila ditolak."

        }
    }

    static reminder = {
        all: {
            title: "Pengingat Acara Mulai",
            instruction: "Peserta yang sudah terdaftar akan mendapatkan surel ini 2 jam sebelum acara yang akan menginformasikan mereka bahwa acara akan dimulai."
        }
    }
}