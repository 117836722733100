import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap"


import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from 'prop-types'

import 'moment/locale/id'
import {TextField} from "@material-ui/core"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import {AiFillWarning, MdAdd} from "react-icons/all";
import {animateScroll as scroll} from "react-scroll";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Palette from "../../util/Palette";
import {MdDelete} from "react-icons/md";
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic';

export default function ManageDisplayTimeModal(props) {
    const {classObj, setClass} = props;

    const mystyle = {
        color: "green",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial",
    };

    ManageDisplayTimeModal.propTypes = {
        show: PropTypes.bool,
        handleClose: PropTypes.func.isRequired,
    }


    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }


    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const handleClose = () => {
        let allFieldsFilled = true;

        classObj.display_time.forEach(time => {
            if(!time) allFieldsFilled = false;
        })

        if(!allFieldsFilled) return promptError('Harap lengkapi semua data');

        setErrorMessage("")
        setSuccessMessage("")
        props.handleClose()
    }

    return (<Modal show={props.show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
            Sunting Tanggal Tertampil
        </Modal.Header>
        <Modal.Body>
            {renderSuccessBox()}
            {renderAlertBox()}
            <Row style={{marginBottom: 5}}>
                {
                    classObj.display_time?.map((time, idx) => {

                        return (
                            <>
                                <Col xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <MdDelete color={'grey'}
                                              size={17}
                                              onClick={() => {
                                                  const classObjTemp = {...classObj};

                                                  classObjTemp.display_time.splice(idx, 1);

                                                  setClass(classObjTemp)
                                              }}
                                              style={{cursor: 'pointer', marginBottom: 15}}/>
                                </Col>
                                <Col xs={3} style={{marginBottom: 10}}>Tanggal Tertampil</Col>
                                <Col xs={9} style={{marginBottom: 10}}>
                                    <div style={{
                                        marginLeft: "1em",
                                    }}>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    fontFamily: "Montserrat"
                                                }
                                            }}
                                            size={'small'}
                                            fullWidth={true}
                                            variant={"outlined"}
                                            value={time}
                                            onChange={evt => {
                                                const classObjTemp = {...classObj};

                                                classObjTemp.display_time[idx] = evt.target.value;

                                                setClass(classObjTemp)
                                            }}
                                            placeholder={"Tanggal Tertampil"}
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                                fontFamily: "Montserrat"
                                            }}/>

                                    </div>
                                </Col>

                                {idx !== classObj.display_time.length - 1 && <Col xs={12} style={{height: 1, backgroundColor: '#dee2e6', marginTop: 30, marginBottom: 30}}/>}
                            </>
                        )
                    })
                }
            </Row>

        </Modal.Body>
        {<Modal.Footer>
            <div style={{
                position : "absolute",
                bottom : "0.75rem",
                left : 12
            }}>
            </div>

            <Button variant="secondary" onClick={handleClose}>
                Tutup
            </Button>
            <Button variant="primary" onClick={() => {
                const classObjTemp = {...classObj};

                if (classObjTemp.display_time) classObjTemp.display_time.push("")
                else classObjTemp.display_time = [""]

                setClass(classObjTemp)
            }}>
                + Tambah Tanggal Tertampil
            </Button>
        </Modal.Footer>}
    </Modal>)


}
