import apiConfig from "./apiConfig";
import GlobalData from "./GlobalData";
import Cookies from 'js-cookie';
import axios from "axios";

export default class ApiRequest {

    static set = async (endpoint, method, body) => {

        let token = GlobalData.token ? GlobalData.token : Cookies.get("token") ? Cookies.get("token") : null

        console.log('API ACCESS: ' + endpoint);

        // console.log('Base url', apiConfig.base_url)

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `bearer ${token}` : null,
                Accept: 'application/json',
            },
            body: JSON.stringify(body)
            // body: "afdadsf"
        });

        console.log(response)

        if (response.ok) {

            return response.json()
        } else {


            let error = await response.json()

            console.log(error)

            throw error

        }


    }

    static setMultipart = async (endpoint, method, body) => {
        // console.log(`token: ${GlobalData.token}`);

        console.log('API ACCESS: ' + endpoint);

        let response = await fetch(apiConfig.base_url + endpoint, {
            method: method,
            headers: {
                Authorization: `bearer ${GlobalData.token}`,
                'Access-Control-Allow-Origin': '*',
            },
            body: body
            // body: "afdadsf"
        });

        if (response.ok) {
            return response.json()
        } else {

            let error = await response.json()

            console.log(error)

            throw error

        }
    }

    static setMultipartWithProgress = async (endpoint, method, body, setProgress) => {
        // console.log(`token: ${GlobalData.token}`);

        console.log('API ACCESS: ' + endpoint);

        try {
            let response = await axios({
                method: method,
                url: apiConfig.base_url + endpoint,
                data: body,
                headers: {
                    Authorization: `bearer ${GlobalData.token}`,
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: (p) => {

                    setProgress(Math.round(p.loaded / p.total * 100));
                    //this.setState({
                    //fileprogress: p.loaded / p.total
                    //})
                }
            })

            console.log('baja unggul res', response)


            if (response.status === 200) {
                return response.data
            }
        } catch (err) {
            throw err.response.data
        }
    }
}
