import React, {useEffect, useRef, useState} from "react";
import Class from "../../models/Class";
import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FiAlignJustify, FiClock, FiInfo, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import {BsMic} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import {AiFillTag, AiOutlineTag} from "react-icons/ai";
import Palette from "../../util/Palette";
import SimpleLineIcon from "react-simple-line-icons";
import Dialog from '@material-ui/core/Dialog';
import Form from "react-bootstrap/Form";
import Rating from "react-rating";
import {FaStar, FaFileDownload} from "react-icons/fa";
import {IoMdStar} from "react-icons/io";
import moment from "moment";
import FeedbackCard from "../reusable/FeedbackCard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import GlobalData from "../../util/GlobalData";
import {CookiesProvider, useCookies} from 'react-cookie';
import {isMobile} from "react-device-detect";
import textFormatter from "../../util/textFormatter";
import Strings from "../../util/Strings";
import FeedbackModal from "../reusable/FeedbackModal";
import QnASection from "../reusable/QnASection";
import {Helmet} from "react-helmet";
import {useHistory, useLocation} from "react-router-dom";
import EditorPageWrapper from "./editor/EditorPageWrapper";

var momentTz = require('moment-timezone');

const BUTTON_STATUS = {
    REGISTER: 'REGISTER',
    REGISTERED: 'REGISTERED',
    BUY_TICKET: 'BUY_TICKET',
    REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',
    PAY: 'PAY',
    IN_QUEUE: 'IN_QUEUE',
    OUT_OF_QUOTA: 'OUT_OF_QUOTA'
}

const WATCH_STATUS = {
    AVAILABLE_AFTER_LOGIN: 'AVAILABLE_AFTER_LOGIN',
    AVAILABLE_AFTER_REGISTER: 'AVAILABLE_AFTER_REGISTER',
    WATCH: 'WATCH',
    TICKET_REQUIRED: 'TICKET_REQUIRED',
    COUNTDOWN: 'COUNTDOWN',
}

export default function PreviewPage(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }

    const waitingStatusRef = useRef(null);

    const classModel = new Class();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isButtonHovered, setButtonHovered] = useState(false);
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [isRegistrationOpen, setRegistrationOpen] = useState(false);

    const [isFeedbackModalShown, setFeedbackModalShown] = useState(false);
    const [isFeedbackExtended, setFeedbackExtended] = useState(false)


    const [cookie, setCookie, removeCookie] = useCookies()

    const [feedbacks, setFeedbacks] = useState([]);
    const [noOfFeedback, setNoOfFeedback] = useState(0);

    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        if (props.code) {
            getSessionFeedbacks()
        }


        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, [])

    const [averageRating, setAverageRating] = useState(0)

    const [count5, setCount5] = useState(0)
    const [count4, setCount4] = useState(0)
    const [count3, setCount3] = useState(0)
    const [count2, setCount2] = useState(0)
    const [count1, setCount1] = useState(0)


    const getSessionFeedbacks = async () => {
        try {
            const feedbacks = await classModel.getFeedbacks(props.code);

            setNoOfFeedback(feedbacks.length);

            console.log('feedbacks', feedbacks)

            let total = 0

            let tempCount5 = 0;
            let tempCount4 = 0;
            let tempCount3 = 0;
            let tempCount2 = 0;
            let tempCount1 = 0;

            for (let f of feedbacks) {

                total += f.average

                if (Math.round(f.average) === 5) {
                    tempCount5++
                } else if (Math.round(f.average) === 4) {
                    tempCount4++
                } else if (Math.round(f.average) === 3) {
                    tempCount3++
                } else if (Math.round(f.average) === 2) {
                    tempCount2++
                } else {
                    tempCount1++
                }

            }

            setCount1(tempCount1)
            setCount2(tempCount2)
            setCount3(tempCount3)
            setCount4(tempCount4)
            setCount5(tempCount5)

            setAverageRating(total / feedbacks.length)

            let filteredFeedbacks = feedbacks.filter((obj, key) => {
                return obj.masukan
            })

            setFeedbacks(filteredFeedbacks);


        } catch (e) {
            console.log('feedbacks_err', e)
        }
    }

    let session = props.session ? props.session : {}

    return (
        <>
            <FeedbackModal
                session={session}
                isOpen={isFeedbackModalShown}
                onClose={(success) => {
                    setFeedbackModalShown(false)
                }}
            />

            <Container style={{marginTop: isMobile ? 0 : 100}}>

                <Row style={{
                    marginBottom: 30
                }}>
                    <Col md={12}>
                        <a href={`#`}
                           onClick={(e) => {
                               e.preventDefault()
                               props.onBack()
                           }}
                           style={{fontFamily: 'OpenSans-Regular'}}>{"<"} Kembali</a>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} lg={7}>
                        <img
                            onLoad={() => setImageLoaded(true)}
                            // onClick={() => {
                            //     if (isImageLoaded && session.class_image_url) setImagePreview(true)
                            // }}
                            src={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                            style={{
                                cursor: isImageLoaded && session.class_image_url ? 'pointer' : 'auto',
                                objectFit: 'contain',
                                width: '100%',
                                backgroundColor: 'white',
                            }}/>
                    </Col>

                    <Col lg={5}>
                        <Row style={{
                            color: '#302a28',
                            fontFamily: 'OpenSans-Bold',
                            fontSize: 30,
                            paddingLeft: 20,
                            lineHeight: 1.375,
                            paddingTop: windowWidth < 992 ? 30 : 0,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            {session.name}
                        </Row>

                        <Row style={{marginLeft: 0, display: 'flex', marginTop: 30,}}>
                            <a href={'#'}>
                                <Button
                                    disabled={!isRegistrationOpen}
                                    style={{
                                        height: 45,
                                        width: session.status !== 'ACCEPTED' && !isRegistrationOpen ? 200 : 140,
                                        borderRadius: 50,
                                        backgroundColor: '#5fcaf9',
                                        opacity: session.status === 'ACCEPTED' || !isRegistrationOpen ? .5 : isButtonHovered ? .7 : 1,
                                        color: 'white',
                                        borderWidth: 0,
                                        fontSize: 14,
                                        fontFamily: 'OpenSans-Bold',
                                    }}
                                    onMouseOver={() => setButtonHovered(true)}
                                    onMouseLeave={() => setButtonHovered(false)}>
                                    {!isRegistrationOpen ? Strings.class_detail.registration_closed : Strings.class_detail.register}
                                </Button>
                            </a>

                            <a>

                                <Button
                                    onClick={async () => {
                                        if (session.classStatus === "ENDED") {
                                            if (!session.recording_url) {
                                                alert(Strings.class_detail.recording_not_available)
                                            } else {
                                                window.location = session.recording_url
                                            }
                                        } else {
                                            if (!session.stream_url) {
                                                alert(Strings.class_detail.button_will_be_active_later)
                                            } else {
                                                window.location = session.stream_url
                                            }
                                        }
                                    }}
                                    style={{
                                        height: 45,
                                        width: session.classStatus === "ENDED" ? 160 : 140,
                                        borderRadius: 50,
                                        backgroundColor: '#5fcaf9',
                                        opacity: (!session.stream_url && session.classStatus !== "ENDED") || (!session.recording_url && session.classStatus === "ENDED") ? .5 : 1,
                                        color: 'white',
                                        borderWidth: 0,
                                        fontSize: 14,
                                        marginLeft: 10,
                                        fontFamily: 'OpenSans-Bold',
                                    }}>
                                    {session.classStatus === "ENDED" ? Strings.class_detail.watch_recording : Strings.class_detail.watch}
                                </Button>

                            </a>

                        </Row>
                        <Row style={{marginLeft: 0, display: 'flex', marginBottom: 30}}>
                            {
                                session?.attachment_url ?
                                    <a href={session?.attachment_url} download>
                                        <Button
                                            style={{
                                                marginTop: 15,
                                                height: 45,
                                                width: 170,
                                                borderRadius: 50,
                                                backgroundColor: '#5fcaf9',
                                                color: 'white',
                                                borderWidth: 0,
                                                fontSize: 14,
                                                fontFamily: 'OpenSans-Bold',
                                                display: "flex",
                                                flexDirection: "row",
                                                marginRight: 10,
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                            {Strings.class_detail.download_attachment} <FaFileDownload
                                            style={{marginLeft: 5}}/>
                                        </Button>
                                    </a>
                                    : null
                            }

                            <Button
                                onClick={() => alert('Fitur ini tidak tersedia untuk halaman pratinjau')}
                                style={{
                                    marginTop: 15,
                                    height: 45,
                                    width: 125,
                                    borderRadius: 50,
                                    backgroundColor: '#5fcaf9',
                                    color: 'white',
                                    borderWidth: 0,
                                    fontSize: 14,
                                    fontFamily: 'OpenSans-Bold',
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: 10,
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                Tes Akhir
                            </Button>

                            <a href={'#'} onClick={() => alert('Fitur ini tidak tersedia untuk halaman pratinjau')}>
                                <Button
                                    style={{
                                        marginTop: 15,
                                        height: 45,
                                        width: 140,
                                        borderRadius: 50,
                                        backgroundColor: '#5fcaf9',
                                        color: 'white',
                                        borderWidth: 0,
                                        fontSize: 14,
                                        marginRight: 10,
                                        fontFamily: 'OpenSans-Bold',
                                    }}>
                                    {Strings.class_detail.certificate} <FaFileDownload/>
                                </Button>
                            </a>

                        </Row>

                        <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                            <SimpleLineIcon name="user" style={{marginRight: 18, color: Palette.SECONDARY}}/>
                            <div style={{
                                fontSize: 14,
                                color: '#575757',
                                fontWeight: "bold",
                                fontFamily: 'OpenSans-Regular'
                            }}>{Strings.class_detail.speaker}</div>
                        </Row>

                        <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                            <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.SECONDARY, opacity: 0}}/>
                            <div style={{
                                fontSize: 14,
                                color: '#575757',
                                fontFamily: 'OpenSans-Regular'
                            }}>{session.speaker}</div>
                        </Row>

                        <Row style={{
                            backgroundColor: '#e9eaec',
                            height: 1,
                            width: '100%',
                            marginTop: 15,
                            marginBottom: 15,
                            marginLeft: 0
                        }}/>

                        {
                            session.show_price ?
                                <>
                                    <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                                        <b style={{marginRight: 18, color: Palette.SECONDARY}}>Rp</b>
                                        {/*<SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.PRIMARY}}/>*/}
                                        <div style={{
                                            fontSize: 14,
                                            color: '#575757',
                                            fontWeight: "bold",
                                            fontFamily: 'OpenSans-Regular'
                                        }}>{Strings.class_detail.price}</div>
                                    </Row>
                                    <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                                        <SimpleLineIcon name="tag" style={{
                                            marginRight: 18,
                                            color: Palette.SECONDARY,
                                            opacity: 0
                                        }}/>
                                        <div style={{
                                            fontSize: 14,
                                            color: '#575757',
                                            fontFamily: 'OpenSans-Regular'
                                        }}>{session.price > 0 ? textFormatter.moneyFormatter(session.price) : Strings.class_detail.free}</div>
                                    </Row>

                                    <Row style={{
                                        backgroundColor: '#e9eaec',
                                        height: 1,
                                        width: '100%',
                                        marginTop: 15,
                                        marginBottom: 15,
                                        marginLeft: 0
                                    }}/>
                                </>
                                :
                                null
                        }


                        <Row
                            style={{
                                color: '#302a28',
                                fontFamily: 'OpenSans-Bold',
                                paddingLeft: 25,
                                lineHeight: 1.375,
                                marginLeft: 0
                            }}>
                            <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.SECONDARY}}/>
                            <div style={{fontSize: 14, color: '#575757'}}>{Strings.class_detail.quota}</div>
                        </Row>

                        <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                            <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.SECONDARY, opacity: 0}}/>
                            <div style={{
                                fontSize: 14,
                                color: '#575757',
                                fontFamily: 'OpenSans-Regular'
                            }}>
                                {session.quota} orang
                            </div>
                        </Row>

                        <Row style={{
                            backgroundColor: '#e9eaec',
                            height: 1,
                            width: '100%',
                            marginTop: 15,
                            marginBottom: 15,
                            marginLeft: 0
                        }}/>

                        <Row
                            style={{
                                color: '#302a28',
                                fontFamily: 'OpenSans-Bold',
                                paddingLeft: 25,
                                lineHeight: 1.375,
                                marginLeft: 0
                            }}>
                            <SimpleLineIcon name="calendar" style={{marginRight: 18, color: Palette.SECONDARY}}/>
                            <div style={{fontSize: 14, color: '#575757'}}>{Strings.class_detail.date}</div>
                        </Row>

                        <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                            <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.SECONDARY, opacity: 0}}/>
                            <div style={{fontSize: 14, color: '#575757', fontFamily: 'OpenSans-Regular'}}>
                                {moment(session.class_date).format('dddd, DD MMMM YYYY')}
                            </div>
                        </Row>

                        <Row style={{
                            backgroundColor: '#e9eaec',
                            height: 1,
                            width: '100%',
                            marginTop: 15,
                            marginBottom: 15,
                            marginLeft: 0
                        }}/>

                        <Row
                            style={{
                                color: '#302a28',
                                fontFamily: 'OpenSans-Bold',
                                paddingLeft: 25,
                                lineHeight: 1.375,
                                marginLeft: 0
                            }}>
                            <SimpleLineIcon name="clock" style={{marginRight: 18, color: Palette.SECONDARY}}/>
                            <div style={{fontSize: 14, color: '#575757'}}>{Strings.class_detail.time}</div>
                        </Row>

                        <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                            <SimpleLineIcon name="tag" style={{
                                marginRight: 18,
                                color: Palette.PRIMARY,
                                opacity: 0,
                                marginLeft: 0
                            }}/>
                            <div style={{
                                fontSize: 14,
                                color: '#575757',
                                fontFamily: 'OpenSans-Regular'
                            }}>{momentTz(session.start_time).tz('Asia/Jakarta').format('HH:mm')} WIB--
                                {momentTz(session.end_time).tz('Asia/Jakarta').format('HH:mm ')} WIB
                            </div>
                        </Row>

                        {/*<Row style={{*/}
                        {/*    backgroundColor: '#e9eaec',*/}
                        {/*    height: 1,*/}
                        {/*    width: '100%',*/}
                        {/*    marginTop: 15,*/}
                        {/*    marginBottom: 15,*/}
                        {/*    marginLeft: 0*/}
                        {/*}}/>*/}

                        {/*<Row*/}
                        {/*    style={{*/}
                        {/*        color: '#302a28',*/}
                        {/*        fontFamily: 'OpenSans-Bold',*/}
                        {/*        paddingLeft: 25,*/}
                        {/*        lineHeight: 1.375,*/}
                        {/*        marginLeft: 0*/}
                        {/*    }}>*/}
                        {/*    <SimpleLineIcon name="folder-alt" style={{marginRight: 18, color: Palette.PRIMARY}}/>*/}
                        {/*    <div style={{fontSize: 14, color: '#575757'}}>{Strings.class_detail.category}</div>*/}
                        {/*</Row>*/}

                        {/*<Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>*/}
                        {/*    <SimpleLineIcon name="tag" style={{*/}
                        {/*        marginRight: 18,*/}
                        {/*        color: Palette.PRIMARY,*/}
                        {/*        opacity: 0,*/}
                        {/*        marginLeft: 0*/}
                        {/*    }}/>*/}
                        {/*    <div style={{fontSize: 14, color: '#575757', fontFamily: 'OpenSans-Regular'}}>*/}
                        {/*        {session.topic ? session.topic.name : null}*/}
                        {/*    </div>*/}
                        {/*</Row>*/}
                    </Col>
                </Row>

                <Row style={{paddingTop: 40}}>
                    <Col lg={12}>
                        <p style={{
                            whiteSpace: 'pre-line',
                            wordWrap: "break-word",
                            width: "300",
                            textAlign: "justify",
                            textJustify: "distribute",
                            textAlignLast: "left",
                            fontFamily: 'OpenSans-Regular'
                        }}>
                            {session.description}
                        </p>
                    </Col>
                </Row>

                <QnASection session={session}/>

                <Row style={{
                    fontFamily: 'OpenSans-Bold',
                    color: '#302a28',
                    fontSize: 16,
                    marginTop: 50,
                    marginBottom: 20,
                    marginLeft: 0
                }}>
                    {Strings.rating.rating_and_review}
                </Row>


                {
                    feedbacks.length === 0 ?
                        <>
                            <Row style={{marginTop: 40}}>
                                <Col xs={12} style={{marginBottom: 30}}>
                                    <div style={{
                                        border: '1px solid #D7D7D7CC',
                                        borderRadius: 8,
                                        minHeight: 220,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#5F5555A6',
                                        fontFamily: 'OpenSans-Regular'
                                    }}>
                                        Nilai dan ulasan belum tersedia
                                    </div>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row style={{marginLeft: 30, display: 'flex'}}
                            >
                                <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: 50}}>
                                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                                        {Math.round(averageRating * 10) / 10}
                                    </Row>
                                    <Row style={{marginTop: -35, display: 'flex', justifyContent: 'center'}}>
                                        <Rating
                                            readonly
                                            initialRating={averageRating}
                                            emptySymbol={<IoMdStar size={20} color={'#C4C4C4'}/>}
                                            fullSymbol={<IoMdStar color={'red'} size={20} color={'#FEBF35'}/>}/>
                                    </Row>

                                    <Row style={{
                                        color: '#00000080',
                                        fontSize: 12,
                                        fontFamily: 'OpenSans-Regular',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: -18
                                    }}>
                                        ({feedbacks.length} {Strings.rating.review})
                                    </Row>

                                    <Row style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                                        <Button
                                            style={{color: Palette.PRIMARY, fontSize: '.3em'}}
                                            variant={'link'}
                                            onClick={() => {
                                                setFeedbackModalShown(true)
                                            }}>
                                            {Strings.rating.add_review}
                                        </Button>
                                    </Row>


                                </div>
                                <div style={{flex: 1, paddingLeft: 80, marginRight: 50}}>
                                    <Row style={{display: 'flex', alignItems: 'center'}}>
                                        <IoMdStar size={20} color={'#FEBF35'}/>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: 14,
                                            marginLeft: 8,
                                            marginRight: 20
                                        }}>5
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            maxWidth: 200,
                                            height: 6,
                                            backgroundColor: '#EBE7DF',
                                            borderRadius: 70,
                                        }}>
                                            <div style={{
                                                height: '100%',
                                                width: `${count5 / noOfFeedback * 100}%`,
                                                backgroundColor: '#FEBF35',
                                                borderRadius: 'inherit',
                                                textAlign: 'right'
                                            }}/>
                                        </div>
                                    </Row>

                                    <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <IoMdStar size={20} color={'#FEBF35'}/>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: 14,
                                            marginLeft: 8,
                                            marginRight: 20
                                        }}>4
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            maxWidth: 200,
                                            height: 6,
                                            backgroundColor: '#EBE7DF',
                                            borderRadius: 70
                                        }}>
                                            <div style={{
                                                height: '100%',
                                                width: `${count4 / noOfFeedback * 100}%`,
                                                backgroundColor: '#FEBF35',
                                                borderRadius: 'inherit',
                                                textAlign: 'right'
                                            }}/>
                                        </div>
                                    </Row>

                                    <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <IoMdStar size={20} color={'#FEBF35'}/>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: 14,
                                            marginLeft: 8,
                                            marginRight: 20
                                        }}>3
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            maxWidth: 200,
                                            height: 6,
                                            backgroundColor: '#EBE7DF',
                                            borderRadius: 70
                                        }}>
                                            <div style={{
                                                height: '100%',
                                                width: `${count3 / noOfFeedback * 100}%`,
                                                backgroundColor: '#FEBF35',
                                                borderRadius: 'inherit',
                                                textAlign: 'right'
                                            }}/>
                                        </div>
                                    </Row>

                                    <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <IoMdStar size={20} color={'#FEBF35'}/>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: 14,
                                            marginLeft: 8,
                                            marginRight: 20
                                        }}>2
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            maxWidth: 200,
                                            height: 6,
                                            backgroundColor: '#EBE7DF',
                                            borderRadius: 70
                                        }}>
                                            <div style={{
                                                height: '100%',
                                                width: `${count2 / noOfFeedback * 100}%`,
                                                backgroundColor: '#FEBF35',
                                                borderRadius: 'inherit',
                                                textAlign: 'right'
                                            }}/>
                                        </div>
                                    </Row>

                                    <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <IoMdStar size={20} color={'#FEBF35'}/>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: 14,
                                            marginLeft: 8,
                                            marginRight: 20
                                        }}>1
                                        </div>
                                        <div style={{
                                            flex: 1,
                                            maxWidth: 200,
                                            height: 6,
                                            backgroundColor: '#EBE7DF',
                                            borderRadius: 70
                                        }}>
                                            <div style={{
                                                height: '100%',
                                                width: `${count1 / noOfFeedback * 100}%`,
                                                backgroundColor: '#FEBF35',
                                                borderRadius: 'inherit',
                                            }}/>
                                        </div>
                                    </Row>

                                </div>
                            </Row>
                        </>
                }

                <Row style={{marginTop: 40}}>
                    {
                        feedbacks.map(
                            (feedback, key) => {

                                if (!isFeedbackExtended && key > 1) {
                                    return
                                }

                                return (
                                    <Col xs={12} md={feedback.masukan.length > 500 ? 12 : 6} style={{marginBottom: 30}}>
                                        <div style={{
                                            border: '1px solid #D7D7D7CC',
                                            borderRadius: 8,
                                            paddingTop: 25,
                                            minHeight: 220,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <div style={{
                                                paddingLeft: 25,
                                                paddingRight: 25,
                                                flex: 1,
                                                paddingBottom: 25
                                            }}>
                                                <div style={{display: 'flex'}}>
                                                    <img
                                                        style={{
                                                            width: "2.5em",
                                                            objectFit: "contain",
                                                            borderRadius: "50%",
                                                            height: "2.5em",
                                                            padding: 1,
                                                            backgroundColor: Palette.PRIMARY
                                                        }}
                                                        src={feedback.image_url ? feedback.image_url : require("../../asset/public_image_default_user.png")}/>

                                                    <div style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        paddingLeft: 15,
                                                        flexDirection: 'column',
                                                        paddingTop: '.25em'
                                                    }}>
                                                        <div style={{fontFamily: 'OpenSans-Bold', fontSize: '1.15em'}}>
                                                            {feedback.full_name}
                                                        </div>

                                                        <div style={{
                                                            marginLeft: 2,
                                                            color: '#5F5555A6',
                                                            fontFamily: 'OpenSans-Regular',
                                                            marginTop: 20,
                                                            maxLines: 5,
                                                            lineHeight: "1.5em",
                                                            minHeight: "7.5em",
                                                            fontSize: '1.05em',
                                                            whiteSpace: 'pre-line'
                                                        }}>
                                                            {feedback.masukan}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div style={{
                                                backgroundColor: '#7C777733',
                                                height: 1,
                                                width: '100%',
                                                marginLeft: 0
                                            }}/>

                                            <div style={{
                                                marginTop: 10,
                                                marginBottom: 15,
                                                textAlign: 'right',
                                                marginRight: 15
                                            }}>
                                                <Rating
                                                    readonly
                                                    initialRating={feedback.average}
                                                    emptySymbol={<IoMdStar size={25} color={'#C4C4C4'}/>}
                                                    fullSymbol={<IoMdStar size={25} color={'#FEBF35'}/>}/>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                        )
                    }
                    <Col md={12}>
                        {
                            feedbacks.length <= 2 ? null : <Button
                                style={{color: Palette.PRIMARY, fontFamily: 'OpenSans-SemiBold'}}
                                variant={'link'}
                                onClick={() => {
                                    setFeedbackExtended(!isFeedbackExtended)
                                }}>
                                {isFeedbackExtended ? 'Tampilkan Beberapa' : 'Tampilkan Semua Ulasan'}
                            </Button>
                        }

                    </Col>
                </Row>
            </Container>
        </>
    )

}
