import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../../models/Member";
import UserParticipationModal from "../../reusable/modals/UserParticipationModal";
import EditMerchandiseModal from "../../reusable/EditMerchandiseModal";
import {CSVLink} from "react-csv";
import Merchandise from "../../../models/Merchandise";
import no_image from "../../../asset/no_image.png";
import textFormatter from "../../../util/textFormatter";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let merchandise;

export default function Profile(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const [userList, setUserList] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [isClassModalVisible, setClassModalVisible] = useState(false)

    const [selectedMerchandise, setSelectedMerchandise] = useState({});

    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [isDelete, setIsDelete] = useState(false)

    let columns = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Nama",
            accessor: "name"
        },
        {
            Header: "Foto Utama",
            accessor: "image_url",
            Cell : (cellInfo)=>{
                console.log('foto', cellInfo.value);

                // console.log(memberData)

                return <>
                        <img
                            src={cellInfo.value ? cellInfo.value : no_image}
                            style={{
                                width: 150,
                                height: 150
                            }}
                        />
                    </>
            }
        },
        {
            Header: "Deskripsi",
            accessor: "description",
            Cell: cellInfo => {
                return(
                    <div style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 200
                    }}>
                        {cellInfo.value}
                    </div>
                )
            }
        },
        {
            Header: "Harga",
            accessor: "price",
            Cell: (cellInfo) => {
                return 'Rp'+textFormatter.moneyFormatter(cellInfo.value)
            }
        },
        {
            Header: "Link Pembelian",
            accessor: "marketplace_link",
            Cell: cellInfo => {
                return(
                    <div style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: 200
                    }}>
                        {cellInfo.value}
                    </div>
                )
            }
        }
    ]

    merchandise = new Merchandise();

    const interactions = [
        {
            name: "Sunting",
            action: (registrant) => {
                setSelectedUserInfo(registrant)
                setUserInfoModalVisible(true)
                setIsNew(false)
            },
            style: {width: 100}
        },
        {
            name: "Hapus",
            action: (member) => {
                setSelectedMerchandise(member)
                setIsDelete(true)
            },
            style: {width: 100}
        },
        {
            name: "Aktivasi",
            action: (member) => {
               activateUser(member.id)
            },
            condition: (member) => {
                return !member.activated && member.is_claimed
            },
            style: {width: 100},

        }
    ];


    const deletedUserInteraction = [
        {
            name: "Pulihkan",
            action: (member) => {
                unDeleteMerchandise(member.id)
            },
            style: {width: 100}
        }
    ];


    useEffect(() => {
        getMerchandiseData()
    }, [])

    const getMerchandiseData = async () => {
        setIsLoadingBar(true)
        try {
            let result = await merchandise.getAll()
            console.log('Merchandise List', result);

            let tempUsers = []
            let tempDeletedUsers = []

            for(let r of result){
                if(r.active){
                    tempUsers.push(r)
                }else{
                    tempDeletedUsers.push(r)
                }
            }

            setUserList(tempUsers)
            setDeletedUsers(tempDeletedUsers)

            setIsLoadingBar(false)


        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const closeUserParticipationModal = () => {
        setClassModalVisible(false)
        setSelectedMerchandise({})
    }

    const activateUser = async(id) =>{

        try{
            let member = new Member();
            let result = await member.activate(id)

            getMerchandiseData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const deleteMerchandise = async() =>{

        try{
            let merchandise = new Merchandise();
            let result = await merchandise.deleteById(selectedMerchandise.id)

            setIsDelete(false)

            getMerchandiseData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const unDeleteMerchandise = async(id) =>{

        try{
            console.log('id to be restored', id)
            let merchandise = new Merchandise();
            let result = await merchandise.restoreById(id)

            getMerchandiseData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const getNewsletterRegistrant = () =>{
        let filteredRegistrant = userList.filter((obj)=>{return obj.active})
        return filteredRegistrant.map((obj, key) => {
            return {
                "Id" : obj.id,
                "Nama" : obj.name,
                "Deskripsi" : obj.description,
                "Harga" : obj.price,
                "Link Pembelian" : obj.marketplace_link,
                "Link Photo" : obj.image_url

            }
        })
    }

    return (
        <>

            <Dialog open={isDelete}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Peserta
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus merchandise <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedMerchandise.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setIsDelete(false)
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={deleteMerchandise}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditMerchandiseModal
                isOpen={isUserInfoModalVisible}
                onClose={() => {
                    setUserInfoModalVisible(false)
                    setSelectedUserInfo({})
                    getMerchandiseData()
                }}
                merchandise={selectedUserInfo}
                isNew={isNew}
            />

            <UserParticipationModal
                isOpen={isClassModalVisible}
                onClose={closeUserParticipationModal}
                user={selectedMerchandise}
            />

            {/*<UserInfoModal*/}
            {/*    show={isUserInfoModalVisible}*/}
            {/*    onClose={() => setUserInfoModalVisible(false)}*/}
            {/*    member={selectedUserInfo}*/}
            {/*/>*/}

            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"merchandise"}
            >
                <>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 0}}>
                        <div style={{
                            marginTop: "0",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Kreasi
                        </div>
                    </div>


                    <Tabs defaultActiveKey="active">
                        <Tab
                            eventKey="active"
                            title={
                                "Aktif"
                            }
                        >
                            <Row style={{marginLeft: 0, marginTop: 20}}>
                                <Button variant={'outline-primary'}
                                        onMouseEnter={() => setBtnHovered(true)}
                                        onMouseLeave={() => setBtnHovered(false)}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 40,
                                            fontSize: '.8em',
                                            fontFamily: 'OpenSans-SemiBold',
                                            color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                            width: 170,
                                            marginLeft: 0,
                                            marginRight: 15
                                        }}
                                        onClick={() => {
                                            setIsNew(true);
                                            setUserInfoModalVisible(true)
                                            setSelectedUserInfo({})
                                        }}
                                >
                                    + <span style={{marginLeft: 6}}>Kreasi Baru</span>
                                </Button>

                                {/*<CSVLink*/}
                                {/*    style={{color: 'white', fontFamily: 'OpenSans-SemiBold', fontSize: '.8em',  display: 'flex',*/}
                                {/*        alignItems: 'center',*/}
                                {/*        justifyContent: 'center',}}*/}
                                {/*    separator={";"}*/}
                                {/*    data={getNewsletterRegistrant()}*/}
                                {/*    filename={`data-log.csv`}*/}
                                {/*    className="btn btn-primary"*/}
                                {/*    target="_blank"*/}
                                {/*>*/}
                                {/*    Download Data Log&nbsp;&nbsp;<FaFileDownload/>*/}
                                {/*</CSVLink>*/}
                            </Row>

                            <MobTable
                                columns={columns}
                                data={userList}
                                interactions={interactions}
                            />
                        </Tab>
                        <Tab
                            eventKey="deleted"
                            title={
                                "Terhapus"
                            }
                        >
                            <MobTable
                                columns={columns}
                                data={deletedUsers}
                                interactions={deletedUserInteraction}
                            />
                        </Tab>
                    </Tabs>



                </>
            </EditorPageWrapper>
        </>
    );

}
