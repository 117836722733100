import React, {useEffect, useMemo, useState} from "react";
import StreamingModel from "../../../models/LiveStreaming";
import moment from "moment";
import EditorPageWrapper from "./EditorPageWrapper";

import MobTable from "../../reusable/Table/MobTable";
import Palette from "../../../util/Palette";
import {Button, Col, Row} from "react-bootstrap";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {useHistory} from "react-router-dom";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import CustomButton from "../../reusable/CustomButton";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";

export default function LiveStreamingPage() {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [streamingData, setStreamingData] = useState([]);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isHardDelete, setHardDelete] = useState(false)
    const [isChangeStatus, setChangeStatus] = useState(false)
    const [valueChangeStatus, setValueChangeStatus] = useState(true)
    const [selectedStream, setSelectedStream] = useState({});

    let history = useHistory()
    const streamModel = new StreamingModel();

    const getLiveStreamingData = async () => {
        let stream = await streamModel.getAll();

        setStreamingData(stream);
    }
    useEffect(() => {
        getLiveStreamingData()
    }, [])

    const hardDeleteStream = async () => {
        try {
            const result = await streamModel.hardDelete(selectedStream.code);
            console.log(result)
            if (result.success) {
                alert('Siaran Langsung ' + selectedStream.name + ' berhasil dihapus!')
                getLiveStreamingData()
                setHardDelete(false);
                history.push("/editor/live-streaming");
            }
        } catch (e) {
            setErrorMsg(e)
        }
    }

    const saveChangeStatus = async () => {
        try {
            if(valueChangeStatus){
                const result = await streamModel.activeStream(selectedStream.code);
                if (result.success) {
                    alert('Siaran Langsung ' + selectedStream.name + ' berhasil di Aktifkan')
                    getLiveStreamingData()
                    setChangeStatus(false);
                    history.push("/editor/live-streaming");
                }
            }else{
                const result = await streamModel.inactiveStream(selectedStream.code);
                if (result.success) {
                    alert('Siaran Langsung ' + selectedStream.name + ' berhasil di Non Aktifkan')
                    getLiveStreamingData()
                    setChangeStatus(false);
                    history.push("/editor/live-streaming");
                }
            }

        } catch (e) {
            setErrorMsg(e)
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: "Kode",
                accessor: "code",

            },
            {
                Header: "Judul",
                accessor: "name",

            },
            {
                Header: "Url Video",
                accessor: "url_video",

            },
            {
                Header: "Status",
                accessor: "active",
                Cell: (cellInfo) => {
                    let rowInfo = cellInfo.row.values;
                    console.log(rowInfo.active);
                    return <>
                        {rowInfo.active === 1 ? 'Aktif' : 'Tidak Aktif'}
                    </>
                }
            },

        ]
    )

    const interactions = [
        {
            name: "Kelola",
            action: (obj) => {
                history.push("/editor/detail-streaming/" + obj.code)
            },
            style: {width: 135},
            condition: (classObj) => {
                return true
                // return classObj.active
            },
        },
        {
            name: "Pulihkan",
            action: (obj) => {
                setSelectedStream(obj)
                setValueChangeStatus(true)
                setChangeStatus(true)
            },
            variant: 'primary',
            style: {width: 135},
            condition: (classObj) => {
                return classObj.active === 0
            },
        },
        {
            name: "Nonaktifkan",
            action: (obj) => {
                setSelectedStream(obj)
                setValueChangeStatus(false)
                setChangeStatus(true)
            },
            variant: 'secondary',
            style: {width: 135},

            condition: (classObj) => {
                return classObj.active === 1
            },
        },
        {
            name: "Ke Siaran",
            action: (obj) => {
                window.location.href = 'https://sinara.narabahasa.id/siaran-langsung/' + obj.code;
            },
            variant: 'secondary',
            style: {width: 135},

            condition: (classObj) => {
                return classObj.active === 1
            },
        },
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedStream(obj)
                setHardDelete(true)
            },
            variant: 'secondary',
            style: {width: 135},
        },
    ];

    return (
        <EditorPageWrapper
            activeTab={"liveStreaming"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >

            <Dialog open={isChangeStatus}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => {
                        setHardDelete(false)
                        setSelectedStream({})
                    }}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                             Konfirmasi {valueChangeStatus === true ? 'Aktifkan' : 'Non Aktifkan'} Siaran Langsung
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin {valueChangeStatus === true ? 'Aktifkan' : 'Non Aktifkan'} kelas <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedStream.name}</span>?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setChangeStatus(false)
                                    setSelectedStream({})
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={saveChangeStatus}
                                variant={"contained"}
                                color="primary">
                                Konfirmasi
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <Dialog open={isHardDelete}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => {
                        setHardDelete(false)
                        setSelectedStream({})
                    }}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus kelas <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedStream.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setHardDelete(false)
                                    setSelectedStream({})
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={hardDeleteStream}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    fontFamily: "OpenSans-Bold",
                    marginRight: 25
                }}>
                    Siaran Langsung
                </div>


                <Row style={{marginLeft: 2, marginTop: 15}}>
                    <Button variant={'outline-primary'}
                            onMouseEnter={() => setBtnHovered(true)}
                            onMouseLeave={() => setBtnHovered(false)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 30,
                                padding: 5,
                                fontSize: '.8em',
                                fontFamily: 'OpenSans-SemiBold',
                                color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                width: 200,
                                marginLeft: 5
                            }}
                            onClick={() => history.push("/editor/create-streaming")}
                    >
                        + <span style={{marginLeft: 6}}>Buat Tautan Streaming</span>
                    </Button>
                </Row>
            </div>
            <>
                <MobTable columns={columns}
                          data={streamingData}
                          interactions={interactions}
                />
            </>

        </EditorPageWrapper>
    )
}