import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Col, Row, Tooltip} from "react-bootstrap";
import Palette from "../../util/Palette";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaSave, FaTimes} from "react-icons/fa";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomButton from "./CustomButton";
import EditorPageWrapper from "../page/editor/EditorPageWrapper";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdEvent} from "react-icons/md";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Member from "../../models/Member";
import ProvincesWithCities from "../../models/ProvincesWithCities";
import MobTable from "./Table/MobTable";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Badge from "@material-ui/core/Badge";
import textFormatter from "../../util/textFormatter";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Class from "../../models/Class";
import Course from "../../models/Course";

export default function UpcomingEventEditPriceModal(props) {

    const [errorMsg, setErrorMsg] = useState('');

    const [checkedCodes, setCheckedCodes] = useState([])

    const {classes, isOpen, onClose, privateClass} = props

    const [newPrice, setNewPrice] = useState(0)

    const [isConfirming, setIsConfirming] = useState(false)

    useEffect(()=>{
        setCheckedCodes([])
        setNewPrice(0)
        setIsConfirming(false)
    },[isOpen])

    const updateBatch = async() => {

        try{
            let classModel = new Class();
            let courseModel = new Course();

            if(privateClass) {
                await courseModel.updatePriceBatch(checkedCodes,newPrice)
            } else {
                await classModel.updatePriceBatch(checkedCodes,newPrice)
            }

            onClose(true)
        }catch(e){
            console.log(e)
        }

    }

    let columns = [
        {
            Header: <>
                <Checkbox
                    checked={checkedCodes.length === classes.length}
                    onChange={() => {
                        if (checkedCodes.length === classes.length) {
                            setCheckedCodes([])
                        } else {
                            let temp;

                            if(privateClass) {
                                temp = classes.map(obj => obj.id)
                            } else {
                                temp = classes.map(obj => obj.code)
                            }

                            setCheckedCodes(temp)
                        }
                    }}
                />
            </>,
            accessor: "code",
            Cell: (cellInfo) => {

                let code = cellInfo.row.values.code

                if(privateClass) {
                    code = cellInfo.row.original.id
                }

                return <Checkbox
                    onChange={(value) => {
                        let temp = [...checkedCodes]

                        if (checkedCodes.includes(code)) {

                            let index = checkedCodes.findIndex((obj) => obj === code)

                            if (index >= 0) {
                                temp.splice(index, 1)
                            }

                        } else {
                            temp.push(code)
                        }
                        setCheckedCodes(temp)
                    }}
                    checked={checkedCodes.includes(code)}
                />
            },
            filterable: false,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: "Nama",
            accessor: "name",
            Cell: (cellInfo) => {
                console.log('cellInfo', cellInfo.row)

                return <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <p>
                        {privateClass ? cellInfo.row.original.title : cellInfo.row.values.name} {cellInfo.row.original.active ? null : <span style={{
                        backgroundColor: Palette.SECONDARY,
                        color: 'white',
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 20,
                        marginLeft: 5,
                        fontFamily: 'OpenSans-SemiBold',
                        fontSize: '.9em'
                    }}>Disembunyikan</span>}</p>
                </div>
            }
        },
        {
            Header: "Tanggal & Waktu",
            accessor: "start_time",
            Cell: (cellInfo) => {
                if (moment(cellInfo.row.original.class_date).format('DD MMM YYYY') === moment(cellInfo.row.original.end_time).format('DD MMM YYYY')) return <p>{new moment(cellInfo.row.original.class_date).format("D MMMM YYYY, ") + new moment(cellInfo.row.original.start_time).format("HH:mm - ") + new moment(cellInfo.row.original.end_time).format('HH:mm')}</p>

                return <p>{new moment(cellInfo.row.original.class_date).format("D MMMM YYYY, ") + new moment(cellInfo.row.original.start_time).format("HH:mm - ") + new moment(cellInfo.row.original.end_time).format('D MMMM YYYY, HH:mm')}</p>
            }
        },
        {
            Header: "Harga",
            accessor: "price",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                return <p>Rp{textFormatter.moneyFormatter(rowInfo.price)}</p>
                // return null;
            }
        },
    ];

    if(privateClass) {
        columns.splice(2, 1)
    }

    return <>
        <Dialog
            open={props.isOpen}
            maxWidth="md"
            fullWidth={true}
        >
            <>
                <DialogTitle onClose={props.onClose}>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            // fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            color: Palette.PRIMARY
                        }}>
                            Ubah Harga
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <IconButton
                                aria-label="close"
                                onClick={props.onClose}
                            >
                                <FaTimes fontSize={20}/>
                            </IconButton>
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <Collapse
                        in={errorMsg.length > 0}
                        style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                    >
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setErrorMsg("");
                                    }}
                                >
                                    <FaTimes fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {errorMsg}
                        </Alert>
                    </Collapse>

                    <MobTable
                        columns={columns}
                        data={props.classes}
                    />

                    <Row>
                        <Col md={6}>

                        </Col>
                        <Col md={6}>

                        </Col>
                    </Row>

                </DialogContent>
                <DialogActions>
                    <Row>
                        <Col md={12} style={{
                            display : "flex",
                            flexDirection : "row",
                            alignItems : "flex-end"
                        }}>
                            <LagFreeTextEditor
                                label={"Harga Baru"}
                                value={newPrice}
                                placeholder={"100000"}
                                changeValue={value => {
                                    setNewPrice(value)
                                }}
                            />
                            <div>
                                <CustomButton
                                    disabled={checkedCodes.length === 0}
                                    onClick={()=>{setIsConfirming(true)}}
                                    style={{marginRight: 10, marginLeft : 10}}
                                    disableElevation
                                    color={"primary"}
                                    variant={"contained"}
                                >Ubah Harga</CustomButton>
                            </div>

                        </Col>
                    </Row>

                </DialogActions>
                {/*{renderModalFooter()}*/}
            </>
        </Dialog>
        <Dialog
            open={isConfirming}
            maxWidth="sm"
            fullWidth={true}
        >
            <>
                <DialogTitle onClose={() => setIsConfirming(false)}>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            // fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            color: Palette.PRIMARY
                        }}>
                            Konfirmasi
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <Row>
                        <Col md={12}>
                            Anda akan mengubah harga dari {checkedCodes.length} kelas menjadi Rp{textFormatter.moneyFormatter(newPrice)}
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <Row>
                        <Col md={12}>
                            <CustomButton
                                onClick={()=>setIsConfirming(false)}
                                style={{marginRight : 10}}
                                disableElevation
                                color={"primary"}
                            >Batal</CustomButton>
                            <CustomButton
                                onClick={()=>{
                                    updateBatch()
                                }}
                                style={{marginRight : 10}}
                                disableElevation
                                color={"primary"}
                                variant={"contained"}
                            >Ok</CustomButton>
                        </Col>
                    </Row>

                </DialogActions>
                {/*{renderModalFooter()}*/}
            </>
        </Dialog>
    </>


}
