import {Component, useEffect, useState} from "react";
import React from "react";

import {useCookies} from 'react-cookie';
import GlobalData from "../../../util/GlobalData";
import User from "../../../models/User"

import {
    isMobile
} from "react-device-detect";

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Palette from "../../../util/Palette";
import {IconContext} from "react-icons";
import CustomDrawer from "../../reusable/CustomDrawer";
import {useHistory, useLocation} from "react-router-dom";
import EditorHeader from "../../reusable/EditorHeader";
import {Container, Spinner} from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";

import CustomButton from "../../reusable/CustomButton";
import {animateScroll as scroll} from "react-scroll";
import Instructor from "../../../models/Instructor";

let scrollbarComponent;

const scrollToTop = () => {
    if (scrollbarComponent) {
        scrollbarComponent.contentElement.parentElement.scrollTo({
            behavior: 'smooth',
            top: 0
        });
    }
}

export default function EditorPageWrapper(props) {
    const location = useLocation();
    /*
    const scrollToTop = () =>{
        if(scrollbarComponent){
            scrollbarComponent.contentElement.parentElement.scrollTo({
                behavior: 'smooth',
                top: 0
            });
        }
    }*/

    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
    const [userData, setUserData] = useState(GlobalData.user);
    const history = useHistory()
    const routerLocation = useLocation()
    let [toggler, setToggler] = useState(0)
    let [isSideBarOpen, setIsSideBarOpen] = useState(!isMobile)
    const [menu, setMenu] = useState([]);

    let redirectToForbidden = () => {
        GlobalData.token = ""

        removeCookie("token")

        history.push({
            pathname: "/forbidden",
            state: {
                route: routerLocation.pathname.replace("/", "")
            }
        })
    }

    useEffect(() => {
        setToggler(toggler + 1)
    }, [routerLocation.pathname])

    let doLogin = async () => {
        console.log("do login is triggered with ", cookie.token, GlobalData.token)

        if (!GlobalData.token && cookie.token) {

            try {
                console.log("There is Token " + cookie.token)
                let userModel = new User()
                let instructorModel = new Instructor()
                GlobalData.token = cookie.token
                let user;

                if (cookie.userType === 'admin' || cookie.userType === 'superadmin') {
                    user = await userModel.tokenLogin()
                } else {
                    user = await instructorModel.tokenLogin()
                }

                setMenu(JSON.parse(user.menu));
                setUserData(user)

                console.log("get user ", user)

                console.log('get user (2)', window.location.href)
                GlobalData.user = user
                setToggler(toggler + 1)
            } catch (e) {
                console.log("NO HERE")

                redirectToForbidden()
            }

        } else {
            if (!cookie.token) {
                console.log("NO HERE 2")
                redirectToForbidden()
            }
        }
    }

    useEffect(() => {
        scroll.scrollTo(0, {
            smooth: false,
            duration: 0
        })
        console.log("DBG CSL IS CALLED")
        doLogin()
    }, [])

    useEffect(() => {
        if (menu.length > 0) {
            const url = routerLocation.pathname;

            if (!menu.includes('home')) {
                if (url) {

                }
            }

            // switch () {
            //     case
            // }
        }
    }, [menu])

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: Palette.PRIMARY,
                contrastText: '#fff'
            },
        },
    });

    // console.log("page wrapper")

    return (
        <ThemeProvider theme={theme}>
            <IconContext.Provider value={{style: {verticalAlign: 'middle'}}}>
                <div style={{
                    marginTop: isMobile ? 0 : '22x',
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    scrollBehavior: "smooth"
                }}>

                    <EditorHeader
                        notFound={props.notFound}
                        privateClass={props.privateClass}
                        active={props.headerActive}
                        activeTab={props.activeTab}
                        isSideBarOpen={isSideBarOpen}
                        setIsSideBarOpen={setIsSideBarOpen}
                    />

                    {/*<div style={{height: props.headerShy ? 0 : "8em"}}/>*/}

                    <div style={{
                        marginLeft: isSideBarOpen ? 300 : 0,
                        flex: 1
                    }}>
                        <div style={{
                            height: "100%",
                            width: "100%",
                            marginTop: "7em"
                        }}>

                            {/*

                        <ScrollBar
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                            trackYProps={{
                                renderer: props => {
                                    const { elementRef, ...restProps } = props;
                                    return <span {...restProps} style={{...restProps.style, backgroundColor : "white"}} ref={elementRef} className="trackY" />;
                                }
                            }}
                            smoothScrolling={true}
                            ref={ref=>scrollbarComponent=ref}
                        >

                           */}

                            <Container fluid={isSideBarOpen || isMobile} style={{
                                width: "90%",
                                display: "flex",
                                flexDirection: "column",
                                marginTop: isMobile ? "-6em" : '0em',
                                fontFamily: "Poppins"
                            }}>


                                {
                                    props.isLoadingFull ?
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100vh",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                            <Spinner animation={"grow"} variant={"primary"}/>
                                        </div>
                                        :
                                        <>
                                            {props.children}
                                        </>

                                }
                                {/*<CustomButton*/}
                                {/*    onClick={()=>{*/}
                                {/*        if(scrollbarComponent){*/}
                                {/*            console.log("scrollbar",scrollbarComponent)*/}
                                {/*            scrollbarComponent.contentElement.parentElement.scrollTo({*/}
                                {/*                behavior: 'smooth',*/}
                                {/*                top: 0*/}
                                {/*            });*/}
                                {/*        }else{*/}
                                {/*            console.log("nada")*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>HEOY</CustomButton>*/}

                                {/*<div style={{height: "5em"}}/>*/}


                            </Container>

                            {/*</ScrollBar>*/}
                        </div>
                    </div>

                    {props.isLoadingBar ? <LinearProgress
                        style={{
                            position: "fixed",
                            top: "5.7em",
                            width: "100%",
                            zIndex: 1000
                        }}
                        color="primary"/> : null}


                </div>
            </IconContext.Provider>
        </ThemeProvider>
    );

}
