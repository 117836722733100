import ApiRequest from "../util/ApiRequest";

export default class Complaint {
    getAll = async () => {
        return await ApiRequest.set("/v1/complaint_forms", "GET");
    }

    updateNote = async (complaint) =>{
        return await ApiRequest.set(`/v1/complaint_forms/${complaint.id}/note`, "PUT", {
            note: complaint.note
        });
    }
}
