import {ButtonGroup, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core"
import PropTypes from 'prop-types'
import {Col, Container, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, {useState, useEffect} from "react";
import Topic from "../../models/Topic";
import {FaSave, FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import MiniTooltip from "./MiniTooltip";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Voucher from "../../models/Voucher";


export default function NoteVoucherModal(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }

    NoteVoucherModal.propTypes = {
        voucher: PropTypes.object.isRequired,
        visible: PropTypes.bool.isRequired,
        onSave: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired
    }

    const [currentVoucher, setCurrentVoucher] = useState({})

    useEffect(() =>{
        setCurrentVoucher({...props.voucher})
    }, [props.voucher])


    return (
        <Dialog open={props.visible}
                maxWidth="md"
                fullWidth={true}
        >
            <DialogTitle onClose={() => props.onHide()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        Ubah Catatan
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>


                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Catatan'}
                            changeValue={value => {
                                setCurrentVoucher({
                                    ...currentVoucher,
                                    note: value
                                })
                            }}
                            value={currentVoucher.note}
                            placeholder={"Catatan"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={props.onHide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={() => {props.onSave(currentVoucher)}}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}
