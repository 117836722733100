import React, {useEffect, useState} from "react";
import EditorPageWrapper from "./editor/EditorPageWrapper";
import Order from '../../models/Order'
import MobTable from "../reusable/Table/MobTable";
import textFormatter from "../../util/textFormatter";
import {Button, Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomButton from "../reusable/CustomButton";
import { CSVLink, CSVDownload } from "react-csv";
import {FaFileDownload} from "react-icons/fa";
export default function Transaction() {
    let history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const orderModel = new Order();
    const [orders, setOrders] = useState([])
    const [selectedOrder, setSelectedOrder] = useState({})
    const [isDetailModalVisible, setDetailModalVisible] = useState(false)
    const [listMonths, setListMonths] = useState([])
    const [selectedMonth, setSelectedMonth] = useState("")
    const [filteredMonthData, setFilteredMonthData] = useState([])

    const [monthSelection, setMonthSelection] = useState([])
    const [selectedMonthYear, setSelectedMonthYear] = useState(moment().format("YYYYM"))


    const generateMonthSelection=async ()=> {

        let temp = 0;

        let iteratedDate = new moment()

        const monthYearBottomLimit = {
            month: 9,
            year: 2020
        }

        let months = []

        let currentMonthYear = {
            month: moment().format("M"),
            year: moment().format("YYYY"),
            label : iteratedDate.format("MMM YYYY"),
        }

        while(iteratedDate.isAfter("2021-06-01")){
            months.push({
                label : iteratedDate.format("MMM YYYY"),
                month: iteratedDate.format("M"),
                year: iteratedDate.format("YYYY"),
                value : iteratedDate.format("YYYYM")
            })
            iteratedDate.subtract(1, "month")
        }

        setMonthSelection(months)
        console.log("months", months)

    }

    //function list month For Dropdown
    const getListMonth = () => {
        let arr = [];
        let x = moment();
        let xx = x.format('YYYY-MM');

        let dateStart = moment('2020-09');
        let dateEnd = moment(xx);
        let timeValues = [];
        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
            timeValues.push(dateStart.format('MMMM YYYY'));
            dateStart.add(1,'month');
        }
        setListMonths(timeValues.reverse());

    }

    //function export Data
    const exportToCsv = () => {
        let arr = [];
        if(selectedMonth !== "" && selectedMonth != 0){
            arr = filteredMonthData;
        }else {
            arr = orders;
        }

        return  arr.map((data, key) => {
                let voucher = data.voucher;
                let voucher_amount = !voucher ? '-' : voucher.amount ? 'Rp' + textFormatter.moneyFormatter(voucher.amount) : voucher.percentage + '%';
                let amount = 'Rp' + textFormatter.moneyFormatter(data.amount)
            let status = data.paid_status;

            let statusString = "MENUNGGU PEMBAYARAN"
            //console.log(cellInfo)
            let color = "#000000"
            if (status === "settlement" || status === "capture") {
                statusString = "DITERIMA"
            } else if (status === "expire" || status === "cancel") {
                statusString = "DITOLAK"
            } else if (status === "WAIT_SNAP_NOTIFICATION") {
                statusString = "MENUNGGU PEMBAYARAN"

            }

                return {
                    'ID Transaksi': data.order_id,
                    'Tanggal/Waktu Transaksi' : moment(data.created_at).format('DD-MM-YYYY HH:mm'),
                    'Pengguna' :data.member ? data.member.full_name : '-',
                    'Nominal Transaksi' :  amount,
                    'Voucher' : voucher_amount,
                    'Status' : statusString
                }
            })



    }

    let interactions = [
        {
            name: "Selengkapnya",
            action: (order) => {
                console.log('selectedOrder', selectedOrder)
                setDetailModalVisible(true)
                setSelectedOrder(order)
            }
        },
    ]

    let selectedOrderInteractions = [
        {
            name: "Lihat Kelas",
            action: (order) => {
                if(order.class?.code) {
                    history.push(`/editor/classDetail/${order.class?.code}`)
                } else {
                    history.push(`/editor/privateClassDetail/${order.course_id}`)
                }
                // history.push(`/editor/detail/${}`)
                // history.push(`/editor/privateClassDetail/${}`)
                console.log('selectedOrder', order)
            }
        },
    ]

    const columns = [
        {
            Header: "ID Transaksi",
            accessor: "order_id"
        },
        {
            Header: "Tanggal/Waktu Transaksi",
            accessor: "created_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let created_at = rowInfo["created_at"]

                return <>
                    {created_at ? moment(created_at).format("DD MMM YYYY HH:mm") : '-'}
                </>
            }
        },
        {
            Header: "Pengguna",
            accessor: "member",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let member = rowInfo["member"]

                return <>
                    {member ? member.full_name : '-'}
                </>
            }
        },
        {
            Header: "Nominal Transaksi",
            accessor: "amount",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let amount = rowInfo["amount"]

                return <>
                    Rp{textFormatter.moneyFormatter(amount)}
                </>
            }
        },
        {
            Header: "Voucer",
            accessor: "voucher",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let voucher = rowInfo["voucher"]

                return <>
                    {!voucher ? '-' : voucher.amount ? 'Rp' + textFormatter.moneyFormatter(voucher.amount) : voucher.percentage + '%'}
                </>
            }
        },
        {
            Header: "Status",
            accessor: "paid_status",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let status = rowInfo["paid_status"]

                console.log("RINF", rowInfo)

                let statusString = "MENUNGGU PEMBAYARAN"

                //console.log(cellInfo)
                let color = "#000000"

                if (status === "settlement" || status === "capture") {

                    color = "LimeGreen"
                    statusString = "DITERIMA"


                }
                else if (rowInfo.status === "REJECTED") {
                    color = "Red"
                    statusString = "DITOLAK"

                }
                else if (status === "expire" || status === "cancel" || status === "deny") {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (status === "WAIT_SNAP_NOTIFICATION") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                }

                return <p style={{color: color}}>{statusString}</p>
            }
        },
    ];

    const selectedOrderColumns = [
        {
            Header: "Nama Kelas",
            accessor: "a",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.original

                console.log('rowInfo', cellInfo.row.original)

                return <>{rowInfo.class_code ? rowInfo.class.name : rowInfo.course.title}</>
            }
        },
        {
            Header: "Harga",
            accessor: "b",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.original

                console.log('rowInfo', cellInfo.row.original)

                return <>Rp{rowInfo.price ? textFormatter.moneyFormatter(rowInfo.price) : '-'}</>
            }
        },
    ];

    useEffect(() => {
        getListMonth();
        generateMonthSelection();
    }, [])

    useEffect(() => {
        getAllOrder()
    },[selectedMonthYear])

    useEffect(() => {
        console.log('colokan', selectedOrder)
    }, [selectedOrder])

    const formatDateTime = (d) => {
        return moment(d).format("DD-MM-YYYY HH:mm:ss")
    }

    const getAllOrder = async () => {
        setIsLoadingBar(true)
        try {

            let month, year;

            let findResult = monthSelection.find(obj=>{
                return obj.value === selectedMonthYear
            })

            if(findResult){
                month = findResult.month
                year = findResult.year
            }


            const result = await orderModel.getSomeByDate(month, year);

            console.log('result', result);

            setOrders(result.orders);

        } catch (e) {
            console.log(e)
        }
        setIsLoadingBar(false)

    }

    return (
        <EditorPageWrapper
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
            activeTab={"transaction"}
        >
            <Modal show={isDetailModalVisible} onHide={() => setDetailModalVisible(false)} size={"lg"}>
                <Modal.Header closeButton>
                    Detil Transaksi
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col lg={12}><h3>Info Transaksi</h3></Col>
                    </Row>
                    <Row>
                        <Col lg={4}>ID Transaksi</Col>
                        <Col lg={8}>{selectedOrder.order_id}</Col>
                    </Row>

                    <Row>
                        <Col lg={4}>Status Registrasi</Col>
                        <Col lg={8}
                             style={{color: selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ? 'LimeGreen' : selectedOrder.paid_status === 'cancel' || selectedOrder.paid_status === 'expire' || selectedOrder.paid_status === 'deny' ? 'Red' : 'MediumTurquoise'}}>{selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ? 'DITERIMA' : selectedOrder.paid_status === 'cancel' || selectedOrder.paid_status === 'expire' || selectedOrder.paid_status === 'deny' ? 'DITOLAK' : 'MENUNGGU PEMBAYARAN'}</Col>
                    </Row>

                    <Row>
                        <Col lg={4}>ID Pengguna</Col>
                        <Col lg={8}>{selectedOrder.member ? selectedOrder.member.id : '-'}</Col>
                    </Row>

                    <Row>
                        <Col lg={4}>Nama Lengkap</Col>
                        <Col lg={8}>{selectedOrder.member ? selectedOrder.member.full_name : '-'}</Col>
                    </Row>

                    <Row>
                        <Col lg={4}>Surel</Col>
                        <Col lg={8}>{selectedOrder.member ? selectedOrder.member.email : '-'}</Col>
                    </Row>
                    <Row>
                        <div style={{height: "1em"}}></div>
                    </Row>

                    <Row>
                        <Col lg={4}>Waktu Registrasi Dibuat</Col>
                        <Col lg={8}>{formatDateTime(new Date(selectedOrder?.created_at))}</Col>
                    </Row>

                    {
                        selectedOrder.paid_status === 'settlement' || selectedOrder.paid_status === 'capture' ?
                            <Row>
                                <Col lg={4}>Waktu Pembayaran Diproses</Col>
                                <Col lg={8}>{formatDateTime(new Date(selectedOrder?.paid_at))}</Col>
                            </Row>
                            :
                            null
                    }

                    {
                        selectedOrder.paid_status !== 'settlement' && selectedOrder.paid_status !== 'capture' ?
                            <>
                                <Row>
                                    <Col lg={4}>Waktu Kadaluarsa</Col>
                                    <Col lg={8}>{formatDateTime(new Date(selectedOrder?.should_expire_at))}</Col>
                                </Row>
                            </>
                            : null
                    }

                    {
                        selectedOrder.paid_status === "expire" || selectedOrder.paid_status === "deny" ?
                            <>
                                <Row>
                                    <Col lg={4}>Waktu Ditolak</Col>
                                    <Col lg={8}>{formatDateTime(new Date(selectedOrder?.expired_at))}</Col>
                                </Row>
                            </>
                            : null
                    }

                    <Row>
                        <div style={{height: "1em"}}></div>
                    </Row>

                    <Row>
                        <Col lg={4}>URL pembayaran Midtrans</Col>
                        <Col lg={8}>{selectedOrder?.snap_payment_url ? selectedOrder?.snap_payment_url : "-"}</Col>
                    </Row>

                    <Row style={{marginTop: 20}}>
                        <Col lg={12}><h3>Kelas dalam Transaksi Ini</h3></Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <MobTable columns={selectedOrderColumns}
                                      data={selectedOrder.memberCourses && selectedOrder.participants ? selectedOrder.memberCourses.concat(selectedOrder.participants) : []}
                                      interactions={selectedOrderInteractions}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => setDetailModalVisible(false)}>Tutup</Button>
                </Modal.Footer>
            </Modal>
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                paddingTop: '1em'
            }}
            >
                <Col xs={12} style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
                    <CSVLink
                        style={{color: 'white', marginBottom: 10}}
                        data={exportToCsv()}
                        filename={`data-presensi.csv`}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        Unduh File CSV&nbsp;&nbsp;<FaFileDownload/>
                    </CSVLink>
                </Col>

                <Col md={12} style={{marginBottom: 15}}>
                    <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>
                     Filter Transaksi
                    </div>

                    {/*<Select*/}
                    {/*    style={{marginTop: 6, width: '100%'}}*/}
                    {/*    value={selectedMonth !== "" ? selectedMonth : 0}*/}
                    {/*    onChange={e => {*/}
                    {/*        setSelectedMonth(*/}
                    {/*           e.target.value*/}
                    {/*        )*/}
                    {/*        console.log( e.target.value)*/}
                    {/*        if( e.target.value !== "" && e.target.value != 0){*/}
                    {/*            if(orders.length > 0){*/}
                    {/*                let filtered_data = orders.filter((value) => {*/}
                    {/*                    let _month = moment().month(e.target.value).format("MM-YYYY");*/}
                    {/*                    let created_at_moment = moment(value.created_at).format("MM-YYYY");*/}
                    {/*                    return _month === created_at_moment;*/}

                    {/*                });*/}
                    {/*                console.log('filtered_data',filtered_data);*/}
                    {/*                console.log('orders',orders);*/}
                    {/*                setFilteredMonthData(filtered_data);*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*    }}>*/}
                    {/*    <MenuItem value={0} key={-1}>*/}
                    {/*      Pilih Bulan*/}
                    {/*    </MenuItem>*/}
                    {/*    {listMonths.map((option, key) => (*/}
                    {/*        <MenuItem value={option} key={key}>*/}
                    {/*            {option}*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}

                    <Select
                        style={{marginLeft: 10, marginTop: 6, width : "100%"}}
                        value={selectedMonthYear}
                        onChange={e => {
                            console.log("months", e.target.value)
                            setSelectedMonthYear(e.target.value)
                        }}>
                        {monthSelection.map((option, key) => (
                            <MenuItem value={option.value} key={key}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>

                </Col>

                <Col xs={12}>
                    <MobTable columns={columns} data={(selectedMonth !== "" && selectedMonth != 0)  ? filteredMonthData : orders}
                              interactions={interactions}
                    />
                </Col>
            </Row>
        </EditorPageWrapper>
    )
}
