import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap"

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from 'prop-types'

import 'moment/locale/id'
import {Input, TextField} from "@material-ui/core"
import Email from "../../../../../models/Email"
import moment from "moment"
import AlertBox from "../../../../reusable/AlertBox/AlertBox"
import LagFreeTextEditor from "../../../../reusable/LagFreeTextEditor";
import ApiRequest from "../../../../../util/ApiRequest"
import Participation from "../../../../../models/Participation"


export default function RegistrantNoteDialog({registrant, isOpen, onClose, onSaveSuccess}) {

    RegistrantNoteDialog.propTypes = {
        registrant: PropTypes.object,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onSaveSuccess: PropTypes.func
    }

    let [currentNote, setCurrentNote] = useState("")

    const saveNote = () => {
        let participantId = registrant.id
        let note = currentNote
        let p = new Participation()
        p.updateNote(participantId, note).then(response=>{
            onSaveSuccess({
                ...registrant,
                note: note
            })
        }).catch(err=> {
            alert("Error just occured. Check Console.")
            console.error(err)
        })
    }

    useEffect(() =>{
        setCurrentNote(registrant.note || "")
    }, [registrant])

    return(<Modal show={isOpen} onHide={onClose} size={"lg"}>
        <Modal.Header closeButton>
            Catatan Admin untuk Peserta ID {registrant.id} | {registrant.full_name} | {registrant.email}
        </Modal.Header>
        <Modal.Body>
            <LagFreeTextEditor multiline row={4} value={currentNote} changeValue={(value) => {setCurrentNote(value)}}/>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onClose}>Tutup</Button>
            <Button onClick={saveNote}>Simpan</Button>
        </Modal.Footer>
    </Modal>)

}
