import {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React from "react";
import moment from "moment";
import {
    isMobile
} from "react-device-detect";

export default class CountdownRow extends Component {

    constructor(props){
        super(props)

        let {startTime, endTime} = this.props

        console.log(startTime.format("dd mm yyyy"))
        console.log(endTime.format("dd mm yyyy"))

        this.state={
            currentTime : new moment()
        }


    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({currentTime : new moment()}), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){

        let {startTime, endTime} = this.props
        let currentTime = this.state.currentTime

        let deltaDay = startTime.diff(currentTime, 'days');
        let deltaHour = startTime.diff(currentTime, 'hour') - deltaDay * 24;
        let deltaMinutes = startTime.diff(currentTime, 'minutes') - deltaHour * 60 - deltaDay * 60 * 24;
        let deltaSeconds = startTime.diff(currentTime, 'seconds') - deltaMinutes * 60 - (deltaHour * 60 + deltaDay * 60 * 24) * 60 ;

        // console.log("hi" , deltaDay, deltaHour, deltaMinutes, deltaSeconds)

        return <Row
                style={{
                    justifyContent: 'center', paddingTop: 10,
                    ...this.props.containerStyle
                }}>
            {
                this.props.text ?
                    <Col lg={12}>
                        <h1 style={{
                            fontSize: "3em",
                            textAlign : "center"
                        }}>
                            {this.props.text}
                        </h1>
                    </Col> : null
            }

            <Col xs={{span : 2}} style={{justifyContent: 'center'}}>
                <h1 style={{
                    fontSize: "4em",
                    textAlign : "center"
                }}>
                    {deltaDay}
                </h1>
                <h3 style={{
                    textAlign : "center"
                }}>
                    HARI
                </h3>
            </Col>
            <Col xs={{offset : isMobile ? 1 : 0, span : 2}} style={{justifyContent: 'center'}}>
                <h1 style={{
                    fontSize: "4em",
                    textAlign : "center"
                }}>
                    {deltaHour}
                </h1>
                <h3 style={{
                    textAlign : "center"
                }}>
                    JAM
                </h3>
            </Col>
            <Col xs={{offset :  isMobile ? 1 : 0, span : 2}} style={{justifyContent: 'center'}}>
                <h1 style={{
                    fontSize: "4em",
                    textAlign : "center"
                }}>
                    {deltaMinutes}
                </h1>
                <h3 style={{
                    textAlign : "center"
                }}>
                    MENIT
                </h3>
            </Col>
            <Col xs={{offset :  isMobile ? 1 : 0, span : 2}} style={{justifyContent: 'center'}}>
                <h1 style={{
                    fontSize: "4em",
                    textAlign : "center"
                }}>
                    {deltaSeconds}
                </h1>
                <h3 style={{
                    textAlign : "center"
                }}>
                    DETIK
                </h3>
            </Col>
        </Row>

    }
}
