import Palette from "../../../util/Palette";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import React, {useEffect, useMemo, useState} from "react";
import EditorPageWrapper from "./EditorPageWrapper";
import {Col, Row} from "react-bootstrap";
import CustomButton from "../../reusable/CustomButton";
import streamModel from "../../../models/LiveStreaming";
import {AiFillWarning, MdEvent, MdSchedule} from "react-icons/all";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {useHistory, useParams, useLocation} from "react-router-dom";
import StreamingModel from "../../../models/LiveStreaming";
import {PUBLISH_STATUS} from "../../../models/Event";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

export default function LiveStreamingEditor(props) {
    let history = useHistory()

    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [streamData, setStreamData] = useState({})
    const [errorMsg, setErrorMsg] = useState('');
    const event_url = useParams()
    const [activeEventURL, setActiveEventURL] = useState(event_url.code ? 'update' : 'create')

    const getLiveStreamingData = async () => {
        const streamModel = new StreamingModel();
        let stream = await streamModel.getByCode(event_url.code);
        setStreamData(stream);
    }

    useEffect(() => {

        if (activeEventURL === 'update') {
            getLiveStreamingData();
        }
    }, [])


    const saveStream = async () => {
        if (!streamData.name || !streamData.url_video) {
            setErrorMsg('Mohon mengisi formulir dengan lengkap')
        } else {
            let model_stream = new streamModel();
            try {

                let result = [];
                if (activeEventURL === 'create') {
                    result = await model_stream.insert({
                        ...streamData,
                    })
                    if (result.name) {
                        alert('Siaran Langsung berhasil ditambahkan!');
                        history.push("/editor/live-streaming")
                    }
                } else {
                    result = await model_stream.update({
                        name: streamData.name,
                        url_video: streamData.url_video,
                        stream_finish : streamData.stream_finish
                    }, streamData.code)
                    if (result) {
                        alert('Siaran Langsung berhasil diubah!');
                        history.push("/editor/live-streaming")
                    }
                }

            } catch (e) {
                console.log(e)
            }
        }
    }


    return (
        <EditorPageWrapper
            activeTab={"liveStreaming"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <>

                    <div style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.75em",
                        fontFamily: "OpenSans-Bold",
                        marginRight: 25
                    }}>Siaran Langsung
                    </div>

                    <Collapse
                        in={errorMsg.length > 0}
                        style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                    >
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setErrorMsg("");
                                    }}
                                >
                                    <FaTimes fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {errorMsg}
                        </Alert>
                    </Collapse>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: "1em",
                        fontFamily: 'OpenSans-SemiBold'
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Kode'}
                                disabled={activeEventURL === 'update'}
                                changeValue={value => setStreamData({
                                    ...streamData,
                                    code: value
                                })}
                                value={streamData.code}
                                subtitle={'(akan dibuat secara acak jika dibiarkan kosong)'}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: "1em",
                        fontFamily: 'OpenSans-SemiBold'
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Judul'}
                                changeValue={value => setStreamData({
                                    ...streamData,
                                    name: value
                                })}
                                value={streamData.name}
                                placeholder={"Judul Streaming"}/>
                        </Col>
                    </Row>
                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: "1em",
                        fontFamily: 'OpenSans-SemiBold'
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Tautan Url'}
                                changeValue={value => setStreamData({
                                    ...streamData,
                                    url_video: value
                                })}
                                value={streamData.url_video}
                                placeholder={"Tautan Url"}/>
                        </Col>
                        <div style={{
                            color: "grey",
                            // fontWeight: "lighter"
                        }}>
                            <AiFillWarning
                                style={{
                                    marginRight: 5,
                                    marginLeft: 10,
                                    color: Palette.ORANGE,
                                    fontSize: "1.2em"
                                }}/>
                            Peringatan : Pastikan URL yang diketik valid (contoh :
                            https://www.youtube.com/watch?v=UEFgW--0094)
                        </div>
                    </Row>
                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: "1em",
                        fontFamily: 'OpenSans-SemiBold',
                    }}>
                        <Col md={6}>
                            <span style={{
                                fontSize: ".9rem",
                                fontFamily: 'OpenSans-SemiBold'
                            }}>Tanggal Siaran Berakhir</span>
                            <DatePicker
                                allowKeyboardControl={false}
                                // disablePast={true}
                                autoOk={true}
                                // disabled={publishStatus === PUBLISH_STATUS.DONE}
                                // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                                style={{
                                    marginLeft: "0.5em",
                                    width: "15em",
                                    cursor: "pointer",
                                    fontSize: '1.2em'
                                }}
                                format="D MMM YYYY"
                                InputProps={{
                                    style: {
                                        fontSize: "1em",
                                        fontFamily: "OpenSans-Regular",
                                        cursor: "pointer"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <MdEvent style={{color: Palette.PRIMARY}}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={streamData.stream_finish}
                                onChange={date => {

                                    let startTime = new moment(streamData.stream_finish)

                                    date.set({
                                        hour: startTime.hour(),
                                        minute: startTime.minute()
                                    })

                                    setStreamData(
                                        {
                                            ...streamData,
                                            stream_finish: date,
                                        }
                                    )

                                }}
                                placeholder={`Tanggal Siaran Berakhir`}/>
                        </Col>
                        <Col md={6}>
                            <span style={{
                                fontSize: ".9rem",
                                fontFamily: 'OpenSans-SemiBold'
                            }}>Waktu Siaran Berakhir</span>
                            <TimePicker
                                okLabel={"Simpan"}
                                cancelLabel={"Batal"}
                                ampm={false}
                                allowKeyboardControl={false}
                                autoOk={true}
                                style={{
                                    marginLeft: "0.5em",
                                    width: "15em",
                                    cursor: "pointer",
                                    fontSize: '1.2em'
                                }}
                                format="HH:mm"
                                InputProps={{
                                    style: {
                                        fontSize: "1em",
                                        fontFamily: "OpenSans-Regular",
                                        cursor: "pointer"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton>
                                                <MdSchedule style={{color: Palette.PRIMARY}}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} value={streamData.stream_finish}
                                onChange={(value) => {

                                    let startDate = new moment(streamData.stream_finish)
                                    let newValue = new moment(value)

                                    startDate.set({
                                        hour: newValue.hour(),
                                        minute: newValue.minute()
                                    })

                                    setStreamData({
                                        ...streamData,
                                        stream_finish: startDate
                                    })

                                }}
                                placeholder={`Waktu Batas Bertanya*`}/>
                        </Col>
                    </Row>
                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: "1em",
                        fontFamily: 'OpenSans-SemiBold'
                    }}
                    >
                        <Col md={12}>
                            <div class="float-right">
                                <CustomButton
                                    onClick={saveStream}
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"contained"}
                                    color="primary">
                                    Simpan
                                </CustomButton>
                            </div>

                        </Col>
                    </Row>


                </>
            </MuiPickersUtilsProvider>
        </EditorPageWrapper>
    )
}
