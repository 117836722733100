import ApiRequest from "../util/ApiRequest";

export default class Dashboard {
    getData = async () => {
        return await ApiRequest.set(`/v1/dashboard/data`, "GET");
    }

    getFeedbacks = async (page) => {
        console.log(page)
        return await ApiRequest.set(`/v1/dashboard/feedbacks?page=${page}`, "GET");
    }

    getQuestions = async (page) => {
        return await ApiRequest.set(`/v1/dashboard/questions?page=${page}`, "GET");
    }

    getFilteredUserByCity = async (city) => {
        return await ApiRequest.set(`/v1/dashboard/filteredUser?city=${city}`, "GET");
    }

    getFilteredUserByGender = async (gender) => {
        return await ApiRequest.set(`/v1/dashboard/filteredUser?gender=${gender}`, "GET");
    }

    getFilteredUserBySinaraRegisterDate = async (registerDate) => {
        console.log(`/v1/dashboard/filteredUser?registerDate=${registerDate}`)

        return await ApiRequest.set(`/v1/dashboard/filteredUser?registerDate=${registerDate}`, "GET");
    }

    getFilteredUserByRegisterDate = async (registerDate) => {
        return await ApiRequest.set(`/v1/dashboard/filteredUserByRegistrationDate?registerDate=${registerDate}`, "GET");
    }

    getFilteredUserByAge = async (minAge,maxAge) => {
        return await ApiRequest.set(`/v1/dashboard/filteredUserByAge?minAge=${minAge}&maxAge=${maxAge}`, "GET");
    }

    getWeeklyData = async (course_id) => {
        return await ApiRequest.set(`/v1/dashboard/weeklyCourseRegistrations/`+course_id, "GET");
    }

    getAllWeeklyData = async (course_id) => {
        return await ApiRequest.set(`/v1/dashboard/weeklyCourseRegistrations`, "GET");
    }

    getFilteredCourseUserByRegisterDateRange = async (startDate,endDate) => {
        return await ApiRequest.set(`/v1/dashboard/filteredUserByCourseRegistrationDateRange?startDate=${startDate}&endDate=${endDate}`, "GET");
    }

}
