import ApiRequest from "../util/ApiRequest";

export default class Voucher {

    getAll = async () => {
        return await ApiRequest.set("/v1/vouchers", "GET");
    }

    create = async (voucher) => {
        return await ApiRequest.set("/v1/vouchers", "POST", voucher);
    }

    createBatch = async (voucher) => {
        return await ApiRequest.set("/v1/vouchers/batch", "POST", voucher);
    }

    deleteVoucher = async (voucher) => {
        return await ApiRequest.set("/v1/voucher/"+voucher, "DELETE");
    }

    updateNote = async (voucher) =>{
        return await ApiRequest.set(`/v1/voucher/${voucher.code}/note`, "PUT", {
            note: voucher.note
        });
    }

    deactivate = async (code) =>{
        return await ApiRequest.set(`/v1/voucher/${code}/deactivate`, "POST");
    }

    activate = async (code) =>{
        return await ApiRequest.set(`/v1/voucher/${code}/activate`, "POST");
    }

}
