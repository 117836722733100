import ApiRequest from "../util/ApiRequest";

export default class ClassInstructors {
    getByInstructorId = async () => {
        return await ApiRequest.set(`/v1/class_instructors`, "GET");
    }

    create = async (body) => {
        return await ApiRequest.set(`/v1/class_instructors/create`, "POST", body);
    }

    getByClassCode = async (code) => {
        return await ApiRequest.set(`/v1/class_instructors/${code}/class`, "GET");
    }

    delete = async (id) => {
        return await ApiRequest.set(`/v1/class_instructors/${id}`, "DELETE");
    }
}
