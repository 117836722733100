import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Col, Row, Spinner} from "react-bootstrap";
import Palette from "../../../util/Palette";
import FileUpload from "../FileUpload";
import Papa from "papaparse";
import QuestionModel from "../../../models/Question";
import MobTable from "../Table/MobTable";
import CustomButton from "../CustomButton";
import React, {useMemo, useState} from "react";
import moment from "moment";
import {FaFileDownload} from "react-icons/all";
import {CSVLink} from "react-csv";
import Class from "../../../models/Class";
import Member from "../../../models/Member";
import {
    CAlert,
    CButton,
    CCol,
    CCollapse,
    CDataTable,
    CModal,
    CModalBody,
    CModalHeader,
    CPagination,
    CRow
} from "@coreui/react";

export default function BatchMemberRegisterModal(props) {

    const [uploadedUsers, setUploadedUsers] = useState([])
    const [uploadResult, setUploadResult] = useState({});

    const [isLoading, setIsLoading] = useState(false)

    const stagedQuestionColumns = useMemo(() => [
        {
            Header: "No.",
            accessor: "idx"
        },
        {
            Header: "Surel",
            accessor: "email"
        },
        {
            Header: "Nama",
            accessor: "nama"
        },
        {
            Header: "Organisasi",
            accessor: "organisasi"
        },
        {
            Header: "Provinsi",
            accessor: "provinsi"
        },
        {
            Header: "Kota",
            accessor: "kota"
        },
        {
            Header: "Ponsel",
            accessor: "ponsel"
        },
        {
            Header: "Jenis Kelamin",
            accessor: "jenis kelamin"
        },
        {
            Header: "Tanggal Lahir",
            accessor: "tanggal lahir"
        },
    ])

    const formatJSONArray = (arr) => {
        return arr.map((obj, key) => {
            return {
                ...obj,
                full_name: obj.nama,
                idx: key + 1
            }
        })
    }

    const onClose = (refresh) => {

        if (props.onClose) {
            props.onClose(refresh)
        }

        setUploadedUsers([])
        setUploadResult({})

    }

    return <Dialog
        open={props.open}
        maxWidth={"xl"}
        onClose={onClose}>
        <DialogTitle title={"Unggah CSV"}>
            <Row>
                <Col style={{
                    fontFamily: "OpenSans-Bold",
                    color: Palette.PRIMARY,
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    Unggah CSV
                </Col>
            </Row>
            <hr/>
        </DialogTitle>
        <DialogContent>
            {/*Display upload button only when stagedQuestion is empty*/}
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row"
            }}>

                <CSVLink
                    style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                    separator={";"}
                    data={
                        `email;nama;organisasi;provinsi;kota;ponsel;jenis kelamin;tanggal lahir;
email@email.com;nama contoh;organisasi contoh;Banten;Serang;0123456 ;M;13-12-2000
email@email.com;nama contoh;organisasi contoh;Banten;Cilegon;0123456;F;13-12-2000`
                    }
                    filename={`contoh-undangan.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Contoh Format &nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <FileUpload
                    allowedType={["text/csv", "application/vnd.ms-excel"]}
                    hideSpinner={true}
                    text={"Unggah File CSV"}
                    // isLoading={isLoadingBar}
                    buttonStyle={{
                        fontSize: "2e0m"
                    }}
                    onDrop={async (result) => {
                        setUploadResult({});

                        let reader = new FileReader();
                        reader.readAsText(result[0], "UTF-8");
                        reader.onload = async (e) => {

                            let stringCSV = e.target.result

                            let parseResult = Papa.parse(stringCSV, {
                                delimiter: ";",
                                header: true,
                                skipEmptyLines: true
                            });
                            console.log(parseResult)

                            setUploadedUsers(parseResult.data)

                        }


                    }}
                />

            </div>


            {
                uploadedUsers.length > 0 ?
                    <MobTable
                        columns={stagedQuestionColumns} data={formatJSONArray(uploadedUsers)}/>
                    : null
            }


            <div style={{marginTop: 15}}/>

            <CCollapse show={uploadResult?.success?.length > 0} timeout={1000}>
                <CAlert color="success">
                    Berhasil Diproses {uploadResult?.success?.length}/{uploadedUsers.length}
                </CAlert>
            </CCollapse>

            <div style={{marginTop: 15}}/>

            <CCollapse show={uploadResult?.duplicate?.length > 0} timeout={1000}>
                <CAlert color="info">
                    {uploadResult?.duplicate?.length}/{uploadedUsers.length} pengguna telah terdaftar

                    <table style={{
                        width: "100%",
                        marginTop: 10
                    }}>
                        <tr>
                            <th>No</th>
                            <th>Surel</th>
                        </tr>
                        {
                            uploadResult?.duplicate?.map((obj, key) => {
                                return <tr>
                                    <td>{key + 1}</td>
                                    <td>{obj}</td>
                                </tr>
                            })
                        }

                    </table>
                </CAlert>
            </CCollapse>

            <CCollapse show={uploadResult?.fail?.length > 0} timeout={1000}>
                <CAlert color="danger">
                    Gagal Diproses ({uploadResult?.fail?.length} Kesalahan)

                    <table style={{
                        width: "100%",
                        marginTop: 10
                    }}>
                        <tr>
                            <th>No</th>
                            <th>Surel</th>
                            <th>Kode</th>
                        </tr>
                        {
                            uploadResult?.fail?.map((obj, key) => {
                                if (obj) {
                                    return <tr>
                                        <td>{key + 1}</td>
                                        <td>{obj.err?.email}</td>
                                        <td>{obj.err?.code}</td>
                                    </tr>
                                }
                            })
                        }

                    </table>
                </CAlert>
            </CCollapse>

            {/*<CCollapse show={true} timeout={1000}>*/}
            {/*    <CAlert color="success">*/}
            {/*       Test*/}
            {/*    </CAlert>*/}
            {/*</CCollapse>*/}


            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 30,
                marginBottom: 15
            }}>
                <CustomButton
                    onClick={() => {
                        setUploadResult({})
                        onClose()
                    }}
                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                    variant={"outlined"}
                    disabled={isLoading}
                    color="primary">
                    {((uploadResult.success || uploadResult.duplicate || uploadResult.fail))? "Selesai" : "Batal"}
                </CustomButton>
                {
                    (!(uploadResult.success || uploadResult.duplicate || uploadResult.fail)) &&
                    <CustomButton
                        style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={async () => {
                            try {

                                setIsLoading(true)

                                let formattedMembers = uploadedUsers.map((obj) => {
                                    return {
                                        email: obj.email,
                                        full_name: obj.nama,
                                        organization: obj.organisasi,
                                        province: obj.provinsi,
                                        city: obj.kota,
                                        phone_num: obj.ponsel,
                                        gender: obj["jenis kelamin"],
                                        date_of_birth: new moment(obj["tanggal lahir"], "DD-MM-YYYY").toDate()
                                    }
                                })

                                console.log(formattedMembers)

                                let memberModel = new Member()
                                let result = await memberModel.registerBatch(formattedMembers)

                                console.log('result', result)

                                setUploadResult(result)

                                // onClose(true)

                            } catch (err) {
                                alert("Kesalahan terjadi. Periksa console")

                                console.log('err', err)
                            }
                            setIsLoading(false)
                        }}
                        disabled={uploadedUsers.length === 0 || isLoading}
                        variant={"contained"}
                        color="secondary">
                        Simpan{isLoading ? <>&nbsp;<Spinner animation="border" size={"sm"} /></> : null }
                    </CustomButton>
                }



            </div>
        </DialogContent>
    </Dialog>

}
