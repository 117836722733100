import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import CustomButton from "./CustomButton";
import React, {useState} from "react";
import Topic from "../../models/Topic";
import {FaSave, FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import MiniTooltip from "./MiniTooltip";
import LagFreeTextEditor from "./LagFreeTextEditor";

export default function ManageTopicModal(props) {
    const topicModel = new Topic();
    const [errorMsg, setErrorMsg] = useState('')
    const [topic, setTopic] = useState({});

    function isObjEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    const addNewTopic = async () => {
        setErrorMsg('');

        if(!topic.name) return setErrorMsg('Harap mengisi nama topik');

        try {
            const result = await topicModel.create(topic);

            if (result.name === topic.name) {
                props.hide();
                props.updateData();
                alert('Topik baru berhasil ditambahkan!')
            }
            console.log(result)
        } catch (e) {
            console.log('error', e);

            if (e.msg && typeof e.msg === 'string') {
                return setErrorMsg(e.msg);
            } else {
                return setErrorMsg('Kesalahan terjadi');
            }
        }
    }

    const updateTopic = async () => {
        setErrorMsg('');

        if(!topic.name) return setErrorMsg('Harap mengisi nama topik');

        try {
            const result = await topicModel.update(props.selectedTopic.code, topic);

            console.log('result', result)

            if (result.success) {
                props.hide();
                props.updateData();
                alert('Topik baru berhasil diperbaharui!')
            }
            console.log(result)
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                return setErrorMsg(e.msg);
            } else {
                return setErrorMsg('Kesalahan terjadi');
            }
        }
    }

    return (
        <Dialog open={props.visible}
                maxWidth="md"
                fullWidth={true}
        >
            <DialogTitle onClose={() => this.onClose()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {isObjEmpty(props.selectedTopic) || !props.selectedTopic ? 'Tambah' : 'Sunting'} Topik
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Collapse in={errorMsg.length > 0}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit"/>
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                <Row style={{
                    marginTop: '1.5em',
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Kode'}
                            changeValue={value => {
                                if (isObjEmpty(props.selectedTopic)) {
                                    setTopic({
                                        ...topic,
                                        code: value
                                    })
                                }
                            }}
                            disabled={!isObjEmpty(props.selectedTopic)}
                            value={props.selectedTopic ? props.selectedTopic.code : topic.code}
                            subtitle={isObjEmpty(props.selectedTopic) || !props.selectedTopic ? '(akan dibuat secara acak jika dibiarkan kosong)' : null}
                            placeholder={"Kode"}/>
                    </Col>
                </Row>
                {/*<Row style={{*/}
                {/*    paddingInlineStart: "2%",*/}
                {/*    paddingInlineEnd: "2%",*/}
                {/*}}*/}
                {/*>*/}
                {/*    <Col md={12} sm={12} style={{*/}
                {/*        fontSize: "1.2em",*/}
                {/*        display: "flex",*/}
                {/*        alignItems: "flex-end"*/}
                {/*    }}>*/}
                {/*        <TextField*/}
                {/*            disabled={props.selectedTopic && !isObjEmpty(props.selectedTopic)}*/}
                {/*            // onBlur={()=>editEventState("nama", name)}*/}
                {/*            onChange={evt => {*/}
                {/*                if (isObjEmpty(props.selectedTopic)) {*/}
                {/*                    setTopic({*/}
                {/*                        ...topic,*/}
                {/*                        code: evt.target.value*/}
                {/*                    })*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            style={{*/}
                {/*                marginLeft: "0.5em",*/}
                {/*                style: {*/}
                {/*                    fontSize: "1em",*/}
                {/*                    fontFamily: "OpenSans-Regular",*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            InputProps={{*/}
                {/*                style: {*/}
                {/*                    fontSize: "1em",*/}
                {/*                    fontFamily: "OpenSans-Regular",*/}
                {/*                    cursor: "pointer"*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            defaultValue={props.selectedTopic ? props.selectedTopic.code : ''}*/}
                {/*            value={topic.code}*/}
                {/*            fullWidth placeholder={"Kode"}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Nama'}
                            changeValue={value => {
                                setTopic({
                                    ...topic,
                                    name: value
                                })
                            }}
                            defaultValue={props.selectedTopic ? props.selectedTopic.name : null}
                            value={props.selectedTopic ? props.selectedTopic.name : topic.name}
                            placeholder={"Nama"}/>
                    </Col>
                </Row>
                {/*<Row style={{*/}
                {/*    paddingInlineStart: "2%",*/}
                {/*    paddingInlineEnd: "2%",*/}
                {/*}}*/}
                {/*>*/}
                {/*    <Col md={12} sm={12} style={{*/}
                {/*        fontSize: "1.2em",*/}
                {/*        display: "flex",*/}
                {/*        alignItems: "flex-end"*/}
                {/*    }}>*/}
                {/*        <TextField*/}
                {/*            // onBlur={()=>editEventState("nama", name)}*/}
                {/*            onChange={evt => setTopic({*/}
                {/*                ...topic,*/}
                {/*                name: evt.target.value*/}
                {/*            })}*/}
                {/*            style={{*/}
                {/*                marginLeft: "0.5em",*/}
                {/*                style: {*/}
                {/*                    fontSize: "1em",*/}
                {/*                    fontFamily: "OpenSans-Regular",*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            InputProps={{*/}
                {/*                style: {*/}
                {/*                    fontSize: "1em",*/}
                {/*                    fontFamily: "OpenSans-Regular",*/}
                {/*                    cursor: "pointer"*/}
                {/*                }*/}
                {/*            }}*/}
                {/*            defaultValue={props.selectedTopic ? props.selectedTopic.name : null}*/}
                {/*            value={topic.name}*/}
                {/*            fullWidth placeholder={"Nama"}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={props.hide}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={isObjEmpty(props.selectedTopic) || !props.selectedTopic ? addNewTopic : updateTopic}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>
    )
}