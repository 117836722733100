import EditorPageWrapper from "./EditorPageWrapper";
import Palette from "../../../util/Palette";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Row} from "react-bootstrap";
import MobTable from "../../reusable/Table/MobTable";
import {useHistory, useLocation, useParams} from "react-router-dom";
import GlobalData from "../../../util/GlobalData";
import {Dialog, DialogTitle} from "@material-ui/core";
import Col from "react-bootstrap/Col";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CustomButton from "../../reusable/CustomButton";
import CourseChapter from "../../../models/CourseChapter";
import Course from "../../../models/Course";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import {Input} from "@material-ui/core"
import MemberCourse from "../../../models/MemberCourse";

export default function Syllabus(props) {
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isBtnHovered2, setBtnHovered2] = useState(false);
    let history = useHistory()
    const [selectedSyllabus, setSelectedSyllabus] = useState({})
    const [isDeleteSyllabusDialogShown, setDeleteSyllabusDialogShown] = useState(false);
    const [syllabus, setSyllabus] = useState([]);
    const [totalSyllabusContent, setTotalSyllabusContent] = useState(0);
    const [totalSyllabusContentDuration, setTotalSyllabusContentDuration] = useState(0);
    const [syllabusContent, setSyllabusContent] = useState({})
    const [snackBarProp, setSnackBarProp] = useState({});
    const [anyParticipantRegistered, setAnyParticipantRegistered] = useState(true);
    const [isUpdatingSequence, setUpdatingSequence] = useState(false);
    const [course, setCourse] = useState(null);

    Number.prototype.toHHMMSS = function () {
        var hours = Math.floor(this / 3600) < 10 ? ("00" + Math.floor(this / 3600)).slice(-2) : Math.floor(this / 3600);
        var minutes = ("00" + Math.floor((this % 3600) / 60)).slice(-2);
        var seconds = ("00" + (this % 3600) % 60).slice(-2);
        return {
            hours,
            minutes,
            seconds
        };
    }

    const columns = useMemo(
        () => [
            {
                Header: `Bab (Total: ${syllabus.length})`,
                accessor: "name"
            },
            {
                Header: `Materi (Total: ${totalSyllabusContent})`,
                accessor: "no_of_syllabus_content"
            },
            {
                Header: `Durasi ${totalSyllabusContentDuration ? `(Total:${totalSyllabusContentDuration.toHHMMSS().hours !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().hours) + " Jam " : ""}${totalSyllabusContentDuration.toHHMMSS().minutes !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().minutes) + " Menit" : ""}${totalSyllabusContentDuration.toHHMMSS().seconds !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().seconds) + " Detik" : ""})` : ''}`,
                accessor: "duration",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    const {duration} = rowInfo;

                    if (!duration) return '0';

                    return <>
                        {duration.toHHMMSS().hours !== "00" ? " " + parseInt(duration.toHHMMSS().hours) + " Jam " : ""}{duration.toHHMMSS().minutes !== "00" ? " " + parseInt(duration.toHHMMSS().minutes) + " Menit" : ""}{duration.toHHMMSS().seconds !== "00" ? " " + parseInt(duration.toHHMMSS().seconds) + " Detik" : ""}
                    </>
                })
            }
        ]
    )

    const updatingSequenceColumns = useMemo(
        () => [
            {
                Header: `Urutan`,
                accessor: "idx",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    console.log()

                    const {idx, id} = rowInfo;

                    return <>
                        <Input
                            style={{width: 40, textAlign: 'center'}}
                            inputProps={{style: {textAlign: 'center'}}}
                            onChange={(e) => {
                                console.log(!e.target.value)

                                if (!e.target.value || !isNaN(parseInt(e.target.value))) {
                                    const syllabusTemp = [...syllabus];

                                    syllabusTemp.forEach((syllabus, idx2) => {
                                        if (syllabus.id === cellInfo.row.original.id) {
                                            if (e.target.value) {
                                                syllabusTemp[idx2].idx = parseInt(e.target.value)
                                            } else {
                                                syllabusTemp[idx2].idx = null
                                            }
                                        }
                                    })

                                    setSyllabus(syllabusTemp)
                                }
                            }}
                            value={idx}/>
                    </>
                })
            },
            {
                Header: `Bab (Total: ${syllabus.length})`,
                accessor: "name"
            },
            {
                Header: `Materi (Total: ${totalSyllabusContent})`,
                accessor: "no_of_syllabus_content"
            },
            {
                Header: `Durasi ${totalSyllabusContentDuration ? `(Total:${totalSyllabusContentDuration.toHHMMSS().hours !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().hours) + " Jam " : ""}${totalSyllabusContentDuration.toHHMMSS().minutes !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().minutes) + " Menit" : ""}${totalSyllabusContentDuration.toHHMMSS().seconds !== "00" ? " " + parseInt(totalSyllabusContentDuration.toHHMMSS().seconds) + " Detik" : ""})` : ''}`,
                accessor: "duration",
                Cell: (cellInfo => {

                    let rowInfo = cellInfo.row.values

                    const {duration} = rowInfo;

                    if (!duration) return '0';

                    return <>
                        {duration.toHHMMSS().hours !== "00" ? " " + parseInt(duration.toHHMMSS().hours) + " Jam " : ""}{duration.toHHMMSS().minutes !== "00" ? " " + parseInt(duration.toHHMMSS().minutes) + " Menit" : ""}{duration.toHHMMSS().seconds !== "00" ? " " + parseInt(duration.toHHMMSS().seconds) + " Detik" : ""}
                    </>
                })
            }
        ]
    )

    const interactions = [
        {
            name: "Kelola",
            action: (obj) => {
                history.push(`/editor/syllabus/${props.match.params.event_url}/${obj.id}/manage`)
            },
            style: {width: 135},
        },
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedSyllabus(obj)
                setDeleteSyllabusDialogShown(true)
            },
            variant: 'secondary',
            style: {width: 135},
        }
    ];

    useEffect(() => {
        getCourseById();
    }, [])

    useEffect(() => {
        if (course) {
            getByCourseId()
            if (course.active) {
                getNoOfAcceptedParticipants();
            } else {
                setAnyParticipantRegistered(false)
            }
        }
    }, [course])

    const getCourseById = async () => {
        try {
            const courseModel = new Course();

            const result = await courseModel.getById(props.match.params.event_url);

            setCourse(result);
        } catch (e) {
            console.log(e)
        }
    }

    const getNoOfAcceptedParticipants = async () => {
        const memberCourseModel = new MemberCourse();

        try {
            const result = await memberCourseModel.getNoOfAcceptedParticipants(props.match.params.event_url);

            if (result.accepted_participants === 0) {
                setAnyParticipantRegistered(false)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getByCourseId = async () => {
        let customChapterSequence = null;

        if (course.custom_chapter_sequence) {
            customChapterSequence = JSON.parse(course.custom_chapter_sequence)
        }

        console.log(customChapterSequence)

        const courseChapterModel = new CourseChapter();

        try {
            const result = await courseChapterModel.getByCourseId(props.match.params.event_url);

            console.log('result', result)

            let totalSyllabusContentTemp = 0;

            let totalSyllabusContentDurationTemp = 0;

            const sortedSyllabus = [];

            result.courses.forEach((course, idx) => {
                totalSyllabusContentTemp += result.courseSubChapters[course.id].length;

                result.courseSubChapters[course.id].forEach(courseSubChapter => {
                    totalSyllabusContentDurationTemp += courseSubChapter.duration;

                    if (result.courses[idx].duration) {
                        result.courses[idx].duration += courseSubChapter.duration
                    } else {
                        result.courses[idx].duration = courseSubChapter.duration;
                    }
                })

                console.log(result.courses)

                result.courses[idx].idx = idx + 1
                result.courses[idx].no_of_syllabus_content = result.courseSubChapters[course.id].length
            })

            if (customChapterSequence) {
                for (let idx = 0; idx < result.courses.length; idx++) {
                    sortedSyllabus.push(null);
                }

                result.courses.forEach((course, idx) => {
                    customChapterSequence.forEach((item, idx2) => {
                        if (course.id === item) {
                            sortedSyllabus[idx2] = course;
                        }
                    })
                })

                sortedSyllabus.forEach((syllabus, idx) => {
                    sortedSyllabus[idx].idx = idx + 1;
                })
            }

            setTotalSyllabusContent(totalSyllabusContentTemp);
            setTotalSyllabusContentDuration(totalSyllabusContentDurationTemp)
            setSyllabus(customChapterSequence ? sortedSyllabus : result.courses);

            console.log('ceking ceking', result)
        } catch (e) {
            console.log(e)
        }
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    return (
        <>
            <EditorPageWrapper
                activeTab={"syllabus"}
                privateClass
            >
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackBarProp.open} autoHideDuration={6000}
                    onClose={() => {
                        setSnackBarProp({
                            open: false
                        })
                    }}>
                    <Alert severity={snackBarProp.severity}>
                        {snackBarProp.message}
                    </Alert>
                </Snackbar>

                <Dialog open={isDeleteSyllabusDialogShown}
                        onClose={() => setDeleteSyllabusDialogShown(false)}
                        maxWidth="sm">
                    <>
                        <DialogTitle>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Bab
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus bab <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedSyllabus.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleteSyllabusDialogShown(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        const courseChapterModel = new CourseChapter();

                                        try {
                                            const result = await courseChapterModel.delete(selectedSyllabus.id);

                                            if (result.affectedRows === 1) {
                                                showSnackBar('Bab berhasil terhapus!')

                                                setDeleteSyllabusDialogShown(false);

                                                getByCourseId()
                                            }
                                            console.log(result)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>


                <>
                    <div style={{
                        marginTop: "0.5em",
                        // marginBottom: "0.5em",
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontWeight: "800",
                        fontSize: "1.5em"
                    }}>Bab
                    </div>

                    <Row style={{marginLeft: 2, marginTop: 15}}>
                        {syllabus.length > 1 &&
                        <Button variant={'outline-primary'}
                            // onClick={() => history.push(`/editor/syllabus/${props.match.params.event_url}/replaceWithSyllabusId/manage`)}
                                onMouseEnter={() => setBtnHovered2(true)}
                                onMouseLeave={() => setBtnHovered2(false)}
                                onClick={async () => {
                                    if (!isUpdatingSequence) {
                                        setUpdatingSequence(true)
                                    } else {
                                        const customSequence = [];

                                        for (let idx = 0; idx < syllabus.length; idx++) {
                                            customSequence.push(null);
                                        }

                                        let isNotify = false;

                                        let courseId;

                                        syllabus.forEach((item, idx) => {
                                            courseId = item.course_id;

                                            if (!syllabus[idx].idx) {
                                                if (!isNotify) {
                                                    alert('Terdapat urutan yang belum terisi');
                                                }

                                                isNotify = true
                                            } else if (syllabus[idx].idx > syllabus.length) {
                                                if (!isNotify) {
                                                    alert('Terdapat urutan yang melebihi jumlah bab yang tersedia');
                                                }

                                                isNotify = true
                                            } else {
                                                customSequence[syllabus[idx].idx - 1] = item.id;
                                            }
                                        })

                                        let isDuplicate = false;

                                        customSequence.forEach(item => {
                                            if (!item) {
                                                isDuplicate = true;
                                                if (!isNotify) {
                                                    alert('2 atau lebih bab memiliki urutan yang sama');
                                                }

                                                isNotify = true
                                            }
                                        })

                                        if (!isNotify) {
                                            const courseModel = new Course()

                                            try {
                                                const result = await courseModel.update(courseId, {
                                                    custom_chapter_sequence: JSON.stringify(customSequence)
                                                })

                                                alert('Urutan berhasil diperbarui!')
                                                window.location.reload();
                                                console.log(result)
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }
                                    }
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    width: 180,
                                    marginLeft: 5,
                                    color: isBtnHovered2 ? 'white' : Palette.PRIMARY,
                                }}
                        >
                                    <span style={{marginLeft: 6}}>
                                {isUpdatingSequence ? 'Simpan Urutan' : 'Sunting Urutan Bab'}
                                    </span>
                        </Button>
                        }

                        {syllabus.length > 1 && isUpdatingSequence &&
                        <Button variant={'danger'}
                            // onClick={() => history.push(`/editor/syllabus/${props.match.params.event_url}/replaceWithSyllabusId/manage`)}
                                onClick={() => {
                                    setUpdatingSequence(false)
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    width: 180,
                                    marginLeft: 5,
                                }}
                        >
                                    <span style={{marginLeft: 6}}>
                                    Batal Sunting
                                    </span>
                        </Button>
                        }

                        {!isUpdatingSequence &&
                        <Button variant={'outline-primary'}
                                onMouseEnter={() => setBtnHovered(true)}
                                onMouseLeave={() => setBtnHovered(false)}
                            // onClick={() => history.push(`/editor/syllabus/${props.match.params.event_url}/replaceWithSyllabusId/manage`)}
                                disabled={anyParticipantRegistered}
                                onClick={() => {
                                    history.push(`/editor/syllabus/${props.match.params.event_url}/create`)
                                }}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 30,
                                    fontSize: '.8em',
                                    fontFamily: 'OpenSans-SemiBold',
                                    color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                    width: 180,
                                    marginLeft: 5
                                }}
                        >
                            + <span style={{marginLeft: 6}}>Buat Bab Baru</span>
                        </Button>
                        }

                        {anyParticipantRegistered && !isUpdatingSequence &&
                        <span style={{
                            marginTop: 5,
                            marginLeft: 7,
                            fontSize: '.7em',
                            color: 'red',
                            fontFamily: 'OpenSans-Regular'
                        }}>Tidak bisa membuat bab baru karena sudah ada peserta terdaftar.</span>
                        }
                    </Row>

                    <div>
                        <MobTable
                            columns={isUpdatingSequence ? updatingSequenceColumns : columns}
                            data={syllabus}
                            interactions={interactions}
                        />
                    </div>
                </>
            </EditorPageWrapper>
        </>
    )
}
