import ApiRequest from "../util/ApiRequest";
export default class LiveStreaming{
    getAll = async () => {
        return await ApiRequest.set(`/v1/liveStreaming`, "GET");
    }
    insert = async (streamingData) => {
        return await ApiRequest.set("/v1/liveStreaming", "POST", streamingData);
    }

    update = async (streamingData,code) => {
        return await ApiRequest.set(`/v1/liveStreaming/${code}`, "PUT", streamingData);
    }

    activeStream = async (code) => {
        return await ApiRequest.set(`/v1/liveStreaming/${code}/active`, "PUT");
    }
    inactiveStream = async (code) => {

        return await ApiRequest.set(`/v1/liveStreaming/${code}/inactive`, "PUT");
    }

    getByCode = async (code) => {
        return await ApiRequest.set(`/v1/liveStreaming/${code}`, "GET");
    }

    hardDelete = async (code) => {
        return await ApiRequest.set(`/v1/liveStreaming/${code}/delete`, "DELETE");
    }

}