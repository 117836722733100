import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import {withCookies, Cookies} from 'react-cookie';
import Container from "react-bootstrap/Container";

import {GiPisces} from "react-icons/gi";
import {FaHome, FaBook, FaHandsHelping} from "react-icons/fa";
import {FiClock} from "react-icons/fi";
import {AiFillStar} from "react-icons/ai";

import {
    withRouter
} from 'react-router-dom'

function Header(props) {

    let [scrollPosition, setScrollPosition] = useState(0)
    let history = useHistory()
    let user = GlobalData.user
    const [cookie, setCookie, removeCookie] = useCookies(["token"])

    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)

        console.log(scrolled)
    }

    useEffect(() => window.addEventListener('scroll', listenToScroll))
    useEffect(() => {
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    if (isMobile) {
        return <>
            <div style={{
                width: "100%",
                height: "3.5em",
                position: "fixed",
                bottom: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "5px 5px 5px 5px #888888",
                display: "flex",
                flexDirection: "row",
                color: "grey"
            }}>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "home" ? Palette.PRIMARY : null
                }} onClick={() => props.history.push("/")}
                >
                    <FaHome style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Beranda
                    </div>
                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "book-store" ? Palette.PRIMARY : null
                }} onClick={() => props.history.push("/pasar-buku")}>
                    <FaBook style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Pasar Buku
                    </div>
                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "my-session" ? Palette.PRIMARY : null
                }} onClick={() => props.history.push("/festival-literasi")}>
                    <AiFillStar style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Festival
                    </div>
                </div>

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "donation" ? Palette.PRIMARY : null
                }} onClick={() => props.history.push("/galang-patjar")}>
                    <FaHandsHelping style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Galang Patjar
                    </div>
                </div>
            </div>
        </>
    }
    return <>
        <Slide bottom when={scrollPosition > 0.9}>
            <div style={{
                width: "100%",
                height: "2em",
                position: "fixed",
                bottom: 0,
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                background: Palette.PRIMARY,
                boxShadow: "0.5px 0.5px 0.5px #888888"
            }}>
                <a style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    color: "white",
                    fontSize: "0.8em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    Powered By Intellivent
                </a>
            </div>
        </Slide>
    </>

}

export default withRouter(Header)
