import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Button, Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaSave, FaTimes} from "react-icons/fa";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomButton from "./CustomButton";
import EditorPageWrapper from "../page/editor/EditorPageWrapper";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdEvent} from "react-icons/md";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Member from "../../models/Member";
import Participation from "../../models/Participation";
import FileUpload from "./FileUpload";
import CustomDropzone from "./CustomDropzone";
import CustomDropzoneV2 from "./CustomDropzoneV2";
import Merchandise from "../../models/Merchandise";
import {AiFillWarning} from "react-icons/all";

export default function EditUserModal(props) {

    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false)
    const [img, setImg] = useState({})
    const [isNewMerch, setIsNewMerch] = useState(false);
    const [productPictHoveredIdx, setProductPictHoveredIdx] = useState(false);
    const [tempImages, setTempImages] = useState([])
    let imgRef = React.createRef();

    const [selectedMerchandise, setSelectedMerchandise] = useState({})

    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const updateMerchandise = async () => {

        try {
            let merchandise = new Merchandise();
            console.log('Body', selectedMerchandise)


            let result = await merchandise.updateById(selectedMerchandise.id, selectedMerchandise)


            console.log('hasil edit', result)

            props.onClose()
        } catch (e) {
            setErrorMsg(JSON.stringify(e))
            console.log(e)
        }

    }

    const addMerchandise = async () => {

        try {
            let merchandise = new Merchandise();
            let body = {...selectedMerchandise}


            if (body.name === '' || body.name === null) {
                return alert("Mohon isi nama kreasi!");
            }
            if (body.description === '' || body.description === null) {
                return alert("Mohon isi deskripsi kreasi!");
            }
            if (body.price === '' || body.price === null) {
                return alert("Mohon isi harga kreasi!");
            }
            if (body.marketplace_link === '' || body.marketplace_link === null) {
                return alert("Mohon isi link pembelian kreasi!");
            }
            if (body.image_url === '' || body.image_url === null) {
                return alert("Mohon upload foto utama kreasi!");
            }
            console.log('Create body', body);
            let result = await merchandise.create(body)


            console.log('Body', selectedMerchandise)

            console.log('hasil edit', result)

            props.onClose()
        } catch (e) {
            setErrorMsg(JSON.stringify(e))
            console.log(e)
        }

    }

    function blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    useEffect(() => {

        console.log(props.merchandise)
        setSelectedMerchandise(props.merchandise)
        setIsNewMerch(props.isNew)

    }, [props.merchandise])

    return <Dialog
        open={props.isOpen}
        maxWidth="lg"
        fullWidth={true}
    >
        <>
            <DialogTitle onClose={() => this.onClose()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        // fontWeight: "bold",
                        display: 'flex',
                        alignItems: 'center',
                        color: Palette.PRIMARY
                    }}>
                        {isNewMerch ? "Tambah Kreasi" : "Sunting Kreasi"}
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Collapse
                    in={errorMsg.length > 0}
                    style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                >
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit"/>
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12} style={{fontFamily: 'OpenSans-SemiBold'}}>
                        <LagFreeTextEditor
                            title={'Nama'}
                            changeValue={value => setSelectedMerchandise({
                                ...selectedMerchandise,
                                name: value
                            })}
                            value={selectedMerchandise.name}
                            placeholder={"Nama"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Deskripsi'}
                            changeValue={value => setSelectedMerchandise({
                                ...selectedMerchandise,
                                description: value
                            })}
                            multiline={true}
                            value={selectedMerchandise.description}
                            placeholder={"Deskripsi"}/>

                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Harga'}
                            changeValue={value => setSelectedMerchandise({
                                ...selectedMerchandise,
                                price: value
                            })}
                            value={selectedMerchandise.price}
                            placeholder={"Harga"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Link Pembelian'}
                            changeValue={value => setSelectedMerchandise({
                                ...selectedMerchandise,
                                marketplace_link: value
                            })}
                            value={selectedMerchandise.marketplace_link}
                            placeholder={"Link Pembelian"}/>

                        {
                            !validURL(selectedMerchandise.marketplace_link) ?
                                <div style={{
                                    color: "grey",
                                    // fontWeight: "lighter"
                                }}>
                                    <AiFillWarning
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 10,
                                            color: Palette.ORANGE,
                                            fontSize: "1.2em"
                                        }}/>
                                    Peringatan : Pastikan URL yang diketik valid (contoh :
                                    https://www.tokopedia.com/narabahasa/kaus-narabahasa-01-s-lengan-pendek?whid=0)
                                </div>
                                :
                                null
                        }
                    </Col>
                </Row>

                <Row
                    style={{
                        fontFamily: "OpenSans-Regular",
                        paddingInlineStart: "3.7%",
                        paddingInlineEnd: "3.7%",
                        paddingTop: '1em',
                        fontWeight: 'bold'
                    }}
                >
                    Foto Utama
                </Row>

                <Row style={{
                    marginLeft: 10
                }}>
                    <CustomDropzoneV2
                        width={"250px"}
                        height={"250px"}
                        // id={"reference-component"}
                        aspect={1}
                        imageSrc={selectedMerchandise.image_url}
                        loading={isLoading}
                        prompt={<>Foto Kreasi<br/>(Rekomendasi 1:1)</>}
                        onDrop={async (acceptedFiles) => {
                            console.log(acceptedFiles)

                            setIsLoading(true)

                            try {
                                let result;

                                let file = blobToFile(acceptedFiles, 'image')
                                if (isNewMerch) {
                                    result = await new Merchandise().uploadImage(file);
                                    console.log(result)
                                    setSelectedMerchandise({...selectedMerchandise, image_url: result.location});
                                    return setIsLoading(false)
                                }

                                result = await new Merchandise().reUploadImage(selectedMerchandise.id, file);
                                console.log(result)
                                setSelectedMerchandise({...selectedMerchandise, image_url: result.location});


                                return setIsLoading(false)

                            } catch (e) {
                                console.log(e)
                            }

                            setIsLoading(false);
                        }}
                    />
                </Row>

                <Row
                    style={{
                        fontFamily: "OpenSans-Regular",
                        paddingInlineStart: "3.7%",
                        paddingInlineEnd: "3.7%",
                        paddingTop: '1em',
                        fontWeight: 'bold'
                    }}
                >
                    Foto Lainnya
                </Row>

                <div
                    style={{
                        overflow: "auto",
                        whiteSpace: "nowrap"
                    }}
                >
                    {selectedMerchandise?.other_image_url?.map((obj, key) => {
                        return (<div style={{marginLeft: 10, marginTop: 10, display: "inline-block"}}>
                                <img
                                    onMouseOver={() => setProductPictHoveredIdx(key)}
                                    onMouseLeave={() => setProductPictHoveredIdx(null)}
                                    onClick={() => {
                                        let temp = [...selectedMerchandise.other_image_url];
                                        temp.splice(key, key);
                                        setSelectedMerchandise({...selectedMerchandise, other_image_url: temp});
                                        console.log('Delete initiated!')

                                        const index = temp.indexOf(obj);
                                        if (index > -1) {
                                            temp.splice(index, 1);
                                        }
                                    }}
                                    src={obj} style={{
                                    width: 250,
                                    height: 250,
                                    marginLeft: 10,

                                    filter: productPictHoveredIdx === key ? 'brightness(.4)' : 'brightness(1)',
                                    cursor: 'pointer',
                                    objectFit: 'contain',
                                    backgroundColor: 'white'
                                }}/>
                            </div>

                        )
                    })}
                </div>

                <Row style={{
                    marginLeft: 10
                }}>
                    <CustomDropzoneV2
                        editing={true}
                        width={"250px"}
                        height={"250px"}
                        // id={"reference-component"}
                        aspect={1}
                        imageSrc={null}
                        loading={isLoading2}
                        prompt={<>Foto Kreasi<br/>(Rekomendasi 1:1)</>}
                        onDrop={async (acceptedFiles) => {
                            console.log(acceptedFiles)

                            setIsLoading2(true)

                            try {
                                let result;
                                let temp = [];

                                let file = blobToFile(acceptedFiles, 'image')
                                if (isNewMerch) {
                                    let tempNew = [];
                                    result = await new Merchandise().uploadImage(file);
                                    console.log(result)

                                    if (selectedMerchandise.other_image_url) {
                                        tempNew = [...selectedMerchandise.other_image_url];
                                    } else {
                                        tempNew = []
                                    }

                                    tempNew.push(result.location)
                                    setSelectedMerchandise({...selectedMerchandise, other_image_url: tempNew});
                                    return setIsLoading2(false)
                                }

                                result = await new Merchandise().reUploadImage(selectedMerchandise.id, file);
                                console.log(result)


                                if (selectedMerchandise.other_image_url) {
                                    temp = [...selectedMerchandise.other_image_url];
                                } else {
                                    temp = [result.location]
                                }

                                temp.push(result.location)
                                setSelectedMerchandise({...selectedMerchandise, other_image_url: temp});
                                return setIsLoading2(false)

                            } catch (e) {
                                console.log(e)
                            }

                            setIsLoading2(false);
                        }}
                    />
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={() => {
                            setSelectedMerchandise({})
                            props.onClose()
                        }}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={isNewMerch ? addMerchandise : updateMerchandise}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
            {/*{renderModalFooter()}*/}
        </>
    </Dialog>

}
