import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Container, ListGroup, Row, Tabs, Tab} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {
    FaTimes,
} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import MobTable from "../../reusable/Table/MobTable"
import Slider from '@material-ui/core/Slider';

import {Stage, Layer, Rect, Text, Image} from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Participation from "../../../models/Participation";
import {useParams} from "react-router-dom";
import Class from "../../../models/Class";
import textFormatter from "../../../util/textFormatter";
import Registration from "../../../models/Registration";
import FileUpload from "../../reusable/FileUpload";
import ColorPicker from "material-ui-color-picker";
import Voucher from "../../../models/Voucher";

import {jsPDF} from "jspdf";
import CertificatePredicateModal from "../../reusable/modals/CertificatePredicateModal";
import MultiPredicateModal from "../../reusable/modals/MultiPredicateModal";

import _ from "lodash"
import Switch from "@material-ui/core/Switch/Switch";

import apiConfig from "../../../util/apiConfig";
import Cookies from "js-cookie";
import UpcomingEventEditPriceModal from "../../reusable/UpcomingEventEditPriceModal";
import DownloadSomeCertificateModal from "../../reusable/DownloadSomeCertificateModal";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {FaSave} from "react-icons/fa";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let containerRef;
let stageRef;
let stageRef2;
let imageRef;
let imageRef2;

export default function CertificatePage(props) {
    const [certPage1File, setCertPage1File] = useState(null);
    const [certPage2File, setCertPage2File] = useState(null);

    const defaultBackgroundImageURL = require("../../../asset/certificate_template_v2.png");
    const defaultBackgroundImageURL2 = require("../../../asset/certificate_template_p2.PNG");

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [showMultiPredicateModal, setShowMultiPredicateModal] = useState(false)

    const [showPredicateModal, setShowPredicateModal] = useState(false)
    const [activeParticipant, setActiveParticipant] = useState({})

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [top, setTop] = useState(42)
    const [left, setLeft] = useState(10.75)

    const [topNumber, setTopNumber] = useState(15.5)
    const [leftNumber, setLeftNumber] = useState(19)
    const [fontSizeNumber, setFontSizeNumber] = useState(14)
    const [numberPrefix, setNumberPrefix] = useState("001")
    const [numberSuffix, setNumberSuffix] = useState("/NBKD77/10/20")

    const [fontSize, setFontSize] = useState(16)

    const [secondTop, setSecondTop] = useState(71)
    const [secondLeft, setSecondLeft] = useState(58)
    const [secondFontSize, setSecondFontSize] = useState(8)

    const [secondTextColor, setSecondTextColor] = useState("#e9eaec")

    const [competenciesCertificate, setCompetenciesCertificate] = useState('')
    const [secondCompetenciesTop, setSecondCompetenciesTop] = useState(34.7)
    const [secondCompetenciesLeft, setSecondCompetenciesLeft] = useState(25.7)
    const [secondCompetenciesFontSize, setSecondCompetenciesFontSize] = useState(16)

    const [secondCompetenciesTextColor, setSecondCompetenciesTextColor] = useState("#353232")

    const [predicate, setPredicate] = useState("Istimewa")

    const [canvasWidth, setCanvasWidth] = useState(0)
    const [canvasHeight, setCanvasHeight] = useState(0)

    const {event_url} = useParams()

    const [registrants, setRegistrants] = useState([])

    const [name, setName] = useState()
    const [testingName, setTestingName] = useState()
    const [textColor, setTextColor] = useState("#a52620")


    const [backgroundImageURL, setBackgroundImageURL] = useState(defaultBackgroundImageURL)
    const [backgroundImageURL2, setBackgroundImageURL2] = useState(defaultBackgroundImageURL2)
    const [templateName, setTemplateName] = useState("")

    const [isUploading, setIsUploading] = useState(false)
    const [successCount, setSuccessCount] = useState(0)

    const [hidePageTwo, setHidePageTwo] = useState(false)

    const [isDownloadingCertificate, setIsDownloadingCertificate] = useState(false)

    const [competencies, setCompetencies] = useState([]);
    const [errorMsg, setErrorMsg] = useState('')
    const [competency, setCompetency] = useState('');

    const [isManageCompetencyModalVisible, setManageCompetencyModalVisible] = useState(false)
    const [isDeleteCompetencyModalVisible, setDeleteCompetencyModalVisible] = useState(false)
    const [classObj, setClass] = useState({});
    const [selectedCompetencyIdx, setSelectedCompetencyIdx] = useState(null);

    const getClass = async () => {
        const classModel = new Class();

        try {
            const classObj = await classModel.getByCode(event_url);

            setClass(classObj)

            if (classObj.what_to_learn) {
                let competenciesTemp = JSON.parse(classObj.what_to_learn).map(competency => {
                    return {competency}
                })

                let competenciesCertificateTemp = '';

                competenciesTemp.forEach(competency => {
                    competenciesCertificateTemp += '• ' + competency.competency + '\n';
                })

                console.log('competenciesCertificateTemp', competenciesCertificateTemp)

                setCompetenciesCertificate(competenciesCertificateTemp)
                setCompetencies(competenciesTemp)
            }
        } catch (e) {
            console.log(e)
        }
    }


    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    const downloadCertificatesZip = async (classCode) => {
        setIsUploading(true)

        let token = GlobalData.token ? GlobalData.token : Cookies.get("token") ? Cookies.get("token") : null

        console.log(GlobalData.token ? GlobalData.token : Cookies.get("token") ? Cookies.get("token") : null)

        fetch(`${apiConfig.base_url}/v1/class/${classCode}/certificates`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: token ? `bearer ${token}` : null,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                setIsUploading(false)
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Certificates.zip`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).catch((e) => {
            console.log(e)
        });
    }

    const getCustomCertificateTemplate = async () => {
        const classModel = new Class();

        const result = await classModel.getByCode(event_url);
        console.log('result', result)
        if (result.custom_certificate_template) {
            const customCertificateTemplate = JSON.parse(result.custom_certificate_template);


            if (customCertificateTemplate.certPage1) {
                let blob = await fetch(customCertificateTemplate.certPage1).then(r => r.blob());

                setBackgroundImageURL(URL.createObjectURL(blob))
            }

            if (customCertificateTemplate.certPage2) {
                let blob = await fetch(customCertificateTemplate.certPage2).then(r => r.blob());

                setBackgroundImageURL2(URL.createObjectURL(blob))
            }

            setFontSize(customCertificateTemplate.fontSize);
            setFontSizeNumber(customCertificateTemplate.fontSizeNumber)
            setHidePageTwo(customCertificateTemplate.hidePageTwo)

            setLeft(customCertificateTemplate.left);
            setLeftNumber(customCertificateTemplate.leftNumber)

            setNumberPrefix(customCertificateTemplate.numberPrefix)
            setNumberSuffix(customCertificateTemplate.numberSuffix)

            setSecondFontSize(customCertificateTemplate.secondFontSize)
            setSecondLeft(customCertificateTemplate.secondLeft)
            setSecondTextColor(customCertificateTemplate.secondTextColor)
            setSecondTop(customCertificateTemplate.secondTop)

            setTextColor(customCertificateTemplate.textColor)

            setTop(customCertificateTemplate.top)
            setTopNumber(customCertificateTemplate.topNumber)

            setSecondCompetenciesTop(customCertificateTemplate.secondCompetenciesTop)
            setSecondCompetenciesFontSize(customCertificateTemplate.secondCompetenciesFontSize)
            setSecondCompetenciesLeft(customCertificateTemplate.secondCompetenciesLeft)
            setSecondCompetenciesTextColor(customCertificateTemplate.secondCompetenciesTextColor)
        }
    }

    const getParticipants = async () => {

        setIsLoading(true)

        let classModel = new Class()
        let result = await classModel.getClassParticipant(event_url)
        console.log(result)

        let totalIncome = 0;

        let registeredRegistrants = []

        for (let r of result) {

            if (r.status === "ACCEPTED") {
                registeredRegistrants.push(
                    {
                        ...r.member,
                        ...r,
                        full_name_sorter: r.member?.full_name.toUpperCase()
                    }
                )
                setName(r.member?.full_name)
                setTestingName(r.member?.full_name)
            }

        }

        setIsLoading(false)

        setRegistrants(registeredRegistrants)

    }

    useEffect(() => {
        getClass()
        getParticipants()

        getCustomCertificateTemplate()
    }, [])

    const competencyInteraction = [
        {
            name: "Sunting",
            action: (obj, key) => {
                setCompetency(obj.competency)
                setSelectedCompetencyIdx(key)
                setManageCompetencyModalVisible(true)
            },
            style: {width: 100},
        },
        {
            name: "Hapus",
            action: (obj, key) => {
                setSelectedCompetencyIdx(key)
                setDeleteCompetencyModalVisible(true)
                console.log(key)
            },
            variant: 'secondary',
            style: {width: 100},
        },
    ]

    const competencyColumns = [
        {
            Header: "Kompetensi",
            accessor: "competency"
        }
    ]


    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }


    const columns = [
        {
            Header: "ID Registrasi",
            accessor: "id"
        },
        {
            Header: "Nama Lengkap",
            accessor: "full_name_sorter",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let member = rowInfo["member"]

                return <>
                    {member.full_name}
                </>
            }
        },
        {
            Header: "Email",
            accessor: "member",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let member = rowInfo["member"]

                return <>
                    {member.email}
                </>
            }
        },
        {
            Header: "Presensi",
            accessor: "is_present",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let isClaimed = rowInfo["is_present"]

                return <>
                    {isClaimed ? "Ya" : "Tidak"}
                </>
            }
        },
        {
            Header: "Predikat",
            accessor: "predicate",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let predicate = rowInfo["predicate"]

                return <a
                    onClick={() => {
                        setShowPredicateModal(true)
                        setActiveParticipant(rowInfo)
                    }}
                    href={"#"}>
                    {predicate ? predicate : "(Tambah)"}
                </a>

            }
        },
        {
            Header: "Status Sertifikat",
            accessor: "certificate_url",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let certificateUrl = rowInfo["certificate_url"]

                return <p
                    style={{color: certificateUrl ? "LimeGreen" : "Red"}}>{certificateUrl ? "Terkirim" : "Belum Dibuat"}</p>

            }
        },
        {
            Header: "Opsi",
            accessor: "cert",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let certificateUrl = rowInfo["certificate_url"]
                let participantId = rowInfo["id"]

                return <div style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <FileUpload
                        allowedType={["application/pdf"]}
                        hideSpinner={true}
                        text={"Unggah"}
                        isLoading={isLoadingBar}
                        onDrop={async (result) => {

                            setIsLoadingBar(true)

                            console.log(result)
                            let participation = new Participation()

                            try {
                                let uploadResult = await participation.uploadSingleCertificate(result[0], participantId, event_url)
                                console.log(uploadResult)
                                alert("Upload Berhasil")
                                getParticipants()
                                setIsLoadingBar(false)
                            } catch (e) {
                                console.log(e)
                                setIsLoadingBar(false)
                            }
                        }}
                    />
                    {
                        certificateUrl ?
                            <a href={certificateUrl} download>
                                <Button style={{color: "white", marginLeft: 10}}>Unduh</Button>
                            </a>
                            :
                            null
                    }

                </div>


            }
        },
    ]


    const renderOldEditor = () => {
        return <>
            <canvas>
            </canvas>
            <img
                style={{
                    width: "100%",
                    objectFit: "contain"
                }}
                src={require("../../../../src/asset/blank_certificate.png")}/>

            <div
                style={{
                    backgroundColor: "#00000020",
                    position: "absolute",
                    left: `${left}%`,
                    top: `${top}%`,
                    fontSize: `${fontSize}px`
                }}>
                Nama Peserta
            </div>
        </>
    }

    // console.log("inner width " + canvasWidth)

    let tryImage = new window.Image();
    // tryImage.src="https://patjarmerah.com/static/media/festival-buku-asia-logo.54f7ac9e.png"
    tryImage.src = backgroundImageURL
    tryImage.onload = () => {
        if (imageRef) {
            imageRef.getLayer().batchDraw()
        }
    }

    let tryImage2 = new window.Image();
    // tryImage.src="https://patjarmerah.com/static/media/festival-buku-asia-logo.54f7ac9e.png"
    tryImage2.src = backgroundImageURL2
    tryImage2.onload = () => {
        if (imageRef2) {
            imageRef2.getLayer().batchDraw()
        }
    }


    const renderNewEditor = () => {

        let realWidth = 3503
        let realHeight = 2480

        return <div style={{width: "100%"}} ref={ref => {
            if (canvasWidth === 0 && ref !== undefined && ref?.clientWidth) {
                setCanvasWidth(ref?.clientWidth)
                setCanvasHeight(ref?.clientWidth * 0.71)
            }
        }}>
            <Stage
                ref={ref => stageRef = ref}
                style={{
                    background: "#ffefff",
                    width: "100%",
                    height: canvasHeight
                }}
                width={canvasWidth} height={canvasHeight}>
                <Layer>
                    {/*UNTUK DEFINE GAMBAR*/}
                    <Image
                        ref={ref => {
                            imageRef = ref
                            // console.log(ref)
                            // console.log(ref?.image())
                        }}
                        width={canvasWidth}
                        height={canvasHeight}
                        image={tryImage}/>
                    {/*UNTUK MASUKIN TULISAN*/}
                    <Text
                        align={"left"}
                        fontSize={fontSize}
                        fontStyle={"bold"}
                        fill={textColor}
                        x={canvasWidth * left / 100}
                        y={canvasHeight * top / 100}
                        width={canvasWidth}
                        text={name ? name.toUpperCase() : ""}/>

                    {/*Wibi: UNTUK MASUKKIN NOMOR SURAT*/}
                    <Text
                        align={"left"}
                        fontSize={fontSizeNumber}
                        fontStyle={"bold"}
                        fontFamily={"Lora"}
                        fill={"black"}
                        x={canvasWidth * leftNumber / 100}
                        y={canvasHeight * topNumber / 100}
                        width={canvasWidth}
                        text={numberPrefix + numberSuffix}/>
                </Layer>
            </Stage>
        </div>
    }

    const renderEditorPage2 = () => {

        let realWidth = 3503
        let realHeight = 2480

        return <div style={{width: "100%"}} ref={ref => {
            if (canvasWidth === 0 && ref !== undefined && ref?.clientWidth) {
                setCanvasWidth(ref?.clientWidth)
                setCanvasHeight(ref?.clientWidth * 0.71)
            }
        }}>
            <Stage
                ref={ref => stageRef2 = ref}
                style={{
                    background: "#ffefff",
                    width: "100%",
                    height: canvasHeight
                }}
                width={canvasWidth} height={canvasHeight}>
                <Layer>
                    <Image
                        ref={ref => {
                            imageRef2 = ref
                            // console.log(ref)
                            // console.log(ref?.image())
                        }}
                        width={canvasWidth}
                        height={canvasHeight}
                        image={tryImage2}/>
                    {/*image={image}/>*/}
                    <Text
                        align={"center"}
                        fontSize={secondFontSize}
                        fontStyle={"bold"}
                        fill={secondTextColor}
                        x={canvasWidth * secondLeft / 100}
                        y={canvasHeight * secondTop / 100}
                        offsetY={fontSize / 2}
                        offsetX={canvasWidth / 2}
                        width={canvasWidth}
                        text={predicate}
                    />


                    <Text
                        align={"left"}
                        fontSize={secondCompetenciesFontSize}
                        fontStyle={"bold"}
                        fill={secondCompetenciesTextColor}
                        x={canvasWidth * secondCompetenciesLeft / 100}
                        y={canvasHeight * secondCompetenciesTop / 100}
                        width={canvasWidth}
                        text={competenciesCertificate}
                    />
                </Layer>
            </Stage>
        </div>
    }


    const waitTimeout = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    const generateAllCertificate = async () => {

        let isPredicateFilled = true
        for (let r of registrants) {
            if (!r.predicate) {
                isPredicateFilled = false
            }
        }

        if (!isPredicateFilled && !hidePageTwo) {
            alert("Peringatan : Tidak Semua Predikat Terisi, refresh halaman ini untuk membatalkan, tekan Ok untuk melanjutkan")
        }

        let participation = new Participation()

        let success = 0

        setIsUploading(true)

        try {

            let number = 0;

            let sortedRegistrants = _.orderBy(registrants, (obj) => {
                return obj?.member?.full_name.toLowerCase()
            }, ['asc'])

            for (let r of sortedRegistrants) {

                setName(r?.member?.full_name)

                number++;

                console.log(number + " " + r.id + " generating for " + r?.member?.full_name)

                setNumberPrefix(textFormatter.zeroPadder(number, 3))

                if (!r.predicate && !hidePageTwo) {
                    continue
                }

                setPredicate(r.predicate)

                await waitTimeout(200)

                const pdf = new jsPDF(
                    'l', 'mm', [297, 210]
                );

                let result = stageRef.toDataURL({pixelRatio: 4})
                pdf.addImage(result, 'JPEG', 0, 0, 297, 210, undefined, "FAST");

                if (!hidePageTwo) {
                    let result2 = stageRef2.toDataURL({pixelRatio: 4})
                    pdf.addPage()
                    pdf.addImage(result2, 'JPEG', 0, 0, 297, 210, undefined, "FAST");
                }

                let pdfResult = pdf.output('blob');

                console.log(pdfResult)

                try {
                    let result = await participation.uploadSingleCertificate(pdfResult, r.id, event_url)
                    console.log('upload result', result)
                    console.log("success " + r.id)

                    success++;
                    setSuccessCount(success)
                } catch (e) {
                    console.log(e)
                }

            }
        } catch (e) {
            setIsUploading(false)
            alert("Sukses mengunggah " + success + "/" + registrants.length)

            console.log(e)

            getParticipants()

            return
        }

        setIsUploading(false)
        alert("Sukses mengunggah " + success + "/" + registrants.length)
        getParticipants()

    }

    const renderContent = () => {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "OpenSans-Regular",
            marginTop: 30
        }}>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    marginRight: 25,
                }}>
                    Sertifikat
                </div>

            </div>

            <div>
                <Button
                    onClick={() => {
                        setIsDownloadingCertificate(true)
                    }}
                    disabled={isUploading}
                    variant={"primary"} style={{marginTop: 25, marginLeft: 10, color: "white"}}>
                    Unduh Sertifikat
                </Button>

                <Button
                    onClick={() => {
                        setShowMultiPredicateModal(true)
                    }}
                    disabled={isUploading}
                    variant={"primary"} style={{marginTop: 25, marginLeft: 10, color: "white"}}>
                    Isi Predikat
                </Button>
            </div>

            {/*<Col xl={6}>*/}

            <div style={{
                paddingTop: "1em",
                paddingBottom: "1em",
            }}>
                <MobTable
                    columns={columns} data={registrants}/>
            </div>

            <Container fluid>
                <Row>
                    <Col xs={3}/>
                    <Col xs={6} style={{marginTop: 20}}>
                        {/*{renderOldEditor()}*/}
                        {renderNewEditor()}
                    </Col>
                    <Col xs={3}/>
                    <Col xs={3}/>
                    <Col xs={6} style={{marginTop: 20}}>
                        {/*{renderOldEditor()}*/}
                        {!hidePageTwo ? renderEditorPage2() : null}
                    </Col>
                    <Col xs={3}/>
                    <Col md={12}>

                        <div style={{marginTop: "2em"}}>
                            <div>
                                <b style={{marginRight: 20}}>Template Sertifikat</b>
                            </div>
                            <div>
                                Harap Unggah Template Gambar (Rasio A4)

                                <div style={{
                                    marginTop: 10,
                                    marginBottom: 10
                                }}>

                                    Sembunyikan Halaman Dua

                                    <Switch
                                        checked={hidePageTwo}
                                        onChange={() => {
                                            setHidePageTwo(!hidePageTwo)
                                        }}
                                        color="primary"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />

                                </div>


                                <div style={{
                                    marginTop: 10,
                                    fontSize: "1.1em",
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <FileUpload
                                        text={"Unggah Halaman 1"}
                                        onDrop={(result) => {
                                            setCertPage1File(result[0])

                                            let r = URL.createObjectURL(result[0])
                                            console.log(result)
                                            console.log('result', r)
                                            setBackgroundImageURL(r)
                                            setTemplateName(result[0]?.name)
                                        }}
                                    />

                                    <div style={{width: "1em"}}/>

                                    {
                                        !hidePageTwo ?
                                            <FileUpload
                                                text={"Unggah Halaman 2"}
                                                onDrop={(result) => {
                                                    setCertPage2File(result[0])

                                                    let r = URL.createObjectURL(result[0])
                                                    console.log(result)
                                                    setBackgroundImageURL2(r)
                                                    setTemplateName(result[0]?.name)
                                                }}
                                            />
                                            :
                                            null
                                    }

                                </div>

                            </div>

                            <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                                <Button
                                    onClick={async () => {
                                        // console.log(stageRef.toDataURL


                                        const pdf = new jsPDF(
                                            'l', 'mm', [297, 210]
                                        );

                                        let result = stageRef.toDataURL({pixelRatio: 4})

                                        pdf.addImage(result, 'JPEG', 0, 0, 297, 210, undefined, "FAST");

                                        if (!hidePageTwo) {
                                            let result2 = stageRef2.toDataURL({pixelRatio: 4})
                                            pdf.addPage()
                                            pdf.addImage(result2, 'JPEG', 0, 0, 297, 210, undefined, "FAST");
                                        }

                                        pdf.save("download.pdf");

                                        // let output = pdf.output()
                                        // console.log(output)

                                        // let a = document.createElement("a"); //Create <a>
                                        // a.href = result; //Image Base64 Goes here
                                        // a.download = "Image.png"; //File name Here
                                        // a.click(); //Downloaded file
                                        //
                                        // let a2 = document.createElement("a"); //Create <a>
                                        // a2.href = result2; //Image Base64 Goes here
                                        // a2.download = "Image2.png"; //File name Here
                                        // a2.click(); //Downloaded file

                                        // window.location.href = 'data:image/png;base64,' + result;
                                        console.log(result)
                                    }}
                                    disabled={isUploading}
                                    variant={"primary"}
                                    style={{color: "white"}}>
                                    Download Satu
                                </Button>
                                <Button
                                    onClick={() => {
                                        generateAllCertificate()
                                    }}
                                    disabled={isUploading}
                                    variant={"primary"} style={{marginLeft: 10, color: "white"}}>
                                    Generate dan upload untuk semua
                                </Button>
                                {
                                    isUploading ?
                                        <div style={{
                                            marginLeft: 10,
                                            display: "flex", justifyContent: "center", alignItems: "center"
                                        }}>
                                            ({successCount}/{registrants.length})
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Nama Peserta (Untuk Testing)"}
                            fullWidth
                            value={name}
                            disabled={isUploading}
                            placeholder={"Untuk Testing"}
                            changeValue={value => {
                                setName(value)
                                setTestingName(value)
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Predikat (Untuk Testing)"}
                            fullWidth
                            value={predicate}
                            disabled={isUploading}
                            placeholder={"Untuk Testing"}
                            changeValue={value => {
                                setPredicate(value)
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Depan dari Nomor Surat"}
                            fullWidth
                            value={numberPrefix}
                            disabled={isUploading}
                            changeValue={value => {
                                setNumberPrefix(value)
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Belakang dari Nomor Surat"}
                            fullWidth
                            value={numberSuffix}
                            disabled={isUploading}
                            changeValue={value => {
                                setNumberSuffix(value)
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{paddingTop: 30}}>
                        <b>Nomor Surat</b>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Ukuran Font"}
                                    onChange={e => {
                                        setFontSizeNumber(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={fontSizeNumber}/>
                                <Slider
                                    value={fontSizeNumber}
                                    min={1}
                                    step={1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setFontSizeNumber(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Horizontal"}
                                    onChange={e => {
                                        setLeftNumber(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={leftNumber}/>
                                <Slider
                                    value={leftNumber}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setLeftNumber(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Vertikal"}
                                    onChange={e => {
                                        setTopNumber(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={topNumber}/>
                                <Slider
                                    value={topNumber}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setTopNumber(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{paddingTop: 30}}>
                        <b>Halaman 1</b>
                    </Col>

                    <Col md={12} style={{paddingTop: 30}}>
                        <b>Nama Peserta</b>
                    </Col>

                    <Col md={4} style={{
                        paddingTop: 20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end"
                    }}
                    >
                        <ColorPicker
                            label={"Warna Text"}
                            name='color'
                            fullWidth={true}
                            style={{
                                flex: 1
                            }}
                            value={textColor}
                            defaultValue={"Klik Untuk Mengubah Warna"}
                            // value={this.state.color} - for controlled component
                            onChange={color => setTextColor(color)}
                        />
                        {textColor}
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Ukuran Font"}
                                    onChange={e => {
                                        setFontSize(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={fontSize}/>
                                <Slider
                                    value={fontSize}
                                    min={1}
                                    step={1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setFontSize(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Horizontal"}
                                    onChange={e => {
                                        setLeft(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={left}/>
                                <Slider
                                    value={left}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setLeft(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Vertikal"}
                                    onChange={e => {
                                        setTop(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isUploading}
                                    value={top}/>
                                <Slider
                                    value={top}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setTop(value)
                                    }}
                                    disabled={isUploading}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

                {
                    !hidePageTwo ?
                        <>
                            <Row>
                                <Col md={12} style={{paddingTop: 30}}>
                                    <b>Halaman 2</b>
                                </Col>

                                <Col md={12} style={{paddingTop: 30}}>
                                    <b>Predikat</b>
                                </Col>


                                <Col md={4} style={{
                                    paddingTop: 20,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end"
                                }}>
                                    <ColorPicker
                                        label={"Warna Text"}
                                        name='color'
                                        fullWidth={true}
                                        style={{
                                            flex: 1
                                        }}
                                        value={secondTextColor}
                                        defaultValue={"Klik Untuk Mengubah Warna"}
                                        // value={this.state.color} - for controlled component
                                        onChange={color => setSecondTextColor(color)}
                                    />
                                    {secondTextColor}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div style={{marginTop: "1em"}}>
                                        <div>
                                            <TextField
                                                label={"Ukuran Font"}
                                                onChange={e => {
                                                    setSecondFontSize(e.target.value)
                                                    // console.log(value + " ! " + fontSize)
                                                }}
                                                disabled={isUploading}
                                                value={secondFontSize}/>
                                            <Slider
                                                value={secondFontSize}
                                                min={1}
                                                step={1}
                                                max={100}
                                                scale={(x) => x}
                                                // getAriaValueText={valueLabelFormat}
                                                // valueLabelFormat={valueLabelFormat}
                                                onChange={(e, value) => {
                                                    // console.log(value)
                                                    setSecondFontSize(value)
                                                }}
                                                disabled={isUploading}
                                                style={{flex: 1}}
                                                // valueLabelDisplay="auto"
                                                // aria-labelledby="non-linear-slider"
                                            />
                                        </div>
                                    </div>

                                </Col>

                                <Col md={4}>
                                    <div style={{marginTop: "1em"}}>
                                        <div>
                                            <TextField
                                                label={"Posisi Horizontal"}
                                                onChange={e => {
                                                    setSecondLeft(e.target.value)
                                                    // console.log(value + " ! " + fontSize)
                                                }}
                                                disabled={isUploading}
                                                value={secondLeft}/>
                                            <Slider
                                                value={secondLeft}
                                                min={1}
                                                step={0.1}
                                                max={100}
                                                scale={(x) => x}
                                                // getAriaValueText={valueLabelFormat}
                                                // valueLabelFormat={valueLabelFormat}
                                                onChange={(e, value) => {
                                                    // console.log(value)
                                                    setSecondLeft(value)
                                                }}
                                                disabled={isUploading}
                                                style={{flex: 1}}
                                                // valueLabelDisplay="auto"
                                                // aria-labelledby="non-linear-slider"
                                            />
                                        </div>
                                    </div>

                                </Col>

                                <Col md={4}>
                                    <div style={{marginTop: "1em"}}>
                                        <div>
                                            <TextField
                                                label={"Posisi Vertikal"}
                                                onChange={e => {
                                                    setSecondTop(e.target.value)
                                                    // console.log(value + " ! " + fontSize)
                                                }}
                                                disabled={isUploading}
                                                value={secondTop}/>
                                            <Slider
                                                value={secondTop}
                                                min={1}
                                                step={0.1}
                                                max={100}
                                                scale={(x) => x}
                                                // getAriaValueText={valueLabelFormat}
                                                // valueLabelFormat={valueLabelFormat}
                                                onChange={(e, value) => {
                                                    // console.log(value)
                                                    setSecondTop(value)
                                                }}
                                                disabled={isUploading}
                                                style={{flex: 1}}
                                                // valueLabelDisplay="auto"
                                                // aria-labelledby="non-linear-slider"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            {backgroundImageURL2 !== defaultBackgroundImageURL2 &&
                            <>
                                <Row>
                                    <Col md={12} style={{paddingTop: 30}}>
                                        <b>Kompetensi</b>
                                    </Col>


                                    <Col md={4} style={{
                                        paddingTop: 20,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end"
                                    }}>
                                        <ColorPicker
                                            label={"Warna Text"}
                                            name='color'
                                            fullWidth={true}
                                            style={{
                                                flex: 1
                                            }}
                                            value={secondCompetenciesTextColor}
                                            defaultValue={"Klik Untuk Mengubah Warna"}
                                            // value={this.state.color} - for controlled component
                                            onChange={color => setSecondCompetenciesTextColor(color)}
                                        />
                                        {secondCompetenciesTextColor}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        <div style={{marginTop: "1em"}}>
                                            <div>
                                                <TextField
                                                    label={"Ukuran Font"}
                                                    onChange={e => {
                                                        setSecondCompetenciesFontSize(e.target.value)
                                                        // console.log(value + " ! " + fontSize)
                                                    }}
                                                    disabled={isUploading}
                                                    value={secondCompetenciesFontSize}/>
                                                <Slider
                                                    value={secondCompetenciesFontSize}
                                                    min={1}
                                                    step={1}
                                                    max={100}
                                                    scale={(x) => x}
                                                    // getAriaValueText={valueLabelFormat}
                                                    // valueLabelFormat={valueLabelFormat}
                                                    onChange={(e, value) => {
                                                        // console.log(value)
                                                        setSecondCompetenciesFontSize(value)
                                                    }}
                                                    disabled={isUploading}
                                                    style={{flex: 1}}
                                                    // valueLabelDisplay="auto"
                                                    // aria-labelledby="non-linear-slider"
                                                />
                                            </div>
                                        </div>

                                    </Col>

                                    <Col md={4}>
                                        <div style={{marginTop: "1em"}}>
                                            <div>
                                                <TextField
                                                    label={"Posisi Horizontal"}
                                                    onChange={e => {
                                                        setSecondCompetenciesLeft(e.target.value)
                                                        // console.log(value + " ! " + fontSize)
                                                    }}
                                                    disabled={isUploading}
                                                    value={secondCompetenciesLeft}/>
                                                <Slider
                                                    value={secondCompetenciesLeft}
                                                    min={1}
                                                    step={0.1}
                                                    max={100}
                                                    scale={(x) => x}
                                                    // getAriaValueText={valueLabelFormat}
                                                    // valueLabelFormat={valueLabelFormat}
                                                    onChange={(e, value) => {
                                                        // console.log(value)
                                                        setSecondCompetenciesLeft(value)
                                                    }}
                                                    disabled={isUploading}
                                                    style={{flex: 1}}
                                                    // valueLabelDisplay="auto"
                                                    // aria-labelledby="non-linear-slider"
                                                />
                                            </div>
                                        </div>

                                    </Col>

                                    <Col md={4}>
                                        <div style={{marginTop: "1em"}}>
                                            <div>
                                                <TextField
                                                    label={"Posisi Vertikal"}
                                                    onChange={e => {
                                                        setSecondCompetenciesTop(e.target.value)
                                                        // console.log(value + " ! " + fontSize)
                                                    }}
                                                    disabled={isUploading}
                                                    value={secondCompetenciesTop}/>
                                                <Slider
                                                    value={secondCompetenciesTop}
                                                    min={1}
                                                    step={0.1}
                                                    max={100}
                                                    scale={(x) => x}
                                                    // getAriaValueText={valueLabelFormat}
                                                    // valueLabelFormat={valueLabelFormat}
                                                    onChange={(e, value) => {
                                                        // console.log(value)
                                                        setSecondCompetenciesTop(value)
                                                    }}
                                                    disabled={isUploading}
                                                    style={{flex: 1}}
                                                    // valueLabelDisplay="auto"
                                                    // aria-labelledby="non-linear-slider"
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                            }
                        </>
                        :
                        null
                }

                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 30}}>
                    <Button
                        variant={"primary"}
                        style={{color: "white"}}
                        onClick={async () => {
                            console.log(certPage1File)
                            const classModel = new Class();
                            let certPage1 = null;
                            let certPage2 = null;
                            try {
                                if (certPage1File) {
                                    let formData = new FormData();
                                    formData.append('upload', certPage1File, certPage1File.name)

                                    const result = await classModel.uploadCertificateTemplate(event_url, formData, 1)

                                    certPage1 = result.location
                                } else if (backgroundImageURL !== defaultBackgroundImageURL) {
                                    certPage1 = backgroundImageURL;
                                }

                                if (certPage2File) {
                                    let formData = new FormData();
                                    formData.append('upload', certPage2File, certPage2File.name)

                                    const result = await classModel.uploadCertificateTemplate(event_url, formData, 2)

                                    certPage2 = result.location
                                } else if (backgroundImageURL2 !== defaultBackgroundImageURL2) {
                                    certPage2 = backgroundImageURL2;
                                }

                                const result = await classModel.update(event_url, {
                                    custom_certificate_template: JSON.stringify({
                                        certPage1,
                                        certPage2,
                                        hidePageTwo,
                                        numberPrefix,
                                        numberSuffix,
                                        fontSizeNumber: parseFloat(fontSizeNumber),
                                        leftNumber: parseFloat(leftNumber),
                                        topNumber: parseFloat(topNumber),
                                        textColor,
                                        fontSize: parseFloat(fontSize),
                                        left: parseFloat(left),
                                        top: parseFloat(top),
                                        secondTextColor,
                                        secondFontSize: parseFloat(secondFontSize),
                                        secondTop: parseFloat(secondTop),
                                        secondLeft: parseFloat(secondLeft),
                                        secondCompetenciesTextColor,
                                        secondCompetenciesFontSize: parseFloat(secondCompetenciesFontSize),
                                        secondCompetenciesLeft: parseFloat(secondCompetenciesLeft),
                                        secondCompetenciesTop: parseFloat(secondCompetenciesTop)
                                    })
                                })

                                if (result.success) {
                                    showSnackBar('Template Sertifikat Berhasil Diperbarui!')
                                }
                                console.log('result', result)
                            } catch (e) {
                                console.log('e', e)
                            }
                        }}
                    >
                        Simpan Template
                    </Button>
                </div>


            </Container>
        </div>
    }

    const renderCompetencies = () => {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "OpenSans-Regular",
            marginTop: 30
        }}>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    marginRight: 25,
                }}>
                    Kompetensi
                </div>

                <div>
                    <Button
                        onClick={() => {
                            setManageCompetencyModalVisible(true)
                        }}
                        variant={"primary"} style={{marginTop: 25, marginLeft: 10, color: "white"}}>
                        Tambah Kompetensi
                    </Button>
                </div>

                <div style={{
                    paddingTop: "1em",
                    paddingBottom: "1em",
                }}>
                    <MobTable
                        interactions={competencyInteraction}
                        columns={competencyColumns} data={competencies}/>
                </div>
            </div>
        </div>
    }


    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar || isUploading}
                isLoadingFull={isLoading}
                activeTab={"certificate"}
            >
                <Dialog open={isDeleteCompetencyModalVisible}
                        maxWidth="sm">
                    <>
                        <DialogTitle onClose={() => this.onClose()}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Kompetensi
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus kompetensi ini?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeleteCompetencyModalVisible(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={async () => {
                                        const whatToLearn = JSON.parse(classObj.what_to_learn);

                                        whatToLearn.splice(selectedCompetencyIdx, 1);

                                        const classModel = new Class();

                                        try {
                                            const result = await classModel.update(event_url, {
                                                what_to_learn: JSON.stringify(whatToLearn)

                                            })

                                            setDeleteCompetencyModalVisible(false)

                                            getClass();
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>


                <Dialog open={isManageCompetencyModalVisible}
                        maxWidth="md"
                        fullWidth={true}
                >
                    <DialogTitle onClose={() => {

                    }}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {!selectedCompetencyIdx ? 'Tambah' : 'Sunting'} Kompetensi
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>
                    <DialogContent>
                        <Collapse in={errorMsg.length > 0}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setErrorMsg("");
                                        }}
                                    >
                                        <FaTimes fontSize="inherit"/>
                                    </IconButton>
                                }
                            >
                                {errorMsg}
                            </Alert>
                        </Collapse>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            marginTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Kompetensi'}
                                    changeValue={value => {
                                        setCompetency(value)
                                    }}
                                    value={competency}
                                    placeholder={"Kompetensi"}/>
                            </Col>
                        </Row>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '3em',
                            paddingBottom: '1em',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                        >
                            <CustomButton
                                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => {
                                    setCompetency("");
                                    setManageCompetencyModalVisible(false)
                                    setErrorMsg("")
                                    setSelectedCompetencyIdx(null)
                                }}
                                variant={"outlined"} color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                onClick={async () => {


                                    let whatToLearn = [];

                                    if (!competency) {
                                        setErrorMsg("Kompetensi tidak boleh kosong!")
                                    } else {
                                        if (classObj.what_to_learn) {
                                            whatToLearn = JSON.parse(classObj.what_to_learn);
                                        }

                                        if (selectedCompetencyIdx !== null) {
                                            whatToLearn[selectedCompetencyIdx] = competency
                                        } else {
                                            whatToLearn.push(competency)
                                        }

                                        const classModel = new Class();

                                        try {
                                            const result = await classModel.update(event_url, {
                                                what_to_learn: JSON.stringify(whatToLearn)

                                            })

                                            setCompetency("");
                                            setManageCompetencyModalVisible(false)
                                            setErrorMsg("")
                                            setSelectedCompetencyIdx(null)

                                            getClass();
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }
                                }}
                                style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"contained"} color="primary">
                                <>Simpan&nbsp;&nbsp;<FaSave/></>
                            </CustomButton>
                        </Row>
                    </DialogContent>
                </Dialog>


                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={snackBarProp.open} autoHideDuration={6000}
                    onClose={() => {
                        setSnackBarProp({
                            open: false
                        })
                    }}>
                    <Alert severity={snackBarProp.severity}>
                        {snackBarProp.message}
                    </Alert>
                </Snackbar>

                {renderAlertBox()}
                {renderSuccessBox()}

                <Tabs defaultActiveKey="certificate">
                    <Tab
                        eventKey="certificate"
                        title={
                            "Sertifikat"
                        }
                    >
                        {
                            renderContent()
                        }
                    </Tab>

                    <Tab
                        eventKey="competencies"
                        title={
                            "Kompetensi"
                        }
                    >
                        {renderCompetencies()}
                    </Tab>
                </Tabs>


                <MultiPredicateModal
                    show={showMultiPredicateModal}
                    participations={registrants}
                    onClose={(refresh) => {
                        setShowMultiPredicateModal(false)
                        console.log("is refresh ? " + refresh)
                        if (refresh) {
                            console.log("on lcose true")
                            getParticipants()
                        }
                    }}
                />

                <CertificatePredicateModal
                    show={showPredicateModal}
                    participant={activeParticipant}
                    onClose={(refresh) => {
                        setShowPredicateModal(false)
                        setActiveParticipant({})
                        console.log("is refresh ? " + refresh)
                        if (refresh) {
                            console.log("on lcose true")
                            getParticipants()
                        }
                    }}
                />

                <DownloadSomeCertificateModal
                    classCode={event_url}
                    participants={registrants}
                    isOpen={isDownloadingCertificate}
                    onClose={(refresh) => {
                        setIsDownloadingCertificate(false)
                    }}
                />

            </EditorPageWrapper>
        </>
    );

}
