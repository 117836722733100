export default {
    token : null,
    domain : "http://localhost:3000/",
    // subfolder : "inibukanvirtual/"
    // subfolder : "192.168.0.105:3000/jauhdimatadekatdihati/",
    // subfolder : "http://localhost:3000/jauhdimatadekatdihati/",
    subfolder : "https://patjarmerah.com/",
    // subfolder : "https://patjarmerah.com/jauhdimatadekatdihati/",
    privateClass: false //for mockup purpose
};
