import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Session {

    getRegistrations = async (id) =>{
        let registrations = await ApiRequest.set(`/v2/sessions/${id}/registration/info`, "GET");

        return registrations
    }

}
