import {Button, Col, Row} from "react-bootstrap";
import Palette from "../../../util/Palette";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useEffect, useMemo, useState} from "react";
import Topic from "../../../models/Topic";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import ManageTopicModal from "../ManageTopicModal";
import InputAdornment from "@material-ui/core/InputAdornment";
import LagFreeTextEditor from "../LagFreeTextEditor";
import CustomButton from "../CustomButton";
import {AiFillWarning, FaCloudUploadAlt, MdClear, MdFileDownload, MdOpenInNew} from "react-icons/all";
import textFormatter from "../../../util/textFormatter";
import FileUpload from "../FileUpload";
import Email from "../../../models/Email";
import Course from "../../../models/Course";
import IconButton from "@material-ui/core/IconButton";
import {MdCheckCircle, MdClose, MdModeEdit} from "react-icons/md";
import {AiOutlineLink} from "react-icons/ai";
import ManageRecordingModal from "../ManageRecordingModal";
import MobTable from "../Table/MobTable";
import Form from "react-bootstrap/Form";
import {FaSave} from "react-icons/fa";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FileUploadIcon from "../FileUploadNew";
import RichText from "../RichText/LimitedRichText";

let speakerTimer;
let nameTimer;
let shortDescriptionTimer;
let longDescriptionTimer;
let urlTimer;
const topicModel = new Topic();

export default function AboutEditor(props) {
    const [progress, setProgress] = useState(false)
    const [whatToLearnEditValue, setWhatToLearnEditValue] = useState('')
    const [categoryEditValue, setCategoryEditValue] = useState('')
    const [selectedWhatToLearnIdx, setSelectedWhatToLearnIdx] = useState(-1);
    const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(-1);

    let {editSessionState, editEventState, classObj, setClass} = props

    console.log('classObj', classObj)

    const [code, setCode] = useState(null);
    const [source, setSource] = useState('');
    const [streamUrl, setStreamUrl] = useState('');
    let [speaker, setSpeaker] = useState("")
    let [name, setName] = useState("")
    let [description, setDescription] = useState("")
    let [url, setURL] = useState("")
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(0);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isAddTopicModalShown, setAddTopicModalShown] = useState(false);

    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)
    const [attachment, setAttachment] = useState({})

    const [isManageRecordingModalShown, setManageRecordingModalShown] = useState(false);

    const [isWhatWillBeLearntDialogShown, setWhatWillBeLearntDialogShown] = useState(false)
    const [isManageCategoryDialogShown, setManageCategoryDialogShown] = useState(false);

    useEffect(() => {
        getTopic();

        setCode(props.classObj.code);
        setSource(props.classObj.source);
        setSelectedTopic(props.classObj.topic_code)
        setName(props.classObj.title)
        setStreamUrl(props.classObj.preview_video_url);

        setSpeaker(props.speaker)
        setDescription(props.description)

        setURL(props.url)
    }, [props.speaker, props.shortDescription, props.longDescription, props.name, props.classObj])

    const getTopic = async () => {
        const result = await topicModel.getAll();

        setTopics(result)
    }

    const editSpeaker = (value) => {
        setSpeaker(value)

        setClass({
            ...classObj,
            speaker: value
        })
    }

    const editDescription = (value) => {
        setDescription(value)

        setClass({
            ...classObj,
            description: value
        })
    }

    const editName = (value) => {
        setName(value)

        setClass({
            ...classObj,
            title: value
        })
    }

    const addAttachment = async function (addedFile) {

        setIsUploadingAttachment(true)

        try {


            let result = await new Email().addAttachment(addedFile[0])
            speaker = result.web_url_download
            setAttachment({
                value: result.location,
                label: addedFile[0].name
            })

            setClass({
                ...classObj,
                attachment_url: result.location
            })

            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }


    const addAttachmentGdrive = async function (addedFile) {
        if (addedFile[0].type !== 'video/mp4') {
            return alert('Harap hanya unggah video dengan format MP4');
        }


        setIsUploadingAttachment(true)

        try {
            let result = await new Course().uploadVideo(addedFile[0], setProgress)
            // console.log('isinya' + result.web_url_download)
            setStreamUrl(result.location);
            setClass({
                ...classObj,
                preview_video_url: result.location
            })
            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }

    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    return <>
        <Dialog open={isManageCategoryDialogShown}
                maxWidth="md"
                fullWidth={true}
                onClose={() => setManageCategoryDialogShown(false)}
        >
            <DialogTitle>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        Kategori
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Row>
                    <Col>
                        <LagFreeTextEditor
                            title={'Kategori'}
                            changeValue={(value) => setCategoryEditValue(value)}
                            value={categoryEditValue}
                            placeholder={"Kategori"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{
                            borderWidth: 0,
                            marginRight: 10,
                            width: 120,
                            fontFamily: 'OpenSans-SemiBold'
                        }}
                        onClick={() => {
                            setSelectedCategoryIdx(-1)

                            setTimeout(() => {
                                setCategoryEditValue('')
                            }, 100)

                            setManageCategoryDialogShown(false)
                        }}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={() => {
                            if (categoryEditValue) {
                                setSelectedCategoryIdx(-1)
                                setManageCategoryDialogShown(false)
                                const classObjTemp = {...classObj};

                                if (selectedCategoryIdx === -1) {
                                    classObjTemp.category.push(categoryEditValue)
                                } else {
                                    classObjTemp.category[selectedCategoryIdx] = categoryEditValue;
                                }


                                setTimeout(() => {
                                    setCategoryEditValue('')
                                }, 100)
                            } else {
                                alert('Harap mengisi kategori')
                            }
                        }}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>

        <Dialog open={isWhatWillBeLearntDialogShown}
                maxWidth="md"
                fullWidth={true}
                onClose={() => setWhatWillBeLearntDialogShown(false)}
        >
            <DialogTitle>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        color: Palette.PRIMARY,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        Kelola yang akan dipelajari
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Row>
                    <Col>
                        <LagFreeTextEditor
                            title={'Yang akan dipelajari'}
                            changeValue={(value) => setWhatToLearnEditValue(value)}
                            value={whatToLearnEditValue}
                            placeholder={"Yang akan dipelajari"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '3em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{
                            borderWidth: 0,
                            marginRight: 10,
                            width: 120,
                            fontFamily: 'OpenSans-SemiBold'
                        }}
                        onClick={() => {
                            setSelectedWhatToLearnIdx(-1)

                            setTimeout(() => {
                                setWhatToLearnEditValue('')
                            }, 100)

                            setWhatWillBeLearntDialogShown(false)
                        }}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={() => {
                            if (whatToLearnEditValue) {
                                setSelectedWhatToLearnIdx(-1)
                                setWhatWillBeLearntDialogShown(false)
                                const classObjTemp = {...classObj};

                                if (selectedWhatToLearnIdx === -1) {
                                    classObjTemp.what_to_learn.push(whatToLearnEditValue)
                                } else {
                                    classObjTemp.what_to_learn[selectedWhatToLearnIdx] = whatToLearnEditValue;
                                }

                                setTimeout(() => {
                                    setWhatToLearnEditValue('')
                                }, 100)
                            } else {
                                alert('Harap mengisi yang akan dipelajari')
                            }
                        }}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
        </Dialog>

        <ManageRecordingModal show={isManageRecordingModalShown}
                              handleClose={() => setManageRecordingModalShown(false)}
                              classObj={classObj}
                              setClass={setClass}/>

        <Row style={{marginTop: 30}}>
            <Col md={12}>
                <p style={{
                    color: Palette.PRIMARY,
                    fontFamily: "OpenSans-Bold",
                    fontSize: "1.2em"
                }}>Deskripsi Kelas</p>
            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*><Col md={12}>*/}
        {/*    <LagFreeTextEditor*/}
        {/*        title={'Kode Kelas'}*/}
        {/*        disabled={props.update}*/}
        {/*        changeValue={value => {*/}
        {/*            if (!props.update) {*/}
        {/*                setClass({*/}
        {/*                    ...classObj,*/}
        {/*                    code: value*/}
        {/*                })*/}
        {/*            }*/}
        {/*        }}*/}
        {/*        subtitle={'(akan dibuat secara acak jika dibiarkan kosong)'}*/}
        {/*        value={code}*/}
        {/*        placeholder={"Kode Kelas"}/>*/}
        {/*</Col>*/}
        {/*</Row>*/}

        {/*{*/}
        {/*    props.update ?*/}
        {/*        <Row style={{*/}
        {/*            paddingInlineStart: "2%",*/}
        {/*            paddingInlineEnd: "2%",*/}
        {/*            marginTop: "1em",*/}
        {/*            fontFamily: 'OpenSans-SemiBold'*/}
        {/*        }}*/}
        {/*        ><Col md={12}>*/}
        {/*            <LagFreeTextEditor*/}


        {/*                InputProps={{*/}
        {/*                    style: {*/}
        {/*                        fontFamily: "OpenSans-Regular"*/}
        {/*                    },*/}
        {/*                    endAdornment: (*/}
        {/*                        <InputAdornment position="end">*/}
        {/*                            {*/}

        {/*                                <IconButton*/}
        {/*                                    onMouseDown={e => e.preventDefault()}*/}
        {/*                                >*/}
        {/*                                    <a target="_blank"*/}
        {/*                                       href={`https://sinara.narabahasa.id/seminar/detail/${code}`}>*/}
        {/*                                        <MdOpenInNew style={{color: Palette.PRIMARY}}/>*/}
        {/*                                    </a>*/}
        {/*                                </IconButton>*/}

        {/*                            }*/}

        {/*                        </InputAdornment>*/}
        {/*                    ),*/}
        {/*                }}*/}

        {/*                title={'Tautan Halaman Pratinjau'}*/}
        {/*                disabled={props.update}*/}
        {/*                changeValue={value => {*/}
        {/*                    if (!props.update) {*/}
        {/*                        setClass({*/}
        {/*                            ...classObj,*/}
        {/*                            code: value*/}
        {/*                        })*/}
        {/*                    }*/}
        {/*                }}*/}
        {/*                value={`https://s.narabahasa.id/kelas-daring-mandiri/${code}/pratinjau`}*/}
        {/*                placeholder={"Kode Kelas"}/>*/}
        {/*        </Col>*/}
        {/*        </Row>*/}
        {/*        :*/}
        {/*        null*/}
        {/*}*/}

        <div style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            // display: props.update && !classObj.course_code ? 'none' : 'block'
        }}>
            <LagFreeTextEditor
                changeValue={value => setClass({
                    ...classObj,
                    course_code: value
                })}
                InputProps={{
                    style: {
                        fontFamily: "OpenSans-Regular"
                    },
                }}
                subtitle={props.update ? null : '(akan dibuat secara acak jika dibiarkan kosong)'}
                title={classObj.course_code || !props.update ? 'Kode Kelas' : 'ID Kelas'}
                disabled={props.update}
                value={classObj.course_code ? classObj.course_code : classObj.id}
                placeholder={classObj.course_code  || !props.update ? 'Kode Kelas' : 'ID Kelas'}/>
        </div>

        <div style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            // display: props.update && !classObj.course_code ? 'none' : 'block'
        }}>
            <LagFreeTextEditor
                changeValue={value => setClass({
                    ...classObj,
                    ios_product_id: value
                })}
                InputProps={{
                    style: {
                        fontFamily: "OpenSans-Regular"
                    },
                }}
                title={classObj.ios_product_id || !props.update ? 'Product ID' : 'ID Product'}
                // disabled={props.update}
                value={classObj.ios_product_id ? classObj.ios_product_id : classObj.id}
                placeholder={classObj.ios_product_id  || !props.update ? 'Product ID' : 'ID Product'}/>
        </div>

        {props.update &&
        <div style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
        }}>
            <LagFreeTextEditor
                InputProps={{
                    style: {
                        fontFamily: "OpenSans-Regular"
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            {

                                <IconButton
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    <a target="_blank"
                                       href={classObj.course_code ? `https://sinara.narabahasa.id/${classObj.course_code}/kelas-daring-mandiri/pratinjau` : `https://sinara.narabahasa.id/kelas-daring-mandiri/${classObj.id}/pratinjau`}>
                                        <MdOpenInNew style={{color: Palette.PRIMARY}}/>
                                    </a>
                                </IconButton>

                            }

                        </InputAdornment>
                    ),
                }}
                title={'Tautan Kelas'}
                disabled={true}
                value={classObj.course_code ? `https://sinara.narabahasa.id/${classObj.course_code}/kelas-daring-mandiri/pratinjau` : `https://sinara.narabahasa.id/kelas-daring-mandiri/${classObj.id}/pratinjau`}
                placeholder={"Tautan Kelas"}/>
        </div>
        }

        <div style={{
            display: "flex",
            flexDirection: "row",
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
            // paddingInlineStart: "2%",
            // paddingInlineEnd: "2%",
            // marginTop: "1em",
            // fontFamily: 'OpenSans-SemiBold'
        }}
        >

            <div style={{
                flex: 1
            }}>

                <LagFreeTextEditor
                    title={'Tautan Video Pratinjau'}
                    subtitle={'(kosongkan bila belum tersedia)'}
                    changeValue={value => setClass({
                        ...classObj,
                        preview_video_url: value
                    })}
                    value={streamUrl}
                    placeholder={"Tautan Video Pratinjau"}/>
                {
                    !validURL(classObj.preview_video_url) ?
                        <div style={{
                            color: "grey",
                            fontWeight: "lighter"
                        }}>
                            <AiFillWarning
                                style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>
                            Peringatan : Pastikan URL yang diketik valid (contoh :
                            https://www.youtube.com/watch?v=fRVr8e_5yFs)
                        </div>
                        :
                        null
                }

            </div>
            <div>
                <FileUploadIcon
                    style={{
                        marginTop: "0.5em"
                    }}
                    isLoading={isUploadingAttachment}
                    onDrop={addAttachmentGdrive}
                    progress={progress}
                />

            </div>
        </div>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                {/*<div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>*/}
                {/*    <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold'}}>Tautan Rekaman <span*/}
                {/*        style={{fontFamily: 'OpenSans-Regular', fontSize: '.8em', color: 'grey',}}>(kosongkan bila belum tersedia)</span></span>*/}

                {/*    <div style={{display: 'flex', flexDirection: 'row'}}>*/}
                {/*        {*/}
                {/*            classObj.recording_url_array?.map(recording => {*/}
                {/*                return (*/}
                {/*                    <a href={recording.url} style={{marginRight: 15}}>*/}
                {/*                        <CustomButton*/}
                {/*                            style={{*/}
                {/*                                fontSize: "0.8em",*/}
                {/*                                fontFamily: "Poppins",*/}
                {/*                                textTransform: "none",*/}
                {/*                                marginTop: 6,*/}
                {/*                                color: Palette.PRIMARY*/}
                {/*                            }}*/}
                {/*                            variant={"outlined"}>*/}
                {/*                            <AiOutlineLink style={{marginRight: 5, color: Palette.PRIMARY}}/> {recording.title}*/}
                {/*                        </CustomButton>*/}
                {/*                    </a>*/}
                {/*                )*/}
                {/*            })*/}
                {/*        }*/}


                {/*        <CustomButton*/}
                {/*            onClick={() => setManageRecordingModalShown(true)}*/}
                {/*            style={{*/}
                {/*                fontSize: "0.8em",*/}
                {/*                fontFamily: "Poppins",*/}
                {/*                textTransform: "none",*/}
                {/*                marginTop: 6*/}
                {/*            }}*/}
                {/*            variant={"outlined"}>*/}
                {/*            <MdModeEdit style={{marginRight: 5}}/> Sunting Tautan*/}
                {/*        </CustomButton>*/}
                {/*    </div>*/}
                {/*</div>*/}


                {/*<LagFreeTextEditor*/}
                {/*    title={'Tautan Rekaman'}*/}
                {/*    subtitle={'(kosongkan bila belum tersedia)'}*/}
                {/*    changeValue={value => setClass({*/}
                {/*        ...classObj,*/}
                {/*        recording_url: value*/}
                {/*    })}*/}
                {/*    value={classObj.recording_url}*/}
                {/*    placeholder={"Tautan Rekaman"}/>*/}
                {/*{*/}
                {/*    !validURL(classObj.recording_url) ?*/}
                {/*        <div style={{*/}
                {/*            color: "grey",*/}
                {/*            fontWeight: "lighter"*/}
                {/*        }}>*/}
                {/*            <AiFillWarning*/}
                {/*                style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>*/}
                {/*            Peringatan : Pastikan URL yang diketik valid (contoh :*/}
                {/*            https://us02web.zoom.us/j/82590270612)*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}

            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <LagFreeTextEditor*/}
        {/*            title={'Tautan Whatsapp'}*/}
        {/*            subtitle={'(kosongkan bila belum tersedia)'}*/}
        {/*            changeValue={value => setClass({*/}
        {/*                ...classObj,*/}
        {/*                whatsapp_url: value*/}
        {/*            })}*/}
        {/*            value={classObj.whatsapp_url}*/}
        {/*            placeholder={"Tautan Whatsapp"}/>*/}
        {/*        {*/}
        {/*            !validURL(classObj.whatsapp_url) ?*/}
        {/*                <div style={{*/}
        {/*                    color: "grey",*/}
        {/*                    fontWeight: "lighter"*/}
        {/*                }}>*/}
        {/*                    <AiFillWarning*/}
        {/*                        style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>*/}
        {/*                    Peringatan : Pastikan URL yang diketik valid (contoh :*/}
        {/*                    https://us02web.zoom.us/j/82590270612)*/}
        {/*                </div>*/}
        {/*                :*/}
        {/*                null*/}
        {/*        }*/}

        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <LagFreeTextEditor
                    title={'Nama Kelas'}
                    changeValue={value => editName(value)}
                    value={name}
                    placeholder={"Nama Kelas"}/>
            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <span style={{*/}
        {/*            fontSize: ".9rem",*/}
        {/*            fontFamily: 'OpenSans-SemiBold',*/}
        {/*            marginBottom: 15*/}
        {/*        }}>Tingkat Kesulitan</span>*/}
        {/*    </Col>*/}

        {/*    <Col md={12}>*/}
        {/*        <Checkbox*/}
        {/*            style={{*/}
        {/*                fontFamily: "Poppins",*/}
        {/*            }}*/}
        {/*            InputProps={{*/}
        {/*                style: {*/}
        {/*                    fontSize: "1em",*/}
        {/*                    color: "green"*/}
        {/*                },*/}
        {/*            }}*/}
        {/*            onChange={() => {*/}
        {/*                console.log('classObj', classObj)*/}

        {/*                const classTemp = {...classObj};*/}

        {/*                if (classTemp.additional_information?.includes('Pemula')) {*/}
        {/*                    classTemp.additional_information.forEach((info, idx) => {*/}
        {/*                        if (info === 'Pemula') {*/}
        {/*                            classTemp.additional_information.splice(idx, 1)*/}
        {/*                        }*/}
        {/*                    })*/}
        {/*                } else {*/}
        {/*                    classTemp.additional_information.push('Pemula')*/}
        {/*                }*/}

        {/*                if (classTemp.additional_information?.includes('Tingkat Lanjut')) {*/}
        {/*                    classTemp.additional_information.forEach((info, idx) => {*/}
        {/*                        if (info === 'Tingkat Lanjut') {*/}
        {/*                            classTemp.additional_information.splice(idx, 1)*/}
        {/*                        }*/}
        {/*                    })*/}
        {/*                }*/}

        {/*                setClass(classTemp)*/}
        {/*            }}*/}
        {/*            color="primary"*/}
        {/*            checked={Array.isArray(classObj.additional_information) && classObj.additional_information.includes('Pemula')}*/}
        {/*            name={'test'}/>*/}

        {/*        <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-Regular'}}>Pemula</span>*/}
        {/*    </Col>*/}

        {/*    <Col md={12}>*/}
        {/*        <Checkbox*/}
        {/*            style={{*/}
        {/*                fontFamily: "Poppins",*/}
        {/*            }}*/}
        {/*            InputProps={{*/}
        {/*                style: {*/}
        {/*                    fontSize: "1em",*/}
        {/*                    color: "green"*/}
        {/*                },*/}
        {/*            }}*/}
        {/*            onChange={() => {*/}
        {/*                console.log('classObj', classObj)*/}

        {/*                const classTemp = {...classObj};*/}

        {/*                if (classTemp.additional_information?.includes('Tingkat Lanjut')) {*/}
        {/*                    classTemp.additional_information.forEach((info, idx) => {*/}
        {/*                        if (info === 'Tingkat Lanjut') {*/}
        {/*                            classTemp.additional_information.splice(idx, 1)*/}
        {/*                        }*/}
        {/*                    })*/}
        {/*                } else {*/}
        {/*                    classTemp.additional_information.push('Tingkat Lanjut')*/}
        {/*                }*/}

        {/*                if (classTemp.additional_information?.includes('Pemula')) {*/}
        {/*                    classTemp.additional_information.forEach((info, idx) => {*/}
        {/*                        if (info === 'Pemula') {*/}
        {/*                            classTemp.additional_information.splice(idx, 1)*/}
        {/*                        }*/}
        {/*                    })*/}
        {/*                }*/}

        {/*                console.log('classTemp', classTemp)*/}

        {/*                setClass(classTemp)*/}
        {/*            }}*/}
        {/*            color="primary"*/}
        {/*            checked={Array.isArray(classObj.additional_information) && classObj.additional_information.includes('Tingkat Lanjut')}*/}
        {/*            name={'test'}/>*/}

        {/*        <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-Regular'}}>Tingkat Lanjut</span>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold', marginBottom: 15}}>Sertifikat</span>
            </Col>

            <Col md={12}>
                <Checkbox
                    style={{
                        fontFamily: "Poppins",
                    }}
                    InputProps={{
                        style: {
                            fontSize: "1em",
                            color: "green"
                        },
                    }}

                    onChange={() => {
                        console.log('classObj', classObj)

                        const classTemp = {...classObj};

                        if (!classTemp.additional_information?.includes('Sertifikat')) {
                            classTemp.additional_information.push('Sertifikat')
                        }

                        setClass(classTemp)
                    }}
                    color="primary"
                    checked={Array.isArray(classObj.additional_information) && classObj.additional_information.includes('Sertifikat')}
                    name={'test'}/>

                <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-Regular'}}>Iya</span>
            </Col>

            <Col md={12}>
                <Checkbox
                    style={{
                        fontFamily: "Poppins",
                    }}
                    InputProps={{
                        style: {
                            fontSize: "1em",
                            color: "green"
                        },
                    }}

                    onChange={() => {
                        console.log('classObj', classObj)

                        const classTemp = {...classObj};

                        if (classTemp.additional_information?.includes('Sertifikat')) {
                            classTemp.additional_information.forEach((info, idx) => {
                                if (info === 'Sertifikat') {
                                    classTemp.additional_information.splice(idx, 1)
                                }
                            })
                        }

                        setClass(classTemp)
                    }}
                    color="primary"
                    checked={Array.isArray(classObj.additional_information) && !classObj.additional_information.includes('Sertifikat')}
                    name={'test'}/>

                <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-Regular'}}>Tidak</span>
            </Col>
        </Row>


        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <LagFreeTextEditor*/}
        {/*            title={'Pembicara'}*/}
        {/*            changeValue={value => editSpeaker(value)}*/}
        {/*            value={classObj.speaker}*/}
        {/*            placeholder={"Pembicara"}/>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
        }}
        >
            <Col md={12}>
                <div style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold', marginBottom: '.5em'}}>Deskripsi</div>
                <RichText
                    onBlurAndSave={(value) => {
                        editDescription(value)
                    }
                    } value={classObj.description}/>
            </Col>
        </Row>
        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12} sm={12} style={{*/}
        {/*        fontSize: "1.2em",*/}
        {/*        display: "flex",*/}
        {/*        alignItems: "flex-end"*/}
        {/*    }}>*/}
        {/*        <TextField*/}
        {/*            // onBlur={()=>editEventState("nama", name)}*/}
        {/*            onChange={evt => editName(evt.target.value)}*/}
        {/*            style={{*/}
        {/*                marginLeft: "0.5em",*/}
        {/*                fontSize: "1em",*/}
        {/*                fontFamily: 'OpenSans-Regular'*/}
        {/*            }}*/}
        {/*            inputProps={{*/}
        {/*                style: {*/}
        {/*                    fontSize: "1em",*/}
        {/*                    fontFamily: 'OpenSans-Regular',*/}
        {/*                    cursor: "pointer"*/}
        {/*                }*/}
        {/*            }}*/}
        {/*            value={name} fullWidth placeholder={"Nama Kelas"}/>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "3em",
            fontFamily: 'OpenSans-SemiBold',
        }}
        >
            <Col md={12} style={{fontSize: '.9em', display: 'flex'}}><span
                style={{flex: 1}}>Apa yang akan dipelajari?</span> <Button
                onMouseEnter={() => setBtnHovered(true)}
                onMouseLeave={() => setBtnHovered(false)}
                onClick={() => setWhatWillBeLearntDialogShown(true)}
                style={{fontSize: '.8em', fontFamily: 'OpenSans-SemiBold'}} variant={'link'}>Tambah</Button></Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
        }}
        >
            <Col md={12} sm={12} style={{
                display: "flex",
                flexWrap: 'wrap',
            }}>
                {
                    classObj.what_to_learn &&
                    classObj.what_to_learn.map((whatToLearn, idx) => {
                        return (
                            <div style={{
                                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                borderRadius: 5,
                                paddingLeft: 15,
                                // paddingRight: 15,
                                color: Palette.PRIMARY,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginRight: 15,
                                marginTop: 10
                            }}>
                                <div style={{paddingTop: 10, paddingBottom: 10}}>
                                    {whatToLearn}
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: 5}}>
                                    <IconButton onClick={() => {
                                        setSelectedWhatToLearnIdx(idx)

                                        setTimeout(async () => {
                                            setWhatToLearnEditValue(whatToLearn)
                                        }, 100);

                                        setWhatWillBeLearntDialogShown(true)
                                    }}>
                                        <MdModeEdit color={'grey'} style={{cursor: 'pointer'}} size={17}/>
                                    </IconButton>

                                    <IconButton onClick={() => {
                                        const classObjTemp = {...classObj};

                                        classObjTemp.what_to_learn.splice(idx, 1)

                                        setClass(classObjTemp)
                                    }}>
                                        <MdClose color={Palette.SECONDARY} style={{cursor: 'pointer'}} size={20}/>
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                }
            </Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "3em",
            fontFamily: 'OpenSans-SemiBold',
        }}
        >
            <Col md={12} style={{fontSize: '.9em', display: 'flex'}}><span style={{flex: 1}}>Kategori</span> <Button
                onMouseEnter={() => setBtnHovered(true)}
                onMouseLeave={() => setBtnHovered(false)}
                onClick={() => setManageCategoryDialogShown(true)}
                style={{fontSize: '.8em', fontFamily: 'OpenSans-SemiBold'}} variant={'link'}>Tambah
                Kategori</Button></Col>
        </Row>

        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
        }}
        >
            <Col md={12} sm={12} style={{
                display: "flex",
                flexWrap: 'wrap',
            }}>
                {
                    classObj.category &&
                    classObj.category.map((category, idx) => {
                        return (
                            <div style={{
                                boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                borderRadius: 5,
                                paddingLeft: 15,
                                // paddingRight: 15,
                                color: Palette.PRIMARY,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginRight: 15,
                                marginTop: 10
                            }}>
                                <div style={{paddingTop: 10, paddingBottom: 10}}>
                                    {category}
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', marginLeft: 5}}>
                                    <IconButton onClick={() => {
                                        setSelectedCategoryIdx(idx)

                                        setTimeout(async () => {
                                            setCategoryEditValue(category)
                                        }, 100);

                                        setManageCategoryDialogShown(true)
                                    }}>
                                        <MdModeEdit color={'grey'} style={{cursor: 'pointer'}} size={17}/>
                                    </IconButton>

                                    <IconButton onClick={() => {
                                        const classObjTemp = {...classObj};

                                        classObjTemp.category.splice(idx, 1)

                                        setClass(classObjTemp)
                                    }}>
                                        <MdClose color={Palette.SECONDARY} style={{cursor: 'pointer'}} size={20}/>
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })
                }
            </Col>
        </Row>

        {/*<Row style={{*/}
        {/*    paddingInlineStart: "2%",*/}
        {/*    paddingInlineEnd: "2%",*/}
        {/*    marginTop: "1em",*/}
        {/*    fontFamily: 'OpenSans-SemiBold'*/}
        {/*}}*/}
        {/*>*/}
        {/*    <Col md={12}>*/}
        {/*        <p style={{*/}
        {/*            marginTop: "1em"*/}
        {/*        }}>*/}
        {/*            Materi :*/}
        {/*        </p>*/}

        {/*        <div style={{*/}
        {/*            marginLeft: "1em",*/}
        {/*            display: "flex",*/}
        {/*            flexDirection: "column"*/}
        {/*        }}>*/}

        {/*<CreatableSelect*/}
        {/*    isMulti*/}
        {/*    formatCreateLabel={(inputValue) => {*/}
        {/*        return "Tambah Tujuan: " + inputValue*/}
        {/*    }}*/}
        {/*    onChange={handleAttachmentChanges}*/}
        {/*    onClick={()=>{*/}
        {/*        console.log("woei")*/}
        {/*    }}*/}
        {/*    menuIsOpen={false}*/}
        {/*    value={attachments}*/}
        {/*/>*/}

        {/*            <div style={{*/}
        {/*                marginTop: "0.5em"*/}
        {/*            }}>*/}
        {/*                <a*/}
        {/*                    style={{*/}
        {/*                        display: classObj.attachment_url ? null : "none"*/}
        {/*                    }}*/}
        {/*                    href={classObj.attachment_url} target={'_blank'} download>*/}
        {/*                    <CustomButton*/}
        {/*                        style={{*/}
        {/*                            fontSize: "0.85em",*/}
        {/*                            fontFamily: "Poppins",*/}
        {/*                            textTransform: "none",*/}
        {/*                            marginBottom: "1em",*/}
        {/*                        }}*/}
        {/*                        variant={"outlined"}>*/}
        {/*                        {classObj.attachment_url ? textFormatter.getAttachmentURL(classObj.attachment_url) : ""}*/}
        {/*                        <MdFileDownload style={{marginLeft: "0.5em"}}/>*/}
        {/*                    </CustomButton>*/}
        {/*                </a>*/}

        {/*                <FileUpload*/}
        {/*                    style={{*/}
        {/*                        marginTop: "0.5em"*/}
        {/*                    }}*/}
        {/*                    label={classObj.attachment_url ? "Ubah Lampiran" : "+ Unggah Lampiran"}*/}
        {/*                    isLoading={isUploadingAttachment}*/}
        {/*                    onDrop={addAttachment}*/}
        {/*                />*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    </Col>*/}
        {/*</Row>*/}

    </>

}
