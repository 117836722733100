import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {
    Button,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tab,
    Tabs
} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

import {CSVLink} from "react-csv";

import {useHistory, useLocation, useParams} from "react-router-dom";

import Palette from "../../../../util/Palette";

import EditorPageWrapper from "../EditorPageWrapper";

import 'moment/locale/id'
import CustomDropzone from "../../../reusable/CustomDropzone";

import Event, {PAYMENT_TYPE, PUBLISH_STATUS} from "../../../../models/Event"
import CustomButton from "../../../reusable/CustomButton";
import MiniTooltip from "../../../reusable/MiniTooltip";

import Session from "../../../../models/Session";
import {FaEye, FaUserFriends, FaFileDownload, FaUserPlus, FaUsers} from "react-icons/all";
import MobTable from "../../../reusable/Table/MobTable"
import moment from "moment"
import RegistrantInspectorDialog from "./specifics/ReigstrantInspectorDialog"
import ApiRequest from "../../../../util/ApiRequest"
import User from "../../../../models/User"
import Registration from "../../../../models/Registration"
import RegistrantRejectionDialog from "./specifics/RegistrantRejectionDialog"
import AlertBox from "../../../reusable/AlertBox/AlertBox"

import textFormatter from "../../../../util/textFormatter";

import _ from "lodash"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

import Class from "../../../../models/Class";
import InviteUserModal from "../../../reusable/InviteUserModal";
import EditUserModal from "../../../reusable/EditUserModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RegistrantNoteDialog from "./specifics/RegistrantNoteDialog"
import BroadcastReminderDialog from "./specifics/BroadcastReminderDialog"
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import FileUpload from "../../../reusable/FileUpload";
import QuestionModel from "../../../../models/Question";

import Papa from "papaparse"
import BatchInvitationModal from "../../../reusable/modals/BatchInvitationModal";
import {HiSpeakerphone} from "react-icons/hi";
import LagFreeTextEditor from "../../../reusable/LagFreeTextEditor";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MdEvent, MdSchedule} from "react-icons/md";
import MomentUtils from '@date-io/moment';
import Course from "../../../../models/Course";
import MemberCourse from "../../../../models/MemberCourse";
import Card from "react-bootstrap/Card";
import {Bar} from "react-chartjs-2";
import Dashboard from "../../../../models/Dashboard";

let classModel = new Class()

export default function UserManagement(props) {

    const {event_url} = useParams()
    let history = useHistory()

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeClass, setActiveClass] = useState({})
    const [registrationField, setRegistrationFields] = useState([])
    const [registrantsProcessed, setRegistrantsProcessed] = useState([])

    const [paymentType, setPaymentType] = useState("FREE")

    //Alert message
    let [errorMessage, setErrorMessage] = useState("")
    let [successMessage, setSuccessMessage] = useState("")

    //for the table
    const [registrants, setRegistrants] = useState([])
    const [requiredVisitorInformation, setRequiredVisitorInformation] = useState([])


    //For the note dialog
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState({})

    const [isInviting, setIsInviting] = useState(false)

    //Processed Info for the seperate tables
    const categorizedRegistrants = useMemo(() => {
        let waiting = registrants.filter(r => r.status === Registration.states.WAITING)
        let accepted = registrants.filter(r => r.status === Registration.states.ACCEPTED)
        let rejected = registrants.filter(r => r.status === Registration.states.REJECTED || r.status === Registration.states.CANCELLED)
        let pending = registrants.filter(r => r.status === Registration.states.PENDING)

        return {
            waiting,
            accepted,
            rejected,
            pending
        }

    }, [registrants])

    //for the registrant dialog
    const [activeRegistrant, setActiveRegistrant] = useState({})
    const [registrantInspectorOpen, setRegistrantInspectorOpen] = useState(false)

    //for the rejection reason dialog
    const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false)
    const [rejectedRegistrant, setRejectedRegistrant] = useState({})
    const [rejectionMessage, setRejectionMessage] = useState("")

    const [totalIncome, setTotalIncome] = useState(0)

    const [showUploadDialog, setShowUploadDialog] = useState(false)

    const [classStartDate, setClassStartDate] = useState(null);
    const [classStartTime, setClassStartTime] = useState(null);
    const [classEndTime, setClassEndTime] = useState(null);
    let [currentNote, setCurrentNote] = useState("")

    const [registrationGraph, setRegistrationGraph] = useState({})

    const courseModel = new Course();

    useEffect(() => {
        getActiveSession()
        getWeeklyRegistrationData()
        getClassInfo()
    }, [])

    const getActiveSession = async () => {
        try {
            const result = await courseModel.getById(event_url)

            console.log('activeSession', result)

            setActiveSession(result)
        } catch (e) {
            console.log(e)
        }
    }

    const columns = [
        {
            Header: "ID Registrasi",
            accessor: "id"
        },
        {
            Header: "Nama Lengkap",
            accessor: "member_full_name",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let full_name = rowInfo["member_full_name"]

                return <>
                    {full_name}
                </>
            }
        },
        {
            Header: "Surel",
            accessor: "member_email",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let email = rowInfo["member_email"]

                return <>
                    {email}
                </>
            }
        },
        // {
        //     Header: "Presensi",
        //     accessor: "is_present",
        //     Cell: (cellInfo) => {
        //         let rowInfo = cellInfo.row.values
        //         let isClaimed = rowInfo["is_present"]
        //         let id = rowInfo["id"]
        //
        //         return <>
        //             <Select
        //                 value={isClaimed}
        //                 onChange={(val) => {
        //                     updateAbsent(id, !isClaimed)
        //                 }}
        //             >
        //                 <MenuItem value={1}>Ya</MenuItem>
        //                 <MenuItem value={0}>Tidak</MenuItem>
        //             </Select>
        //             {/*{isClaimed ? "Ya" : "Tidak"}*/}
        //         </>
        //     }
        // },
        {
            Header: "Status Pembayaran",
            accessor: "status",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let value = cellInfo.value

                let statusString = "MENUNGGU PEMBAYARAN"

                //console.log(cellInfo)
                let color = "#000000"

                if (rowInfo.status === "ACCEPTED") {

                    color = "LimeGreen"
                    statusString = "DITERIMA"


                } else if (rowInfo.status === "REJECTED" || rowInfo.status === "CANCELLED") {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (rowInfo.status === "WAITING") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                } else if (rowInfo.status === "PENDING") {
                    color = "DarkOrange"
                    statusString = "DALAM ANTREAN"

                } else if (rowInfo.status === "EXPIRED") {
                    color = "Red"
                    statusString = "KEDALUWARSA"
                }
                return <p style={{color: color}}>{statusString}</p>
            }
        },
        // {
        //     Header: "Harga",
        //     accessor: "transaction",
        //     Cell: (cellInfo) => {
        //         let rowInfo = cellInfo.row.values
        //         let transaction = rowInfo["transaction"]
        //
        //         return <>
        //             {transaction.amount ? `Rp${textFormatter.moneyFormatter(transaction.amount)}` : "-"}
        //         </>
        //     }
        // },
        {
            Header: "Voucer",
            accessor: "voucher",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let voucher = rowInfo["voucher"]

                return <>
                    {!voucher ? '-' : voucher.amount ? `Rp${textFormatter.moneyFormatter(voucher.amount)}` : `${voucher.percentage}%`}
                </>
            }
        },
        {
            Header: "Catatan",
            accessor: "participant_note",
            Cell: (cellInfo) => {
                let participant = cellInfo.row.original
                let note = participant.participant_note ?? "-"
                if (note.length > 35) {
                    note = note.substr(0, 35) + "..."
                }
                return <>{note}</>
            }
        },
        {
            Header: "Kemajuan",
            accessor: "progress",
            Cell: (cellInfo) => {
                let participant = cellInfo.row.original
                let progress = participant.progress
                let totalCount = activeClass.subChaptersCount

                return <>{progress}/{totalCount}</>
            }
        },
        {
            Header: "Opsi",
            accessor: "confirmed_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let originalRowInfo = cellInfo.row.original
                return <div style={{display: "flex", flexDirection: "row"}}>
                    <CustomButton
                        style={{
                            marginRight: 10
                        }}
                        variant={"outlined"}
                        onClick={() => {
                            console.log('activeRegistrant', originalRowInfo)

                            setActiveRegistrant(originalRowInfo)
                            setRegistrantInspectorOpen(true)
                        }}
                    >
                        Info
                    </CustomButton>

                    {/*{*/}
                    {/*    rowInfo.status === "ACCEPTED" && rowInfo.confirmed_at === null ?*/}
                    {/*        <CustomButton variant={"outlined"} onClick={async () => {*/}
                    {/*            try {*/}
                    {/*                setIsLoadingBar(true)*/}

                    {/*                let result = await Registration.acceptParticipant(rowInfo.id)*/}
                    {/*                console.log(result)*/}
                    {/*                await getParticipants()*/}
                    {/*                setIsLoadingBar(false)*/}
                    {/*                alert("Sukses")*/}

                    {/*            } catch {*/}
                    {/*                alert("Terjadi Kesalahan")*/}
                    {/*                setIsLoadingBar(false)*/}

                    {/*                console.error()*/}
                    {/*            }*/}

                    {/*        }}>*/}
                    {/*            KONFIRMASI*/}
                    {/*        </CustomButton>*/}
                    {/*        :*/}
                    {/*        null*/}
                    {/*}*/}
                    {/*{*/}
                    <CustomButton
                        variant={"outlined"}
                        onClick={() => {
                            console.log('originalRowInfo', originalRowInfo)

                            setCurrentNote(originalRowInfo.participant_note)
                            setSelectedParticipant(originalRowInfo)
                            setShowNoteModal(true)
                        }}
                    >
                        Catatan
                    </CustomButton>
                    {/*}*/}

                </div>
            }
        },
    ]

    //Interaction information for MobTable
    const interactions = []

    const getParticipants = async () => {

        let result = await classModel.getClassParticipant(event_url)
        console.log(result)

        let totalIncome = 0;

        for (let r of result) {

            if (r.status === "ACCEPTED") {
                totalIncome += r.transaction.amount
            }

        }

        let formattedRegistrants = result.map((obj, key) => {
            return {
                ...obj.member,
                ...obj,
                member: {...obj.member}
            }
        })

        console.log('formattedRegistrants', formattedRegistrants)

        setTotalIncome(totalIncome)
        setRegistrants(formattedRegistrants)

    }

    const getWeeklyRegistrationData = async () => {

        try {
            const dashboardModel = new Dashboard();
            const weeklyData = await dashboardModel.getWeeklyData(event_url);

            console.log("weekly_data", weeklyData)

            const registrationLabel = [];
            const registrationValue = [];
            const registrationColor = [];

            weeklyData.map((member, idx) => {
                registrationLabel.push(moment(member.raw_max).startOf("week").format('DD MMM YY') + "-" +moment(member.raw_max).endOf('week').format('DD MMM YY'))
                // registrationLabel.push(moment(member.raw_max).startOf("week").format('DD MMM YY') + "-" +moment(member.max_date).add(6, 'days').format('DD MMM YY'))
                registrationValue.push(member.registration_count);

                registrationColor.push('#4673c8')

                setRegistrationGraph({
                    label: registrationLabel,
                    value: registrationValue,
                    bgColor: registrationColor
                })
            })
        } catch (e) {
            console.log(e)
        }

    }

    const getClassInfo = async function () {
        const courseModel = new Course();

        const courses = await courseModel.getById(event_url)

        console.log('courses', courses)

        courses.waiting = 0;
        courses.accepted = 0;
        courses.rejected = 0;

        courses.acceptedMemberCourses = []
        courses.waitingMemberCourses = []
        courses.rejectedMemberCourses = []

        for (const key in courses.memberCourses) {
            const status = courses.memberCourses[key].status;

            let member = courses.memberCourses[key]?.member

            let objectToPush = {
                ...courses.memberCourses[key],
                member_full_name : member?.full_name,
                member_email : member?.email
            }

            courses.memberCourses[key].member_full_name = member?.full_name
            courses.memberCourses[key].member_email = member?.email

            if (status === 'WAITING') {
                courses.waitingMemberCourses.push(objectToPush)
                courses.waiting++;
            } else if (status === 'ACCEPTED') {
                courses.acceptedMemberCourses.push(objectToPush)
                courses.accepted++;
            } else if (status === 'REJECTED' || status === 'CANCELLED') {
                courses.rejectedMemberCourses.push(objectToPush)
                courses.rejected++;
            }
        }

        setActiveClass(courses)
        console.log('courses', courses)
        // const ClassInstance = new Class()
        // let cls = await ClassInstance.getByCode(event_url)
        // setActiveClass(cls)
    }

    const getNewsletterRegistrant = () => {
        let filteredRegistrant = activeClass.acceptedMemberCourses?.filter((obj) => {
            return obj.member.subscribe_newsletter
        })


        if (Array.isArray(filteredRegistrant)) {
            return filteredRegistrant.map((obj, key) => {
                return {
                    "Email": obj.member?.email,
                    "FullName": obj.member?.full_name
                }
            })
        }

        return [];
    }

    const renderContent = () => {

        //console.log(categorizedRegistrants.accepted)

        return <>
            <AlertBox error message={errorMessage} handleClose={() => {
                setErrorMessage("")
            }}/>
            <AlertBox message={successMessage} handleClose={() => {
                setSuccessMessage("")
            }}/>
            <Row style={{marginBottom: "1em"}}>
                <Col md={12}>
                    <Collapse
                        in={activeEvent.publish_status === PUBLISH_STATUS.DRAFT || activeEvent.publish_status === PUBLISH_STATUS.HIDDEN}>
                        <Alert
                            severity="warning"
                            action={
                                <></>
                            }
                            style={{
                                marginTop: "1em"
                            }}
                        >
                            Pendaftaran acara
                            mu {activeEvent.publish_status === PUBLISH_STATUS.DRAFT ? "belum" : "sedang tidak"} dibuka.
                            Aktifkan pendaftaran acaramu&nbsp;
                            <a
                                onClick={() => {
                                    history.push("/editor/detail/" + activeEvent.event_url)
                                }}
                                href={""}>disini</a>.
                        </Alert>
                    </Collapse>
                </Col>
            </Row>

            <Modal show={showNoteModal} onHide={() => {
                setShowNoteModal(false)
            }} size={"lg"}>
                <Modal.Header closeButton>
                    Catatan Admin untuk Peserta
                    ID {selectedParticipant.id} | {selectedParticipant.member?.full_name} | {selectedParticipant.member?.email}
                </Modal.Header>
                <Modal.Body>
                    <LagFreeTextEditor multiline row={4} value={currentNote} changeValue={(value) => {
                        setCurrentNote(value)
                    }}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setShowNoteModal(false)
                    }}>Tutup</Button>
                    <Button onClick={async () => {
                        try {
                            const memberCourseModel = new MemberCourse()

                            const result = await memberCourseModel.update(selectedParticipant.id, {
                                participant_note: currentNote
                            })

                            setShowNoteModal(false)
                            getClassInfo()
                            console.log(result)
                        } catch (e) {
                            console.log(e)
                        }
                    }}>Simpan</Button>
                </Modal.Footer>
            </Modal>

            <RegistrantInspectorDialog show={registrantInspectorOpen}
                                       registrant={activeRegistrant}
                                       handleClose={() => setRegistrantInspectorOpen(false)}
                                       onAccept={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let memberCourseModel = new MemberCourse()
                                               await memberCourseModel.acceptMemberCourse(activeRegistrant.id)
                                               await getClassInfo()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }
                                       }}
                                       onReject={async () => {
                                           setRejectionMessage(activeRegistrant.status_change_info)
                                           setRejectionDialogOpen(true)
                                           setRejectedRegistrant(activeRegistrant)
                                           setRegistrantInspectorOpen(false)
                                       }}
                                       onSendScenarioEmail={async (scenario) => {
                                           setIsLoadingBar(true)
                                           // try {
                                           //     let participationModel = new Participation()
                                           //     await participationModel.sendScenarioEmail(activeRegistrant.id, scenario)
                                           //     alert("Pengiriman email berhasil!")
                                           //     setRegistrantInspectorOpen(false)
                                           // } catch (e) {
                                           //     console.log(e)
                                           //     alert("Sebuah kesalahan terjadi. Periksa console.")
                                           // }
                                           setIsLoadingBar(false)
                                       }}
                                       activeSession={activeSession}
            />

            <RegistrantRejectionDialog show={rejectionDialogOpen}
                                       registrant={rejectedRegistrant}
                                       handleClose={() => setRejectionDialogOpen(false)}
                                       onReject={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let memberCourseModel = new MemberCourse()
                                               await memberCourseModel.rejectWithEmail(activeRegistrant.id, {
                                                   status: 'REJECTED',
                                                   status_change_info: rejectionMessage
                                               })
                                               getClassInfo()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                               setRejectionDialogOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }
                                       }}
                                       onRejectWithoutEmail={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let memberCourseModel = new MemberCourse()
                                               await memberCourseModel.update(activeRegistrant.id, {
                                                   status: 'REJECTED',
                                                   status_change_info: rejectionMessage
                                               })
                                               getClassInfo()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                               setRejectionDialogOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }
                                       }}
                                       onAbort={() => {
                                           setRejectionDialogOpen(false);
                                           setRejectedRegistrant({})
                                       }}
                                       rejectionMessage={rejectionMessage}
                                       onReasonChange={(reason) => {
                                           setRejectionMessage(reason)
                                       }}
                                       activeSession={activeSession}
            />
            <span>
                Peserta Sesi
            </span>

            <b style={{
                color: Palette.SECONDARY,
                fontSize: "2em",
                fontFamily: "Montserrat"
            }}>
                {activeEvent.name}
            </b>
            <div style={{marginBottom: "1em"}}>
                <span>
                    <FaUserFriends/>&nbsp; Peserta Sesi : {!isNaN(activeClass.waiting) && !isNaN(activeClass.accepted) ? activeClass.waiting + activeClass.accepted : '0'}
                </span>
                <div>
                    <br/>
                    <b>Ringkasan</b>
                    <br/>

                    <div style={{marginTop: 4}}>
                        <div style={{marginLeft: 5}}>Terdaftar
                            : {!isNaN(activeClass.waiting) && !isNaN(activeClass.accepted) ? activeClass.waiting + activeClass.accepted : '0'}</div>
                        <div style={{marginLeft: 5}}>Telah Bayar
                            : {!isNaN(activeClass.accepted) ? activeClass.accepted : '0'}</div>
                        {/*<div style={{marginLeft: 5}}>Pemasukan :*/}
                        {/*    Rp{textFormatter.moneyFormatter(totalIncome)}</div>*/}
                    </div>

                </div>
            </div>

            <Row>
                <Col md={12} style={{marginBottom: 50}}>
                    <Card style={{
                        backgroundColor: 'white',
                        height: "100%",
                        marginTop: "2em",
                        paddingBottom: 20
                    }}
                    >
                        <Card.Body className="pb-0">
                            <div style={{marginBottom: 20}}>
                                Pendaftar per Minggu
                            </div>

                            <Bar
                                getElementAtEvent={(elem) => {
                                    const clickedIdx = elem[0]._datasetIndex;
                                    let date = new moment(elem[0]._model.label, "DD-MMM-YYYY").format('YYYY-MM-DD')
                                    console.log(date)

                                    if (clickedIdx === 0) {
                                        // getFilteredUserByRegisterDate(date)
                                    } else if (clickedIdx === 1) {
                                        // getFilteredUserBySinaraRegisterDate(date)
                                    }
                                }}
                                data={{
                                    labels: registrationGraph.label,
                                    datasets: [
                                        {
                                            label: 'Registrasi KDM',
                                            backgroundColor: registrationGraph.bgColor,
                                            borderColor: 'rgba(255,99,132,1)',
                                            borderWidth: 1,
                                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                            hoverBorderColor: 'rgba(255,99,132,1)',
                                            data: registrationGraph.value
                                        }
                                    ]
                                }}
                                height={"100px"}
                                options={{
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: 'white'
                                        }
                                    },
                                    scales: {
                                        xAxes: [
                                            {
                                                gridLines: {
                                                    zeroLineColor: "rgba(0,0,0,1)",
                                                    color: "rgba(255,255,255,0.0)",
                                                },
                                                ticks: {
                                                    fontColor: "black"
                                                },
                                            }
                                        ],
                                        yAxes: [
                                            {
                                                gridLines: {
                                                    zeroLineColor: "rgba(0,0,0,1)",
                                                    color: "rgba(255,255,255,0.1)",
                                                },
                                                ticks: {
                                                    suggestedMin: 0,
                                                    beginAtZero: true,
                                                    fontColor: "black"
                                                },
                                            }
                                        ]
                                    },
                                    legend: {
                                        display: false
                                    },
                                    tooltips: {
                                        callbacks: {
                                            label: function (tooltipItem, obj) {
                                                let index = tooltipItem.datasetIndex
                                                let label = obj.datasets[index].label
                                                return label + ": " + tooltipItem.yLabel

                                            }
                                        }
                                    }
                                }}
                            />
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            <Row
                style={{
                    marginBottom: "3em",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                {Array.isArray(activeClass.acceptedMemberCourses) &&
                <CSVLink
                    style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                    separator={";"}
                    data={
                        activeClass.acceptedMemberCourses?.map((obj, key) => {
                            return {
                                timestamp: new moment(obj.accepted_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Nama Lengkap": obj.member?.full_name,
                                "Organisasi": obj.member?.organization,
                                "Surel": obj.member?.email,
                                "Nomor Ponsel": obj.member?.phone_num ? `${obj.member?.phone_num}` : "",
                                "Topik Pelatihan": activeClass.title,
                                // "Sumber Informasi": obj.source,
                                "Kota": obj.member?.city,
                                "Nawala": obj.member?.subscribe_newsletter,
                                "Bukti Bayar": "",
                                "Kuitansi": "",
                                "#Daftar": obj.id,
                                "Tanggal Daftar": new moment(obj.registered_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Biaya": obj.price ? obj.price : 0,
                            }
                        })
                    }
                    filename={`daftar-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Semua Data&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>
                }

                <CSVLink
                    style={{marginRight: "1em", color: 'white', marginBottom: 10}}
                    separator={";"}
                    data={getNewsletterRegistrant()}
                    filename={`email-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Unduh Data Untuk Email&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white", marginBottom: 10
                        }}
                        variant={"primary"}
                        onClick={() => {
                            setIsInviting(true)
                        }}
                    >
                        Undang Peserta&nbsp;&nbsp;<FaUserPlus/>
                    </Button>

                </div>


                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white", marginBottom: 10
                        }}
                        variant={"primary"}
                        onClick={() => {
                            setShowUploadDialog(true)
                        }}
                    >
                        Undang Banyak Peserta&nbsp;&nbsp;<FaUsers/>
                    </Button>

                </div>

            </Row>


            {
                activeClass.memberCourses?.length > 0 ?
                    <>
                        <Tabs defaultActiveKey="all">
                            <Tab
                                eventKey="all"
                                title={
                                    "Semua"
                                }
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable interactions={null} columns={columns} data={activeClass.memberCourses}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="accepted"
                                title={"Diterima"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              interactions={null}
                                              data={activeClass.acceptedMemberCourses ? activeClass.acceptedMemberCourses : []}
                                    />
                                </div>
                            </Tab>
                            <Tab
                                eventKey="waiting"
                                title={"Menunggu Pembayaran"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              interactions={null}
                                              data={activeClass.waitingMemberCourses ? activeClass.waitingMemberCourses : []}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="rejected"
                                title={"Ditolak"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns}
                                              interactions={null}
                                              data={activeClass.rejectedWaitingCourses ? activeClass.rejectedWaitingCourses : []}/>
                                </div>
                            </Tab>
                        </Tabs>

                    </>
                    :
                    <>
                        Data Pendaftaran tidak ditemukan
                    </>
            }

        </>
    }


    return (
        <>
            <InviteUserModal
                isOpen={isInviting}
                privateClass={true}
                onClose={() => {
                    setIsInviting(false)
                    getClassInfo()
                }}
                eventUrl={event_url}
            />
            <BatchInvitationModal
                eventCode={event_url}
                privateClass={true}
                open={showUploadDialog}
                onClose={(refresh) => {
                    if (refresh) {
                        getClassInfo()
                    }
                    setShowUploadDialog(false)
                }}
            />
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"participation"}
                privateClass={true}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
