import {useHistory, useParams, useLocation} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Class from "../../models/Class";
import Course from "../../models/Course";
import {
    FaEnvelope,
    FaTimes,
    FaPen,
    FaMedal,
    FaUserFriends,
    FaChevronLeft,
    FaBars,
    FaCog,
    FaUser,
    FaTag,
    FaSignOutAlt, FaHome,
    MdApps,
    FaClipboardList,
    FaUserEdit,
    FaKey,
    MdDashboard,
    FaChalkboardTeacher, FaTshirt, FaAlignJustify,
    FaEnvelopeSquare, GrDocument
} from "react-icons/all";
import Divider from "@material-ui/core/Divider";
import Col from "react-bootstrap/Col";

import Event from "../../models/Event"

import Palette from "../../util/Palette";
import {Row, Spinner} from "react-bootstrap";
import Dropzone from "react-dropzone";
import IconButton from "@material-ui/core/IconButton";

import {useCookies} from "react-cookie";
import textFormatter from "../../util/textFormatter";
import GlobalData from "../../util/GlobalData";
import ScrollBar from "react-scrollbars-custom";
import ImageThumbnail from "./ImageThumbnail";

import {
    isMobile
} from "react-device-detect";
import User from "../../models/User";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomButton from "./CustomButton";
import {FaSave, FaUserCheck, FaDesktop, FaUserTie, FaMoneyCheck, FaFileVideo,} from "react-icons/fa";
import Admin from "../../models/Admin"
import LagFreeTextEditor from "./LagFreeTextEditor";
import {MdFeedback, MdOndemandVideo, MdQuestionAnswer} from "react-icons/md";
import {HiSpeakerphone} from "react-icons/hi";
import {RiFileList2Fill, RiFileWarningFill, RiQuestionnaireFill, RiQuestionnaireLine} from "react-icons/ri";
import {FiUserCheck} from "react-icons/fi";
import {AiFillNotification} from "react-icons/ai";
import Instructor from "../../models/Instructor";

const adminModel = new Admin();

export default function CustomDrawer(props) {

    let history = useHistory()
    let location = useLocation();

    const anchor = "left"

    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [events, setEvents] = useState([])
    const [activeEvent, setActiveEvent] = useState({})

    const {event_url} = useParams()
    const [activeEventURL, setActiveEventURL] = useState(event_url)

    const [isEdit, setEdit] = useState(false);
    const [user, setUser] = useState({});
    const [tempUser, setTempUser] = useState({});

    const [errorMsg, setErrorMsg] = useState('');

    const [passwordEditErrorMsg, setPasswordEditErrorMsg] = useState("")

    const [isEditingPassword, setIsEditingPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [className, setClassName] = useState('');
    const [menu, setMenu] = useState([])
    // console.log("active tab " + props.activeTab)

    useEffect(() => {
        // getAllEvents()
        // updateActiveEvent()
        getUserData()

        if (!props.notFound) getClassByCode()

        //console.log('props', props)
    }, [activeEventURL, GlobalData])
    //  const menuData = JSON.parse(user.menu);

    const getClassByCode = async () => {
        const classModel = new Class();
        const courseModel = new Course();

        if (activeEventURL) {
            if (props.privateClass) {

                const result = await courseModel.getById(activeEventURL);

                setClassName(result.title)
            } else {
                const result = await classModel.getByCode(activeEventURL);
                setClassName(result.name)
            }

        }

    }

    const getUserData = async () => {
            let userModel = new User()
            let instructorModel = new Instructor();
            let user;

            try {
                if (cookie.userType === 'admin' || cookie.userType === 'superadmin') {
                    user = await userModel.tokenLogin()
                } else {
                    user = await instructorModel.tokenLogin()
                }

                if (user.menu) {
                    setMenu(JSON.parse(user.menu))
                }

                setUser(user)
            } catch (e) {
                console.log('token login e', e)
            }

        // setUser(user)
        // console.log(user)
    }


    const updateActiveEvent = () => {

        console.log("active event code", activeEventURL, events)

        if (activeEventURL) {

            let event = events.find((obj) => {
                return obj.event_url === activeEventURL
            })

            if (event) {
                console.log("haoihoihhoi")
                setActiveEvent(event)
            }
        }
    }

    const updateProfile = async () => {
        if (!tempUser.email || !tempUser.name) setErrorMsg('Mohon mengisi formulir dengan lengkap')
        else {
            try {
                const result = await adminModel.updateMyProfile({
                    email: tempUser.email,
                    name: tempUser.name
                });

                if (result.affectedRows === 1) {
                    setEdit(false)
                    setUser(tempUser);
                    alert('Profil berhasil diperbarui!');
                }
            } catch (e) {
                if (e.msg) {
                    if (e.msg.code === 'ER_DUP_ENTRY') {
                        setErrorMsg('Surel sudah pernah terdaftar di akun lain sebelumnya');
                    } else {
                        setErrorMsg('Terjadi kesalahan');
                    }
                } else {
                    setErrorMsg('Terjadi kesalahan');
                }
            }
        }
    }

    const updatePassword = async () => {

        if (oldPassword.length === 0) {
            return setPasswordEditErrorMsg("Harap isi kata sandi lama")
        }

        if (newPassword.length === 0) {
            return setPasswordEditErrorMsg("Harap isi kata sandi baru")
        }

        if (newPasswordConfirmation.length === 0) {
            return setPasswordEditErrorMsg("Harap isi konfirmasi kata sandi")
        }

        if (newPasswordConfirmation !== newPassword) {
            return setPasswordEditErrorMsg("Konfirmasi kata sandi salah")
        }

        try {
            const result = await adminModel.updatePassword({
                oldPassword: oldPassword,
                newPassword: newPassword
            });

            if (result.affectedRows === 1) {
                setIsEditingPassword(false)
                alert('Password Berhasil Diperbaharui!');
            }
        } catch (e) {
            if (e.code) {

                if (e.code === "INVALID_PASSWORD") {
                    setPasswordEditErrorMsg('Kata sandi lama salah');
                } else {
                    setPasswordEditErrorMsg('Terjadi kesalahan');
                }

            } else {
                setPasswordEditErrorMsg('Terjadi kesalahan');
            }
        }

    }

    const renderPasswordDialog = () => {
        return <Dialog
            open={isEditingPassword}
            maxWidth="xs"
            fullWidth={true}
        >
            <>
                <DialogTitle onClose={() => setIsEditingPassword(false)}>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Ganti Password
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <Collapse
                        in={passwordEditErrorMsg.length > 0}
                        style={{marginBottom: passwordEditErrorMsg.length > 0 ? '1.5em' : '0em'}}
                    >
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setPasswordEditErrorMsg("");
                                    }}
                                >
                                    <FaTimes fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {passwordEditErrorMsg}
                        </Alert>
                    </Collapse>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Kata sandi lama'}
                                changeValue={value => setOldPassword(value)}
                                value={oldPassword}
                                type={'password'}
                                placeholder={"Kata sandi lama"}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: 20
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Kata sandi baru'}
                                changeValue={value => setNewPassword(value)}
                                value={newPassword}
                                type={'password'}
                                placeholder={"Kata sandi baru"}/>
                        </Col>
                    </Row>
                    {/*<Row style={{*/}
                    {/*    paddingInlineStart: "2%",*/}
                    {/*    paddingInlineEnd: "2%",*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*    <Col md={12} sm={12} style={{*/}
                    {/*        fontSize: "1.2em",*/}
                    {/*        display: "flex",*/}
                    {/*        alignItems: "flex-end"*/}
                    {/*    }}>*/}
                    {/*        <TextField*/}
                    {/*            // onBlur={()=>editEventState("nama", name)}*/}
                    {/*            onChange={evt => setNewPassword(evt.target.value)}*/}
                    {/*            style={{*/}
                    {/*                marginLeft: "0.5em",*/}
                    {/*                style: {*/}
                    {/*                    fontSize: "1em",*/}
                    {/*                    fontFamily: "Poppins",*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            InputProps={{*/}
                    {/*                style: {*/}
                    {/*                    fontSize: "1em",*/}
                    {/*                    fontFamily: "Poppins",*/}
                    {/*                    cursor: "pointer"*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            value={newPassword}*/}
                    {/*            type={"password"}*/}
                    {/*            fullWidth placeholder={"Kata sandi baru"}/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        marginTop: 20,
                        fontFamily: 'OpenSans-SemiBold'
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Konfirmasi kata sandi baru'}
                                changeValue={value => setNewPasswordConfirmation(value)}
                                value={newPasswordConfirmation}
                                type={'password'}
                                placeholder={"Konfirmasi kata sandi baru"}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        paddingTop: '1em',
                        paddingBottom: '1em',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: "row",
                        marginTop: 50
                    }}
                    >
                        <CustomButton
                            style={{marginRight: 10, fontFamily: 'OpenSans-SemiBold', borderWidth: 0}}
                            onClick={() => {
                                setOldPassword("")
                                setNewPassword("")
                                setNewPasswordConfirmation("")
                                setIsEditingPassword(false)
                            }}
                            variant={"outlined"} color="primary">
                            Batal
                        </CustomButton>

                        <CustomButton
                            onClick={updatePassword}
                            style={{color: 'white', fontFamily: 'OpenSans-SemiBold'}}
                            variant={"contained"} color="primary">
                            <>Simpan&nbsp;&nbsp;<FaSave/></>
                        </CustomButton>

                    </Row>

                </DialogContent>
                {/*{renderModalFooter()}*/}
            </>
        </Dialog>
    }

    const renderEditProfileDialog = () => {
        return <Dialog
            open={isEdit}
            maxWidth="sm"
            fullWidth={true}
        >
            <>
                <DialogTitle onClose={() => this.onClose()}>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            color: Palette.PRIMARY,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            Sunting Profil
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>
                <DialogContent>
                    <Collapse
                        in={errorMsg.length > 0}
                        style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                    >
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setErrorMsg("");
                                    }}
                                >
                                    <FaTimes fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {errorMsg}
                        </Alert>
                    </Collapse>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Surel'}
                                changeValue={value => setTempUser({
                                    ...tempUser,
                                    email: value
                                })}
                                value={tempUser.email}
                                placeholder={"Surel"}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        paddingTop: '1em'
                    }}
                    >
                        <Col md={12}>
                            <LagFreeTextEditor
                                title={'Nama'}
                                changeValue={value => setTempUser({
                                    ...tempUser,
                                    name: value
                                })}
                                value={tempUser.name}
                                placeholder={"Nama"}/>
                        </Col>
                    </Row>

                    <Row style={{
                        paddingInlineStart: "2%",
                        paddingInlineEnd: "2%",
                        paddingTop: '1em',
                        paddingBottom: '1em',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: "row"
                    }}
                    >
                        <CustomButton
                            style={{marginRight: 10, fontFamily: 'OpenSans-SemiBold', borderWidth: 0}}
                            onClick={() => {
                                setEdit(false)
                                setTempUser({...user})
                            }}
                            variant={"outlined"} color="primary">
                            Batal
                        </CustomButton>

                        <CustomButton
                            style={{marginRight: 10, fontFamily: 'OpenSans-SemiBold'}}
                            onClick={() => {
                                setIsEditingPassword(true)
                            }}
                            variant={"outlined"} color="primary">
                            Ganti Password&nbsp;&nbsp;<FaKey/>
                        </CustomButton>

                        <CustomButton
                            onClick={updateProfile}
                            style={{color: 'white', fontFamily: 'OpenSans-SemiBold'}}
                            variant={"contained"} color="primary">
                            <>Simpan&nbsp;&nbsp;<FaSave/></>
                        </CustomButton>
                    </Row>
                </DialogContent>
                {/*{renderModalFooter()}*/}
            </>
        </Dialog>

    }

    return (
        <>

            {renderEditProfileDialog()}
            {renderPasswordDialog()}

            <Drawer
                PaperProps={{
                    style: {
                        zIndex: 500,
                    }
                }}

                variant={isMobile ? "temporary" : "persistent"}
                anchor={anchor} open={props.isOpen} onClose={() => props.onClose()}>

                <ScrollBar
                    style={{
                        width: 300,
                        display: "flex",
                        flexDirection: "column",
                        marginTop: isMobile ? "0" : "6.5em",
                        overflowY: "auto",
                        paddingLeft: 200
                    }}
                    trackYProps={{
                        renderer: props => {
                            const {elementRef, ...restProps} = props;
                            return <span {...restProps} style={{...restProps.style, backgroundColor: "white"}}
                                         ref={elementRef} className="trackY"/>;
                        }
                    }}
                    smoothScrolling={true}
                >
                    <IconButton
                        style={{
                            marginTop: "1em",
                            display: isMobile ? "inline" : "none"
                        }}
                        onClick={() => {
                            console.log("hoi")
                            props.setIsSideBarOpen(false)
                        }}
                        onMouseDown={e => e.preventDefault()}
                    >
                        <FaBars style={{
                            fontSize: "1em"
                        }}/>
                    </IconButton>
                    {
                        activeEventURL && !props.privateClass && !props.notFound ?
                            <>

                                <div style={{
                                    height: "1em"
                                }}/>

                                <div style={{
                                    fontSize: "1.1em",
                                    fontFamily: "OpenSans-Bold",
                                    // textAlign: "center",
                                    width: "100%",
                                    marginLeft: 10,
                                }}>
                                    {className}
                                </div>

                                <List>

                                    <ListItem
                                        containerProps={{
                                            style: {
                                                marginTop: "4em",
                                                marginBottom: 50,
                                                backgroundColor: "green"
                                            }
                                        }}
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        onClick={() => {
                                            if (props.privateClass) {
                                                history.push("/editor/privateClassDetail/" + activeEventURL)
                                            } else {
                                                history.push("/editor/classDetail/" + activeEventURL)
                                            }
                                        }}
                                        selected={props.activeTab === "detail"}
                                        button>
                                        <ListItemIcon><FaPen
                                            color={props.activeTab === "detail" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "detail" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "detail" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Kelas</span>
                                    </ListItem>
                                    <ListItem
                                        containerProps={{
                                            style: {
                                                marginTop: "4em",
                                                marginBottom: 50,
                                                backgroundColor: "green"
                                            }
                                        }}
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        onClick={() => {
                                            if (props.privateClass) {
                                                history.push("/editor/privateClassInstructors/" + activeEventURL)
                                            } else {
                                                history.push("/editor/instructor/" + activeEventURL)
                                            }
                                        }}
                                        selected={props.activeTab === "instructor"}
                                        button>
                                        <ListItemIcon><FaChalkboardTeacher
                                            color={props.activeTab === "instructor" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "instructor" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "instructor" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Widyaiswara</span>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            history.push("/editor/visitors/" + activeEventURL)
                                        }}
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        selected={props.activeTab === "user-management"}
                                        button>

                                        <ListItemIcon><FaUserFriends
                                            color={props.activeTab === "user-management" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "user-management" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "user-management" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Peserta</span>
                                    </ListItem>
                                    <ListItem
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        selected={props.activeTab === "email-management"}
                                        button
                                        onClick={() => {
                                            history.push("/editor/email/" + activeEventURL)
                                        }}>
                                        <ListItemIcon><FaEnvelopeSquare
                                            color={props.activeTab === "email-management" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "email-management" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "email-management" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Surel</span>
                                    </ListItem>

                                    <ListItem
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        selected={props.activeTab === "question"}
                                        button
                                        onClick={() => {
                                            history.push("/editor/question/" + activeEventURL)
                                        }}>
                                        <ListItemIcon><MdQuestionAnswer
                                            color={props.activeTab === "question" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "question" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "question" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Pertanyaan</span>
                                    </ListItem>

                                    <ListItem
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        selected={props.activeTab === "quiz"}
                                        button
                                        onClick={() => {
                                            history.push("/editor/quiz/" + activeEventURL)
                                        }}>
                                        <ListItemIcon><FaClipboardList
                                            color={props.activeTab === "quiz" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "quiz" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "quiz" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Tes Akhir</span>
                                    </ListItem>

                                    <ListItem
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        selected={props.activeTab === "certificate"}
                                        button
                                        onClick={() => {
                                            history.push("/editor/certificate/" + activeEventURL)
                                        }}>
                                        <ListItemIcon><FaMedal
                                            color={props.activeTab === "certificate" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "certificate" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "certificate" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Sertifikat</span>
                                    </ListItem>

                                    <ListItem
                                        style={{
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                        }}
                                        button
                                        selected={props.activeTab === "main-feedback"}
                                        onClick={() => {
                                            history.push("/editor/feedback/" + activeEventURL)
                                        }}>
                                        <ListItemIcon><MdFeedback
                                            color={props.activeTab === "main-feedback" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                        <span style={{
                                            color: props.activeTab === "main-feedback" ? Palette.PRIMARY : null,
                                            fontFamily: props.activeTab === "main-feedback" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                        }}>Umpan Balik</span>
                                    </ListItem>

                                </List>

                                {/*<div style={{marginLeft: 10, marginRight: 10, marginBottom: 15}}>*/}
                                {/*    <CustomButton*/}
                                {/*        style={{color: 'white', width: '100%', fontFamily: 'OpenSans-SemiBold', fontSize: '.8em'}}*/}
                                {/*        variant={"contained"} color="primary">*/}
                                {/*        <>Broadcast Pengingat Kelas&nbsp;&nbsp;<HiSpeakerphone/></>*/}
                                {/*    </CustomButton>*/}
                                {/*</div>*/}
                                <Divider/>
                            </>
                            :
                            activeEventURL && props.privateClass && !props.notFound ?
                                <>

                                    <div style={{
                                        height: "1em"
                                    }}/>

                                    <div style={{
                                        fontSize: "1.1em",
                                        fontFamily: "OpenSans-Bold",
                                        // textAlign: "center",
                                        width: "100%",
                                        marginLeft: 10,
                                    }}>
                                        {className}
                                    </div>

                                    <List>

                                        <ListItem
                                            containerProps={{
                                                style: {
                                                    marginTop: "4em",
                                                    marginBottom: 50,
                                                    backgroundColor: "green"
                                                }
                                            }}
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            onClick={() => {
                                                if (props.privateClass) {
                                                    history.push("/editor/privateClassDetail/" + activeEventURL)
                                                } else {
                                                    history.push("/editor/classDetail/" + activeEventURL)
                                                }
                                            }}
                                            selected={props.activeTab === "detail"}
                                            button>
                                            <ListItemIcon><FaPen
                                                color={props.activeTab === "detail" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "detail" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "detail" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Kelas</span>
                                        </ListItem>

                                        <ListItem
                                            containerProps={{
                                                style: {
                                                    marginTop: "4em",
                                                    marginBottom: 50,
                                                    backgroundColor: "green"
                                                }
                                            }}
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            onClick={() => {
                                                if (props.privateClass) {
                                                    history.push("/editor/privateClassInstructors/" + activeEventURL)
                                                } else {
                                                    history.push("/editor/instructor/" + activeEventURL)
                                                }
                                            }}
                                            selected={props.activeTab === "instructor"}
                                            button>
                                            <ListItemIcon><FaChalkboardTeacher
                                                color={props.activeTab === "instructor" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "instructor" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "instructor" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Widyaiswara</span>
                                        </ListItem>

                                        <ListItem
                                            containerProps={{
                                                style: {
                                                    marginTop: "4em",
                                                    marginBottom: 50,
                                                    backgroundColor: "green"
                                                }
                                            }}
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            onClick={() => {
                                                history.push("/editor/syllabus/" + activeEventURL)
                                            }}
                                            selected={props.activeTab === "syllabus"}
                                            button>
                                            <ListItemIcon><RiFileList2Fill
                                                color={props.activeTab === "syllabus" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "syllabus" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "syllabus" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Bab</span>
                                        </ListItem>


                                        <ListItem
                                            containerProps={{
                                                style: {
                                                    marginTop: "4em",
                                                    marginBottom: 50,
                                                    backgroundColor: "green"
                                                }
                                            }}
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            onClick={() => {
                                                history.push("/editor/privateClassVisitors/" + activeEventURL)
                                            }}
                                            selected={props.activeTab === "participation"}
                                            button>
                                            <ListItemIcon><FaUserFriends
                                                style={{color: props.activeTab === "participation" ? Palette.SKY_BLUE : null,}}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "participation" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "participation" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Peserta</span>
                                        </ListItem>

                                        <ListItem
                                            containerProps={{
                                                style: {
                                                    marginTop: "4em",
                                                    marginBottom: 50,
                                                    backgroundColor: "green"
                                                }
                                            }}
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            onClick={() => {
                                                history.push("/editor/privateClassFeedback/" + activeEventURL)
                                            }}
                                            selected={props.activeTab === "main-feedback"}
                                            button>
                                            <ListItemIcon><MdFeedback
                                                style={{color: props.activeTab === "main-feedback" ? Palette.SKY_BLUE : null,}}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "main-feedback" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "main-feedback" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Umpan Balik</span>
                                        </ListItem>

                                        <ListItem
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            selected={props.activeTab === "certificate"}
                                            button
                                            onClick={() => {
                                                history.push("/editor/private-certificate/" + activeEventURL)
                                            }}>
                                            <ListItemIcon><FaMedal style={{color: props.activeTab === "certificate" ? Palette.SKY_BLUE : null,}}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "certificate" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "certificate" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Sertifikat</span>
                                        </ListItem>

                                        <ListItem
                                            style={{
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                            }}
                                            selected={props.activeTab === "email-management"}
                                            button
                                            onClick={() => {
                                                history.push("/editor/private-email/" + activeEventURL)
                                            }}>
                                            <ListItemIcon><FaEnvelopeSquare
                                                color={props.activeTab === "email-management" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                                            <span style={{
                                                color: props.activeTab === "email-management" ? Palette.PRIMARY : null,
                                                fontFamily: props.activeTab === "email-management" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                                            }}>Surel</span>
                                        </ListItem>
                                    </List>
                                </> : null
                    }

                    <div style={{height: "1em"}}/>
                    <div style={{
                        marginTop: 10,
                        marginLeft: 15,
                        fontFamily: "OpenSans-Bold"
                    }}>
                        Menu Utama
                    </div>

                    <List>
                        <ListItem
                            style={{display: cookie.userType === 'admin' || cookie.userType === 'superadmin' ? null : 'none'}}
                            onClick={() => {
                                history.push("/dashboard")
                            }}
                            button>
                            <ListItemIcon>
                                <MdDashboard
                                    style={{color: props.activeTab === "dashboard" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "dashboard" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "dashboard" ? Palette.SKY_BLUE : null
                            }}>Dasbor</span>
                        </ListItem>
                        <ListItem
                            style={{display: menu.includes('home') ? null : 'none'}}
                            onClick={() => {
                                history.push("/editor")
                            }}
                            button>
                            <ListItemIcon>
                                <FaHome
                                    style={{color: props.activeTab === "home" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "home" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "home" ? Palette.SKY_BLUE : null
                            }}>Kelas</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('private-class') ? null : 'none'}}
                            onClick={() => {
                                history.push("/private-class-editor")
                            }}
                            button>
                            <ListItemIcon>
                                <MdOndemandVideo
                                    style={{color: props.activeTab === "private-class" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "private-class" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "private-class" ? Palette.SKY_BLUE : null
                            }}>Kelas Daring Mandiri</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('instructor') ? null : 'none'}}
                            onClick={() => {
                                history.push("/editor/instructor")
                            }}
                            button>
                            <ListItemIcon>
                                <FaChalkboardTeacher
                                    style={{color: props.activeTab === "all-instructor" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "all-instructor" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "all-instructor" ? Palette.SKY_BLUE : null
                            }}>Widyaiswara</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('transaction') ? null : 'none'}}
                            onClick={() => {
                                history.push("/transaction")
                            }}
                            button>
                            <ListItemIcon>
                                <FaMoneyCheck
                                    style={{color: props.activeTab === "transaction" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "transaction" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "transaction" ? Palette.SKY_BLUE : null
                            }}>Transaksi</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('main-feedback') ? null : 'none'}}
                            onClick={() => {
                                history.push("/feedback")
                            }}
                            button>
                            <ListItemIcon><MdFeedback
                                color={props.activeTab === "main-feedback" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "main-feedback" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "main-feedback" ? Palette.SKY_BLUE : null
                            }}>Umpan Balik</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('users') ? null : 'none'}}
                            onClick={() => {
                                history.push("/users")
                            }}
                            button>
                            <ListItemIcon><FaUser
                                style={{color: props.activeTab === "users" ? Palette.SKY_BLUE : null,}}/></ListItemIcon>
                            <span style={{
                                color: props.activeTab === "users" ? Palette.SKY_BLUE : null,
                                fontFamily: props.activeTab === "users" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                            }}>Pengguna</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('participation') ? null : 'none'}}
                            onClick={() => {
                                history.push("/participation")
                            }}
                            button>
                            <ListItemIcon><FaUserFriends
                                style={{color: props.activeTab === "participation" ? Palette.SKY_BLUE : null,}}/></ListItemIcon>
                            <span style={{
                                color: props.activeTab === "participation" ? Palette.SKY_BLUE : null,
                                fontFamily: props.activeTab === "participation" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                            }}>Peserta</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('voucher') ? null : 'none'}}
                            button
                            selected={props.activeTab === "voucher"}
                            onClick={() => {
                                history.push("/editor/voucher")
                            }}>
                            <ListItemIcon><FaTag
                                color={props.activeTab === "voucher" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                            <span style={{
                                color: props.activeTab === "voucher" ? Palette.PRIMARY : null,
                                fontFamily: props.activeTab === "voucher" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                            }}>Voucer</span>
                        </ListItem>


                        <ListItem
                            style={{display: menu.includes('email') ? null : 'none'}}
                            button
                            selected={props.activeTab === "email"}
                            onClick={() => {
                                history.push("/editor/email")
                            }}>
                            <ListItemIcon><FaEnvelope
                                color={props.activeTab === "email" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                            <span style={{
                                color: props.activeTab === "email" ? Palette.PRIMARY : null,
                                fontFamily: props.activeTab === "email" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                            }}>Surel</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('liveStreaming') ? null : 'none'}}
                            button
                            selected={props.activeTab === "liveStreaming"}
                            onClick={() => {
                                history.push("/editor/live-streaming")
                            }}>
                            <ListItemIcon><FaFileVideo
                                color={props.activeTab === "liveStreaming" ? Palette.PRIMARY : 'grey'}/></ListItemIcon>
                            <span style={{
                                color: props.activeTab === "liveStreaming" ? Palette.PRIMARY : null,
                                fontFamily: props.activeTab === "liveStreaming" ? "OpenSans-SemiBold" : "OpenSans-Regular"
                            }}>Siaran Langsung</span>
                        </ListItem>

                        <ListItem
                            style={{
                                display: user.role === 'superadmin' ? null : "none"
                            }}
                            onClick={() => {
                                history.push("/admins")
                            }}
                            button>
                            <ListItemIcon>
                                <FaUserTie
                                    style={{color: props.activeTab === "admins" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "admins" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "admins" ? Palette.SKY_BLUE : null
                            }}>Admin</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('presence') ? null : 'none'}}
                            onClick={() => {
                                history.push("/presence")
                            }}
                            button>
                            <ListItemIcon>
                                <FaUserCheck
                                    style={{color: props.activeTab === "presence" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "presence" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "presence" ? Palette.SKY_BLUE : null
                            }}>Formulir Presensi</span>
                        </ListItem>
                        <ListItem
                            style={{display: menu.includes('complaint') ? null : 'none'}}
                            onClick={() => {
                                history.push("/complaint")
                            }}
                            button>
                            <ListItemIcon>
                                <MdDashboard
                                    style={{color: props.activeTab === "complaint" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "complaint" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "complaint" ? Palette.SKY_BLUE : null
                            }}>Aduan</span>
                        </ListItem>
                        <ListItem
                            style={{display: menu.includes('merchandise') ? null : 'none'}}
                            onClick={() => {
                                history.push("/merchandise")
                            }}
                            button>
                            <ListItemIcon>
                                <FaTshirt
                                    style={{color: props.activeTab === "merchandise" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "merchandise" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "merchandise" ? Palette.SKY_BLUE : null
                            }}>Kreasi</span>
                        </ListItem>
                        <ListItem
                            style={{display: menu.includes('loglist') ? null : 'none'}}
                            onClick={() => {
                                history.push("/loglist")
                            }}
                            button>
                            <ListItemIcon>
                                <FaAlignJustify
                                    style={{color: props.activeTab === "logs" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "logs" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "logs" ? Palette.SKY_BLUE : null
                            }}>Logs</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('help') ? null : 'none'}}
                            onClick={() => {
                                history.push("/help")
                            }}
                            button>
                            <ListItemIcon>
                                <RiQuestionnaireFill
                                    style={{color: props.activeTab === "help" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "help" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "help" ? Palette.SKY_BLUE : null
                            }}>Formulir Pertanyaan</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('formulir-dokumen') ? null : 'none'}}
                            onClick={() => {
                                history.push("/formulir-dokumen")
                            }}
                            button>
                            <ListItemIcon>
                                <GrDocument
                                    style={{color: props.activeTab === "document-form" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "document-form" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "document-form" ? Palette.SKY_BLUE : null
                            }}>Formulir Dokumen</span>
                        </ListItem>

                        <ListItem
                            style={{display: menu.includes('tampilan-antarmuka') ? null : 'none'}}
                            onClick={() => {
                                history.push("/tampilan-antarmuka")
                            }}
                            button>
                            <ListItemIcon>
                                <FaDesktop
                                    style={{color: props.activeTab === "ui" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "ui" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "ui" ? Palette.SKY_BLUE : null
                            }}>Tampilan Antarmuka</span>
                        </ListItem>

                        <ListItem
                            style={{display:menu.includes('notification') ? null : 'none'}}
                            onClick={() => {
                                history.push("/notification")
                            }}
                            button>
                            <ListItemIcon>
                                <AiFillNotification
                                    style={{color: props.activeTab === "notification" ? Palette.SKY_BLUE : null,}}
                                />
                            </ListItemIcon>
                            <span style={{
                                fontFamily: props.activeTab === "notification" ? 'OpenSans-SemiBold' : "OpenSans-Regular",
                                color: props.activeTab === "notification" ? Palette.SKY_BLUE : null
                            }}>Notifikasi</span>
                        </ListItem>

                        {/*<ListItem*/}
                        {/*    onClick={() => {*/}
                        {/*        history.push("/complaint")*/}
                        {/*    }}*/}
                        {/*    button>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <RiFileWarningFill*/}
                        {/*            style={{color: props.activeTab === "complaint" ? Palette.SKY_BLUE : null,}}*/}
                        {/*        />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <span style={{*/}
                        {/*        fontFamily: props.activeTab === "complaint" ? 'OpenSans-SemiBold' : "OpenSans-Regular",*/}
                        {/*        color: props.activeTab === "complaint" ? Palette.SKY_BLUE : null*/}
                        {/*    }}>Aduan</span>*/}
                        {/*</ListItem>*/}

                        {/*}<ListItem button>
                            <ListItemIcon><FaCog/></ListItemIcon>
                            <span style={{
                                fontFamily: "Montserrat"
                            }}>Pengaturan</span>
                        </ListItem>
                        {*/}


                    </List>


                    {/*<div style={{height : "8em"}}/>*/}

                    <div style={{width: '100%', backgroundColor: '#d9d9d9', height: 1}}/>

                    <div style={{fontFamily: 'OpenSans-Bold', marginTop: 15, marginLeft: 15}}>
                        Profil
                    </div>

                    <div style={{fontFamily: 'OpenSans-Light', marginTop: 15, marginLeft: 15}}>
                        Masuk Sebagai:
                    </div>

                    <div style={{fontFamily: 'OpenSans-Bold', marginTop: 5, marginLeft: 15}}>
                        {user.name} <span style={{
                        marginLeft: 3,
                        fontFamily: 'OpenSans-SemiBold',
                        backgroundColor: user.role === 'superadmin' ? Palette.PRIMARY : 'yellow',
                        color: user.role === 'superadmin' ? 'white' : 'black',
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 15,
                        fontSize: '.9em'
                    }}>{cookie.userType === 'instructor' ? 'Widyaiswara' : user.role === 'superadmin' ? 'Super Admin' : 'Admin'}</span>
                    </div>

                    <div style={{
                        fontFamily: 'OpenSans-Regular',
                        marginTop: 2,
                        marginLeft: 15,
                        color: 'grey',
                        fontSize: '.9em'
                    }}>
                        ({user.email})
                    </div>

                    <List>
                        <ListItem
                            style={{
                                display: cookie.userType === 'admin' || cookie.userType === 'superadmin' ? null : 'none'
                            }}
                            button onClick={() => {
                            setEdit(true);
                            setTempUser({...user})
                            setErrorMsg("")
                        }}>
                            <ListItemIcon><FaUserEdit/></ListItemIcon>
                            <span style={{
                                fontFamily: "OpenSans-Regular"
                            }}>Sunting Profil</span>
                        </ListItem>

                        <ListItem button onClick={() => {
                            removeCookie("token", {path: '/'})
                            delete GlobalData["token"]
                            window.location = "/"

                        }}>
                            <ListItemIcon><FaSignOutAlt/></ListItemIcon>
                            <span style={{
                                fontFamily: "OpenSans-Regular"
                            }}>Keluar</span>
                        </ListItem>
                    </List>

                    {/*<div style={{textAlign: 'right', marginRight: 15, marginTop: 5}}>*/}
                    {/*    <a href={'#'} style={{fontFamily: 'Poppins'}} onClick={() => {*/}
                    {/*        setEdit(true);*/}
                    {/*        setTempUser({...user})*/}
                    {/*    }}>*/}
                    {/*        Sunting Profil*/}
                    {/*    </a>*/}
                    {/*</div>*/}


                </ScrollBar>
            </Drawer>
        </>
    )
}
